import { FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { seriesColours } from "./constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export type XAxisType = {
  labels: string[];
};

export type YMultiAxisType = {
  values: number[];
  name: string;
  type: "y" | "y1";
};

interface TrainingInsightsMultiAxisLineChartProps {
  xAxis: XAxisType;
  yAxisSeries: YMultiAxisType[];
  title: string;
  isAthleteView?: boolean;
}

const TrainingInsightsMultiAxisLineChart: FC<
  TrainingInsightsMultiAxisLineChartProps
> = ({ xAxis, yAxisSeries, title, isAthleteView = false }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: !isAthleteView,
    animation: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    elements: {
      point: {
        radius: 4,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
    scales: {
      y: {
        suggestedMin: 0, // Ensure y-axis starts from 0
        label: "Reps",
        display: "auto",
      },
      y1: {
        type: "linear",
        display: "auto",
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const shortenedXAxisLabels = xAxis.labels.map((label) =>
    label.length > 10 ? `...${label.slice(-10)}` : label
  );

  const data = {
    labels: shortenedXAxisLabels,
    datasets: yAxisSeries.map((yAxis, index) => ({
      label: yAxis.name,
      data: yAxis.values,
      yAxisID: yAxis.type,
      fill: false,
      borderColor: seriesColours[yAxis.name].borderColor,
      backgroundColor: seriesColours[yAxis.name].backgroundColor,
    })),
  };

  const additionalProps = isAthleteView ? { height: 300 } : null;

  return <Line options={options as any} data={data} {...additionalProps} />;
};

export { TrainingInsightsMultiAxisLineChart };
