import { FC, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { TrainingInsightsContent } from "./TrainingInsightsContent";

interface TrainingInsightsProps {
  openSidebar: () => void;
}

const TrainingInsights: FC<TrainingInsightsProps> = ({ openSidebar }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("training-insights.title")}
        </Typography>
      </Box>
      <TrainingInsightsContent />
    </Box>
  );
};

export { TrainingInsights };
