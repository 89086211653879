import { FC, useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useTypedDispatch } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { WorkoutProgram, WorkoutProgramStatus, programsApi } from "shared/api";

interface LiveTrainingWorkoutProgramsSelectorProps {
  selectedUserId: number | null;
  setSelectedWorkoutProgramId: (workoutProgramId: number | null) => void;
  workoutPrograms?: WorkoutProgram[];
  setWorkoutPrograms: (workoutPrograms?: WorkoutProgram[]) => void;
}

const LiveTrainingWorkoutProgramsSelector: FC<
  LiveTrainingWorkoutProgramsSelectorProps
> = ({
  selectedUserId,
  setSelectedWorkoutProgramId,
  workoutPrograms,
  setWorkoutPrograms,
}) => {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();

  const getWorkoutProrams = programsApi.endpoints.getWorkoutPrograms.initiate({
    userId: selectedUserId!!,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");

  async function callGetWorkoutPrograms() {
    try {
      const response = await dispatch(getWorkoutProrams);
      setWorkoutPrograms(
        response.data?.filter(
          (a) => a.workoutProgramStatus !== WorkoutProgramStatus.DRAFT
        ) ?? undefined
      );
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUserId !== null) {
      setIsLoading(true);
      setWorkoutPrograms(undefined);
      setFetchErrorMessage("");
      callGetWorkoutPrograms();
    }
  }, [selectedUserId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {isLoading || !selectedUserId ? (
        <Box />
      ) : (
        <Box sx={{ mt: "10px" }}>
          {workoutPrograms && workoutPrograms.length > 0 ? (
            <FormControl
              sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
              variant="outlined"
            >
              <InputLabel>Select Program</InputLabel>
              <Select
                label="Select Program"
                onChange={(e: { target: { value: any } }) =>
                  setSelectedWorkoutProgramId(e.target.value)
                }
                defaultValue=""
              >
                {workoutPrograms?.map((workoutProgram) => (
                  <MenuItem value={workoutProgram.id} key={workoutProgram.id}>
                    {workoutProgram.name} -{" "}
                    {dayjs(workoutProgram.startDate).format("DD/MM/YYYY")} -{" "}
                    {dayjs(workoutProgram.endDate).format("DD/MM/YYYY")} -{" "}
                    {workoutProgram.workoutProgramStatus}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box sx={{ mt: "10px" }}>
              <Typography sx={sharedStyles.headings.dataGrid}>
                {t("live-training.no-workout-programs")}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export { LiveTrainingWorkoutProgramsSelector };
