import { Skeleton, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTypedSelector } from "shared/stores";

const UserProfileSkeleton = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        height: "100vh",
        position: "fixed",
        top: { xs: "68px", md: "0px" },
        right: "0px",
      }}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          height: "150px",
          width: "100%",
          mb: "12px",
          alignItems: "center",
        }}
      />
      <Skeleton
        variant="circular"
        width={150}
        height={150}
        sx={{
          mb: "28px",
          ml: "40%",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "300px", width: "100%", mb: "12px" }}
      />
    </Box>
  );
};
export { UserProfileSkeleton };
