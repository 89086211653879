import { FC, useState, useEffect } from "react";
import {
  Box,
  Tab,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { LiveTrainingContent } from "pages/LiveTraining/LiveTrainingContent";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "shared/helpers";
import {
  useGetUserProfileItemQuery,
  UserProfileItemResponse,
  useUpdateUserProfileMutation,
  useGetUsersQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { UserProfileProgramsContainer } from "pages/Programs/AdminPrograms/ui/UserProfileProgramsContainer";
import { ClientInsights } from "pages/Users/ClientInsights";
import { ClientFeedbackSummary } from "pages/Users/ClientFeedbackSummary";
import { UserNotesTable } from "pages/UserNotes";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "shared/routers";

interface UpdateUserFormProps {
  currentUserData?: UserProfileItemResponse;
  toggleSuccessfulUpdateModal: () => void;
  isAthlete?: boolean;
}

interface SubmitValues {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  myFitnessPalUsername?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  userRole?: string;
  userStatus?: string;
  userCategory?: string;
  assignedFitnessProfessionalId?: number;
  userLiftingProfileType?: string;
  swapExercisesEnabled?: boolean;
}

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Min 2").required("This field is required"),
  lastName: Yup.string().min(2, "Min 2").required("This field is required"),
});

const UpdateUserForm: FC<UpdateUserFormProps> = ({
  currentUserData,
  toggleSuccessfulUpdateModal,
  isAthlete = false,
}) => {
  const { t } = useTranslation();
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: updatedUser, refetch } = useGetUserProfileItemQuery(
    currentUserData!.id
  );

  const params = useParams();

  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(params.tabId || "1");

  useEffect(() => {
    if (params.tabId !== tabValue) {
      setTabValue(params.tabId || "1");
    }
  }, [params.tabId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (currentUserData?.id)
      navigate(`${Routes.profile.url}/${currentUserData?.id}/${newValue}`);
  };

  const {
    data: fitnessProfessionals,
    isLoading: isLoadingFitnessProfessionals,
  } = useGetUsersQuery({
    count: 0,
    page: 0,
    userRole: "admin",
    orderByName: true,
  });

  const onSubmit = async (params: SubmitValues) => {
    try {
      await updateUserProfile({
        ...params,
        swapExercisesEnabled: `${params.swapExercisesEnabled}`,
      }).unwrap();
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      await refetch();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<SubmitValues>({
    initialValues: {
      id: currentUserData?.id,
      firstName: currentUserData?.firstName,
      lastName: currentUserData?.lastName,
      phoneNumber: currentUserData?.phoneNumber,
      email: currentUserData?.email,
      userStatus: currentUserData?.userStatus,
      userCategory: currentUserData?.userCategory,
      userRole: currentUserData?.userRole,
      dateOfBirth: currentUserData?.dateOfBirth,
      assignedFitnessProfessionalId:
        currentUserData?.assignedFitnessProfessionalId,
      userLiftingProfileType:
        currentUserData?.userLiftingProfileType?.liftingProfileType,
      myFitnessPalUsername: currentUserData?.myFitnessPalUsername,
      swapExercisesEnabled: currentUserData?.swapExercisesEnabled,
    },
    enableReinitialize: true,
    validationSchema: UserProfileSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const checkBeforeSubmit =
    values.dateOfBirth === currentUserData?.dateOfBirth &&
    values.email === currentUserData?.email &&
    values.firstName === currentUserData?.firstName &&
    values.lastName === currentUserData?.lastName &&
    values.phoneNumber === currentUserData?.phoneNumber &&
    values.userRole === currentUserData?.userRole &&
    values.userStatus === currentUserData?.userStatus &&
    values.userCategory === currentUserData?.userCategory &&
    values.assignedFitnessProfessionalId ===
      currentUserData?.assignedFitnessProfessionalId &&
    values.userLiftingProfileType === currentUserData?.userLiftingProfileType &&
    values.myFitnessPalUsername === currentUserData?.myFitnessPalUsername &&
    values.swapExercisesEnabled === currentUserData?.swapExercisesEnabled;

  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleTabChange} aria-label="Tabs">
          <Tab label="Overview" value="1" />
          {!isAthlete && <Tab label="Notes" value="2" />}
          {!isAthlete && <Tab label="Programs" value="3" />}
          {!isAthlete && <Tab label="Training Insights" value="4" />}
          {!isAthlete && <Tab label="Live Training" value="5" />}
        </TabList>
      </Box>
      <Grid>
        <TabPanel value="1">
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <ClientInsights selectedUserId={currentUserData?.id} />
            </Grid>
            <Grid container spacing={2} mt={2}>
              <ClientFeedbackSummary selectedUserId={currentUserData!.id} />
            </Grid>
            <Box
              sx={{
                bgcolor: `${Colors.gray[100]}`,
                mt: "20px",
                padding: "20px",
                height: "auto",
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: isAdminOrCoach ? "50%" : "60%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        mb: "15px",
                      }}
                    >
                      <TextField
                        size="small"
                        type="text"
                        disabled={!isAdminOrCoach}
                        label={t("user-profile.first-name")}
                        value={values.firstName}
                        onBlur={handleBlur("firstName")}
                        onChange={handleChange("firstName")}
                        error={!!errors.firstName}
                        inputProps={{ style: { marginTop: "3px" } }}
                        sx={{
                          height: "40%",
                          width: "95%",
                          mb: { xs: "15px", md: "0px" },
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: isAdminOrCoach ? "50%" : "60%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        mb: "15px",
                      }}
                    >
                      <TextField
                        size="small"
                        type="text"
                        label={t("user-profile.last-name")}
                        disabled={!isAdminOrCoach}
                        error={!!errors.lastName}
                        placeholder={currentUserData?.lastName}
                        value={values.lastName}
                        onBlur={handleBlur("lastName")}
                        onChange={handleChange("lastName")}
                        sx={{
                          height: "40%",
                          width: "95%",
                          mb: { xs: "15px", md: "0px" },
                        }}
                        inputProps={{ style: { marginTop: "3px" } }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: isAdminOrCoach ? "50%" : "60%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        mb: "15px",
                      }}
                    >
                      <TextField
                        size="small"
                        type="email"
                        label={t("user-profile.email")}
                        value={values.email}
                        disabled
                        onBlur={handleBlur("email")}
                        onChange={handleChange("email")}
                        sx={{
                          height: "40%",
                          width: "95%",
                          mb: { xs: "15px", md: "0px" },
                        }}
                        inputProps={{ style: { marginTop: "3px" } }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: isAdminOrCoach ? "50%" : "60%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        mb: "15px",
                      }}
                    >
                      <TextField
                        size="small"
                        type="text"
                        label={t("user-profile.phone")}
                        value={values.phoneNumber}
                        onBlur={handleBlur("phoneNumber")}
                        onChange={handleChange("phoneNumber")}
                        sx={{
                          height: "40%",
                          width: "95%",
                          mb: { xs: "15px", md: "0px" },
                        }}
                        inputProps={{ style: { marginTop: "3px" } }}
                      />
                    </Box>
                  </Box>
                </Grid>
                {isAdminOrCoach ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "50%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          flexGrow: 1,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              width: "95%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography sx={{ width: "100%", pl: "10px" }}>
                              {t("user-profile.user-status")}
                            </Typography>
                            <Select
                              value={
                                values.userStatus || currentUserData?.userStatus
                              }
                              defaultValue={values.userStatus}
                              onBlur={handleBlur("userStatus")}
                              onChange={(e) =>
                                setFieldValue("userStatus", e.target.value)
                              }
                              size="small"
                              sx={{
                                width: "100%",
                                mb: { xs: "15px", md: "0px" },
                                paddingTop: "3px",
                              }}
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          width: "100%",
                          height: isAdminOrCoach ? "50%" : "60%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          flexGrow: 1,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              width: "95%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography sx={{ width: "100%", pl: "10px" }}>
                              {t("user-profile.user-category")}
                            </Typography>
                            <Select
                              value={
                                values.userCategory ||
                                currentUserData?.userCategory
                              }
                              defaultValue={values.userCategory}
                              onBlur={handleBlur("userCategory")}
                              onChange={(e) =>
                                setFieldValue("userCategory", e.target.value)
                              }
                              size="small"
                              sx={{
                                width: "100%",
                                mb: { xs: "15px", md: "0px" },
                                paddingTop: "3px",
                              }}
                            >
                              <MenuItem value="Undefined">None</MenuItem>
                              <MenuItem value="Online">Online</MenuItem>
                              <MenuItem value="InPerson">In Person</MenuItem>
                              <MenuItem value="Hybrid">Hybrid</MenuItem>
                              <MenuItem value="NonCoaching">
                                Non Coaching
                              </MenuItem>
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          width: "100%",
                          height: isAdminOrCoach ? "50%" : "60%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              width: "95%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography sx={{ width: "100%", pl: "10px" }}>
                              {t("user-profile.user-lifting-profile")}
                            </Typography>
                            <Select
                              value={
                                values.userLiftingProfileType ||
                                currentUserData?.userLiftingProfileType
                                  ?.liftingProfileType
                              }
                              defaultValue={values.userStatus}
                              onBlur={handleBlur("userLiftingProfileType")}
                              onChange={(e) =>
                                setFieldValue(
                                  "userLiftingProfileType",
                                  e.target.value
                                )
                              }
                              size="small"
                              sx={{
                                width: "100%",
                                mb: { xs: "15px", md: "0px" },
                                paddingTop: "3px",
                              }}
                            >
                              <MenuItem value="Undefined">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Powerlifting">
                                Powerlifting
                              </MenuItem>
                              <MenuItem value="OlympicWeightlifting">
                                Olympic Weightlifting
                              </MenuItem>
                              <MenuItem value="Bodybuilding">
                                Bodybuilding
                              </MenuItem>
                              <MenuItem value="Crossfit">Crossfit</MenuItem>
                              <MenuItem value="Strongman">Strongman</MenuItem>
                              <MenuItem value="Powerbuilding">
                                Powerbuilding
                              </MenuItem>
                              <MenuItem value="Calisthenics">
                                Calisthenics
                              </MenuItem>
                              <MenuItem value="GeneralFitness">
                                General Fitness
                              </MenuItem>
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "50%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box
                            sx={{
                              width: "95%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography sx={{ width: "100%", pl: "10px" }}>
                              {t("user-profile.swap-exercises-enabled")}
                            </Typography>
                            <Select
                              value={
                                values.swapExercisesEnabled ||
                                currentUserData?.swapExercisesEnabled
                              }
                              defaultValue={values.swapExercisesEnabled}
                              onBlur={handleBlur("swapExercisesEnabled")}
                              onChange={(e) =>
                                setFieldValue(
                                  "swapExercisesEnabled",
                                  e.target.value
                                )
                              }
                              size="small"
                              sx={{
                                width: "100%",
                                mb: { xs: "15px", md: "0px" },
                                paddingTop: "3px",
                              }}
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                ) : null}
                {isAdminOrCoach ? (
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isAdminOrCoach ? "50%" : "60%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        flexGrow: 1,
                        mt: 4,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          sx={{
                            width: "95%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {!isLoadingFitnessProfessionals &&
                          fitnessProfessionals !== undefined &&
                          isAdmin ? (
                            <>
                              <Typography sx={{ width: "100%" }}>
                                {t("user-profile.coach")}
                              </Typography>
                              <Select
                                sx={{ width: "100%", mb: "24px" }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "assignedFitnessProfessionalId",
                                    e.target.value
                                  )
                                }
                                value={
                                  values.assignedFitnessProfessionalId ||
                                  currentUserData?.id
                                }
                              >
                                {fitnessProfessionals.map((user) => (
                                  <MenuItem
                                    key={user.id}
                                    value={user.id}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "1.2rem",
                                      }}
                                    >
                                      {`${user.firstName} ${user.lastName}`}
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                      {user.email}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      onClick={() => {
                        toggleSuccessfulUpdateModal();
                        handleSubmit();
                      }}
                      disabled={checkBeforeSubmit}
                      color="primary"
                      variant="contained"
                      sx={{
                        width: "25%",
                        textTransform: "none",
                      }}
                    >
                      {t("user-profile.update-btn")}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <UserNotesTable preselectedUserId={currentUserData!.id} />
        </TabPanel>
        <TabPanel value="3">
          <UserProfileProgramsContainer userId={currentUserData!.id} />
        </TabPanel>
        <TabPanel value="4">
          <TrainingInsightsContent preselectedUserId={currentUserData!.id} />
        </TabPanel>
        <TabPanel value="5">
          <LiveTrainingContent preselectedUserId={currentUserData!.id} />
        </TabPanel>
      </Grid>
    </TabContext>
  );
};

export { UpdateUserForm };
