import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Button, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../shared/themes";
import { OutstandingClientInvoicesModal } from "./modals/OutstandingClientInvoicesModal";
import { DashboardResponse } from "../../../../shared/api/dashboard/models";

interface OutstandingClientInvoicesProps {
  dashboardResponse: DashboardResponse | undefined;
  isLoading: boolean;
}

const OutstandingClientInvoices: FC<OutstandingClientInvoicesProps> = ({
  dashboardResponse,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );
  }

  const outstandingClientInvoices =
    dashboardResponse?.outstandingClientInvoices ?? [];

  return (
    <Box
      sx={{
        width: "100%",
        height: "131px",
        bgcolor: `${Colors.gray[100]}`,
        mb: "16px",
        padding: "20px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "12px",
        }}
      >
        <Box
          sx={{
            width: "65%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              width: "auto",
              height: "22px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mb: "16px",
            }}
          >
            {t("overdue-invoices.title")}
          </Typography>
        </Box>

        <Button
          onClick={handleOpenModal}
          variant="outlined"
          sx={{
            height: "30px",
            width: "135px",
            color: "primary",
            textTransform: "none",
            bgcolor: `${Colors.blue[1700]}`,
          }}
        >
          {t("overdue-invoices.view-all")}
        </Button>
      </Box>

      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "16px",
          color: `${Colors.blue[1300]}`,
          mb: "14px",
        }}
      >
        {outstandingClientInvoices?.length}{" "}
        {outstandingClientInvoices?.length === 1
          ? t("overdue-invoices.client")
          : t("overdue-invoices.clients")}{" "}
        {t("overdue-invoices.description")}
      </Typography>
      <OutstandingClientInvoicesModal
        handleCloseModal={handleCloseModal}
        isOpenModal={isOpenModal}
        usersData={dashboardResponse!}
      />
    </Box>
  );
};

export { OutstandingClientInvoices };
