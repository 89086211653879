import { Box } from "@mui/material";
import { WorkoutSession } from "../../../../shared/api";
import { getWorkoutImageForKey, Images } from "../../../../shared/themes";
import { SessionCard } from "./SessionCard";

interface WorkoutListProps {
  workouts: WorkoutSession[];
  isCompleted?: boolean;
  isWorkoutProgramCompleted?: boolean;
}

export const WorkoutList: React.FC<WorkoutListProps> = ({
  workouts,
  isCompleted = false,
  isWorkoutProgramCompleted = false,
}) => {
  return (
    <Box>
      {workouts.map((workout, key) => (
        <SessionCard
          item={workout}
          key={workout.id}
          id={workout.id}
          image={
            isCompleted ? Images.RemainingSession : getWorkoutImageForKey(key)
          }
          dayNumber={workout.dayNumber}
          weekNumber={workout.weekNumber}
          isWorkoutProgramCompleted={isWorkoutProgramCompleted}
        />
      ))}
    </Box>
  );
};
