import React from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import { Colors } from "../../../../shared/themes";

const WorkoutPageSkeleton = () => {
  return (
    <Box
      sx={{
        padding: "9px 0px 23px",
      }}
    >
      {Array.from(Array(10).keys()).map((item) => (
        <Skeleton
          key={item.toString()}
          variant="rectangular"
          animation="wave"
          width="100%"
          height="50px"
          sx={{
            bgcolor: `${Colors.gray[500]}`,
            marginBottom: "20px",
          }}
        />
      ))}
    </Box>
  );
};

export { WorkoutPageSkeleton };
