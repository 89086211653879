import { createApi } from "@reduxjs/toolkit/query/react";
import { PowerliftingStatistic } from "./models";
import { baseQuery } from "../baseQuery";

export const userStatisticsApi = createApi({
  baseQuery,
  reducerPath: "userStatisticsApi",
  endpoints: (builder) => ({
    getPowerliftingUserStatistics: builder.query<
      PowerliftingStatistic[],
      number
    >({
      query: (userId) => ({
        url: `/user-statistics/powerlifting/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPowerliftingUserStatisticsQuery } = userStatisticsApi;
