import { CellValue } from "tui-grid";

export function processIncreaseValue(increaseValue: any) {
  const increaseValueFirstCharacter = increaseValue?.toString()[0];
  const newIncreaseValue =
    increaseValueFirstCharacter === "." ? `0${increaseValue}` : increaseValue;
  const increaseValueOnlyNumbersDotAndHyphen = newIncreaseValue
    ?.toString()
    .replace(/[^0-9.-]/g, "");
  const increaseValueTrimmed = increaseValueOnlyNumbersDotAndHyphen
    ?.toString()
    .trim()
    .replace(/\.$/, "");
  return increaseValueTrimmed;
}

export function processBaseValue(baseValue: any) {
  const baseValueFirstCharacter = baseValue?.toString()[0];
  const newBaseValue =
    baseValueFirstCharacter === "." ? `0${baseValue}` : baseValue;
  const baseValueOnlyNumbersSpaceDotAndHyphen = newBaseValue
    ?.toString()
    .replace(/[^0-9\s.-]/g, "");
  const baseValueTrimmed = baseValueOnlyNumbersSpaceDotAndHyphen
    ?.toString()
    .trim()
    .replace(/\.$/, "");
  return baseValueTrimmed;
}

export const roundToNearestGymWeightIncrement = (
  number: number,
  roundUp: boolean
) => {
  const remainder = number % 2.5;
  if (remainder >= 1.25 && roundUp) {
    return Math.ceil(number / 2.5) * 2.5;
  }
  return Math.floor(number / 2.5) * 2.5;
};

export function calculateNewValue(
  baseValue: CellValue,
  increaseValue: CellValue,
  i: number,
  round = false
) {
  const baseArray = baseValue?.toString().split("-");
  const increaseArray = increaseValue?.toString().split("-");

  let newValue;

  if (baseArray && baseArray.length === 2) {
    const baseMin = Number(baseArray[0]);
    const baseMax = Number(baseArray[1]);

    let newMinValue;
    let newMaxValue;

    if (increaseArray && increaseArray.length === 2) {
      const increaseMin = Number(increaseArray[0]);
      const increaseMax = Number(increaseArray[1]);

      newMinValue = baseMin + (i - 1) * increaseMin;
      newMaxValue = baseMax + (i - 1) * increaseMax;
    } else {
      const increaseNumber = Number(increaseValue);

      newMinValue = baseMin + (i - 1) * increaseNumber;
      newMaxValue = baseMax + (i - 1) * increaseNumber;
    }

    newValue = round
      ? `${roundToNearestGymWeightIncrement(
          newMinValue,
          true
        )}-${roundToNearestGymWeightIncrement(newMaxValue, true)}`
      : `${newMinValue}-${newMaxValue}`;
  } else {
    const baseValueNumber = Number(baseValue) ?? 0;

    if (increaseArray && increaseArray.length === 2) {
      const increaseMin = Number(increaseArray[0]);

      const calculatedIncrease = baseValueNumber + (i - 1) * increaseMin;
      newValue = round
        ? roundToNearestGymWeightIncrement(calculatedIncrease, true)
        : calculatedIncrease;
    } else {
      const calculatedIncrease =
        baseValueNumber + (i - 1) * Number(increaseValue);
      newValue = round
        ? roundToNearestGymWeightIncrement(calculatedIncrease, true)
        : calculatedIncrease;
    }
  }

  return newValue;
}
