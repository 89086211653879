import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Colors } from "shared/themes";

type DefaultCardioInputScreenProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
};
// eslint-disable-next-line
const DefaultCardioInputScreen: FC<DefaultCardioInputScreenProps> = ({
  values,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: `${Colors.gray[200]}`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mb: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: 700,
            color: `${Colors.gray[800]}`,
          }}
        >
          Your working set
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mb: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedDistance}
          label="Distance"
          onBlur={handleBlur("completedDistance")}
          onChange={handleChange("completedDistance")}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedSpeed}
          label="Speed"
          onBlur={handleBlur("completedSpeed")}
          onChange={handleChange("completedSpeed")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
          }}
          value={values.completedIntensity}
          label="Intensity (Incline, etc)"
          onBlur={handleBlur("completedIntensity")}
          onChange={handleChange("completedIntensity")}
        />
      </Box>
    </Box>
  );
};

export { DefaultCardioInputScreen };
