import { Button, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../../shared/routers";
import { Colors } from "../../../../shared/themes";

interface SubmitFeedbackProps {
  linkTo?: string;
}

const SubmitFeedback: FC<SubmitFeedbackProps> = ({
  linkTo = Routes.dashboard.url,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        top: "175px",
        width: "100%",
        height: "76%",
        bgcolor: `${Colors.gray[100]}`,
        padding: "24px 24px 0px 24px ",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "92px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "8px",
          }}
        >
          <Box
            sx={{
              width: "56px",
              height: "56px",
              borderRadius: "50px",
              background: "rgba(76, 175, 80, 0.08)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50px",
                bgcolor: `${Colors.green[500]}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                sx={{
                  color: `${Colors.gray[100]}`,
                }}
              >
                check_icon
              </Icon>
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "140%",
            textAlign: "center",
            color: `${Colors.gray[1200]}`,
          }}
        >
          {t("feedback.thanks")}
        </Typography>
        <Button
          sx={{
            width: "100%",
            height: "42px",
            background: "transparent",
            mt: "24px",
            color: `${Colors.blue[1200]}`,
            textTransform: "none",
          }}
        >
          <NavLink
            to={linkTo}
            reloadDocument
            style={{
              height: "42px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            {t("feedback.back")}
          </NavLink>
        </Button>
      </Box>
    </Box>
  );
};

export { SubmitFeedback };
