/* eslint-disable @typescript-eslint/no-unused-vars */
import { Drawer, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTimeline } from "shared/hooks/useTimeline";
import { Colors } from "../../../themes";
import { AthleteListItem } from "./AthleteListItem";
import { SidebarUserProfile } from "../SidebarUserProfile";
import { navData } from "./constants";
import { useTypedSelector } from "../../../stores";
import { userSelectors } from "../../../stores/user";
import { WhatsNewListItem } from "./WhatsNewListItem";

interface AthleteSideNavigationProps {
  isOpenSidebar: boolean;
  closeSidebar?: () => void;
}

const AthleteSideNavigation: FC<AthleteSideNavigationProps> = ({
  isOpenSidebar,
  closeSidebar,
}) => {
  const { t } = useTranslation();

  const fullName = useTypedSelector(userSelectors.fullName);
  const { data: timeline, refetch, isVisible } = useTimeline();

  useEffect(() => {
    refetch();
  }, [isOpenSidebar]);

  return (
    <Drawer
      anchor="left"
      open={isOpenSidebar}
      PaperProps={{
        sx: {
          width: "335px",
          bgcolor: `${Colors.gray[50]}`,
        },
      }}
      BackdropProps={{ invisible: true }}
      onBackdropClick={closeSidebar}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "140%",
          color: "rgba(0, 0, 0, 0.6)",
          paddingLeft: "50px",
          marginBottom: "12px",
          paddingTop: "36px",
        }}
      >
        {t("athlete-sidebar.title")}
      </Typography>

      <Box
        sx={{
          height: "95%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box onClick={closeSidebar}>
          {navData.map((item) => (
            <AthleteListItem
              icon={item.icon}
              title={item.title}
              key={item.title}
              link={item.link}
              unreadCount={isVisible ? timeline?.unreadCount : 0}
            />
          ))}
          <WhatsNewListItem
            icon="notifications_active"
            title={t("admin-sidebar.get-beamer")}
            key="get-beamer"
            className="beamerTrigger"
          />
        </Box>

        <Box
          sx={{
            marginLeft: "24px",
            marginRight: "24px",
            paddingBottom: "42px",
          }}
        >
          <SidebarUserProfile />
        </Box>
      </Box>
    </Drawer>
  );
};
export { AthleteSideNavigation };
