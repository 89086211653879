import { FC, useEffect, useState } from "react";
import { Box, Tab, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { userSelectors } from "../../shared/stores/user";
import {
  WorkflowTemplateLibraryResponse,
  useAddWorkflowTemplateItemByNameToOrganisationMutation,
  workflowTemplateLibraryApi,
  workflowsApi,
  Workflow,
} from "../../shared/api";
import { WorkflowAddWorkflowModal } from "./WorkflowAddWorkflowModal";
import { WorkflowsContainer } from "./WorkflowsContainer";
import { WorkflowTemplatesContainer } from "./WorkflowTemplatesContainer";

const Workflows: FC = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const dispatch = useTypedDispatch();

  const [workflowTemplates, setWorkflowTemplates] =
    useState<WorkflowTemplateLibraryResponse[]>();

  const [workflows, setWorkflows] = useState<Workflow[]>();

  const getWorkflows = workflowsApi.endpoints.getWorkflows.initiate(
    {
      organisationId: useTypedSelector(userSelectors.orgId)!!,
    },
    { forceRefetch: true }
  );

  const getWorkflowTemplates =
    workflowTemplateLibraryApi.endpoints.getWorkflowLibraryTemplates.initiate(
      undefined,
      { forceRefetch: true }
    );
  async function callGetWorkflows() {
    try {
      const response = await dispatch(getWorkflows);
      setWorkflows(response.data ?? undefined);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsSaving(false);
    }
  }

  async function callGetWorkflowTemplates() {
    try {
      setIsSaving(true);
      const response = await dispatch(getWorkflowTemplates);
      setWorkflowTemplates(response.data ?? undefined);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsSaving(false);
    }
  }

  const [createWorkflowTemplate] =
    useAddWorkflowTemplateItemByNameToOrganisationMutation();

  const handleCardClick = async (
    workflowTemplate: WorkflowTemplateLibraryResponse
  ) => {
    setSuccessMessage("");
    setErrorMessage("");
    setIsSaving(true);
    if (workflowTemplate.workflowRepetition === "OnRepeat") {
      try {
        await createWorkflowTemplate({
          name: workflowTemplate.name,
        }).unwrap();

        setSuccessMessage(
          `Successfully added ${workflowTemplate.name} to your organisation`
        );
        setIsSaving(false);
      } catch (error) {
        setErrorMessage("Failed to add workflow template to your organisation");
        setIsSaving(false);
      } finally {
        await callGetWorkflowTemplates();
        await callGetWorkflows();
      }
    }
  };

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    callGetWorkflowTemplates();
    callGetWorkflows();
  }, [tabValue]);

  const [onDemandWorkflowTemplates, setOnDemandWorkflows] = useState<
    WorkflowTemplateLibraryResponse[]
  >([]);
  const [gettingStartedWorkflowTemplates, setGettingStartedWorkflowTemplates] =
    useState<WorkflowTemplateLibraryResponse[]>([]);
  const [onRepeatWorkflows, setOnRepeatWorkflows] = useState<
    WorkflowTemplateLibraryResponse[]
  >([]);

  useEffect(() => {
    if (workflows) {
      setWorkflows(workflows);
    }

    if (workflowTemplates) {
      setOnDemandWorkflows(
        workflowTemplates.filter(
          (workflowTemplate) =>
            workflowTemplate.workflowRepetition === "OnDemand"
        )
      );

      setGettingStartedWorkflowTemplates(
        workflowTemplates.filter(
          (workflowTemplate) =>
            workflowTemplate.workflowRepetition === "GettingStarted"
        )
      );

      setOnRepeatWorkflows(
        workflowTemplates.filter(
          (workflowTemplate) =>
            workflowTemplate.workflowRepetition === "OnRepeat"
        )
      );
    }
  }, [workflowTemplates, workflows]);

  const [isWorkflowAddWorkflowModalOpen, setIsWorkflowAddWorkflowModalOpen] =
    useState(false);

  const handleWorkflowAddWorkflowModalOpen = () =>
    setIsWorkflowAddWorkflowModalOpen(!isWorkflowAddWorkflowModalOpen);

  return (
    <>
      <Box
        sx={
          showSidebar
            ? sharedStyles.containers.sidebar.content
            : sharedStyles.containers.sidebar.contentFullscreen
        }
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Tabs">
              <Tab label="Workflows" value="1" />
              <Tab label="Workflow Templates" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <WorkflowsContainer
              handleWorkflowAddWorkflowModalOpen={
                handleWorkflowAddWorkflowModalOpen
              }
              workflows={workflows}
            />
          </TabPanel>
          <TabPanel value="2">
            <WorkflowTemplatesContainer
              onRepeatWorkflowTemplates={onRepeatWorkflows}
              onDemandWorkflowTemplates={onDemandWorkflowTemplates}
              gettingStartedWorkflowTemplates={gettingStartedWorkflowTemplates}
              handleCardClick={handleCardClick}
              successMessage={successMessage}
              errorMessage={errorMessage}
              isSaving={isSaving}
            />
          </TabPanel>
        </TabContext>
      </Box>
      <WorkflowAddWorkflowModal
        isWorkflowAddWorkflowModalOpen={isWorkflowAddWorkflowModalOpen}
        handleWorkflowAddWorkflowModalClose={handleWorkflowAddWorkflowModalOpen}
      />
    </>
  );
};

export { Workflows };
