import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ClientResponse,
  ClientListParams,
  UserResponse,
  InviteClientParams,
  UserProfileItemResponse,
  UpdateClientParams,
  PaymentDetailsParams,
  SubscriptionResponse,
  SubscriptionDetailsParams,
  DeleteSubscriptionParams,
  SubscriptionItemResponse,
  AddSubscriptionParams,
  InviteUserParams,
  UploadProfileImageParams,
} from "./models";
import { baseQuery } from "../baseQuery";

export const userApi = createApi({
  baseQuery,
  reducerPath: "userApi",
  tagTypes: ["User", "Users", "UsersCount", "Subscriptions"],
  endpoints: (builder) => ({
    getUser: builder.mutation<UserResponse, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["User"],
    }),
    getUsersCount: builder.query<number, ClientListParams>({
      query: (params) => ({
        url: `/users`,
        method: "GET",
        params,
      }),
      providesTags: ["UsersCount"],
    }),
    getUsers: builder.query<ClientResponse[], ClientListParams>({
      query: (params) => ({
        url: "/users",
        method: "GET",
        params,
      }),
      providesTags: ["Users"],
    }),
    getAllUsers: builder.query<ClientResponse[], ClientListParams>({
      query: (params) => ({
        url: "/users",
        method: "GET",
        params,
      }),
      providesTags: ["Users"],
    }),
    inviteClient: builder.mutation<void, InviteClientParams>({
      query: ({
        firstName,
        lastName,
        email,
        userRole,
        userCategory,
        liftingProfileType,
        assignedFitnessProfessionalId,
      }) => ({
        url: "/users",
        method: "POST",
        body: {
          firstName,
          lastName,
          email,
          userRole,
          userCategory,
          liftingProfileType,
          assignedFitnessProfessionalId,
        },
      }),
      invalidatesTags: ["Users", "UsersCount"],
    }),
    inviteUser: builder.mutation<void, InviteUserParams>({
      query: ({
        firstName,
        lastName,
        email,
        userRole,
        organisationId,
        liftingProfileType,
      }) => ({
        url: "/users",
        method: "POST",
        body: {
          firstName,
          lastName,
          email,
          userRole,
          organisationId,
          liftingProfileType,
        },
      }),
    }),
    uploadWorkoutProgram: builder.mutation<void, FormData>({
      query: (data) => ({
        url: "/workoutprogramparser/parse",
        method: "POST",
        body: data,
      }),
    }),
    getUserProfileItem: builder.query<UserProfileItemResponse, number>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    getUsersForUsersPage: builder.query<UserProfileItemResponse[], number>({
      query: () => ({
        url: `/users`,
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation<void, UpdateClientParams>({
      query: ({
        firstName,
        lastName,
        userRole,
        dateOfBirth,
        phoneNumber,
        userStatus,
        userCategory,
        assignedFitnessProfessionalId,
        userLiftingProfileType,
        myFitnessPalUsername,
        swapExercisesEnabled,
        id,
      }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: {
          firstName,
          lastName,
          userRole,
          dateOfBirth,
          phoneNumber,
          userStatus,
          userCategory,
          assignedFitnessProfessionalId,
          userLiftingProfileType,
          myFitnessPalUsername,
          swapExercisesEnabled,
        },
        invalidatesTags: ["User"],
      }),
    }),

    addPaymentDetails: builder.mutation<void, PaymentDetailsParams>({
      query: ({ UserId, OrganisationId, CardToken }) => ({
        url: "/billing/payment-methods/cards",
        method: "POST",
        body: {
          UserId,
          OrganisationId,
          CardToken,
        },
      }),
    }),
    getUserSubscriptions: builder.query<
      SubscriptionResponse[],
      SubscriptionDetailsParams
    >({
      query: ({ UserId, OrganisationId }) => ({
        url: `/billing/subscriptions/${UserId}/${OrganisationId}`,
        method: "GET",
      }),
      providesTags: ["Subscriptions"],
    }),
    deleteUserSubscription: builder.mutation<void, DeleteSubscriptionParams>({
      query: ({ UserId, OrganisationId, SubscriptionId, ...rest }) => ({
        url: `/billing/subscriptions/${UserId}/${OrganisationId}/${SubscriptionId}`,
        method: "DELETE",
        body: rest,
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    getUserSubscriptionPlan: builder.query<SubscriptionItemResponse[], number>({
      query: () => ({
        url: `/billing/products`,
        method: "GET",
      }),
    }),
    addSubscriptionPlan: builder.mutation<void, AddSubscriptionParams>({
      query: ({ UserId, OrganisationId, EndDate, PriceIds, StartDate }) => ({
        url: "/billing/subscriptions",
        method: "POST",
        body: {
          UserId,
          OrganisationId,
          EndDate,
          PriceIds,
          StartDate,
        },
      }),
      invalidatesTags: ["Subscriptions"],
    }),
    uploadProfileImage: builder.mutation<void, UploadProfileImageParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.file);
        formData.append("userId", params.userId.toString());

        return {
          url: "/users/upload",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["User", "Users"],
    }),
  }),
});

export const {
  useGetUserMutation,
  useGetUsersQuery,
  useInviteClientMutation,
  useGetUserProfileItemQuery,
  useGetUsersForUsersPageQuery,
  useUpdateUserProfileMutation,
  useAddPaymentDetailsMutation,
  useGetUserSubscriptionsQuery,
  useDeleteUserSubscriptionMutation,
  useGetUserSubscriptionPlanQuery,
  useAddSubscriptionPlanMutation,
  useGetUsersCountQuery,
  useGetAllUsersQuery,
  useUploadWorkoutProgramMutation,
  useInviteUserMutation,
  useUploadProfileImageMutation,
} = userApi;
