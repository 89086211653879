/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Container,
  InputAdornment,
  IconButton,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import OneSignal from "react-onesignal";
import { useNavigate, Link } from "react-router-dom";
import { useLoginMutation } from "shared/api";
import { Routes } from "shared/routers/Routes";
import { AnyType } from "shared/helpers";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { FormError } from "components/Form/FormError";
import { FormContainer } from "components/Form/FormContainer";
import { LogoLink } from "components/Form/LogoLink";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("validations.email-incorrect")
    .required("validations.email-required"),
  password: Yup.string().required("validations.password-required"),
});

export const SignIn: React.FC = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const jwtToken = useTypedSelector(userSelectors.jwtToken);

  const [login, { isLoading }] = useLoginMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (email: string, password: string) => {
    setErrorMessage("");

    try {
      await login({ email, password }).unwrap();

      OneSignal.setExternalUserId(email);

      navigate(Routes.dashboard.url);
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: ({ email, password }) => onSubmit(email, password),
  });

  const bootstrap = () => {
    if (jwtToken) {
      navigate(Routes.dashboard.url);
    }
  };

  useEffect(bootstrap, []);

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <LogoLink />
        <InputField
          id="emailInput"
          type="email"
          value={values.email}
          onBlur={handleBlur("email")}
          onChange={handleChange("email")}
          label={t("labels.email")}
          error={!!errors.email}
          helperText={errors.email ? t(errors.email) : ""}
          startAdornment="mail"
          placeholder={t("placeholders.email")}
        />
        <InputField
          id="passwordInput"
          type={isShowPassword ? "text" : "password"}
          value={values.password}
          onBlur={handleBlur("password")}
          onChange={handleChange("password")}
          startAdornment="lock"
          InputProps={{
            endAdornment: values.password ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsShowPassword(!isShowPassword)}>
                  <Icon>
                    {isShowPassword ? "visibility_off" : "visibility"}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          label={t("labels.password")}
          error={!!errors.password}
          helperText={
            errors.password ? (
              t(errors.password)
            ) : (
              <Link
                style={sharedStyles.links.forgotPassword}
                to={`${Routes.forgotPassword.url}`}
              >
                {t("login.forgot-password")}{" "}
              </Link>
            )
          }
          placeholder={t("placeholders.password")}
        />
        <PrimaryButton
          value={t("login.sign-in")}
          size="large"
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          onClick={() => handleSubmit()}
          fullWidth
        />
        {errorMessage && <FormError errorMessage={errorMessage} />}
        <Box
          sx={{
            ...sharedStyles.containers.signIn.link,
            display: { xs: "none", md: "block" },
          }}
        >
          {t("login.no-account")}{" "}
          <Link
            style={sharedStyles.links.signIn}
            to={`${Routes.fitnessProfessionalSignup.url}`}
          >
            {t("login.sign-up")}
          </Link>
        </Box>
        <Box sx={sharedStyles.containers.signIn.link}>
          Have a verification code to verify your account?
          <Link
            style={sharedStyles.links.signIn}
            to={`${Routes.clientSignupEmailVerification.url}`}
          >
            {" "}
            Verify account
          </Link>
        </Box>
      </Container>
    </FormContainer>
  );
};
