import { useEffect } from "react";
import { useGetUserMutation } from "../api";
import { useTypedSelector } from "../stores";
import { userSelectors } from "../stores/user";

export const useGetUser = () => {
  const [getUser] = useGetUserMutation();

  const userId = useTypedSelector(userSelectors.userId);

  const bootstrap = async () => {
    if (userId) {
      await getUser(Number(userId)).unwrap();
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);
};
