/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { Navigate } from "react-router-dom";
import { useTypedSelector } from "../stores";
import { userSelectors } from "../stores/user";
import { Routes } from "./Routes";

interface ProtectedRouteProps {
  children?: React.ReactNode | null;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children = null,
}) => {
  const jwtToken = useTypedSelector(userSelectors.jwtToken);

  if (!jwtToken) {
    return <Navigate to={Routes.signIn.url} replace />;
  }

  return <>{children}</>;
};

export const ProtectedAdminRoute: React.FC<ProtectedRouteProps> = ({
  children = null,
}) => {
  const jwtToken = useTypedSelector(userSelectors.jwtToken);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  if (!jwtToken) {
    return <Navigate to={Routes.signIn.url} replace />;
  }

  if (!isAdmin) {
    return <Navigate to={Routes.dashboard.url} replace />;
  }

  return <>{children}</>;
};

export const ProtectedAdminOrCoachRoute: React.FC<ProtectedRouteProps> = ({
  children = null,
}) => {
  const jwtToken = useTypedSelector(userSelectors.jwtToken);
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  if (!jwtToken) {
    return <Navigate to={Routes.signIn.url} replace />;
  }

  if (!isAdminOrCoach) {
    return <Navigate to={Routes.dashboard.url} replace />;
  }

  return <>{children}</>;
};
