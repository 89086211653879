import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  UpdateClientInvoiceParams,
  ClientInvoice,
  useUpdateInvoiceStatusMutation,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";
import * as Yup from "yup";
import { SelectInputField } from "components/Form/SelectInputField";
import { useFormik } from "formik";

interface InvoiceChangeStatusModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  invoice?: ClientInvoice;
}

const UpdateInvoiceSchema = Yup.object().shape({
  invoiceId: Yup.string().required(),
  invoiceStatus: Yup.string().required(),
});

const INVOICE_STATUS = [
  { label: "Paid", value: "paid" },
  { label: "Void", value: "void" },
];

const InvoiceChangeStatusModal: FC<InvoiceChangeStatusModalProps> = ({
  isOpenModal,
  handleCloseModal,
  invoice,
}) => {
  const { t } = useTranslation();
  const [updateInvoiceStatus, { isLoading }] = useUpdateInvoiceStatusMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = async (params: UpdateClientInvoiceParams) => {
    try {
      await updateInvoiceStatus(params).unwrap();
      setSuccessMessage(t("programs-menu.change-status-success"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 1500);
    }
  };

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        invoiceId: invoice?.id ?? "",
        invoiceStatus: "",
      },
      validationSchema: UpdateInvoiceSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    }
  );

  const workoutProgramList = INVOICE_STATUS.map((item) => ({
    label: item.label,
    value: item.value,
    isDisabled: item.value === invoice?.status,
  }));

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Change Invoice Status
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 2,
            }}
          >
            {t("programs-menu.change-status-content")}
          </Typography>
          <SelectInputField
            label="Invoice Status"
            data={workoutProgramList}
            value={values.invoiceStatus}
            defaultValue={values.invoiceStatus}
            onBlur={handleBlur("invoiceStatus")}
            onChange={(e) => setFieldValue("invoiceStatus", e.target.value)}
            error={!!errors.invoiceStatus}
          />
          <PrimaryButton
            value="Submit"
            type="submit"
            variant="contained"
            fullWidth
            loading={isLoading}
            disabled={isLoading}
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { InvoiceChangeStatusModal };
