import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Modal,
  Typography,
  Icon,
  Fade,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { useTypedSelector } from "shared/stores";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel/CircularProgressWithLabel";

interface GridSavingModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  numberOfDays: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const GridSavingModal: FC<GridSavingModalProps> = ({
  isOpenModal,
  handleCloseModal,
  numberOfDays,
}) => {
  const { t } = useTranslation();
  const gridRowHeaders = useTypedSelector(
    (state) => state.workoutBuilder.gridRowHeaders
  );
  const gridDataArray = Object.values(gridRowHeaders);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((gridDataArray.length / numberOfDays) * 100);
  }, [gridDataArray.length]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenModal}
      onClose={handleCloseModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: () => {},
        },
      }}
    >
      <Fade in={isOpenModal}>
        <Box sx={style}>
          <Icon
            onClick={handleCloseModal}
            sx={{
              color: `${Colors.gray[1400]}`,
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            close_icon
          </Icon>
          <Typography sx={sharedStyles.body.modalDescription}>
            Hold on while we save your work. It could take a minute... Please
            keep this window open and avoid navigating away.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {gridDataArray?.length !== numberOfDays && (
              <CircularProgressWithLabel value={progress} size={80} />
            )}
            {gridDataArray?.length === numberOfDays && (
              <CircularProgress size={80} />
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export { GridSavingModal };
