import { createApi } from "@reduxjs/toolkit/query/react";
import {
  UserBodyMeasurement,
  UserBodyMeasurementParams,
  CreateUserBodyMeasurementRequest,
} from "./models";
import { baseQuery } from "../baseQuery";

export const userBodyMeasurementsApi = createApi({
  baseQuery,
  reducerPath: "userBodyMeasurementsApi",
  tagTypes: ["UserBodyMeasurement"],
  endpoints: (builder) => ({
    getUserBodyMeasurements: builder.query<
      UserBodyMeasurement[],
      UserBodyMeasurementParams
    >({
      query: (params) => ({
        url: `/user-body-measurements`,
        method: "GET",
        params,
      }),
      providesTags: ["UserBodyMeasurement"],
    }),
    getUserBodyMeasurement: builder.query<UserBodyMeasurement, number>({
      query: (id) => ({
        url: `/user-body-measurements/${id}`,
        method: "GET",
      }),
      providesTags: ["UserBodyMeasurement"],
    }),
    createUserBodyMeasurement: builder.mutation<
      UserBodyMeasurement,
      CreateUserBodyMeasurementRequest
    >({
      query: (bodyMeasurement) => ({
        url: `/user-body-measurements`,
        method: "POST",
        body: bodyMeasurement,
      }),
      invalidatesTags: ["UserBodyMeasurement"],
    }),
  }),
});

export const {
  useGetUserBodyMeasurementsQuery,
  useGetUserBodyMeasurementQuery,
  useCreateUserBodyMeasurementMutation,
} = userBodyMeasurementsApi;
