import { Modal, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "../../../../../shared/api";
import { Colors } from "../../../../../shared/themes";
import { OutstandingClientInvoiceItem } from "../OutstandingClientInvoiceItem";

interface OutstandingClientInvoicesModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  usersData: DashboardResponse;
}

const OutstandingClientInvoicesModal: FC<
  OutstandingClientInvoicesModalProps
> = ({ handleCloseModal, isOpenModal, usersData }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      sx={{
        width: "95%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5501,
      }}
    >
      <Box
        sx={{
          width: "400px",
          borderRadius: "4px",
          bgcolor: `${Colors.gray[100]}`,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "49px",
            padding: "16px 24px 8px 24px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
          }}
        >
          {t("overdue-invoices.title")}
          <Icon onClick={handleCloseModal} sx={{ cursor: "pointer" }}>
            close_icon
          </Icon>
        </Typography>
        <Typography
          sx={{
            mt: "24px",
            padding: "0px 21px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "49px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
          }}
        >
          {(usersData?.outstandingClientInvoices?.length ?? 0) !== 0
            ? t("overdue-invoices.modal-description", {
                numberOfClients: usersData?.outstandingClientInvoices?.length,
                clients:
                  usersData?.outstandingClientInvoices?.length === 1
                    ? "client"
                    : "clients",
              })
            : t("overdue-invoices.no-description")}

          <Icon
            onClick={handleCloseModal}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            close_icon
          </Icon>
        </Typography>

        <Box
          sx={{
            mt: "24px",
          }}
        >
          {(usersData?.outstandingClientInvoices?.length ?? 0) !== 0
            ? usersData?.outstandingClientInvoices?.map((item) => (
                <OutstandingClientInvoiceItem
                  key={item.clientUserId}
                  item={item}
                />
              ))
            : null}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItem: "center",
            justifyContent: "flex-end",
            padding: "10px 15px",
          }}
        />
      </Box>
    </Modal>
  );
};

export { OutstandingClientInvoicesModal };
