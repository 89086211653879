import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userReducer } from "./user";
import {
  authApi,
  userApi,
  athleteApi,
  programsApi,
  dashboardApi,
  workoutProgramBuilderApi,
  signupApi,
  exercisesApi,
  userPersonalRecordsApi,
  userStatisticsApi,
  userInsightsEngineApi,
  timelinesApi,
  organisationsApi,
  workflowsApi,
  organisationApiKeysApi,
  workoutProgramGroupItemsApi,
  trainingInsightsApi,
  userBodyMeasurementsApi,
  workoutProgramDesignerApi,
  nutritionApi,
  workflowTemplateLibraryApi,
  leadApi,
  clientInsightsApi,
  billingApi,
  userNotesApi,
} from "../api";
import { workoutReducer } from "./workout";
import workoutProgramStatusesSlice from "./statuses/slice";
import workoutBuilderSlice from "./workoutBuilder/slice";
import sidebarSlice from "./sidebar/slice";

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["id", "jwtToken", "refreshToken"],
};

const workoutPersistConfig = {
  key: "workout",
  storage,
  whitelist: [
    "id",
    "dayNumber",
    "weekNumber",
    "description",
    "workoutStatus",
    "workoutProgramId",
  ],
};

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  workout: persistReducer(workoutPersistConfig, workoutReducer),
  workoutProgramStatus: workoutProgramStatusesSlice,
  workoutBuilder: workoutBuilderSlice,
  sidebarState: sidebarSlice,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [athleteApi.reducerPath]: athleteApi.reducer,
  [programsApi.reducerPath]: programsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [workoutProgramBuilderApi.reducerPath]: workoutProgramBuilderApi.reducer,
  [signupApi.reducerPath]: signupApi.reducer,
  [exercisesApi.reducerPath]: exercisesApi.reducer,
  [userPersonalRecordsApi.reducerPath]: userPersonalRecordsApi.reducer,
  [userStatisticsApi.reducerPath]: userStatisticsApi.reducer,
  [userInsightsEngineApi.reducerPath]: userInsightsEngineApi.reducer,
  [timelinesApi.reducerPath]: timelinesApi.reducer,
  [organisationsApi.reducerPath]: organisationsApi.reducer,
  [workflowsApi.reducerPath]: workflowsApi.reducer,
  [organisationApiKeysApi.reducerPath]: organisationApiKeysApi.reducer,
  [workoutProgramGroupItemsApi.reducerPath]:
    workoutProgramGroupItemsApi.reducer,
  [trainingInsightsApi.reducerPath]: trainingInsightsApi.reducer,
  [userBodyMeasurementsApi.reducerPath]: userBodyMeasurementsApi.reducer,
  [workoutProgramDesignerApi.reducerPath]: workoutProgramDesignerApi.reducer,
  [nutritionApi.reducerPath]: nutritionApi.reducer,
  [workflowTemplateLibraryApi.reducerPath]: workflowTemplateLibraryApi.reducer,
  [leadApi.reducerPath]: leadApi.reducer,
  [clientInsightsApi.reducerPath]: clientInsightsApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [userNotesApi.reducerPath]: userNotesApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
