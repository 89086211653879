import { FC } from "react";
import { Box } from "@mui/system";
import {
  Fade,
  Modal,
  Typography,
  Backdrop,
  LinearProgress,
  Icon,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";

interface LoadingAiGenerateModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LoadingAiGenerateModal: FC<LoadingAiGenerateModalProps> = ({
  isOpenModal,
  handleCloseModal,
}) => {
  const handleClose = (event: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseModal();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: () => {},
        },
      }}
    >
      <Fade in={isOpenModal}>
        <Box sx={style}>
          <Icon
            onClick={handleCloseModal}
            sx={{
              color: `${Colors.gray[1400]}`,
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            close_icon
          </Icon>
          <Typography sx={sharedStyles.body.modalDescription}>
            Generating a program, this may take a minute. Please do not close
            this window.
          </Typography>
          <LinearProgress />
        </Box>
      </Fade>
    </Modal>
  );
};

export { LoadingAiGenerateModal };
