import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponse } from "../../api/user/models";

import { authApi, userApi } from "../../api";
import { UserReducer } from "./types";

const initialState: UserReducer = {
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  userRole: null,
  created: "",
  updated: "",
  isVerified: false,
  swapExercisesEnabled: false,
  jwtToken: "",
  refreshToken: "",
  organisationId: null,
  profileImageUrl: "",
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, { payload }: PayloadAction<UserResponse>) => payload,
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (_, { payload }) => payload
    );

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      return initialState;
    });

    builder.addMatcher(authApi.endpoints.logout.matchRejected, () => {
      localStorage.clear();

      return initialState;
    });

    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => Object.assign(state, payload)
    );
  },
});

export const {
  reducer: userReducer,
  actions: { clearUser, setUser },
} = user;
