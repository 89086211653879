import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { Colors } from "shared/themes";

interface MoreInfoButtonProps {
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const MoreInfoButton: FC<MoreInfoButtonProps> = ({ handleClick }) => {
  return (
    <Box
      onClick={(event) => handleClick(event)}
      sx={{
        ml: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: `${Colors.gray[1800]}`,
        borderRadius: "50px",
        cursor: "pointer",
      }}
    >
      <Icon sx={{ color: `${Colors.gray[1400]}` }}>more_horiz</Icon>
    </Box>
  );
};

export { MoreInfoButton };
