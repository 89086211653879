/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";
import { Box } from "@mui/system";
import ReactPlayer from "react-player/lazy";
import { Routes } from "shared/routers";
import {
  Alert,
  Typography,
  TextField,
  Skeleton,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UpdateExerciseParams,
  useGetExerciseByIdQuery,
  useUpdateExerciseByIdMutation,
  useUploadExerciseMediaMutation,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { SelectInputField } from "components/Form/SelectInputField";
import { DeleteExerciseModal } from "components/Modals/DeleteExerciseModal";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { ExerciseDropzone } from "./ExerciseDropzone";

const UpdateExerciseSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  imageUrl: Yup.string(),
  videoUrl: Yup.string(),
  primaryMuscleGroup: Yup.string().required(),
  muscleGroups: Yup.string(),
  videoFile: Yup.mixed().optional(),
});

const PRIMARY_MUSCLE_GROUPS = [
  { label: "", value: "Undefined" },
  { label: "Chest", value: "Chest" },
  { label: "Back", value: "Back" },
  { label: "Legs", value: "Legs" },
  { label: "Shoulders", value: "Shoulders" },
  { label: "Arms", value: "Arms" },
  { label: "Core", value: "Core" },
  { label: "Biceps", value: "Biceps" },
  { label: "Triceps", value: "Triceps" },
  { label: "Forearms", value: "Forearms" },
  { label: "Glutes", value: "Glutes" },
  { label: "Calves", value: "Calves" },
  { label: "Quads", value: "Quads" },
  { label: "Compound Movement", value: "CompoundMovement" },
  { label: "Posterior Chain", value: "PosteriorChain" },
  { label: "Anterior Chain", value: "AnteriorChain" },
];

const EXERCISE_TYPES = [
  { label: "", value: "Undefined" },
  { label: "Strength", value: "Strength" },
  { label: "Cardio", value: "Cardio" },
  { label: "Stretching", value: "Stretching" },
  { label: "WarmUp", value: "WarmUp" },
  { label: "CoolDown", value: "CoolDown" },
];

const ExerciseView: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [dropzoneError, setDropzoneError] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleDropzoneError = (e: string) => {
    setDropzoneError(e);
    setOpen(true);
  };

  const { data: exerciseData, isLoading } = useGetExerciseByIdQuery(
    Number(params.exerciseId)
  );
  const [updateExercise, { isLoading: isUpdateLoading }] =
    useUpdateExerciseByIdMutation();
  const [uploadExerciseMedia, { isLoading: isFileUploading }] =
    useUploadExerciseMediaMutation();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleToggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: exerciseData?.id || 0,
      name: exerciseData?.name || "",
      description: exerciseData?.description || "",
      imageUrl: exerciseData?.imageUrl || "",
      videoUrl: exerciseData?.videoUrl || "",
      primaryMuscleGroup: exerciseData?.primaryMuscleGroup || "",
      muscleGroups: exerciseData?.muscleGroups || "",
      exerciseType: exerciseData?.exerciseType || "",
      videoFile: undefined,
    },
    validationSchema: UpdateExerciseSchema,
    onSubmit: (params) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSubmit(params);
    },
  });

  async function onSubmit(params: UpdateExerciseParams) {
    setErrorMessage("");
    try {
      await updateExercise(params).unwrap();
      if (params.videoFile) {
        await uploadExerciseMedia({
          exerciseId: params.id,
          uploadFile: params.videoFile,
          type: "video",
        }).unwrap();
      }

      setSuccessMessage(t("exercise-library-modal.exercise-success-saved"));
      window.location.reload();
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    }
  }

  const handleDropVideo = (file: File) => {
    setFieldValue("videoFile", file);
  };

  if (isLoading)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  const isAnyLoading = isLoading || isUpdateLoading || isFileUploading;
  const displayVideoPreview =
    values.videoUrl && !values.videoFile && !isAnyLoading;
  const displayEmptyVideoUrlIfFitfocusMedia =
    exerciseData?.videoUrl?.includes("fitfocus");
  const isVideoUrlDirty = dirty && exerciseData?.videoUrl !== values.videoUrl;

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.profileContent}>
        <div>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <PrimaryButton
              value="Back to Exercise Library"
              variant="outlined"
              color="secondary"
              sx={{
                textTransform: "none",
                textDecoration: "none",
              }}
              onClick={() => navigate(Routes.exerciseLibrary.url)}
            />
            <PrimaryButton
              value="Archive"
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                textDecoration: "none",
              }}
              loading={isUpdateLoading}
              disabled={isLoading || isUpdateLoading}
              onClick={handleToggleDeleteModal}
            />
          </Box>

          <Typography sx={sharedStyles.headings.profile}>
            {exerciseData?.name}
          </Typography>
        </div>
        <TextField
          type="text"
          value={values.name}
          name="name"
          label="Name"
          onBlur={handleBlur("name")}
          onChange={handleChange("name")}
          error={!!errors.description}
          helperText={errors.description ? t(errors.description) : ""}
          sx={{
            mb: 2,
            mt: 2,
          }}
          disabled={isAnyLoading}
        />
        <TextField
          type="text"
          value={values.description}
          label="Description"
          onBlur={handleBlur("description")}
          onChange={handleChange("description")}
          error={!!errors.description}
          helperText={errors.description ? t(errors.description) : ""}
          sx={{
            mb: 2,
          }}
          disabled={isAnyLoading}
        />
        <SelectInputField
          label="Primary Muscle Group"
          data={PRIMARY_MUSCLE_GROUPS}
          value={values.primaryMuscleGroup}
          defaultValue={values.primaryMuscleGroup}
          onBlur={handleBlur("primaryMuscleGroup")}
          onChange={(e) => setFieldValue("primaryMuscleGroup", e.target.value)}
          error={!!errors.primaryMuscleGroup}
          disabled={isAnyLoading}
        />
        <SelectInputField
          label="Type"
          data={EXERCISE_TYPES}
          value={values.exerciseType}
          defaultValue={values.exerciseType}
          onBlur={handleBlur("exerciseType")}
          onChange={(e) => setFieldValue("exerciseType", e.target.value)}
          error={!!errors.exerciseType}
          disabled={isAnyLoading}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {displayEmptyVideoUrlIfFitfocusMedia ? (
            <TextField
              type="text"
              value={isVideoUrlDirty ? values.videoUrl : ""}
              label="Video URL"
              onBlur={handleBlur("videoUrl")}
              onChange={handleChange("videoUrl")}
              error={!!errors.videoUrl}
              helperText={errors.videoUrl ? t(errors.videoUrl) : ""}
              sx={{
                mt: 2,
                mb: 1,
              }}
              fullWidth
            />
          ) : (
            <TextField
              type="text"
              value={values.videoUrl}
              label="Video URL"
              onBlur={handleBlur("videoUrl")}
              onChange={handleChange("videoUrl")}
              error={!!errors.videoUrl}
              helperText={errors.videoUrl ? t(errors.videoUrl) : ""}
              sx={{
                mt: 2,
                mb: 1,
              }}
              fullWidth
            />
          )}
          <ExerciseDropzone
            onDrop={handleDropVideo}
            onError={handleDropzoneError}
          />
        </Box>
        {displayVideoPreview && (
          <ReactPlayer
            url={values.videoUrl}
            // eslint-disable-next-line
            controls={true}
            // eslint-disable-next-line
            pip={true}
          />
        )}

        <PrimaryButton
          size="large"
          type="submit"
          value={t("exercise-library-modal.saveChangesBtnText")}
          loading={isAnyLoading}
          disabled={isAnyLoading}
          variant="contained"
          onClick={() => handleSubmit()}
          sx={{
            mt: 3,
            textTransform: "none",
          }}
        />

        <DeleteExerciseModal
          handleCloseModal={handleToggleDeleteModal}
          isOpenModal={isOpenDeleteModal}
          exerciseItem={exerciseData}
        />

        {errorMessage && (
          <Alert
            sx={{
              mt: 3,
              width: "100%",
            }}
            variant="outlined"
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert
            sx={{
              mt: 3,
              width: "100%",
            }}
            variant="outlined"
            severity="success"
          >
            {successMessage}
          </Alert>
        )}

        {dropzoneError && (
          <SnackbarAlert
            position="top"
            isOpen={open}
            onClose={handleClose}
            severity="error"
            message={dropzoneError}
          />
        )}
      </Box>
    </Box>
  );
};

export { ExerciseView };
