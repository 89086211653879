/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TimelineItem,
  TimelineItemType,
  useGetTimelineByIdQuery,
  useGetTimelineItemsCountQuery,
  useGetTimelineItemsQuery,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Skeleton, useMediaQuery } from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { TimelineViewHeading } from "../TimelineViewHeading";
import { TimelineViewItemCard } from "../TimelineViewItemCard";
import { AdminTimelineViewInput } from "./AdminTimelineViewInput";

const PAGE_SIZE = 7;
const SCROLL_IS_NEAR_BOTTOM_OFFSET = 100;

const AdminTimelineView: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const { t } = useTranslation();
  const params = useParams();
  const [loadedItems, setLoadedItems] = useState<TimelineItem[]>([]);
  const [showLoadMoreButton, setShowLoadMore] = useState(true);

  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [replyTo, setReplyTo] = useState<TimelineItem>();
  const [isReply, setIsReply] = useState(false);

  const { data: timelineItemsDataForCount, isLoading: isLoadingItemsLength } =
    useGetTimelineItemsCountQuery({
      timelineId: Number(params.timelineId) ?? 0,
    });
  const timelineItemsLength = timelineItemsDataForCount || 0;
  const hasNextPage = timelineItemsLength > page * pageSize;
  const timelineItemsRef = useRef(null);

  const { data: timelineData, isLoading } = useGetTimelineByIdQuery(
    Number(params.timelineId)
  );

  const { data: timelineItemsData, isLoading: isLoadingItems } =
    useGetTimelineItemsQuery({
      timelineId: Number(params.timelineId),
      orderByDescending: true,
      count: pageSize,
      page,
    });

  useEffect(() => {
    if (timelineItemsData) {
      setLoadedItems([...loadedItems, ...timelineItemsData]);
    }
  }, [timelineItemsData]);

  useEffect(() => {
    if (timelineItemsData && page === 1) {
      setLoadedItems(timelineItemsData);
      setShowLoadMore(true);
    }
  }, [loadedItems]);

  const handleLoadMore = () => {
    if (!isLoadingItems && hasNextPage) {
      setPage(page + 1);
      setShowLoadMore(false);
    }
  };

  const handleScroll = () => {
    const element = timelineItemsRef.current as any;
    if (element) {
      const isScrollNearBottom =
        element.scrollHeight -
          element.scrollTop -
          SCROLL_IS_NEAR_BOTTOM_OFFSET <=
        element.clientHeight;
      setShowLoadMore(isScrollNearBottom);
    }
  };

  const handleAddItem = () => {
    const element = timelineItemsRef.current as any;
    if (element) {
      element.scrollTop = 0;
    }
    setPage(1);
    setLoadedItems([]);
    setReplyTo(undefined);
    setIsReply(false);
  };

  const handleReplyComment = (timelineItem: TimelineItem) => {
    if (timelineItem?.id === replyTo?.id) {
      setReplyTo(undefined);
      setIsReply(false);
      return;
    }
    setReplyTo(timelineItem);
    setIsReply(true);
  };

  if (isLoadingItems || isLoading || isLoadingItemsLength)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
      style={{ backgroundColor: Colors.gray[200] }}
    >
      <Box
        sx={{
          width: "100%",
          height: "90vh",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <TimelineViewHeading timelineData={timelineData} />
        <AdminTimelineViewInput
          timelineId={Number(params.timelineId)}
          timelineTitle={timelineData?.name}
          onAddItem={handleAddItem}
          replyTo={replyTo}
          isReply={isReply}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flex: 3,
            gap: "12px",
            height: "100%",
            overflow: "auto",
          }}
          ref={timelineItemsRef}
          onScroll={handleScroll}
        >
          {!isLoadingItems &&
            loadedItems?.map((item, index) => (
              <TimelineViewItemCard
                timelineItem={item}
                key={index}
                onReply={handleReplyComment}
                timelineUserId={timelineData?.userId ?? 0}
              />
            ))}
        </Box>
        {hasNextPage && (
          <PrimaryButton
            size="small"
            type="button"
            value={t("timeline.load-more")}
            loading={isLoading}
            disabled={isLoading || !showLoadMoreButton}
            variant="contained"
            onClick={handleLoadMore}
            style={{
              width: "200px",
              alignSelf: "center",
              justifySelf: "center",
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export { AdminTimelineView };
