import { Box, Button, Icon, Typography } from "@mui/material";
import { Colors } from "../../../../shared/themes";

interface ActionButtonProps {
  isActive: boolean;
  onPress: () => void;
  title: string;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  isActive,
  onPress,
  title,
}) => {
  return (
    <Button
      variant="text"
      sx={{
        width: "100%",
        padding: "0px",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-start",
        textTransform: "none",
        textAlign: "left",
      }}
      onClick={onPress}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
            width: "70%",
            height: "25px",
          }}
        >
          {title}
        </Typography>

        <Icon color="action">{isActive ? "expand_less" : "expand_more"}</Icon>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#E0E0E0",
          marginBottom: "16px",
        }}
      />
    </Button>
  );
};
