import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateUserNoteParams,
  CreateUserNoteWithMediaParams,
  UserNote,
  UserNotesCountParams,
  UserNotesListParams,
  UpdateUserNoteParams,
  GenerateNoteParams,
} from "./models";
import { WorkoutProgram } from "../workoutProgramBuilder/model";

export const userNotesApi = createApi({
  baseQuery,
  reducerPath: "userNotesApi",
  tagTypes: ["UserNotes", "UserNotesCount", "TranscribeToken"],
  endpoints: (builder) => ({
    addUserNote: builder.mutation<UserNote, CreateUserNoteParams>({
      query: (params) => ({
        url: "/user-notes/no-file",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["UserNotes"],
    }),
    addUserNoteWithMedia: builder.mutation<
      UserNote,
      CreateUserNoteWithMediaParams
    >({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.audioFile as File);
        formData.append("dateTime", params.dateTime);
        formData.append("title", params.title);
        formData.append("userId", `${params.userId}`);

        return {
          url: "/user-notes/file",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["UserNotes"],
    }),
    getUserNoteById: builder.query<UserNote, number>({
      query: (id) => ({
        url: `/user-notes/${id}`,
        method: "GET",
      }),
      providesTags: ["UserNotes"],
    }),
    getUserNotes: builder.query<UserNote[], UserNotesListParams>({
      query: (params) => ({
        url: `/user-notes`,
        method: "GET",
        params,
      }),
      providesTags: ["UserNotes"],
    }),
    getUserNotesCount: builder.query<number, UserNotesCountParams>({
      query: (params) => ({
        url: `/user-notes`,
        method: "GET",
        params: {
          countOnly: true,
          userId: params.userId,
        },
      }),
      providesTags: ["UserNotesCount"],
    }),
    getUserNotesByOrganisation: builder.query<
      UserNote[],
      Omit<UserNotesListParams, "userId">
    >({
      query: (params) => ({
        url: `/user-notes/organisation`,
        method: "GET",
        params,
      }),
      providesTags: ["UserNotes"],
    }),
    getUserNotesCountByOrganisation: builder.query<
      number,
      Omit<UserNotesCountParams, "userId">
    >({
      query: (params) => ({
        url: `/user-notes/organisation`,
        method: "GET",
        params: {
          countOnly: true,
        },
      }),
      providesTags: ["UserNotesCount"],
    }),
    startTranscription: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user-notes/transcribe/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["UserNotes", "UserNotesCount"],
    }),
    getTranscript: builder.query<UserNote, number>({
      query: (id) => ({
        url: `/user-notes/transcript/${id}`,
        method: "GET",
      }),
      providesTags: ["UserNotes", "UserNotesCount"],
    }),
    sendUserNoteEmailToClient: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user-notes/send-client-summary-email/${id}`,
        method: "POST",
      }),
    }),
    sendReferralLetterEmailToClient: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user-notes/send-client-referral-letter-email/${id}`,
        method: "POST",
      }),
    }),
    updateUserNote: builder.mutation<UserNote, UpdateUserNoteParams>({
      query: (params) => ({
        url: `/user-notes/${params.id}`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: ["UserNotes"],
    }),
    generateNotes: builder.mutation<UserNote, GenerateNoteParams>({
      query: (params) => ({
        url: `/user-notes/generate-summary/${params.id}?clinicalAbbreviations=${params.clinicalAbbreviations}&includePreviousHistory=${params.includePreviousHistory}`,
        method: "POST",
      }),
      invalidatesTags: ["UserNotes"],
    }),
    generateClientSummary: builder.mutation<UserNote, number>({
      query: (id) => ({
        url: `/user-notes/generate-client-summary/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["UserNotes"],
    }),
    generateProgram: builder.mutation<WorkoutProgram, number>({
      query: (id) => ({
        url: `/user-notes/generate-program/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["UserNotes"],
    }),
    generateReferralLetter: builder.mutation<UserNote, number>({
      query: (id) => ({
        url: `/user-notes/generate-referral-letter/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["UserNotes"],
    }),
    generateTranscribeAuthToken: builder.query<{ token: string }, {}>({
      query: () => ({
        url: `/user-notes/transcribe-auth-token`,
        method: "GET",
      }),
      providesTags: ["TranscribeToken"],
    }),
  }),
});

export const {
  useAddUserNoteMutation,
  useAddUserNoteWithMediaMutation,
  useGetUserNoteByIdQuery,
  useGetUserNotesQuery,
  useGetUserNotesCountQuery,
  useGetUserNotesByOrganisationQuery,
  useGetUserNotesCountByOrganisationQuery,
  useStartTranscriptionMutation,
  useLazyGetTranscriptQuery,
  useSendUserNoteEmailToClientMutation,
  useSendReferralLetterEmailToClientMutation,
  useUpdateUserNoteMutation,
  useGenerateNotesMutation,
  useGenerateClientSummaryMutation,
  useGenerateProgramMutation,
  useGenerateReferralLetterMutation,
  useGenerateTranscribeAuthTokenQuery,
} = userNotesApi;
