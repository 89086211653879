import { createApi } from "@reduxjs/toolkit/query/react";
import {
  Workflow,
  WorkflowParams,
  PatchWorkflowRequest,
  CreateCustomWorkflowRequest,
} from "./models";
import { baseQuery } from "../baseQuery";

export const workflowsApi = createApi({
  baseQuery,
  reducerPath: "workflowsApi",
  endpoints: (builder) => ({
    getWorkflows: builder.query<Workflow[], WorkflowParams>({
      query: (params) => ({
        url: "/workflows",
        method: "GET",
        params,
      }),
    }),
    getWorkflowById: builder.query<Workflow, number>({
      query: (id) => `/workflows/${id}`,
    }),
    updateWorkflow: builder.mutation<void, PatchWorkflowRequest>({
      query: ({
        id,
        workflowStatus,
        workflowRunFrequencyMinutes,
        workflowTriggerConditionMinutes,
        workflowSteps,
      }) => ({
        url: `/workflows/${id}`,
        method: "PATCH",
        body: {
          workflowStatus,
          workflowRunFrequencyMinutes,
          workflowTriggerConditionMinutes,
          workflowSteps,
        },
      }),
    }),
    createCustomWorkflow: builder.mutation<void, CreateCustomWorkflowRequest>({
      query: ({ name, description }) => ({
        url: "/workflows/custom",
        method: "POST",
        body: {
          name,
          description,
        },
      }),
    }),
  }),
});

export const {
  useGetWorkflowsQuery,
  useGetWorkflowByIdQuery,
  useUpdateWorkflowMutation,
  useCreateCustomWorkflowMutation,
} = workflowsApi;
