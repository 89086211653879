import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography, Grid, Button, Icon } from "@mui/material";
import { Colors } from "shared/themes";
import { useTypedSelector } from "shared/stores";
import {
  useGetLatestNutritionPlanForUserIdQuery,
  useGetUserProfileItemQuery,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { userSelectors } from "shared/stores/user";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { NutritionMacroInputModal } from "./NutritionMacroInputModal";
import { BodyweightContainer } from "./BodyweightContainer";
import { NutritionMyFitnessPalContainer } from "./NutritionMyFitnessPalContainer";

interface NutritionContentProps {
  preselectedUserId: number | null;
}

const NutritionContent: FC<NutritionContentProps> = ({ preselectedUserId }) => {
  const { t } = useTranslation();

  const [selectedUserId] = useState<number | null>(
    preselectedUserId ?? useTypedSelector(userSelectors.userId)
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: user, refetch } = useGetUserProfileItemQuery(selectedUserId!!);

  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const calculateCalories = (protein: number, carbs: number, fat: number) => {
    return Math.round(protein * 4 + carbs * 4 + fat * 9);
  };

  const {
    data: latestNutritionPlan,
    isError,
    error: fetchErrorMessage,
    isLoading: isLoadingNutritionPlan,
  } = useGetLatestNutritionPlanForUserIdQuery(selectedUserId!!, {
    skip: !selectedUserId,
  });

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: "32px",
        mb: "16px",
        overflowY: "auto",
      }}
    >
      {!latestNutritionPlan && !isLoadingNutritionPlan && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "140%",
            mb: 2,
            color: `${Colors.gray[1200]}`,
          }}
        >
          You do not have a nutrition plan. Speak to your coach to get started.
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
            <Typography sx={sharedStyles.headings.dataGrid}>
              {t("nutrition.macros-sub-title")}
              {isAdminOrCoach && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    height: "36px",
                    ml: 2,
                  }}
                  type="submit"
                  size="small"
                  onClick={handleToggleModal}
                  disabled={isLoadingNutritionPlan}
                >
                  <Icon
                    sx={{
                      color: `${Colors.blue[1200]}`,
                      fontSize: "14px",
                      mr: "5px",
                    }}
                  >
                    create
                  </Icon>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      lineHeight: "24px",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      color: `${Colors.blue[1200]}`,
                      textTransform: "none",
                    }}
                  >
                    Edit
                  </Typography>
                </Button>
              )}
            </Typography>
          </Box>
          <Box>
            {!isLoadingNutritionPlan ? (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Target</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Protein
                      </TableCell>
                      <TableCell>
                        {latestNutritionPlan
                          ? latestNutritionPlan.nutritionPlanItems[0]
                              .proteinTarget
                          : 0}
                        g
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Carbs
                      </TableCell>
                      <TableCell>
                        {latestNutritionPlan
                          ? latestNutritionPlan.nutritionPlanItems[0]
                              .carbohydrateTarget
                          : 0}
                        g
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Fat
                      </TableCell>
                      <TableCell>
                        {latestNutritionPlan
                          ? latestNutritionPlan.nutritionPlanItems[0].fatTarget
                          : 0}
                        g
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Calories
                      </TableCell>
                      <TableCell>
                        {latestNutritionPlan
                          ? latestNutritionPlan.nutritionPlanItems[0]
                              .calorieTarget
                          : 0}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.gray[1200]}`,
                }}
              >
                Loading...
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <BodyweightContainer preselectedUserId={selectedUserId} />
        </Grid>
      </Grid>
      {user?.myFitnessPalUsername && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <NutritionMyFitnessPalContainer
              myFitnessPalUsername={user?.myFitnessPalUsername ?? ""}
            />
          </Grid>
        </Grid>
      )}
      {fetchErrorMessage && (
        <SnackbarAlert
          position="bottom"
          isOpen={!!fetchErrorMessage}
          onClose={() => {}}
          severity="error"
          message={t("nutrition.fetch-macros-error")}
        />
      )}
      <NutritionMacroInputModal
        handleCloseModal={handleToggleModal}
        isOpenModal={isOpenModal}
        preselectedUserId={selectedUserId}
        calculateCalories={calculateCalories}
        latestNutritionPlan={latestNutritionPlan}
      />
    </Box>
  );
};

export { NutritionContent };
