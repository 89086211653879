/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";
import { Alert, Typography, useMediaQuery } from "@mui/material";
import { Timeline } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Colors } from "shared/themes";

interface TimelineViewHeadingProps {
  timelineData?: Timeline;
}

const TimelineViewHeading: FC<TimelineViewHeadingProps> = ({
  timelineData,
}) => {
  return (
    <div style={{ display: "flex", gap: "16px" }}>
      <IdenticonAvatar
        seedValue={timelineData?.userId.toString() ?? ""}
        sizeValue={50}
        profileImageUrl={timelineData?.profileImageUrl}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "22px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {timelineData?.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {timelineData?.timelineStatus}
        </Typography>
      </div>
    </div>
  );
};

export { TimelineViewHeading };
