import { FC, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Alert,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { AnyType } from "shared/helpers";
import { Colors } from "shared/themes";
import {
  useGetWorkflowByIdQuery,
  PatchWorkflowRequest,
  useUpdateWorkflowMutation,
  WorkflowStep,
} from "shared/api";

const IndividualWorkflow: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [workflowSteps, setWorkflowSteps] = useState<WorkflowStep[]>([]);

  const [workflowUpdating, setWorkflowUpdating] = useState(false);

  const {
    data: workflow,
    refetch,
    isLoading,
  } = useGetWorkflowByIdQuery(Number(params.workflowId));

  const WorkflowSchema = Yup.object().shape({
    workflowStatus: Yup.string(),
    workflowRunFrequencyMinutes: Yup.number(),
    workflowTriggerConditionMinutes: Yup.number(),
  });

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const displayWorkflowStepContent = (workflowStep: any) => {
    if (
      workflowStep.workflowActionType === "SendTimelineItem" ||
      workflowStep.workflowActionType === "SendPushNotification" ||
      workflowStep.workflowActionType === "SendSms" ||
      workflowStep.workflowActionType === "ConfigureEmail"
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = async (params: PatchWorkflowRequest) => {
    try {
      setWorkflowUpdating(true);
      setSuccessMessage("");
      setErrorMessage("");

      if (!workflow?.id) {
        setErrorMessage("Workflow not found");
        setWorkflowUpdating(false);
        return;
      }

      params.workflowSteps = workflowSteps;

      await updateWorkflow(params).unwrap();
      await refetch();

      setWorkflowSteps(workflow?.workflowSteps || []);

      setSuccessMessage("Workflow updated successfully");
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
      setWorkflowUpdating(false);
      window.setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<PatchWorkflowRequest>({
    initialValues: {
      id: workflow?.id || 0,
      workflowStatus: workflow?.workflowStatus || "Undefined",
      workflowRunFrequencyMinutes: workflow?.workflowRunFrequencyMinutes || 0,
      workflowTriggerConditionMinutes:
        workflow?.workflowTriggerConditionMinutes || 0,
      workflowSteps: workflow?.workflowSteps || [],
    },
    enableReinitialize: true,
    validationSchema: WorkflowSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  useEffect(() => {
    setWorkflowSteps(workflow?.workflowSteps || []);
  }, [workflow]);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {workflow?.name}
        </Typography>
      </Box>
      <Box sx={{ mt: "-20px", mb: 2 }}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {workflow?.description}
        </Typography>
      </Box>
      {errorMessage && (
        <Alert
          sx={{
            mt: 3,
            width: "100%",
          }}
          variant="outlined"
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert
          sx={{
            mt: 3,
            width: "100%",
          }}
          variant="outlined"
          severity="success"
        >
          {successMessage}
        </Alert>
      )}
      <Box sx={sharedStyles.containers.dataGrid.wrapper}>
        <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "0.16px",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              Status
            </Typography>
            <Select
              value={values.workflowStatus || workflow?.workflowStatus}
              defaultValue={values.workflowStatus}
              onBlur={handleBlur("workflowStatus")}
              onChange={(e) => setFieldValue("workflowStatus", e.target.value)}
              sx={{
                width: "100%",
                mb: { xs: "15px", md: "0px" },
                paddingTop: "3px",
              }}
            >
              <MenuItem value="Enabled">Enabled</MenuItem>
              <MenuItem value="Disabled">Disabled</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "0.16px",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              Run Frequency (Minutes)
            </Typography>
            <TextField
              type="number"
              value={values.workflowRunFrequencyMinutes}
              onBlur={handleBlur("workflowRunFrequencyMinutes")}
              onChange={handleChange("workflowRunFrequencyMinutes")}
              sx={{
                height: "40%",
                width: "100%",
                mb: { xs: "15px", md: "0px" },
              }}
              inputProps={{ style: { marginTop: "3px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "0.16px",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              Trigger Condition (Minutes)
            </Typography>
            <TextField
              type="number"
              value={values.workflowTriggerConditionMinutes}
              onBlur={handleBlur("workflowTriggerConditionMinutes")}
              onChange={handleChange("workflowTriggerConditionMinutes")}
              sx={{
                height: "40%",
                width: "100%",
                mb: { xs: "15px", md: "0px" },
              }}
              inputProps={{ style: { marginTop: "3px" } }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Divider />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
                mb: "16px",
                mt: "16px",
              }}
            >
              Steps
            </Typography>

            {workflowSteps?.map((workflowStep, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  height: "auto",
                  background: `${Colors.gray[100]}`,
                  borderRadius: "4px",
                  padding: "16px 24px 24px",
                  border: `1px solid ${Colors.gray[1400]}`,
                  marginBottom: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "16px" }}>
                  {workflowStep.stepOrder}
                </div>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "140%",
                      color: `${Colors.blue[1300]}`,
                    }}
                  >
                    {workflowStep.name}
                  </Typography>

                  <Typography
                    sx={{
                      width: "100%",
                      height: "44px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "140%",
                      color: `${Colors.blue[1300]}`,
                      marginBottom: "8px",
                    }}
                  >
                    {workflowStep.description}
                  </Typography>
                  {displayWorkflowStepContent(workflowStep) && (
                    <TextField
                      sx={{
                        width: "100%",
                        height: "44px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "140%",
                        color: `${Colors.blue[1300]}`,
                        marginBottom: "8px",
                      }}
                      value={workflowStep.content}
                      onChange={(e) => {
                        const updatedWorkflowSteps = workflowSteps.map(
                          (step) => {
                            if (step.id === workflowStep.id) {
                              return {
                                ...step,
                                content: e.target.value,
                              };
                            }
                            return step;
                          }
                        );
                        setWorkflowSteps(updatedWorkflowSteps);
                      }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} sm={12}>
            <LoadingButton
              loading={workflowUpdating || isLoading}
              onClick={() => {
                handleSubmit();
              }}
              color="primary"
              variant="contained"
              sx={{
                mt: 2,
                textTransform: "none",
              }}
            >
              Update Workflow
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { IndividualWorkflow };
