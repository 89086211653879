import { createApi } from "@reduxjs/toolkit/query/react";
import {
  LoginRequest,
  LoginResponse,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from "./models";
import { baseQuery } from "../baseQuery";

export const authApi = createApi({
  baseQuery,
  reducerPath: "authApi",
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ email, password }) => ({
        url: "/users/authenticate",
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/users/revoke-token",
        method: "POST",
      }),
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
      query: ({ email }) => ({
        url: "/users/forgot-password",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: ({ token, password, confirmPassword }) => ({
        url: "/users/reset-password",
        method: "POST",
        body: {
          token,
          password,
          confirmPassword,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
