import { Chip, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { userSelectors } from "shared/stores/user";
import { useTypedSelector } from "shared/stores";
import {
  CombinedData,
  WorkoutItemType,
  WorkoutLoadStatus,
} from "../../../../shared/api";
import { Colors } from "../../../../shared/themes";
import { ChangeWorkoutDetailsModal } from "./ChangeWorkoutDetailsModal";
import { WorkoutTrendIcon } from "./WorkoutTrendIcon";

interface WorkoutListViewItemProps {
  exercise: CombinedData;
  selectedUserId: number;
}

const WorkoutListViewItem: FC<WorkoutListViewItemProps> = ({
  exercise,
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const [exerciseName, setExerciseName] = useState(exercise.exerciseName);

  const isExerciseCompleted = exercise.workoutItemSchemeBreakdowns.every(
    (item) =>
      item.workoutItemLogs.length > 0 &&
      item.workoutItemLogs.every(
        (log) =>
          log.completedReps ||
          log.completedPartialReps ||
          log.completedIntensity ||
          log.completedWeight ||
          log.completedRepsLeftSide ||
          log.completedRepsRightSide ||
          log.completedPartialRepsLeftSide ||
          log.completedPartialRepsRightSide ||
          log.completedIntensityLeftSide ||
          log.completedIntensityRightSide ||
          log.completedWeightLeftSide ||
          log.completedWeightRightSide ||
          log.completedDistance ||
          log.completedTime ||
          log.completedSpeed
      )
  );

  const displayTrend =
    isAdminOrCoach &&
    exercise.workoutLoadStatus !== WorkoutLoadStatus.Undefined;

  const hasTrainerNotes = exercise.notes?.length > 0;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          openModal();
        }}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          mb: "20px",
          boxShadow:
            "0px 10px 40px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
          borderRadius: "12px",
          padding: "20px",
          backgroundColor: "#FAFAFA",
          border: "1px solid #F5F5F5",
          boxSizing: `border-box`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: 1,
          }}
        >
          <Box
            sx={{
              width: "70%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "140%",
                    color: `${Colors.gray[1200]}`,
                    textTransform: "none",
                    mb: "4px",
                  }}
                >
                  {isExerciseCompleted ? (
                    <Icon
                      sx={{
                        color: `${Colors.blue[1200]}`,
                        fontSize: "16px",
                        mr: "4px",
                      }}
                    >
                      check_circle_outline
                    </Icon>
                  ) : null}
                  {exercise ? exerciseName : ""}

                  {displayTrend ? (
                    <WorkoutTrendIcon finalTrend={exercise.workoutLoadStatus} />
                  ) : null}
                </Typography>
                {exercise.workoutItemSchemeBreakdowns.map((exerciseItem) => (
                  <div
                    key={`${exercise.exerciseId}-${exerciseItem.workoutItemId}`}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "140%",
                        color: `${Colors.oxford[1100]}`,
                        opacity: "0.87",
                        textDecoration: "none",
                        mb: "7px",
                      }}
                    >
                      <Icon
                        sx={{
                          fontSize: "16px !important",
                          mr: "4px",
                          ml: "4px",
                          color: `${Colors.oxford[1100]}`,
                          opacity: "0.87",
                        }}
                      >
                        fitness_center
                      </Icon>
                      {exerciseItem.prescribedSets}{" "}
                      {Number.isNaN(
                        parseInt(exerciseItem.prescribedSets, 10)
                      ) || parseInt(exerciseItem.prescribedSets, 10) > 1
                        ? "sets"
                        : "set"}{" "}
                      {exerciseItem.prescribedReps
                        ? ` x ${exerciseItem.prescribedReps} ${
                            Number.isNaN(
                              parseInt(exerciseItem.prescribedReps, 10)
                            ) || parseInt(exerciseItem.prescribedReps, 10) > 1
                              ? "reps"
                              : "rep"
                          }`
                        : null}
                      {exerciseItem.prescribedIntensity &&
                      (exerciseItem.workoutItemType !== WorkoutItemType.RPE ||
                        exerciseItem.prescribedIntensity !== "0")
                        ? ` @ ${exerciseItem.prescribedIntensity} ${
                            exerciseItem.workoutItemType !==
                            WorkoutItemType.UNDEFINED
                              ? exerciseItem.workoutItemType
                              : ""
                          }`
                        : null}
                      {exerciseItem.prescribedWeight &&
                      exerciseItem.prescribedWeight !== "0"
                        ? ` @ ${exerciseItem.prescribedWeight} ${
                            exerciseItem?.workoutItemWeightUnit ?? "Kg"
                          }`
                        : null}
                    </Typography>

                    {!!exerciseItem.restTime && (
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "140%",
                          color: `${Colors.blue[1300]}`,
                          opacity: "0.87",
                          textDecoration: "none",
                        }}
                      >
                        {t("session-card.rest-time")} {exerciseItem.restTime}{" "}
                      </Typography>
                    )}
                  </div>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              zIndex: 999,
              width: "38px",
              height: "24px",
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "38px",
                  height: "24px",
                }}
              >
                <Icon
                  sx={{
                    color: `${Colors.blue[1200]}`,
                    fontSize: "16px",
                    marginLeft: "-100%",
                  }}
                >
                  add_circle_outline
                </Icon>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  lineHeight: "24px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  marginLeft: "-50%",
                  color: `${Colors.blue[1200]}`,
                }}
              >
                Enter
              </Typography>
            </Box>
          </Box>
        </Box>
        {hasTrainerNotes && (
          <Chip
            label={
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.oxford[1100]}`,
                  opacity: "0.87",
                  textDecoration: "none",
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                <Icon
                  className="material-icons-outlined"
                  sx={{
                    fontSize: "16px !important",
                    mr: "4px",
                    ml: "4px",
                    color: `${Colors.oxford[1100]}`,
                    opacity: "0.87",
                  }}
                >
                  sms
                </Icon>
                {t("session-card.has-trainers-notes")}
              </Typography>
            }
          />
        )}
      </Box>
      <ChangeWorkoutDetailsModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        exercise={exercise}
        exerciseName={exerciseName}
        setExerciseName={setExerciseName}
        selectedUserId={selectedUserId}
      />
    </Box>
  );
};

export { WorkoutListViewItem };
