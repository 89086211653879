import { FC, useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, Button, Icon } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { useTypedSelector } from "shared/stores";
import { createLeadsRows } from "shared/helpers";
import { sharedStyles } from "shared/themes/shared/styles";
import { useGetLeadsCountQuery, useGetLeadsQuery } from "../../shared/api";
import { AddLeadModal } from "./AddLeadModal";
import { LeadStatusItem } from "./LeadStatusItem";

interface LeadsContainerProps {
  openSidebar: () => void;
}

const LeadsContainer: FC<LeadsContainerProps> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { data: leadsCount } = useGetLeadsCountQuery();

  const { data: leads, isLoading } = useGetLeadsQuery({
    count: pageSize,
    page,
    countOnly: false,
  });

  const [rowCountState, setRowCountState] = useState(leadsCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      leadsCount !== undefined ? leadsCount : prevRowCountState
    );
  }, [leadsCount, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "leadFullName",
      headerName: t("leads.name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: t("leads.email"),
      flex: 1,
      sortable: false,
    },
    {
      field: "leadStatus",
      headerName: t("leads.leadStatus"),
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return <LeadStatusItem status={params.value} />;
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.leads.url}/${id}`}>
            <Button
              color="primary"
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              {t("leads.view-btn")}
            </Button>
          </Link>
        );
      },
      filterable: false,
    },
  ];

  const rows = createLeadsRows(leads!);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("leads.title")}
        </Typography>
      </Box>
      <AddLeadModal
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
      />
      <Box
        sx={{
          mt: "-20px",
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {t("leads.sub-title")}
        </Typography>

        <Button
          variant="contained"
          onClick={() => setIsOpenModal(true)}
          sx={{
            height: "36px",
            background: `${Colors.blue[1200]}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            sx={{
              marginRight: "8px",
            }}
          >
            add_icon
          </Icon>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              color: `${Colors.gray[100]}`,
              textTransform: "none",
            }}
          >
            {t("leads.add-btn")}
          </Typography>
        </Button>
      </Box>
      <Box sx={sharedStyles.containers.dataGrid.wrapper}>
        <Box sx={{ height: "700px" }}>
          <DataGrid
            headerHeight={36}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows || []}
            disableColumnFilter
            rowCount={rowCountState}
            paginationMode="server"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { LeadsContainer };
