import { FC } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTranslation } from "react-i18next";
import { useGetUsersQuery } from "../../shared/api";

interface LiveTrainingClientSelectorProps {
  setSelectedUserId: (id: number | null) => void;
}

const LiveTrainingClientSelector: FC<LiveTrainingClientSelectorProps> = ({
  setSelectedUserId,
}) => {
  const { t } = useTranslation();

  const {
    data: users,
    isLoading,
    error,
  } = useGetUsersQuery({
    userRole: "athlete",
    orderByName: true,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {error && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("errors.server-unable")}
          </Typography>
        </Alert>
      )}
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <FormControl
          sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Select Client
          </InputLabel>
          <Select
            label="Select Client"
            onChange={(e: { target: { value: any } }) =>
              setSelectedUserId(e.target.value)
            }
            defaultValue=""
          >
            {users?.map((user) => (
              <MenuItem value={user.id} key={user.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export { LiveTrainingClientSelector };
