import { InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import React from "react";
import { sharedStyles } from "shared/themes/shared/styles";

export type BaseOption = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

type SelectInputFieldProps = {
  label?: string;
  placeholder?: string; // Add placeholder prop
  data: BaseOption[];
};

export const SelectInputField: React.FC<
  SelectInputFieldProps & SelectProps
> = ({ placeholder, ...props }) => {
  return (
    <div style={{ width: "100%" }}>
      {props.label && <InputLabel htmlFor={props.id}>{props.label}</InputLabel>}
      <Select
        sx={sharedStyles.inputs.signIn}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return placeholder || "Select an option";
          }
          return (
            props.data.find((item) => item.value === selected)?.label || ""
          );
        }}
        {...props}
        label=""
      >
        {props.data.map((item) => (
          <MenuItem
            value={item.value}
            key={item.value}
            disabled={item?.isDisabled ?? false}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
