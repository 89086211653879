import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  Button,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import {
  UserBodyMeasurementParams,
  useCreateUserBodyMeasurementMutation,
  CreateUserBodyMeasurementRequest,
  useGetUserBodyMeasurementsQuery,
} from "shared/api";
import { UserBodyMeasurementsChart } from "pages/Users/UserBodyMeasurementsChart";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";

interface BodyweightContainerProps {
  preselectedUserId: number | null;
}

const BodyweightContainer: FC<BodyweightContainerProps> = ({
  preselectedUserId = null,
}) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const { data: userBodyMeasurements, isLoading: isLoadingFetch } =
    useGetUserBodyMeasurementsQuery(
      {
        userId: preselectedUserId!!,
      } as UserBodyMeasurementParams,
      { skip: !preselectedUserId }
    );

  const [openModal, setOpenModal] = useState(false);
  const [weightValue, setWeightValue] = useState("");
  const [weightUnitValue, setWeightUnitValue] = useState("kg");

  const handleAddUserBodyMeasurement = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setWeightValue("");
    setWeightUnitValue("kg");
  };

  const [createUserBodyMeasurement] = useCreateUserBodyMeasurementMutation();

  async function handleSaveUserBodyMeasurement() {
    if (weightValue) {
      const params: CreateUserBodyMeasurementRequest = {
        userId: preselectedUserId!!,
        weight: weightValue ? Number(weightValue) : null,
        weightUnit: weightUnitValue,
      };

      setIsLoadingCreate(true);

      try {
        await createUserBodyMeasurement(params);
        setSuccessMessage(t("nutrition.body-weight-save-success"));
        handleCloseModal();
      } catch {
        setErrorMessage(t("nutrition.body-weight-save-error"));
      } finally {
        setIsLoadingCreate(false);
      }
    }
  }

  const isLoading = isLoadingCreate || isLoadingFetch;

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        mb: "16px",
        overflowY: "auto",
      }}
    >
      <UserBodyMeasurementsChart userBodyMeasurements={userBodyMeasurements} />
      <Box
        sx={{
          mt: 2,
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleAddUserBodyMeasurement}
          loading={isLoading}
        >
          Log Body Weight
        </LoadingButton>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 8,
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>Log Body Measurement</DialogTitle>
        <DialogContent>
          <Box sx={{ marginBottom: "2.5rem" }}>
            <TextField
              label="Weight"
              type="number"
              value={weightValue}
              onChange={(e) => setWeightValue(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box>
            <Select
              label="Unit"
              value={weightUnitValue}
              onChange={(e) => setWeightUnitValue(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="kg">Kg</MenuItem>
              <MenuItem value="lbs">Lbs</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <LoadingButton
            onClick={() => handleSaveUserBodyMeasurement()}
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {errorMessage && (
        <SnackbarAlert
          isOpen={!!errorMessage}
          onClose={() => {
            setErrorMessage("");
          }}
          severity="error"
          message={errorMessage}
          position="bottom"
        />
      )}
      {successMessage && (
        <SnackbarAlert
          isOpen={!!successMessage}
          onClose={() => {
            setSuccessMessage("");
          }}
          severity="success"
          message={successMessage}
        />
      )}
    </Box>
  );
};

export { BodyweightContainer };
