import { FC } from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const YourScheduleSkeleton: FC = () => {
  return (
    <Box sx={{ width: "100%", height: "156px" }}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "22px", width: "100%", mb: "12px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "22px", width: "100%", mb: "12px" }}
      />
      <Skeleton
        variant="circular"
        width={40}
        height={40}
        sx={{
          mb: "18px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "30px", width: "100%", mb: "12px" }}
      />
    </Box>
  );
};

export { YourScheduleSkeleton };
