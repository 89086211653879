import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";

interface GridStatusItemProps {
  status?: string;
}

const GridStatusItem: FC<GridStatusItemProps> = ({ status }) => {
  const { t } = useTranslation();
  const statusToUse = status || "Active";
  const badgeText =
    statusToUse === "Active"
      ? t("users-management.status-active")
      : t("users-management.status-inactive");
  return (
    <Box
      sx={
        statusToUse === "Active"
          ? sharedStyles.containers.dataGrid.tagActive
          : sharedStyles.containers.dataGrid.tagInactive
      }
    >
      <Box
        sx={
          statusToUse === "Active"
            ? sharedStyles.tags.tagActive
            : sharedStyles.tags.tagInactive
        }
      />
      {badgeText}
    </Box>
  );
};

export { GridStatusItem };
