import {
  BaseWorkoutProgramDesignerDto,
  WorkoutProgramDesignerRowWeek,
  WorkoutProgramDesignerSection,
  WorkoutProgramGroupItem,
} from "shared/api";
import { GridRowType } from "./types";

export const findMatchingWorkoutProgram = (
  groupItems: WorkoutProgramGroupItem[],
  programs: BaseWorkoutProgramDesignerDto[],
  versionNumber: number
) => {
  const matchingGroupItem = groupItems?.find(
    (item) => item.versionNumber === versionNumber
  );

  if (matchingGroupItem) {
    const matchingWorkoutProgram = programs?.find(
      (item) =>
        item.workoutProgramId === matchingGroupItem.childWorkoutProgramId
    );

    return matchingWorkoutProgram;
  }

  return null;
};

export const mapExerciseWeekRows = (detail: WorkoutProgramDesignerRowWeek) => ({
  [`week${detail.weekNumber}weight`]: detail.weight ?? "",
  [`week${detail.weekNumber}intensity`]: detail.rpe ?? "",
  [`week${detail.weekNumber}reps`]: detail.reps ?? "",
  [`week${detail.weekNumber}sets`]: detail.sets ?? "",
  [`week${detail.weekNumber}rest`]: detail.rest ?? "",
  [`week${detail.weekNumber}log`]: detail.readOnlyCompletedLog ?? "",
});

export const mapToExerciseGridRows = (
  workout: WorkoutProgramDesignerSection
) => {
  const rowValues = workout.workoutItems.map((workoutItem) => {
    const weeks = workoutItem.weeks.map((detail) =>
      mapExerciseWeekRows(detail)
    );
    return {
      exercise: workoutItem.exercise,
      type: workoutItem?.type === "Undefined" ? "" : workoutItem.type,
      unit: workoutItem?.unit === "Undefined" ? "" : workoutItem.unit,
      reps: workoutItem.baseReps,
      base: workoutItem.baseValue,
      // Engineering Note: By default we use week 1 of week1intensity as the base intensity
      baseIntensity: `${workoutItem.weeks[0]?.rpe}` ?? "",
      sets: workoutItem.baseSets,
      supersets: workoutItem.supersetGroup,
      rest: workoutItem.baseRest,
      increase: workoutItem.increase,
      workoutItemId: workoutItem.workoutItemId,
      notes: workoutItem.notes,
      ...Object.assign({}, ...weeks),
    } as GridRowType;
  });
  return {
    dayNumber: workout.dayNumber,
    rows: rowValues,
  };
};
