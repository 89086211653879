import { Alert, AlertProps } from "@mui/lab";
import React from "react";

type FormErrorProps = {
  errorMessage: string;
};

export const FormError: React.FC<FormErrorProps & AlertProps> = ({
  ...props
}) => {
  return (
    <Alert
      sx={() => ({
        width: "100%",
        mb: 2,
        mt: 2,
      })}
      {...props}
      variant="outlined"
      severity="error"
    >
      {props.errorMessage}
    </Alert>
  );
};
