/* eslint-disable jsx-a11y/media-has-caption */
import { Alert, Button, Skeleton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { RealtimeTranscriber } from "assemblyai";
import { BaseOption, SelectInputField } from "components/Form/SelectInputField";
import { useState, useRef, FC, useEffect } from "react";
import RecordRTC from "recordrtc";
import AudioIndicator from "./AudioIndicator";

type AudioRecorderProps = {
  shortLivedToken: string;
  handleCreateWithLiveRecordedAudio: (transcription: string) => void;
  handleDisplayUploadFileScreen: () => void;
};

const AudioRecorder: FC<AudioRecorderProps> = ({
  handleCreateWithLiveRecordedAudio,
  shortLivedToken,
  handleDisplayUploadFileScreen,
}) => {
  const [hasMicPermission, setHasMicPermission] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [transcript, setTranscript] = useState<string>("");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [audioInputDevices, setAudioInputDevices] = useState<BaseOption[]>();
  const [selectedInputDevice, setSelectedInputDevice] = useState<string>();

  const realtimeTranscriber = useRef<RealtimeTranscriber | null>(null);

  const handleSubmitRecording = async () => {
    handleCreateWithLiveRecordedAudio(transcript);
  };

  const [loadingRecordingModules, setLoadingRecordingModules] = useState(false);

  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasMicPermission(true);
      setStream(stream);
    } catch (err) {
      console.error("Microphone permission denied", err);
      setHasMicPermission(false);
    }
  };

  useEffect(() => {
    const checkMicPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        if (permissionStatus.state === "granted") {
          setHasMicPermission(true);
        } else if (permissionStatus.state === "denied") {
          setHasMicPermission(false);
        }
        // Handle 'prompt' state if you want to auto-ask for permission when not denied
      } catch (err) {
        console.error("Error checking microphone permission", err);
      }
    };

    checkMicPermission();
  }, []);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // audio only
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      if (audioInputDevices.length === 0) {
        setHasMicPermission(false);
      }

      const audioInputDevicesNoDefault = audioInputDevices.filter(
        (device) => device.deviceId !== "default"
      );

      setAudioInputDevices(
        audioInputDevicesNoDefault?.map((device) => ({
          label: device.label,
          value: device.deviceId,
        })) ?? []
      );
    });
  }, [hasMicPermission]);

  useEffect(() => {
    if (selectedInputDevice) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedInputDevice
              ? { exact: selectedInputDevice }
              : undefined,
          },
        })
        .then((stream) => {
          setStream(stream);
        });
    }
  }, [selectedInputDevice]);

  const startTranscription = async () => {
    if (!hasMicPermission || !stream) return;
    if (realtimeTranscriber.current) return;
    setLoadingRecordingModules(true);
    setAudioUrl("");
    setTranscript("");

    realtimeTranscriber.current = new RealtimeTranscriber({
      token: shortLivedToken,
      sampleRate: 16_000,
    });

    const texts: { [key: number]: string } = {};
    realtimeTranscriber.current.on("transcript", (transcript) => {
      let msg = "";
      texts[transcript.audio_start] = transcript.text;
      const keys = Object.keys(texts).map(Number);
      keys.sort((a, b) => a - b);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`;
        }
      }
      setTranscript(msg);
    });

    realtimeTranscriber.current.on("error", (event) => {
      console.error(event);
      realtimeTranscriber.current?.close();
      realtimeTranscriber.current = null;
    });

    realtimeTranscriber.current.on("close", (code, reason) => {
      console.log(`Connection closed: ${code} ${reason}`);
      realtimeTranscriber.current = null;
    });

    await realtimeTranscriber.current.connect();

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: selectedInputDevice
            ? { exact: selectedInputDevice }
            : undefined,
        },
      })
      .then((stream) => {
        setStream(stream);
        setLoadingRecordingModules(false);
        const recordRTC = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: async (blob: Blob) => {
            if (!realtimeTranscriber.current) return;
            const buffer = await blob.arrayBuffer();
            realtimeTranscriber.current.sendAudio(buffer);
          },
        });
        setRecorder(recordRTC);
        recordRTC.startRecording();
      })
      .catch((err) => console.error(err));

    setIsRecording(true);
  };

  const endTranscription = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setIsSaving(true);
    setIsRecording(false);

    if (realtimeTranscriber.current) {
      await realtimeTranscriber.current.close();
      realtimeTranscriber.current = null;
    }

    // if transcription is empty, stop recording and return
    if (transcript.length === 0) {
      if (recorder) {
        recorder.stopRecording(() => {
          const audioBlob = recorder.getBlob();
          if (audioBlob) {
            const url = URL.createObjectURL(audioBlob);
            setAudioUrl(url); // Set the audio URL state
          }
        });
      }

      setRecorder(null);
      setIsSaving(false);
      return;
    }

    if (recorder) {
      recorder.stopRecording(async () => {
        const audioBlob = recorder.getBlob();
        if (audioBlob) {
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url); // Set the audio URL state
        }
      });
    }

    setRecorder(null);
    setIsSaving(false);
  };

  const handleDownload = () => {
    if (audioUrl) {
      const a = document.createElement("a");
      a.href = audioUrl;
      a.download = "audio.wav";
      a.click();
    }
  };

  const handleSelectInputDevice = (e: any) => {
    setSelectedInputDevice(e.target.value as string);
  };

  const displayAudioIndicator = stream && selectedInputDevice;
  const isLoadingRecording = !isRecording && loadingRecordingModules;

  return (
    <div className="App">
      {hasMicPermission && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <SelectInputField
            data={audioInputDevices ?? []}
            value={selectedInputDevice}
            onChange={handleSelectInputDevice}
            disabled={isRecording || loadingRecordingModules}
            placeholder="Select a microphone"
          />
          {displayAudioIndicator && (
            <AudioIndicator
              stream={stream}
              isLoadingRecording={isLoadingRecording}
            />
          )}
        </Box>
      )}
      {!hasMicPermission ? (
        <Button
          size="large"
          type="button"
          variant="outlined"
          fullWidth
          onClick={requestMicPermission}
          sx={{
            textTransform: "none",
            fontWeight: "600",
            mt: 1,
            mb: 1,
          }}
        >
          Enable Microphone
        </Button>
      ) : (
        <Box>
          {isRecording ? (
            <Button
              size="large"
              type="button"
              variant="contained"
              color="error"
              fullWidth
              onClick={endTranscription}
              sx={{
                textTransform: "none",
                fontWeight: "600",
                mt: 1,
                mb: 1,
              }}
              disabled={loadingRecordingModules}
            >
              Stop recording
            </Button>
          ) : (
            <Button
              size="large"
              type="button"
              variant="contained"
              fullWidth
              onClick={startTranscription}
              sx={{
                textTransform: "none",
                fontWeight: "600",
                mt: 1,
                mb: 1,
              }}
              disabled={
                loadingRecordingModules ||
                !selectedInputDevice ||
                !stream ||
                isSaving
              }
            >
              {transcript.length > 0 ? "Record again" : "Start recording"}
            </Button>
          )}
        </Box>
      )}

      {transcript.length > 0 && (
        <TextField
          variant="outlined"
          name="description"
          fullWidth
          multiline
          rows={10}
          type="text"
          value={transcript}
          sx={{
            mt: 1,
            mb: 1,
          }}
        />
      )}

      {isSaving && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ height: "110px", width: "100%", mb: "12px" }}
        />
      )}

      {audioUrl && !isRecording && !isSaving && transcript.length !== 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
            borderRadius: 2,
          }}
        >
          <audio src={audioUrl} controls />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 1,
              gap: 1,
            }}
          >
            <Button
              type="button"
              onClick={handleDownload}
              disabled={!transcript}
              variant="text"
              color="info"
              sx={{
                textTransform: "none",
                fontWeight: "600",
              }}
              fullWidth
            >
              Download Audio
            </Button>
            <Button
              type="button"
              onClick={handleSubmitRecording}
              disabled={!transcript}
              variant="contained"
              color="info"
              sx={{
                textTransform: "none",
                fontWeight: "600",
              }}
              fullWidth
            >
              Transcribe Audio
            </Button>
          </Box>
        </Box>
      )}

      <Button
        sx={{
          textTransform: "none",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        color="primary"
        variant="text"
        type="button"
        onClick={handleDisplayUploadFileScreen}
        disabled={isRecording || loadingRecordingModules}
      >
        Or upload a file to transcribe
      </Button>
      {audioUrl && !isRecording && !isSaving && transcript.length === 0 && (
        <Alert
          sx={{
            mb: 1,
            width: "100%",
          }}
          variant="outlined"
          severity="error"
        >
          Something went wrong with the transcription. Please give it another
          try.
        </Alert>
      )}
    </div>
  );
};

export default AudioRecorder;
