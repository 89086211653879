import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { CreateNewWorkoutProgramBuilderRequest, WorkoutProgram } from "./model";

export const workoutProgramBuilderApi = createApi({
  reducerPath: "workoutProgramBuilderApi",
  baseQuery,
  tagTypes: ["WorkoutProgramBuilder"],
  endpoints: (builder) => ({
    createNewWorkoutProgramBuilder: builder.mutation<
      WorkoutProgram,
      CreateNewWorkoutProgramBuilderRequest
    >({
      query: (body) => ({
        url: "/workoutprogrambuilder/create-new-workout-program-builder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkoutProgramBuilder"],
    }),
    updateDraftWorkoutProgramBuilder: builder.mutation<
      void,
      CreateNewWorkoutProgramBuilderRequest
    >({
      query: (body) => ({
        url: "/workoutprogrambuilder/update-draft-workout-program-builder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkoutProgramBuilder"],
    }),
    getWorkoutProgramBuilders: builder.query<
      CreateNewWorkoutProgramBuilderRequest[],
      number
    >({
      query: (workoutProgramId) => ({
        url: `/workoutprogrambuilder/get-workout-program-builder/${workoutProgramId}`,
        method: "GET",
      }),
      providesTags: ["WorkoutProgramBuilder"],
    }),
    updateExistingInProgressWorkoutProgram: builder.mutation<
      WorkoutProgram,
      CreateNewWorkoutProgramBuilderRequest
    >({
      query: (body) => ({
        url: "/workoutprogrambuilder/update-existing-in-progress-workout-program-builder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkoutProgramBuilder"],
    }),
  }),
});

export const {
  useCreateNewWorkoutProgramBuilderMutation,
  useUpdateDraftWorkoutProgramBuilderMutation,
  useGetWorkoutProgramBuildersQuery,
  useUpdateExistingInProgressWorkoutProgramMutation,
} = workoutProgramBuilderApi;
