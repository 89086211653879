import { Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ClientWhoRequireANewProgram } from "../../../../shared/api";
import { Routes } from "../../../../shared/routers";
import { Colors } from "../../../../shared/themes";
import { IdenticonAvatar } from "../../../../shared/ui/IdenticonAvatar";

interface RequiredProgramUserProps {
  item: ClientWhoRequireANewProgram;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const RequiredProgramUser: FC<RequiredProgramUserProps> = ({
  item,
  getUserIdForWorkoutCreation,
}) => {
  const handleClick = () => {
    getUserIdForWorkoutCreation(item.id);
  };
  return (
    <Box sx={{ width: "100%", height: "48px", mb: "8px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IdenticonAvatar
            seedValue={item.id.toString()}
            profileImageUrl={item.profileImageUrl}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "8px",
            }}
          >
            <Typography
              sx={{
                mt: "8px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {item.firstName} {item.lastName}
            </Typography>
            {item.percentageCompleted !== null && (
              <Typography
                sx={{
                  mt: "4px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {item.percentageCompleted}% Completed
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: "120px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Link
          onClick={handleClick}
          style={{ textDecoration: "none" }}
          to={`${Routes.workoutProgramBuilder.url}`}
        >
          <Icon
            color="primary"
            sx={{
              mt: "12px",
              cursor: "pointer",
            }}
          >
            {" "}
            control_point
          </Icon>
        </Link>
      </Box>
    </Box>
  );
};

export { RequiredProgramUser };
