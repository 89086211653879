import { forwardRef, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { Colors } from "shared/themes";
import { useTypedDispatch } from "shared/stores";
import {
  setIsModified,
  updateGridRowHeaders,
} from "shared/stores/workoutBuilder/slice";
import { Typography, Button, Icon } from "@mui/material";
import Grid from "@toast-ui/react-grid";
import "tui-grid/dist/tui-grid.css";
import { WorkoutSession } from "shared/api";
import { CellValue } from "tui-grid";
import { useTranslation } from "react-i18next";
import useGridColumns from "./useGridColumns";
import { GridRowHeader } from "../types";
import { emptyGrid } from "../constants";
import {
  calculateNewValue,
  processBaseValue,
  processIncreaseValue,
} from "./utils";
import useOnClickOutside from "./useOnClickOutside";

type WorkoutProgramGridProps = {
  data: GridRowHeader;
  numberOfWeeks: number;
  dayNumber: number;
  exerciseNamesList: string[];
  showSimplifiedView: boolean;
  showLogView: boolean;
  showNotesView: boolean;
  isLoadingControls: boolean;
  currentWorkoutList: WorkoutSession[];
};

export const WorkoutProgramGrid = forwardRef<
  HTMLButtonElement,
  WorkoutProgramGridProps
>(
  (
    {
      data: initialData,
      numberOfWeeks,
      dayNumber,
      exerciseNamesList,
      showSimplifiedView,
      showLogView,
      showNotesView,
      isLoadingControls = true,
      currentWorkoutList,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const currentWorkoutsForDay =
      currentWorkoutList?.filter((a) => a.dayNumber === dayNumber) ?? [];
    const { complexColumns, columns } = useGridColumns(
      numberOfWeeks,
      exerciseNamesList,
      currentWorkoutsForDay
    );
    const initialDataExistsAndNotEmpty =
      initialData && initialData?.rows?.length > 0;

    const [data, setData] = useState<GridRowHeader>(
      initialDataExistsAndNotEmpty ? initialData : emptyGrid
    );
    const gridRef = useRef<Grid>(null);
    const [localIsModified, setLocalModified] = useState<boolean>(false);
    const dispatch = useTypedDispatch();

    const handleShowHideSimplifiedView = (hide?: boolean) => {
      const showSimplified = hide || showSimplifiedView;
      if (showSimplified) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.hideColumn(`week${i}weight`);
            instance.hideColumn(`week${i}intensity`);
            instance.hideColumn(`week${i}sets`);
            instance.hideColumn(`week${i}reps`);
            if (showLogView) {
              instance.showColumn(`week${i}log`);
            } else {
              instance.hideColumn(`week${i}log`);
            }
            if (showNotesView) {
              instance.showColumn(`notes`);
            } else {
              instance.hideColumn(`notes`);
            }
          }
        }
      } else if (!showSimplified) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.showColumn(`week${i}weight`);
            instance.showColumn(`week${i}intensity`);
            instance.showColumn(`week${i}sets`);
            instance.showColumn(`week${i}reps`);
            if (showLogView) {
              instance.showColumn(`week${i}log`);
            } else {
              instance.hideColumn(`week${i}log`);
            }
            if (showNotesView) {
              instance.showColumn(`notes`);
            } else {
              instance.hideColumn(`notes`);
            }
          }
        }
      }
    };

    const handleShowHideLogView = (hide?: boolean) => {
      const showLog = hide || showLogView;
      if (showLog) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.showColumn(`week${i}log`);
            if (showSimplifiedView) {
              instance.hideColumn(`week${i}weight`);
              instance.hideColumn(`week${i}intensity`);
              instance.hideColumn(`week${i}sets`);
              instance.hideColumn(`week${i}reps`);
            } else {
              instance.showColumn(`week${i}weight`);
              instance.showColumn(`week${i}intensity`);
              instance.showColumn(`week${i}sets`);
              instance.showColumn(`week${i}reps`);
            }
            if (showNotesView) {
              instance.showColumn(`notes`);
            } else {
              instance.hideColumn(`notes`);
            }
          }
        }
      } else if (!showLog) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.hideColumn(`week${i}log`);
            if (showSimplifiedView) {
              instance.hideColumn(`week${i}weight`);
              instance.hideColumn(`week${i}intensity`);
              instance.hideColumn(`week${i}sets`);
              instance.hideColumn(`week${i}reps`);
            } else {
              instance.showColumn(`week${i}weight`);
              instance.showColumn(`week${i}intensity`);
              instance.showColumn(`week${i}sets`);
              instance.showColumn(`week${i}reps`);
            }
            if (showNotesView) {
              instance.showColumn(`notes`);
            } else {
              instance.hideColumn(`notes`);
            }
          }
        }
      }
    };

    const handleShowNotesView = (hide?: boolean) => {
      const showNotes = hide || showNotesView;
      if (showNotes) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.showColumn(`notes`);
            if (showSimplifiedView) {
              instance.hideColumn(`week${i}weight`);
              instance.hideColumn(`week${i}intensity`);
              instance.hideColumn(`week${i}sets`);
              instance.hideColumn(`week${i}reps`);
            } else {
              instance.showColumn(`week${i}weight`);
              instance.showColumn(`week${i}intensity`);
              instance.showColumn(`week${i}sets`);
              instance.showColumn(`week${i}reps`);
            }
            if (showLogView) {
              instance.showColumn(`week${i}log`);
            } else {
              instance.hideColumn(`week${i}log`);
            }
          }
        }
      } else if (!showNotes) {
        const instance = gridRef?.current?.getInstance();
        if (instance) {
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            instance.hideColumn(`notes`);
            if (showSimplifiedView) {
              instance.hideColumn(`week${i}weight`);
              instance.hideColumn(`week${i}intensity`);
              instance.hideColumn(`week${i}sets`);
              instance.hideColumn(`week${i}reps`);
            } else {
              instance.showColumn(`week${i}weight`);
              instance.showColumn(`week${i}intensity`);
              instance.showColumn(`week${i}sets`);
              instance.showColumn(`week${i}reps`);
            }
            if (showLogView) {
              instance.showColumn(`week${i}log`);
            } else {
              instance.hideColumn(`week${i}log`);
            }
          }
        }
      }
    };

    const saveReduxState = () => {
      const instance = gridRef?.current?.getInstance();
      if (instance) {
        const resp = instance?.getData();
        if (resp && dayNumber) {
          dispatch(
            updateGridRowHeaders({
              [dayNumber]: resp,
            })
          );
        }
      }
    };

    useEffect(() => {
      handleShowHideSimplifiedView(showSimplifiedView);
    }, [showSimplifiedView]);

    useEffect(() => {
      handleShowHideLogView(showLogView);
    }, [showLogView]);

    useEffect(() => {
      handleShowNotesView(showNotesView);
    }, [showNotesView]);

    useEffect(() => {
      if (initialDataExistsAndNotEmpty) {
        setData(initialData!!);
      }
    }, [initialData]);

    useEffect(() => {
      if (localIsModified) {
        dispatch(setIsModified(true));
      }
    }, [localIsModified]);

    useEffect(() => {
      setTimeout(() => {
        setLocalModified(true);
      }, 1000);
    }, []);

    useEffect(() => {
      const instance = gridRef?.current?.getInstance();

      function handleType(
        rowKey: any,
        type: string,
        baseValue: CellValue,
        increaseValue: CellValue,
        numberOfWeeks: number,
        round = false
      ) {
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= numberOfWeeks; i++) {
          const newValue = calculateNewValue(
            baseValue,
            increaseValue,
            i,
            round
          );
          if (instance) {
            instance.setValue(
              rowKey,
              `week${i}${
                type === "RPE" || type === "RIR" ? "intensity" : "weight"
              }`,
              newValue?.toString() ?? ""
            );
          }
        }
      }

      if (instance) {
        instance.on("editingFinish", function handleEditingFinish(ev?: any) {
          if (
            ev?.columnName === "base" ||
            ev?.columnName === "baseIntensity" ||
            ev?.columnName === "increase" ||
            ev?.columnName === "type"
          ) {
            const baseValue = processBaseValue(
              instance.getValue(ev.rowKey, "base")
            );
            const baseIntensity = processBaseValue(
              instance.getValue(ev.rowKey, "baseIntensity")
            );
            const increaseValue = processIncreaseValue(
              instance.getValue(ev.rowKey, "increase")
            );
            const type = instance.getValue(ev.rowKey, "type");

            instance.setValue(ev.rowKey, "base", baseValue);
            instance.setValue(ev.rowKey, "baseIntensity", baseIntensity);
            instance.setValue(ev.rowKey, "increase", increaseValue);

            if (increaseValue && type) {
              if (baseValue) {
                if (type === "Time") {
                  handleType(
                    ev.rowKey,
                    type,
                    baseValue,
                    increaseValue,
                    numberOfWeeks
                  );
                } else if (type === "Percentage") {
                  // eslint-disable-next-line no-plusplus
                  for (let i = 1; i <= numberOfWeeks; i++) {
                    const newValue = calculateNewValue(
                      baseValue,
                      increaseValue,
                      i,
                      true
                    );
                    instance.setValue(ev.rowKey, `week${i}weight`, newValue);
                  }
                } else if (type === "Load") {
                  handleType(
                    ev.rowKey,
                    type,
                    baseValue,
                    increaseValue,
                    numberOfWeeks,
                    true
                  );
                }
              }
              if (baseIntensity) {
                if (type === "RPE" || type === "RIR") {
                  instance.setValue(ev.rowKey, `week1intensity`, baseIntensity);
                  handleType(
                    ev.rowKey,
                    type,
                    baseIntensity,
                    increaseValue,
                    numberOfWeeks
                  );
                }
              }
            }
          }
          if (ev?.columnName === "sets") {
            const baseSets = instance.getValue(ev.rowKey, "sets");
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i <= numberOfWeeks; i++) {
              instance.setValue(ev.rowKey, `week${i}sets`, baseSets);
            }
          }
          if (ev?.columnName === "reps") {
            const baseReps = instance.getValue(ev.rowKey, "reps");
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i <= numberOfWeeks; i++) {
              instance.setValue(ev.rowKey, `week${i}reps`, baseReps);
            }
          }
        });
      }
    }, [gridRef]);

    const appendNewRow = () => {
      const instance = gridRef?.current?.getInstance();
      if (instance) {
        instance.appendRow();
      }
    };

    const customMenuGroup: any = () => {
      return [
        [
          {
            name: "Add New Row",
            label: "Add New Row",
            action: () => {
              appendNewRow();
            },
          },
          {
            name: "Delete Row",
            label: "Delete Row",
            action: () => {
              const currentCell = gridRef?.current
                ?.getInstance()
                ?.getFocusedCell();
              if (currentCell && currentCell?.rowKey)
                gridRef?.current?.getInstance()?.removeRow(currentCell.rowKey);
            },
          },
        ],
      ];
    };

    const checkFinishEditingOnBlur = () => {
      const instance = gridRef?.current?.getInstance();
      if (instance) {
        instance.finishEditing();
      }
    };
    const gridContainerRef = useOnClickOutside<HTMLDivElement>(
      checkFinishEditingOnBlur
    );

    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mt: 4,
              fontWeight: "bold",
              fontSize: 18,
              color: `${Colors.blue[1300]}`,
              letterSpacing: "0.5px",
            }}
          >
            Day {dayNumber}
          </Typography>
          <Button
            type="button"
            variant="contained"
            size="small"
            onClick={saveReduxState}
            sx={{
              display: "none",
            }}
            ref={ref}
          />
        </Box>
        <Box
          ref={gridContainerRef}
          sx={{
            "& td": {
              textAlign: "center !important",
              border: "1px solid #ccc",
            },
            "& .tui-grid-table tbody > tr:nth-child(2n)": {
              "& td": {
                backgroundColor: "#e6f7ff",
              },
            },
            "& .tui-grid-cell-current-row": {
              borderBottom: "2.5px solid #433BFF",
            },
            "& .tui-grid-layer-selection": {
              backgroundColor: "#DEDCFF !important",
            },
            "& .tui-grid-context-menu": {
              zIndex: 999,
              "& li": {
                zIndex: 9999,
              },
            },
            "& .tui-grid-scrollbar-left-bottom": {
              cursor: "not-allowed !important",
              display: "none !important",
            },
            "& .tui-grid-container": {
              cursor: `${isLoadingControls ? "not-allowed" : "pointer"}`,
            },
            "& .tui-grid-cell": {
              pointerEvents: `${isLoadingControls ? "none" : "auto"}`,
            },
          }}
        >
          <Grid
            ref={gridRef}
            data={data.rows}
            columns={columns}
            editingEvent="click"
            contextMenu={customMenuGroup}
            rowHeight="auto"
            draggable
            header={{
              complexColumns,
              height: 80,
            }}
            columnOptions={{
              resizable: true,
              frozenCount: 1,
            }}
            scrollY={false}
            rowHeaders={[
              {
                type: "rowNum",
                header: "",
              },
            ]}
            moveDirectionOnEnter="right"
          />
          <Button
            variant="text"
            color="primary"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              mt: 2,
            }}
            onClick={() => appendNewRow()}
            disabled={isLoadingControls}
          >
            <Icon>add</Icon>
            {t("workout-program-builder.add-new-row")}
          </Button>
        </Box>
      </>
    );
  }
);

WorkoutProgramGrid.displayName = "WorkoutProgramGrid";
