import { Icon, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Image from "mui-image";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import { useTypedSelector } from "../../../../shared/stores";
import { userSelectors } from "../../../../shared/stores/user";
import { Colors, Images } from "../../../../shared/themes";
import { IdenticonAvatar } from "../../../../shared/ui/IdenticonAvatar";

interface HeaderProps {
  toggleSidebar?: () => void;
}

const Header: FC<HeaderProps> = ({ toggleSidebar }) => {
  const userId = useTypedSelector(userSelectors.userId);
  const userProfileImageUrl = useTypedSelector(
    userSelectors.userProfileImageUrl
  );
  return (
    <Box
      sx={{
        width: "100%",
        height: "58px",
        bgcolor: `${Colors.gray[100]}`,
        zIndex: 9999,
        position: "fixed",
        top: "0px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 24px 8px 24px",
        borderBottom: `1px solid ${Colors.gray[400]}`,
      }}
    >
      <IconButton onClick={toggleSidebar}>
        <Icon
          sx={{
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          menu
        </Icon>
      </IconButton>

      <Image src={Images.Logo} duration={250} width="108px" height="34px" />

      <Link
        style={{ textDecoration: "none" }}
        to={`${Routes.profile.url}/${userId}`}
      >
        <IdenticonAvatar
          seedValue={userId?.toString() ?? ""}
          profileImageUrl={userProfileImageUrl}
        />
      </Link>
    </Box>
  );
};

export { Header };
