import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import { FC } from "react";

type SnackbarAlertProps = {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  severity: "success" | "error" | "warning" | "info";
  position?: "top" | "bottom";
};

export const SnackbarAlert: FC<SnackbarAlertProps> = ({
  message,
  isOpen,
  onClose,
  severity,
  position = "bottom",
}) => {
  const anchorPosition: SnackbarOrigin =
    position === "bottom"
      ? {
          horizontal: "right",
          vertical: "bottom",
        }
      : {
          horizontal: "right",
          vertical: "top",
        };

  return (
    <Snackbar
      anchorOrigin={anchorPosition}
      open={isOpen}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert
        sx={{
          mt: 1,
          width: "100%",
        }}
        variant="standard"
        severity={severity}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
