import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SidebarState {
  isOpenSidebar: boolean;
}

const initialState: SidebarState = {
  isOpenSidebar: true,
};

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.isOpenSidebar = action.payload;
    },
    toggleSidebarState: (state) => {
      state.isOpenSidebar = !state.isOpenSidebar;
    },
  },
});

export const { setSidebarState, toggleSidebarState } = sidebarSlice.actions;

export default sidebarSlice.reducer;
