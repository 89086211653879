/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useState, useRef } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Routes } from "../../shared/routers";
import { Colors } from "../../shared/themes";
import { useTypedSelector } from "../../shared/stores";

interface WorkoutProgramBuilderSuccessScreenProps {
  openSidebar: () => void;
}

const WorkoutProgramBuilderSuccessScreen: FC<
  WorkoutProgramBuilderSuccessScreenProps
> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        height: "100%",
        padding: "40px",
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box sx={{ color: `${Colors.green[500]}`, fontSize: "4rem", mb: "20px" }}>
        <CheckCircle fontSize="inherit" />
      </Box>
      <Typography variant="h4" sx={{ textAlign: "center", mb: "20px" }}>
        {t("Success!")}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center", mb: "40px" }}>
        {t("workout-program-builder.success-message")}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Link style={{ textDecoration: "none" }} to={`${Routes.programs.url}`}>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            {t("workout-program-builder.back-to-programs")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export { WorkoutProgramBuilderSuccessScreen };
