import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";

export type ExerciseDropzoneProps = {
  onDrop: (file: File) => void;
  onError: (e: string) => void;
};

const videoMimeTypes = {
  ".mp4": "video/mp4",
  ".avi": "video/x-msvideo",
  ".mov": "video/quicktime",
  ".wmv": "video/x-ms-wmv",
  ".mkv": "video/x-matroska",
};

const BYTES_UPLOAD_LIMIT = 1000 * 1000 * 5;

const ExerciseDropzone: React.FC<ExerciseDropzoneProps> = ({
  onDrop,
  onError,
}) => {
  const { t } = useTranslation();
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const { size, name } = file;
      const extension = name.split(".").pop();

      if (size > BYTES_UPLOAD_LIMIT) {
        onError(
          "File size exceeds the maximum limit of 5MB. Please choose a smaller file."
        );
        return;
      }

      if (Object.values(videoMimeTypes).includes(file.type)) {
        setFileInfo(file);
        onDrop(file);
      } else {
        onError(
          `Unsupported file type: ${extension}. Please upload a valid video file (e.g., .mp4, .avi, .mov, .wmv, .mkv).`
        );
      }
    }
  };

  return (
    <Dropzone onDrop={handleDrop} maxFiles={1} ref={dropzoneRef}>
      {({ getRootProps, getInputProps }) => (
        <Box
          {...getRootProps()}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed grey",
            borderRadius: "0.25em",
            backgroundColor: "white",
            color: `${Colors.oxford[1100]}`,
            outline: "none",
            transition: "250ms ease-in-out",
            cursor: "pointer",
            width: "100%",
            mb: 2,
          }}
        >
          <input {...getInputProps()} />
          <Box sx={{ padding: "2em 1em" }}>
            {fileInfo ? (
              <div>
                <Typography variant="subtitle1">{fileInfo.name}</Typography>
                <Typography variant="body2">{fileInfo.size} bytes</Typography>
                <Typography variant="body2">{fileInfo.type}</Typography>
              </div>
            ) : (
              <>
                <Typography sx={sharedStyles.body.timeline.cardTitle}>
                  Drag or click to upload a file...
                </Typography>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  Only the following file types are supported: .mp4, .avi, .mov,
                  .wmv, .mkv.
                </Typography>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  There is a maximum file size limit of 5mb.
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Dropzone>
  );
};

export { ExerciseDropzone };
