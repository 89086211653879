import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import {
  TimelineItem,
  TimelineItemLike,
  useLikeTimelineItemMutation,
} from "shared/api";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { getTimelineLikeStatement } from "./utils";

type TimelineViewReplyContainerProps = {
  timelineItems?: TimelineItem[];
};

const TimelineViewReplyContainer: FC<TimelineViewReplyContainerProps> = ({
  timelineItems,
}) => {
  const userId = useTypedSelector(userSelectors.userId);
  const [createTimelineItemLike, { isLoading }] = useLikeTimelineItemMutation();
  const onLike = async (timelineItemId: number) => {
    try {
      await createTimelineItemLike({
        timelineItemId,
      }).unwrap();
    } catch (error: any) {
      console.error("Error liking timeline item", error);
    }
  };

  if (!timelineItems)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "80%", mb: "12px" }}
      />
    );

  return (
    <>
      <Typography sx={sharedStyles.body.timeline.cardTitle}>
        Comments
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "80%",
          borderTop: "1px solid #EEEEEE",
          borderBottom: "1px solid #EEEEEE",
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {timelineItems?.map((child, key) => {
          const isLikedByUser = child.timelineItemLikes?.some(
            (like: TimelineItemLike) => like.userId === userId
          );

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              key={key}
            >
              <IdenticonAvatar
                seedValue={child.userId?.toString()}
                profileImageUrl={child.profileImageUrl}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={sharedStyles.body.timeline.cardTitle}>
                  {child.name}
                </Typography>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  {child.content}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, paddingTop: "2px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: `${Colors.blue[1200]}`,
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "2px",
                    }}
                    onClick={() => onLike(child.id)}
                  >
                    <OutlinedIcon
                      iconName={isLikedByUser ? "favorite" : "favorite_border"}
                      style={{
                        color: `${Colors.blue[1200]}`,
                        fontSize: "20px",
                      }}
                    />
                    Like
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: `${Colors.gray[700]}`,
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    {getTimelineLikeStatement(
                      child?.timelineItemLikes || [],
                      userId!!
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export { TimelineViewReplyContainer };
