import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Typography,
  Icon,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  ExerciseItem,
  MergeExerciseParams,
  useGetExercisesLibraryQuery,
  useMergeExercisesMutation,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";
import { useFormik } from "formik";

interface MergeExerciseModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  fromExercise: ExerciseItem | null;
}

const MergeExerciseModal: FC<MergeExerciseModalProps> = ({
  isOpenModal,
  handleCloseModal,
  fromExercise,
}) => {
  const { t } = useTranslation();
  const [mergeExercises, { isLoading }] = useMergeExercisesMutation();

  const { data: allExercisesData, isLoading: isLoadingAllItems } =
    useGetExercisesLibraryQuery({}, { skip: !isOpenModal });

  const allExercisesExcludingFromExercise = isOpenModal
    ? allExercisesData?.filter((item) => item.id !== fromExercise?.id)
    : [];

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = async (params: MergeExerciseParams) => {
    try {
      await mergeExercises({
        fromExerciseId: params.fromExerciseId,
        toExerciseId: params.toExerciseId,
      }).unwrap();

      setSuccessMessage(`Successfully merged exercises.`);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
        handleCloseModal();
      }, 1000);
    }
  };

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromExercise,
      toExercise: { name: "", id: 0, primaryMuscleGroup: "" },
    },
    onSubmit: (params) => {
      onSubmit({
        fromExerciseId: params.fromExercise?.id ?? 0,
        toExerciseId: params.toExercise.id,
      });
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Important Confirmation ({fromExercise?.name})
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 1,
              mb: 4,
            }}
          >
            <br />
            All data related to the exercise <b>{fromExercise?.name}</b> will be
            combined with the selected exercise below.
            <br />
            <br />
            This action <b>CANNOT</b> be undone, and the exercise will be
            deleted. Are you sure you want to continue?
            <br />
          </Typography>
          {!isLoading || !isLoadingAllItems ? (
            <Autocomplete
              sx={{ width: "100%", mt: "-10px" }}
              options={allExercisesExcludingFromExercise ?? []}
              defaultValue={null}
              renderInput={(params) => <TextField {...params} />}
              onChange={(_e, ex) => {
                setFieldValue("toExercise", ex);
              }}
              value={values.toExercise}
              getOptionLabel={(item) =>
                `${`${item.name} - ${item.primaryMuscleGroup}`}`
              }
            />
          ) : null}
          <PrimaryButton
            value="Confirm Merge"
            type="submit"
            variant="contained"
            fullWidth
            loading={isLoading}
            disabled={
              isLoading ||
              isLoadingAllItems ||
              !values.toExercise ||
              !values.fromExercise
            }
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { MergeExerciseModal };
