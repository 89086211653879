/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  TimelineItem,
  timelinesApi,
  useGetTimelineItemsCountQuery,
  useGetTimelineItemsQuery,
  useGetTimelinesQuery,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Skeleton } from "@mui/material";
import { TimelineViewItemCard } from "../TimelineViewItemCard";
import { AthleteTimelineViewInput } from "./AthleteTimelineViewInput";

const PAGE_SIZE = 5;
const SCROLL_IS_NEAR_TOP_OFFSET = 40;

interface AthleteTimelineViewProps {
  closeSidebar: () => void;
}

const AthleteTimelineView: FC<AthleteTimelineViewProps> = ({
  closeSidebar,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [loadedItems, setLoadedItems] = useState<TimelineItem[]>([]);
  const [showLoadMoreButton, setShowLoadMore] = useState(false);
  const [replyTo, setReplyTo] = useState<TimelineItem>();
  const [isReply, setIsReply] = useState(false);

  const { data: timelineItemsDataForCount, isLoading: isLoadingItemsLength } =
    useGetTimelineItemsCountQuery({});
  const timelineItemsLength = timelineItemsDataForCount || 0;
  const hasNextPage = timelineItemsLength > page * pageSize;
  const timelineItemsRef = useRef<HTMLDivElement>(null);

  const { data: timelineItemsData, isLoading: isLoadingItems } =
    useGetTimelineItemsQuery({
      orderByDescending: true,
      count: pageSize,
      page,
    });

  const { data: timelineData, isLoading } = useGetTimelinesQuery({});
  const timeline = timelineData?.[0];

  const checkForLoadMore = () => {
    const element = timelineItemsRef.current;
    return element ? element.scrollTop <= SCROLL_IS_NEAR_TOP_OFFSET : false;
  };

  const handleScroll = () => {
    const isScrollNearTop = checkForLoadMore();
    setShowLoadMore(isScrollNearTop);
  };

  const scollToBottom = () => {
    const element = timelineItemsRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    scollToBottom();
  }, []);

  useEffect(() => {
    if (timelineItemsData) {
      setLoadedItems((prevLoadedItems) => [
        ...prevLoadedItems,
        ...timelineItemsData,
      ]);
    }
  }, [timelineItemsData]);

  useEffect(() => {
    if (timelineItemsData && page === 1) {
      setLoadedItems(timelineItemsData);
      setShowLoadMore(true);
      scollToBottom();
    }
  }, [loadedItems]);

  const handleLoadMore = () => {
    if (!isLoadingItems && hasNextPage) {
      setPage(page + 1);
      const isScrollNearTop = checkForLoadMore();
      setShowLoadMore(isScrollNearTop);
    }
  };

  const handleAddItem = () => {
    scollToBottom();
    setPage(1);
    setLoadedItems([]);
    setReplyTo(undefined);
    setIsReply(false);
  };

  const handleReplyComment = (timelineItem: TimelineItem) => {
    if (timelineItem?.id === replyTo?.id) {
      setReplyTo(undefined);
      setIsReply(false);
      return;
    }
    setReplyTo(timelineItem);
    setIsReply(true);
  };

  if (isLoadingItems || isLoading || isLoadingItemsLength)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  return (
    <Box
      sx={{
        width: "100%",
        background: "white",
        padding: "10px 10px 80px 10px",
        height: "84vh",
        border: "none",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 3,
          gap: "12px",
          height: "100%",
          overflow: "auto",
          mt: 2,
        }}
        onClick={closeSidebar}
        ref={timelineItemsRef}
        onScroll={handleScroll}
      >
        {!isLoadingItems &&
          loadedItems
            ?.slice()
            .reverse()
            .map((item, index) => (
              <TimelineViewItemCard
                timelineItem={item}
                key={index}
                isClientView
                onReply={handleReplyComment}
                timelineUserId={timeline?.userId ?? 0}
              />
            ))}
      </Box>
      <AthleteTimelineViewInput
        timelineId={timeline?.id ?? 0}
        onAddItem={handleAddItem}
        replyTo={replyTo}
        isReply={isReply}
      />
      {hasNextPage && (
        <PrimaryButton
          size="small"
          type="button"
          value={t("timeline.load-more")}
          loading={isLoading}
          disabled={isLoading || !showLoadMoreButton}
          variant="contained"
          onClick={handleLoadMore}
          style={{
            width: "100px",
            alignSelf: "center",
            justifySelf: "center",
            position: "absolute",
            top: 68,
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </Box>
  );
};

export { AthleteTimelineView };
