import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { ClientInsightsEngineResponse } from "./models";

export const clientInsightsApi = createApi({
  baseQuery,
  reducerPath: "clientInsightsApi",
  tagTypes: ["ClientInsightsEngine"],
  endpoints: (builder) => ({
    getClientInsightsEngine: builder.query<
      ClientInsightsEngineResponse,
      number
    >({
      query: (clientId) => ({
        url: `/client-insights/${clientId}`,
        method: "GET",
      }),
      providesTags: ["ClientInsightsEngine"],
    }),
  }),
});

export const { useGetClientInsightsEngineQuery } = clientInsightsApi;
