import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateTimeLineItemParams,
  Timeline,
  TimelineItem,
  TimelineItemsListParams,
  TimelineParams,
  UploadMediaParams,
} from "./models";
import { WorkoutSession } from "../athlete/model";

export const timelinesApi = createApi({
  baseQuery,
  reducerPath: "timelinesApi",
  tagTypes: ["Timelines", "TimelineItems", "Media"],
  endpoints: (builder) => ({
    getTimelinesCount: builder.query<number, void>({
      query: () => ({
        url: `/timelines?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["Timelines"],
    }),
    getTimelines: builder.query<Timeline[], TimelineParams>({
      query: (params) => ({
        url: "/timelines",
        method: "GET",
        params,
      }),
      providesTags: ["Timelines"],
    }),
    getAllTimelines: builder.query<Timeline[], void>({
      query: () => ({
        url: "/timelines",
        method: "GET",
      }),
      providesTags: ["Timelines"],
    }),
    getTimelineById: builder.query<Timeline, number>({
      query: (timelineId) => ({
        url: `/timelines/${timelineId}`,
        method: "GET",
      }),
      providesTags: ["Timelines"],
    }),
    getTimelineItems: builder.query<TimelineItem[], TimelineItemsListParams>({
      query: (params) => ({
        url: `/timeline-items`,
        method: "GET",
        params,
      }),
      providesTags: ["TimelineItems"],
    }),
    getTimelineItemsCount: builder.query<number, TimelineItemsListParams>({
      query: (params) => ({
        url: `/timeline-items?countOnly=true`,
        method: "GET",
        params,
      }),
      providesTags: ["TimelineItems"],
    }),
    createTimelineItem: builder.mutation<void, CreateTimeLineItemParams>({
      query: (body) => ({
        url: `/timeline-items`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TimelineItems"],
    }),
    uploadMedia: builder.mutation<void, UploadMediaParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.uploadFile);

        return {
          url: "/media/upload",
          method: "POST",
          body: formData,
          params: {
            timelineId: params.timelineId ?? undefined,
          },
        };
      },
      invalidatesTags: ["Media", "TimelineItems"],
    }),
    getWorkoutForTimelineItem: builder.query<WorkoutSession, number>({
      query: (timelineItemId) => ({
        url: `/timeline-items/${timelineItemId}/workout`,
        method: "GET",
      }),
    }),
    clearAllUnreadNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `/timeline-items/clear`,
        method: "POST",
      }),
      invalidatesTags: ["Timelines"],
    }),
    likeTimelineItem: builder.mutation<void, { timelineItemId: number }>({
      query: (body) => ({
        url: `/timeline-items/like`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["TimelineItems"],
    }),
  }),
});

export const {
  useGetTimelinesCountQuery,
  useGetTimelinesQuery,
  useGetAllTimelinesQuery,
  useGetTimelineByIdQuery,
  useGetTimelineItemsQuery,
  useGetTimelineItemsCountQuery,
  useCreateTimelineItemMutation,
  useLikeTimelineItemMutation,
  useUploadMediaMutation,
  useGetWorkoutForTimelineItemQuery,
  useClearAllUnreadNotificationsMutation,
} = timelinesApi;
