import { useTypedSelector } from "shared/stores";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Icon,
  Button,
  Typography,
  Skeleton,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  CreateUserNoteWithMediaParams,
  useAddUserNoteMutation,
  useAddUserNoteWithMediaMutation,
  useGenerateTranscribeAuthTokenQuery,
  useLazyGetTranscriptQuery,
  useStartTranscriptionMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { UserNoteDropzone } from "pages/UserNotes/UserNoteDropzone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Routes } from "shared/routers";
import { UserNotesClientSelector } from "pages/UserNotes/UserNotesClientSelector";
import AudioRecorder from "./AudioRecorder";

interface CreateUserNoteModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preSelectedUserId?: number;
}

const CreateUserNoteSchema = Yup.object().shape({
  note: Yup.string().optional(),
  audioFile: Yup.mixed().optional(),
});

const CreateUserNoteModal: FC<CreateUserNoteModalProps> = ({
  isOpenModal,
  handleCloseModal: propsHandleClose,
  preSelectedUserId = 0,
}) => {
  dayjs.extend(utc);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [dropzoneError, setDropzoneError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [addUserNote, { isLoading: isLoadingAdd }] = useAddUserNoteMutation();
  const [addUserNoteWithMedia, { isLoading: isLoadingAddWithMedia }] =
    useAddUserNoteWithMediaMutation();
  const [startTranscription, { isLoading: isLoadingStartTranscription }] =
    useStartTranscriptionMutation();

  const [trigger, { data, isError, isLoading: isLoadingGetTranscript }] =
    useLazyGetTranscriptQuery();

  const [userId, setSelectedUserId] = useState<number | null>(
    preSelectedUserId
  );

  const { data: transcribeToken, isLoading: isLoadingTranscribeToken } =
    useGenerateTranscribeAuthTokenQuery({}, { skip: !isOpenModal });

  const [isPolling, setIsPolling] = useState(false);

  const [noteDate, setNoteDate] = useState<string>(
    dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
  );

  const [open, setOpen] = useState(false);

  const [displayDropzone, setDisplayDropzone] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (params: CreateUserNoteWithMediaParams) => {
    setErrorMessage("");

    try {
      if (params.audioFile) {
        const createdUserNote = await addUserNoteWithMedia(params).unwrap();
        if (createdUserNote) {
          startTranscription(createdUserNote.id).catch((error) => {
            console.error("Error processing user note:", error);
          });
          setIsPolling(true);

          const interval = setInterval(async () => {
            const result = await trigger(createdUserNote.id).unwrap();
            if (result?.userNoteStatus === "Completed") {
              setIsPolling(false);
              clearInterval(interval);
              navigate(`${Routes.userNotes.url}/${createdUserNote.id}`);
            }
            if (isError || result?.userNoteStatus === "Failed") {
              setIsPolling(false);
              clearInterval(interval);
              setErrorMessage(
                "There was an error processing your note. Please try again."
              );
            }
          }, 2500);
        }
      } else {
        const { audioFile, ...rest } = params;
        const createdUserNote = await addUserNote(rest).unwrap();
        navigate(`${Routes.userNotes.url}/${createdUserNote.id}`);
      }
      setSuccessMessage(
        "User note added successfully. Your note is now being processed. Please wait a moment."
      );
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const handleCloseModal = () => {
    propsHandleClose();
    setSelectedUserId(null);
    setSuccessMessage("");
    setErrorMessage("");
    setDropzoneError("");
    setDisplayDropzone(false);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      audioFile: undefined,
    },
    validationSchema: CreateUserNoteSchema,
    onSubmit: (params) => {
      if (!userId) {
        setErrorMessage("Please select a client.");
        throw new Error("User ID is required");
      }
      onSubmit({
        title: params.title,
        dateTime: noteDate,
        userId,
        audioFile: params.audioFile,
      });
    },
  });

  const handleDropVideo = (file: File) => {
    setFieldValue("audioFile", file);
  };

  const handleDropzoneError = (e: string) => {
    setDropzoneError(e);
    setOpen(true);
  };

  const handleCreateWithLiveRecordedAudio = async (transcription: string) => {
    if (!userId) {
      setErrorMessage("Please select a client.");
      throw new Error("User ID is required");
    }
    await onSubmit({
      title: values.title,
      dateTime: noteDate,
      userId,
      audioTranscript: transcription,
    });
  };

  const displayClientSelector = !userId && !isLoadingTranscribeToken;

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box
          sx={{
            ...sharedStyles.containers.modalContent,
            maxHeight: "calc(100vh - 200px)",
            height: "auto",
            width: "500px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: `${
                displayDropzone ? "space-between" : "flex-end"
              }`,
            }}
          >
            {displayDropzone && (
              <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                }}
                color="primary"
                variant="text"
                type="button"
                onClick={() => setDisplayDropzone(false)}
              >
                Back
              </Button>
            )}
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          {isLoadingTranscribeToken && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ height: "243px", width: "100%", mb: "12px" }}
            />
          )}
          {!displayDropzone && !!transcribeToken?.token && (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  // center
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    pointerEvents: "none",
                  }}
                  color="primary"
                  variant="outlined"
                  type="button"
                  disabled={!userId}
                >
                  Record
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                  }}
                  color="primary"
                  variant="text"
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={!userId}
                >
                  Write
                </Button>
              </Box>
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: `${Colors.blue[1300]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                {!userId
                  ? "Select a client to get started."
                  : "Ready to go? Just hit record and start talking!"}
              </Typography>
              {!preSelectedUserId && (
                <UserNotesClientSelector
                  setSelectedUserId={setSelectedUserId}
                />
              )}
              {!displayClientSelector && (
                <AudioRecorder
                  handleCreateWithLiveRecordedAudio={
                    handleCreateWithLiveRecordedAudio
                  }
                  shortLivedToken={transcribeToken.token}
                  handleDisplayUploadFileScreen={() =>
                    setDisplayDropzone(!displayDropzone)
                  }
                />
              )}
            </Box>
          )}
          {displayDropzone && (
            <>
              <UserNoteDropzone
                onDrop={handleDropVideo}
                onError={handleDropzoneError}
              />
              <PrimaryButton
                size="large"
                type="submit"
                value="Upload"
                loading={
                  isLoadingAdd || isLoadingAddWithMedia || isPolling || !userId
                }
                disabled={
                  isLoadingAdd ||
                  isLoadingAddWithMedia ||
                  isPolling ||
                  !userId ||
                  !values.audioFile
                }
                variant="contained"
                fullWidth
                onClick={() => handleSubmit()}
              />
            </>
          )}
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateUserNoteModal };
