import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  useGetUserNotesCountQuery,
  useGetUserNotesQuery,
  UserNote,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { CreateUserNoteModal } from "components/Modals/CreateUserNoteModal";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";

type UserNotesTableProps = {
  preselectedUserId: number;
};

const UserNotesTable: FC<UserNotesTableProps> = ({
  preselectedUserId: userId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const [pageSize, setPageSize] = useState(7);
  const [page, setPage] = useState(1);

  const { data: userNotesArrayLength } = useGetUserNotesCountQuery({
    userId,
  });
  const [rowCountState, setRowCountState] = useState(userNotesArrayLength || 0);
  const [rows, setRows] = useState<UserNote[]>([]);

  const [selectedUserNote, setSelectedUserNote] = useState<UserNote | null>(
    null
  );

  const { data, isLoading } = useGetUserNotesQuery({
    count: pageSize,
    page,
    userId,
    orderByDescending: true,
  });

  useEffect(() => {
    if (!data) return;
    setRows(data);
    setRowCountState((prevRowCountState) =>
      userNotesArrayLength !== undefined
        ? userNotesArrayLength
        : prevRowCountState
    );
  }, [data]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      userNotesArrayLength !== undefined
        ? userNotesArrayLength
        : prevRowCountState
    );
  }, [userNotesArrayLength, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "dateTime",
      headerName: "Date",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {new Date(params.value as string).toLocaleDateString("en-Au", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Typography>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1.25,
      sortable: false,
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.userNotes.url}/${id}`}>
            <Button
              color="primary"
              variant="text"
              sx={{ textTransform: "none" }}
            >
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "630px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: 2,
        mb: "16px",
      }}
    >
      {!isLoading ? (
        <Box sx={{ height: "500px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PrimaryButton
              value="New Note"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleToggleModal}
              icon="add_icon"
            />
          </Box>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            paginationMode="server"
            onRowClick={(params) => {
              setSelectedUserNote(params.row);
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />

          <CreateUserNoteModal
            handleCloseModal={handleToggleModal}
            isOpenModal={isOpenModal}
            preSelectedUserId={userId}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { UserNotesTable };
