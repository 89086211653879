import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import LogRocket from "logrocket";
import { isDev } from "../helpers";
import { rootReducer } from "./rootReducer";
import {
  authApi,
  userApi,
  athleteApi,
  programsApi,
  dashboardApi,
  workoutProgramBuilderApi,
  signupApi,
  exercisesApi,
  userPersonalRecordsApi,
  userStatisticsApi,
  userInsightsEngineApi,
  timelinesApi,
  organisationsApi,
  workflowsApi,
  organisationApiKeysApi,
  workoutProgramGroupItemsApi,
  trainingInsightsApi,
  userBodyMeasurementsApi,
  workoutProgramDesignerApi,
  nutritionApi,
  workflowTemplateLibraryApi,
  leadApi,
  clientInsightsApi,
  billingApi,
  userNotesApi,
} from "../api";

const createStore = () => {
  const defaultMiddleware = [
    thunk,
    authApi.middleware,
    userApi.middleware,
    athleteApi.middleware,
    programsApi.middleware,
    dashboardApi.middleware,
    workoutProgramBuilderApi.middleware,
    signupApi.middleware,
    exercisesApi.middleware,
    userPersonalRecordsApi.middleware,
    userStatisticsApi.middleware,
    userInsightsEngineApi.middleware,
    timelinesApi.middleware,
    organisationsApi.middleware,
    workflowsApi.middleware,
    organisationApiKeysApi.middleware,
    workoutProgramGroupItemsApi.middleware,
    trainingInsightsApi.middleware,
    userBodyMeasurementsApi.middleware,
    workoutProgramDesignerApi.middleware,
    nutritionApi.middleware,
    workflowTemplateLibraryApi.middleware,
    leadApi.middleware,
    clientInsightsApi.middleware,
    billingApi.middleware,
    userNotesApi.middleware,
    // This must always be last
    LogRocket.reduxMiddleware(),
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: isDev ? [...defaultMiddleware] : [...defaultMiddleware],
  });

  return store;
};

export const store = createStore();

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
