import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import DOMPurify from "dompurify";

type TimelineViewReplyProps = {
  title?: string;
  content?: string;
};

const TimelineViewReply: FC<TimelineViewReplyProps> = ({ title, content }) => {
  const sanitisedTimelineItemTitle = DOMPurify.sanitize(title || "");

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: 1,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontSize: "12px",
        }}
      >
        Replying to <b>{sanitisedTimelineItemTitle}</b>
      </Typography>
    </Box>
  );
};

export { TimelineViewReply };
