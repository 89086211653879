import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";

export type UserNoteDropzoneProps = {
  onDrop: (file: File) => void;
  onError: (e: string) => void;
};
const audioMimeTypes = {
  ".3ga": "audio/3gpp",
  ".8svx": "audio/8svx",
  ".aac": "audio/aac",
  ".ac3": "audio/ac3",
  ".aif": "audio/x-aiff",
  ".aiff": "audio/x-aiff",
  ".alac": "audio/alac",
  ".amr": "audio/amr",
  ".ape": "audio/x-ape",
  ".au": "audio/basic",
  ".dss": "audio/x-dss",
  ".flac": "audio/flac",
  ".m4a": "audio/x-m4a",
  ".m4b": "audio/m4b",
  ".m4p": "audio/mp4",
  ".m4r": "audio/mp4",
  ".mp3": "audio/mpeg",
  ".mpga": "audio/mpeg",
  ".ogg": "audio/ogg",
  ".oga": "audio/ogg",
  ".mogg": "audio/ogg",
  ".opus": "audio/opus",
  ".qcp": "audio/qcelp",
  ".tta": "audio/x-tta",
  ".voc": "audio/x-voc",
  ".wav": "audio/wav",
  ".wma": "audio/x-ms-wma",
  ".wv": "audio/x-wavpack",
};

const BYTES_UPLOAD_LIMIT = 1000 * 1000 * 10;

const UserNoteDropzone: React.FC<UserNoteDropzoneProps> = ({
  onDrop,
  onError,
}) => {
  const { t } = useTranslation();
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const { size, name } = file;
      const extension = name.split(".").pop();

      if (size > BYTES_UPLOAD_LIMIT) {
        onError(
          "File size exceeds the maximum limit of 5MB. Please choose a smaller file."
        );
        return;
      }

      if (Object.values(audioMimeTypes).includes(file.type)) {
        setFileInfo(file);
        onDrop(file);
      } else {
        onError(`Unsupported file type: ${extension}. `);
      }
    }
  };

  return (
    <Dropzone onDrop={handleDrop} maxFiles={1} ref={dropzoneRef}>
      {({ getRootProps, getInputProps }) => (
        <Box
          {...getRootProps()}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed grey",
            borderRadius: "0.25em",
            backgroundColor: "white",
            color: `${Colors.oxford[1100]}`,
            outline: "none",
            transition: "250ms ease-in-out",
            cursor: "pointer",
            width: "100%",
            mb: 2,
            mt: 2,
          }}
        >
          <input {...getInputProps()} />
          <Box sx={{ padding: "2em" }}>
            {fileInfo ? (
              <div>
                <Typography variant="subtitle1">{fileInfo.name}</Typography>
                <Typography variant="body2">{fileInfo.size} bytes</Typography>
                <Typography variant="body2">{fileInfo.type}</Typography>
              </div>
            ) : (
              <>
                <Typography sx={sharedStyles.body.timeline.cardTitle}>
                  Drag or click to upload an audio file...
                </Typography>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  There is a maximum file size limit of 10mb.
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Dropzone>
  );
};

export { UserNoteDropzone };
