import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  UpdateWorkoutProgramStatusParams,
  WorkoutProgramItem,
  useUpdateWorkoutProgramStatusMutation,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Colors } from "shared/themes";
import * as Yup from "yup";
import { SelectInputField } from "components/Form/SelectInputField";
import { useFormik } from "formik";

interface ChangeStatusModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  workoutProgram?: WorkoutProgramItem;
}

const UpdateWorkoutProgramSchema = Yup.object().shape({
  workoutProgramId: Yup.number().required(),
  workoutProgramStatus: Yup.string().required(),
});

const WORKOUT_PROGRAM_STATUS = [
  { label: "Draft", value: "Draft" },
  { label: "Not Started", value: "NotStarted" },
  { label: "In Progress", value: "InProgress" },
  { label: "Completed", value: "Completed" },
];

const ChangeStatusModal: FC<ChangeStatusModalProps> = ({
  isOpenModal,
  handleCloseModal,
  workoutProgram,
}) => {
  const { t } = useTranslation();
  const [updateWorkoutProgramStatus, { isLoading }] =
    useUpdateWorkoutProgramStatusMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const onSubmit = async (params: UpdateWorkoutProgramStatusParams) => {
    try {
      await updateWorkoutProgramStatus(params).unwrap();
      setSuccessMessage(t("programs-menu.change-status-success"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 1500);
    }
  };

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        workoutProgramId: workoutProgram?.id ?? 0,
        workoutProgramStatus: "",
      },
      validationSchema: UpdateWorkoutProgramSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    }
  );

  const workoutProgramList = WORKOUT_PROGRAM_STATUS.map((item) => ({
    label: item.label,
    value: item.value,
    isDisabled: item.value === workoutProgram?.workoutProgramStatus,
  }));

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("programs-menu.change-status-heading")}{" "}
              {workoutProgram?.nameOfProgram}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mt: 2,
            }}
          >
            {t("programs-menu.change-status-content")}
          </Typography>
          <SelectInputField
            label="Program Status"
            data={workoutProgramList}
            value={values.workoutProgramStatus}
            defaultValue={values.workoutProgramStatus}
            onBlur={handleBlur("programStatus")}
            onChange={(e) =>
              setFieldValue("workoutProgramStatus", e.target.value)
            }
            error={!!errors.workoutProgramStatus}
          />
          <PrimaryButton
            value="Submit"
            type="submit"
            variant="contained"
            fullWidth
            loading={isLoading}
            disabled={isLoading}
            onClick={() => handleSubmit()}
          />
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { ChangeStatusModal };
