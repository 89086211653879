import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  WorkoutProgramGroupItem,
  WorkoutProgramGroupItemParams,
} from "./models";

export const workoutProgramGroupItemsApi = createApi({
  reducerPath: "workoutProgramGroupItemsApi",
  baseQuery,
  tagTypes: ["WorkoutProgramGroupItems"],
  endpoints: (builder) => ({
    getWorkoutProgramGroupItems: builder.query<
      WorkoutProgramGroupItem[],
      WorkoutProgramGroupItemParams
    >({
      query: (params) => ({
        url: `/workout-program-group-items`,
        params,
        method: "GET",
      }),
      providesTags: ["WorkoutProgramGroupItems"],
    }),
    getWorkoutProgramGroupItem: builder.query<WorkoutProgramGroupItem, number>({
      query: (id) => ({
        url: `/workout-program-group-items/${id}`,
        method: "GET",
      }),
      providesTags: ["WorkoutProgramGroupItems"],
    }),
    deleteWorkoutProgramGroupItem: builder.mutation<void, number>({
      query: (id) => ({
        url: `/workout-program-group-items/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WorkoutProgramGroupItems"],
    }),
  }),
});

export const {
  useGetWorkoutProgramGroupItemsQuery,
  useGetWorkoutProgramGroupItemQuery,
  useDeleteWorkoutProgramGroupItemMutation,
} = workoutProgramGroupItemsApi;
