import { FC } from "react";
import {
  Modal,
  Typography,
  Box,
  Icon,
  Divider,
  Alert,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { WorkflowTemplateLibraryResponse } from "shared/api";
import { Colors } from "shared/themes/Colors";

interface PreviewWorkoutTemplateModalProps {
  isPreviewWorkflowTemplateModalOpen: boolean;
  handlePreviewWorkflowTemplateModalClose: () => void;
  workflowTemplate: WorkflowTemplateLibraryResponse;
  handleCardClick: (params: WorkflowTemplateLibraryResponse) => void;
  isSaving: boolean;
  successMessage: string;
  errorMessage: string;
}

const PreviewWorkoutTemplateModal: FC<PreviewWorkoutTemplateModalProps> = ({
  isPreviewWorkflowTemplateModalOpen,
  handlePreviewWorkflowTemplateModalClose,
  workflowTemplate,
  handleCardClick,
  isSaving,
  successMessage,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const displayWorkflowStepContent = (workflowStep: any) => {
    if (
      workflowStep.workflowActionType === "SendTimelineItem" ||
      workflowStep.workflowActionType === "SendPushNotification" ||
      workflowStep.workflowActionType === "SendSms" ||
      workflowStep.workflowActionType === "ConfigureEmail"
    ) {
      return true;
    }
    return false;
  };

  const buttonText = (workflowTemplate: WorkflowTemplateLibraryResponse) => {
    if (workflowTemplate.workflowRepetition === "OnRepeat") {
      return t("workflow-templates.add-btn");
    }

    if (workflowTemplate.workflowRepetition === "OnDemand") {
      return t("workflow-templates.run-btn");
    }

    return t("workflow-templates.start-btn");
  };

  return (
    <Modal
      open={isPreviewWorkflowTemplateModalOpen}
      onClose={handlePreviewWorkflowTemplateModalClose}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "75%",
            height: "auto",
            background: `${Colors.gray[100]}`,
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {workflowTemplate.name}
            </Typography>
            <Icon
              onClick={handlePreviewWorkflowTemplateModalClose}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {workflowTemplate.description}
          </Typography>
          {workflowTemplate.displayWorkflowSteps && (
            <>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                  mb: "16px",
                  mt: "16px",
                }}
              >
                Steps
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  height: "44px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                  marginBottom: "8px",
                }}
              >
                Once you have added this workflow, you can edit the steps and
                enable or disable it.
              </Typography>
              {workflowTemplate.workflowSteps.map((workflowStep, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: "auto",
                    background: `${Colors.gray[100]}`,
                    borderRadius: "4px",
                    padding: "16px 24px 24px",
                    border: `1px solid ${Colors.gray[1400]}`,
                    marginBottom: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "16px" }}>
                    {workflowStep.stepOrder}
                  </div>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "140%",
                        color: `${Colors.blue[1300]}`,
                      }}
                    >
                      {workflowStep.name}
                    </Typography>

                    <Typography
                      sx={{
                        width: "100%",
                        height: "44px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "140%",
                        color: `${Colors.blue[1300]}`,
                        marginBottom: "8px",
                      }}
                    >
                      {workflowStep.description}
                    </Typography>
                    {displayWorkflowStepContent(workflowStep) && (
                      <TextField
                        sx={{
                          width: "100%",
                          height: "44px",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "140%",
                          color: `${Colors.blue[1300]}`,
                          marginBottom: "8px",
                        }}
                        value={workflowStep.content}
                        disabled
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Box
            sx={{
              mt: 4,
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => handleCardClick(workflowTemplate)}
              size="large"
              loading={isSaving}
              disabled={isSaving}
              color="primary"
              sx={{
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Inter",
                textTransform: "none",
              }}
            >
              {buttonText(workflowTemplate)}
            </LoadingButton>
          </Box>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { PreviewWorkoutTemplateModal };
