import { TimelineItem } from "shared/api";
import * as Yup from "yup";

export interface AdminTimelineViewInputProps {
  timelineId: number;
  timelineTitle?: string;
  onAddItem: () => void;
  isReply?: boolean;
  replyTo?: TimelineItem;
}

export const TimelineViewInputSchema = Yup.object().shape({
  timelineItemType: Yup.string().required(),
  title: Yup.string().required(),
  content: Yup.string(),
  userId: Yup.number(),
  timelineId: Yup.number(),
  timelineItemParentId: Yup.number(),
  file: Yup.mixed().optional(),
});

export type AdminDropzoneProps = {
  onDrop: (file: File) => void;
  onImageClick: () => void;
  onSubmit: () => void;
  disabled: boolean;
  loading: boolean;
};
