import { Icon } from "@mui/material";
import { FC } from "react";

type OutlinedIconProps = {
  iconName: string;
  // add style and classname as props
  className?: string;
  style?: React.CSSProperties;
};

export const OutlinedIcon: FC<OutlinedIconProps> = ({
  iconName,
  className,
  style,
}) => (
  <Icon className={`material-icons-outlined ${className}`} style={style}>
    {iconName}
  </Icon>
);
