import { FC, useState } from "react";
import { Box, Grid, Typography, Divider, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "../../shared/themes";
import { WorkflowTemplateCard } from "./WorkflowTemplateCard";
import { WorkflowTemplateLibraryResponse } from "../../shared/api";

interface WorkflowTemplatesContainerProps {
  onRepeatWorkflowTemplates: WorkflowTemplateLibraryResponse[] | undefined;
  onDemandWorkflowTemplates: WorkflowTemplateLibraryResponse[] | undefined;
  gettingStartedWorkflowTemplates:
    | WorkflowTemplateLibraryResponse[]
    | undefined;
  handleCardClick: (params: WorkflowTemplateLibraryResponse) => void;
  isSaving: boolean;
  successMessage: string;
  errorMessage: string;
}

const WorkflowTemplatesContainer: FC<WorkflowTemplatesContainerProps> = ({
  onRepeatWorkflowTemplates,
  onDemandWorkflowTemplates,
  gettingStartedWorkflowTemplates,
  handleCardClick,
  isSaving,
  successMessage,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("workflow-templates.title")}
        </Typography>
      </Box>
      <Box sx={{ mt: "-20px", mb: 2 }}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {t("workflow-templates.sub-title")}
        </Typography>
      </Box>
      {onRepeatWorkflowTemplates && onRepeatWorkflowTemplates.length > 0 && (
        <>
          <Divider />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "18px",
              letterSpacing: "0.16px",
              color: `${Colors.oxford[1100]}`,
            }}
          >
            On-repeat
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "18px",
              color: `${Colors.oxford[1100]}`,
              mt: "12px",
            }}
          >
            Workflows that run automatically on a schedule
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {onRepeatWorkflowTemplates?.map((workflowLibraryTemplate) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                key={workflowLibraryTemplate.name}
              >
                <WorkflowTemplateCard
                  handleCardClick={handleCardClick}
                  workflowTemplate={workflowLibraryTemplate}
                  isSaving={isSaving}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {onDemandWorkflowTemplates && onDemandWorkflowTemplates.length > 0 && (
        <>
          <Divider />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "18px",
              letterSpacing: "0.16px",
              color: `${Colors.oxford[1100]}`,
            }}
          >
            On-demand
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "18px",
              color: `${Colors.oxford[1100]}`,
              mt: "12px",
            }}
          >
            Workflows that run manually
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {onDemandWorkflowTemplates?.map((workflowLibraryTemplate) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                key={workflowLibraryTemplate.name}
              >
                <WorkflowTemplateCard
                  handleCardClick={handleCardClick}
                  workflowTemplate={workflowLibraryTemplate}
                  isSaving={isSaving}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {gettingStartedWorkflowTemplates &&
        gettingStartedWorkflowTemplates.length > 0 && (
          <>
            <Divider />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "18px",
                letterSpacing: "0.16px",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              Getting started
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "18px",
                color: `${Colors.oxford[1100]}`,
                mt: "12px",
              }}
            >
              Workflows designed to help you get started
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {gettingStartedWorkflowTemplates?.map(
                (workflowLibraryTemplate) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    key={workflowLibraryTemplate.name}
                  >
                    <WorkflowTemplateCard
                      handleCardClick={handleCardClick}
                      workflowTemplate={workflowLibraryTemplate}
                      isSaving={isSaving}
                      successMessage={successMessage}
                      errorMessage={errorMessage}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </>
        )}
    </Box>
  );
};

export { WorkflowTemplatesContainer };
