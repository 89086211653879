import { Icon, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { LoadingButton } from "@mui/lab";
import { AdminDropzoneProps } from "./types";

const AdminDropzone: React.FC<AdminDropzoneProps> = ({
  onDrop,
  onImageClick,
  onSubmit,
  disabled,
  loading,
}) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const { t } = useTranslation();

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFileInfo(file);
      onDrop(file);
    }
  };

  return (
    <Dropzone onDrop={handleDrop} maxFiles={1} ref={dropzoneRef}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <Box
            {...getRootProps()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed grey",
              borderRadius: "0.25em",
              backgroundColor: "white",
              color: `${Colors.oxford[1100]}`,
              outline: "none",
              transition: "250ms ease-in-out",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            <Box sx={{ padding: "2em 1em" }}>
              {fileInfo ? (
                <div>
                  <Typography variant="subtitle1">{fileInfo.name}</Typography>
                  <Typography variant="body2">{fileInfo.size} bytes</Typography>
                  <Typography variant="body2">{fileInfo.type}</Typography>
                </div>
              ) : (
                <Typography sx={sharedStyles.body.timeline.cardTitle}>
                  {t("timeline.drag-and-drop")}
                </Typography>
              )}
            </Box>
            <Box sx={{ alignSelf: "flex-end" }}>
              <IconButton
                aria-label="add media"
                size="small"
                onClick={(e) => {
                  onImageClick();
                  e.stopPropagation();
                }}
              >
                <OutlinedIcon
                  iconName="image"
                  style={{ color: `${Colors.blue[1200]}` }}
                />
              </IconButton>
              <LoadingButton
                type="submit"
                variant="text"
                onClick={(e) => {
                  onSubmit();
                  e.stopPropagation();
                }}
                size="small"
                loading={loading}
                disabled={disabled}
                sx={{
                  fontSize: "12px",
                }}
                endIcon={<Icon>send</Icon>}
              >
                Send
              </LoadingButton>
            </Box>
          </Box>
        </section>
      )}
    </Dropzone>
  );
};

export { AdminDropzone };
