import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTypedDispatch } from "shared/stores";
import { addTimezoneToUtcDate } from "shared/helpers";
import {
  WorkoutResponse,
  dashboardApi,
  AthleteDashboardRequest,
} from "../../shared/api";

interface LiveTrainingWorkoutsSelectorProps {
  selectedUserId: number | null;
  setSelectedWorkoutId: (workoutId: number | null) => void;
  selectedWorkoutProgramId: number | null;
}

export const LiveTrainingWorkoutsSelector = forwardRef<
  HTMLButtonElement,
  LiveTrainingWorkoutsSelectorProps
>(({ selectedUserId, setSelectedWorkoutId, selectedWorkoutProgramId }, ref) => {
  const { t } = useTranslation();

  const getWorkouts =
    dashboardApi.endpoints.getAthleteDashboardWorkouts.initiate(
      {
        userId: selectedUserId!!,
        workoutProgramId: selectedWorkoutProgramId!!,
      } as AthleteDashboardRequest,
      { forceRefetch: true }
    );

  const [isLoading, setIsLoading] = useState(true);
  const [athleteDashboard, setAthleteDashboard] = useState<WorkoutResponse>();
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const dispatch = useTypedDispatch();

  async function callGetDashboardWorkouts() {
    try {
      const response = await dispatch(getWorkouts);
      setAthleteDashboard(response.data ?? undefined);
    } catch (error: any) {
      setFetchErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (selectedWorkoutProgramId !== null) {
      setIsLoading(true);
      setAthleteDashboard(undefined);
      setFetchErrorMessage("");
    }
    if (selectedWorkoutProgramId) callGetDashboardWorkouts();
    if (!selectedWorkoutProgramId) {
      setSelectedWorkoutId(null);
    }
  }, [selectedWorkoutProgramId]);

  const hiddenForceReload = () => {
    callGetDashboardWorkouts();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {isLoading || !selectedUserId ? (
        <Box />
      ) : (
        <Box sx={{ mt: "10px" }}>
          <FormControl
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            variant="outlined"
          >
            <Button
              sx={{ display: "none" }}
              ref={ref}
              type="button"
              onClick={hiddenForceReload}
            >
              Force Reload
            </Button>
            <InputLabel>Select Session</InputLabel>
            <Select
              label="Select Session"
              onChange={(e: { target: { value: any } }) =>
                setSelectedWorkoutId(e.target.value)
              }
              defaultValue=""
            >
              {athleteDashboard?.allWorkouts?.map((workout) => {
                const completedDateString =
                  workout.dateTimeCompleted &&
                  `- ${addTimezoneToUtcDate(workout.dateTimeCompleted).format(
                    "DD/MM/YYYY hh:mm A"
                  )}`;
                return (
                  <MenuItem value={workout.id} key={workout.id}>
                    Week {workout.weekNumber} - Session {workout.dayNumber} -{" "}
                    {workout.workoutStatus} {completedDateString}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
});

LiveTrainingWorkoutsSelector.displayName = "LiveTrainingWorkoutsSelector";
