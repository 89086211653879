import { FC } from "react";
import {
  Badge,
  Icon,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Routes } from "shared/routers";
import { Colors } from "../../../themes";

interface AthleteListItemProps {
  icon: string;
  title: string;
  link: string;
  unreadCount?: number;
}

const AthleteListItem: FC<AthleteListItemProps> = ({
  icon,
  title,
  link,
  unreadCount,
}) => {
  const resolved = useResolvedPath(link);
  const match = useMatch({ path: resolved.pathname, end: true });
  const showTimelineUnreadCountBadge = link === Routes.athleteTimeline.url;
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <ListItem
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "54px",
          padding: 0,
          bgcolor: `${match ? " rgba(239, 236, 255, 0.48)" : Colors.gray[50]}`,
        }}
      >
        <ListItemButton>
          <Box
            sx={{
              marginRight: "16px",
              color: `${Colors.blue[1200]}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "24px",
              height: "24px",
            }}
          >
            <Icon sx={{ color: `${Colors.blue[1200]}` }} fontSize="small">
              {icon}
            </Icon>
          </Box>

          <ListItemText
            primary={title}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.gray[900]}`,
            }}
          />
          {showTimelineUnreadCountBadge && (
            <Badge badgeContent={unreadCount} color="warning" />
          )}
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
export { AthleteListItem };
