import {
  Icon,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { sharedStyles } from "shared/themes/shared/styles";

type InputFieldProps = {
  label?: string;
  startAdornment?: string;
  endAdornment?: string;
};

export const InputField: React.FC<InputFieldProps & TextFieldProps> = ({
  ...props
}) => {
  const startAdornment = props.startAdornment ? (
    <InputAdornment position="start">
      <Icon>{props.startAdornment}</Icon>
    </InputAdornment>
  ) : undefined;

  const endAdornment = props.endAdornment ? (
    <InputAdornment position="end">
      <Icon>{props.endAdornment}</Icon>
    </InputAdornment>
  ) : undefined;

  return (
    <div style={{ width: "100%" }}>
      <InputLabel htmlFor={props.id}>{props?.label}</InputLabel>
      <TextField
        sx={sharedStyles.inputs.signIn}
        {...props}
        label=""
        InputProps={{
          startAdornment,
          endAdornment,
          ...props.InputProps,
        }}
      />
    </div>
  );
};
