import { Icon, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "../../../../../shared/api";
import { Colors } from "../../../../../shared/themes";
import { RequiredProgramUser } from "../RequiredProgramUser";

interface RequireNewProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  usersData: DashboardResponse;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const RequireNewProgramModal: FC<RequireNewProgramModalProps> = ({
  handleCloseModal,
  isOpenModal,
  usersData,
  getUserIdForWorkoutCreation,
}) => {
  const { t } = useTranslation();

  const { clientsWhoRequireANewProgram } = usersData;
  const clientsWhoRequireANewProgramLength =
    clientsWhoRequireANewProgram.length;

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      sx={{
        width: "95%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5501,
      }}
    >
      <Box
        sx={{
          width: "400px",
          borderRadius: "4px",
          bgcolor: `${Colors.gray[100]}`,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "49px",
            padding: "16px 24px 8px 24px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
          }}
        >
          {t("your-schedule.programs-needed")}
          <Icon onClick={handleCloseModal} sx={{ cursor: "pointer" }}>
            close_icon
          </Icon>
        </Typography>

        <Box
          sx={{
            padding: "0px 24px 16px",
          }}
        >
          {clientsWhoRequireANewProgramLength !== 0 ? (
            clientsWhoRequireANewProgram.map((item) => (
              <RequiredProgramUser
                key={item.id}
                item={item}
                getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
              />
            ))
          ) : (
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("your-schedule.users-don't-need-programs")}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { RequireNewProgramModal };
