import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  NutritionPlanHistoryItem,
  NutritionPlanHistoryItemParams,
  CreateNutritionPlanDto,
  NutritionPlan,
} from "./models";

export const nutritionApi = createApi({
  baseQuery,
  reducerPath: "nutritionApi",
  tagTypes: ["Nutrition"],
  endpoints: (builder) => ({
    getNutritionHistoryForUser: builder.query<
      NutritionPlanHistoryItem[],
      NutritionPlanHistoryItemParams
    >({
      query: (params) => ({
        url: `/nutrition-plans/history`,
        method: "GET",
        params,
      }),
      providesTags: ["Nutrition"],
    }),
    createNutritionPlan: builder.mutation<
      NutritionPlanHistoryItem,
      CreateNutritionPlanDto
    >({
      query: (body) => ({
        url: `/nutrition-plans`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Nutrition"],
    }),
    getLatestNutritionPlanForUserId: builder.query<NutritionPlan, number>({
      query: (userId) => ({
        url: `/nutrition-plans/latest?userId=${userId}`,
        method: "GET",
      }),
      providesTags: ["Nutrition"],
    }),
  }),
});

export const {
  useGetNutritionHistoryForUserQuery,
  useCreateNutritionPlanMutation,
  useGetLatestNutritionPlanForUserIdQuery,
} = nutritionApi;
