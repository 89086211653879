import { FC, useCallback, useRef, useState, useEffect } from "react";
import hash from "hash.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Content, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { CheckCircleOutline } from "@mui/icons-material";
import { CircularProgress, Skeleton, Typography } from "@mui/material";
import {
  UpdateUserNoteParams,
  useGenerateClientSummaryMutation,
  useGenerateProgramMutation,
  useGenerateReferralLetterMutation,
  useGenerateNotesMutation,
  UserNote,
  useSendUserNoteEmailToClientMutation,
  useSendReferralLetterEmailToClientMutation,
  useUpdateUserNoteMutation,
} from "shared/api";
import { MenuBar } from "./UserNotesMenuBar";
import { UserNoteFormValues } from "./UserNoteFormValues";
import { AddUserNoteModal, AddUserNoteParams } from "./AddUserNoteModal";

type UserNoteTextEditorProps = {
  content: Content;
  userNote: UserNote;
  type: "note" | "clientSummary" | "audioTranscript" | "referralLetter";
};

const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const UserNoteTextEditor: FC<UserNoteTextEditorProps> = ({
  content,
  userNote,
  type,
}) => {
  dayjs.extend(utc);

  const [noteDate, setNoteDate] = useState<string>(
    userNote?.dateTime || dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
  );

  const handleDateChange = (date: string | null) => {
    setNoteDate(date ?? dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"));
  };

  const [titleToChange, setTitleToChange] = useState<string | null>(
    userNote?.title
  );
  const [debouncedTitle, setDebouncedTitle] = useState<string | null>(
    userNote?.title
  );

  const handleTitleChange = (title: string) => {
    setTitleToChange(title);
  };

  const [openModal, setOpenModal] = useState(false);

  const previousContentHash = useRef<string>("");

  const generateHash = (content: string) => {
    return hash.sha256().update(content).digest("hex");
  };

  const isReadOnly = type === "audioTranscript";
  const showGenerateNoteButton = type === "audioTranscript";
  const showEmailButton = type === "clientSummary";
  const showReferralEmailButton = type === "referralLetter";
  const showGenerateSummaryButton = type === "note";
  const showGenerateProgramButton = type === "note";

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content,
  });

  const [updateUserNoteMutation] = useUpdateUserNoteMutation();
  const [generateNotes, { isLoading: isLoadingGenerateNotes }] =
    useGenerateNotesMutation();
  const [
    generatedClientSummary,
    { isLoading: isLoadingGenerateClientSummary },
  ] = useGenerateClientSummaryMutation();

  const [generateProgram, { isLoading: isLoadingGenerateProgram }] =
    useGenerateProgramMutation();

  const [
    generatedReferralLetter,
    { isLoading: isLoadingGenerateReferralLetter },
  ] = useGenerateReferralLetterMutation();

  const [sendEmail, { isLoading: isLoadingSendEmail }] =
    useSendUserNoteEmailToClientMutation();

  const [
    sendReferralLetterEmailToClient,
    { isLoading: isLoadingSendReferralLetterEmailToClient },
  ] = useSendReferralLetterEmailToClientMutation();

  const onSubmit = async (params: UpdateUserNoteParams, editor: any) => {
    setIsUpdating(true);
    setIsSuccess(false);
    try {
      if (params.id === undefined) {
        return;
      }

      let objectToUpdate = {
        id: params.id,
        dateTime: noteDate || params.dateTime,
        title: debouncedTitle || params.title,
      } as UpdateUserNoteParams;

      if (type === "note") {
        objectToUpdate = {
          ...objectToUpdate,
          note: editor.getHTML(),
        };
      }

      if (type === "clientSummary") {
        objectToUpdate = {
          ...objectToUpdate,
          clientSummary: editor.getHTML(),
        };
      }

      if (type === "referralLetter") {
        objectToUpdate = {
          ...objectToUpdate,
          referralLetter: editor.getHTML(),
        };
      }

      await updateUserNoteMutation(objectToUpdate).unwrap();
      setIsSuccess(true);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setIsUpdating(false);
      setTimeout(() => setIsSuccess(false), 2000);
      setTimeout(() => setErrorMessage(""), 2000);
    }
  };

  const handleGenerateNotesButtonClick = async (payload: AddUserNoteParams) => {
    try {
      await generateNotes({
        id: userNote.id,
        clinicalAbbreviations: payload.clinicalAbbreviations,
        includePreviousHistory: payload.includePreviousHistory,
      }).unwrap();
      setSuccessMessage("Note Generated");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setOpenModal(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleGenerateSummaryButtonClick = async () => {
    try {
      await generatedClientSummary(userNote.id).unwrap();

      setSuccessMessage("Client Summary Generated");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleGenerateProgramButtonClick = async () => {
    try {
      const program = await generateProgram(userNote.id).unwrap();
      setSuccessMessage(
        "Draft Program Generated. Go to the Programs page to review."
      );
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleGenerateReferralLetterButtonClick = async () => {
    try {
      await generatedReferralLetter(userNote.id).unwrap();
      setSuccessMessage("Referral Letter Generated");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleSendEmailButtonClick = async () => {
    try {
      await sendEmail(userNote.id).unwrap();
      setSuccessMessage("Email Sent");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleSendReferralLetterEmailButtonClick = async () => {
    try {
      await sendReferralLetterEmailToClient(userNote.id).unwrap();
      setSuccessMessage("Email Sent");
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleEditorUpdate = useCallback(
    debounce(() => {
      if (editor) {
        const contentHTML = editor.getHTML();
        const currentHash = generateHash(contentHTML);
        if (previousContentHash.current !== currentHash) {
          previousContentHash.current = currentHash;
          onSubmit(
            {
              id: userNote?.id,
            },
            editor
          );
        }
      }
    }, 350),
    [editor]
  );

  editor?.on("update", handleEditorUpdate);

  useEffect(() => {
    if (!titleToChange) return;

    const handler = setTimeout(() => {
      setDebouncedTitle(titleToChange);
    }, 350);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(handler);
    };
  }, [titleToChange]);

  useEffect(() => {
    if (!debouncedTitle) return;
    if (debouncedTitle !== userNote?.title && editor) {
      onSubmit(
        {
          id: userNote?.id,
        },
        editor
      );
    }
  }, [debouncedTitle]);

  useEffect(() => {
    if (noteDate !== userNote?.dateTime && editor) {
      onSubmit(
        {
          id: userNote?.id,
        },
        editor
      );
    }
  }, [noteDate]);

  const [statusIndicator, setStatusIndicator] = useState<JSX.Element | null>(
    null
  );

  const handleOnClickGenerateNote = () => {
    setErrorMessage("");
    setOpenModal(true);
  };

  useEffect(() => {
    if (isUpdating) {
      setStatusIndicator(<CircularProgress size={24} sx={{ ml: 2 }} />);
    } else if (isSuccess) {
      setStatusIndicator(<CheckCircleOutline color="success" sx={{ ml: 2 }} />);
    } else {
      setStatusIndicator(null);
    }
  }, [isUpdating, isSuccess]);

  if (!userNote.id) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "900px", width: "100%", mb: "12px" }}
      />
    );
  }

  return (
    <Box sx={{ backgroundColor: "white", padding: 2, borderRadius: 4 }}>
      <UserNoteFormValues
        titleToChange={titleToChange}
        noteDate={noteDate}
        handleDateChange={handleDateChange}
        handleTitleChange={handleTitleChange}
      />
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <MenuBar editor={editor} />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          {showGenerateNoteButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none" }}
              loading={isLoadingGenerateNotes}
              onClick={handleOnClickGenerateNote}
            >
              Generate Note
            </LoadingButton>
          )}
          {showEmailButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none" }}
              loading={isLoadingSendEmail}
              onClick={handleSendEmailButtonClick}
            >
              Email summary to Client
            </LoadingButton>
          )}
          {showReferralEmailButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none" }}
              loading={isLoadingSendReferralLetterEmailToClient}
              onClick={handleSendReferralLetterEmailButtonClick}
            >
              Email referral to Client
            </LoadingButton>
          )}
          {showGenerateSummaryButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none" }}
              loading={isLoadingGenerateClientSummary}
              onClick={handleGenerateSummaryButtonClick}
            >
              Generate Client Summary
            </LoadingButton>
          )}
          {showGenerateProgramButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none", marginLeft: 24 }}
              loading={isLoadingGenerateProgram}
              onClick={handleGenerateProgramButtonClick}
            >
              Generate Program
            </LoadingButton>
          )}
          {showGenerateSummaryButton && (
            <LoadingButton
              variant="outlined"
              type="button"
              size="large"
              style={{ textTransform: "none", marginLeft: 24 }}
              loading={isLoadingGenerateReferralLetter}
              onClick={handleGenerateReferralLetterButtonClick}
            >
              Generate Referral Letter
            </LoadingButton>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: 1,
            "& .ProseMirror": {
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: 2,
              height: "calc(100vh - 500px)",
              overflow: "auto",
              cursor: isReadOnly ? "not-allowed" : "auto",
              pointerEvents: isReadOnly ? "none" : "auto",
            },
          }}
        >
          <Box
            sx={{
              mb: 2,
              height: 24,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIndicator}

            {errorMessage && (
              <Typography sx={{ mt: 2, color: "red" }}>
                Error: {errorMessage}
              </Typography>
            )}
            {successMessage && (
              <Typography sx={{ mt: 2, color: "green" }}>
                {successMessage}
              </Typography>
            )}
          </Box>
          <EditorContent editor={editor} />
        </Box>
      </Box>
      <AddUserNoteModal
        isOpenModal={openModal}
        handleCloseModal={() => setOpenModal(!openModal)}
        isLoading={isLoadingGenerateNotes}
        onSubmit={(payload: AddUserNoteParams) => {
          handleGenerateNotesButtonClick(payload);
        }}
      />
    </Box>
  );
};
