import { FC, useState } from "react";
import { Modal, Alert, Typography, Box, Icon, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AnyType } from "../../shared/helpers";
import {
  useCreateCustomWorkflowMutation,
  CreateCustomWorkflowRequest,
} from "../../shared/api";
import { Colors } from "../../shared/themes/Colors";

interface WorkflowAddWorkflowModalProps {
  isWorkflowAddWorkflowModalOpen: boolean;
  handleWorkflowAddWorkflowModalClose: () => void;
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("workflow-automation.custom.validations.name"),
  description: Yup.string().required(
    "workflow-automation.custom.validations.description"
  ),
});

const WorkflowAddWorkflowModal: FC<WorkflowAddWorkflowModalProps> = ({
  isWorkflowAddWorkflowModalOpen,
  handleWorkflowAddWorkflowModalClose,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [createCustomWorkflow, { isLoading }] =
    useCreateCustomWorkflowMutation();

  const onSubmit = async (params: CreateCustomWorkflowRequest) => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      await createCustomWorkflow(params).unwrap();

      setSuccessMessage(t("workflow-automation.custom.success"));
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<CreateCustomWorkflowRequest>({
    initialValues: {
      name: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  return (
    <Modal
      open={isWorkflowAddWorkflowModalOpen}
      onClose={handleWorkflowAddWorkflowModalClose}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "400px",
            height: "auto",
            background: `${Colors.gray[100]}`,
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("workflow-automation.custom.title")}
            </Typography>
            <Icon
              onClick={handleWorkflowAddWorkflowModalClose}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {t("workflow-automation.custom.description")}
          </Typography>
          <TextField
            sx={{ width: "100%", height: "56px", mb: "64px" }}
            type="text"
            value={values.name}
            label="Name"
            onChange={handleChange("name")}
            error={!!errors.name}
            helperText={
              errors.name ? t(errors.name) : "The name of the workflow"
            }
          />
          <TextField
            sx={{ width: "100%", height: "150px", mb: "64px" }}
            type="text"
            value={values.description}
            label="Description"
            onChange={handleChange("description")}
            error={!!errors.description}
            helperText={
              errors.description
                ? t(errors.description)
                : "Describe what this workflow does. Try to be as specific as possible and break it down into steps. Example: When a new client is added, send them a welcome email and add them to the newsletter list."
            }
          />
          <LoadingButton
            sx={{
              width: "100%",
              height: "36px",
              background: `${Colors.blue[1200]}`,
              mt: "5px",
            }}
            size="large"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {t("workflow-automation.custom.save-btn")}
            </Typography>
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { WorkflowAddWorkflowModal };
