import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Colors } from "shared/themes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type UserNoteFormValuesProps = {
  titleToChange: string | null;
  handleTitleChange: (title: string) => void;
  noteDate: string | null;
  handleDateChange: (date: string | null) => void;
};

dayjs.extend(utc);

export const UserNoteFormValues: FC<UserNoteFormValuesProps> = ({
  titleToChange,
  handleTitleChange,
  noteDate,
  handleDateChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        width: "100%",
        borderRadius: "4px",
      }}
    >
      <Box>
        <Typography
          sx={{
            width: "100%",
            height: "5%",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            mb: 2,
            color: `${Colors.gray[2400]}`,
          }}
        >
          Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD/MM/YYYY"
            value={noteDate}
            onChange={(date) => handleDateChange(date)}
            renderInput={(params: any) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <Box>
        <Typography
          sx={{
            width: "100%",
            height: "5%",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            mb: 2,
            color: `${Colors.gray[2400]}`,
          }}
        >
          Title
        </Typography>
        <TextField
          type="text"
          value={titleToChange}
          onChange={(event) => handleTitleChange(event.target.value)}
        />
      </Box>
    </Box>
  );
};
