import { Box, FormControlLabel, Icon, Switch, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WorkoutItemSchemeBreakdown, WorkoutItemType } from "shared/api";
import { Colors } from "shared/themes";

type WorkoutPrescribedDataProps = {
  selectedWorkoutItemId?: number;
  workoutSchemas?: WorkoutItemSchemeBreakdown[];
  videoUrl?: string;
};

const WorkoutPrescribedData: FC<WorkoutPrescribedDataProps> = ({
  selectedWorkoutItemId,
  workoutSchemas,
  videoUrl,
}) => {
  let groupLabelForSwitch = "";

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: 2,
        ml: 0,
      }}
    >
      {workoutSchemas?.map((exerciseItem, key) => {
        const fontWeight =
          selectedWorkoutItemId === exerciseItem.workoutItemId ? "600" : "400";
        if (fontWeight === "600") groupLabelForSwitch = `Group ${key + 1}`;

        return (
          <div key={key}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight,
                fontSize: "18px",
                lineHeight: "20px",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              <Icon
                sx={{
                  fontSize: "18px !important",
                  mr: "4px",
                  ml: "4px",
                  color: `${Colors.oxford[1100]}`,
                  opacity: "0.87",
                }}
              >
                fitness_center
              </Icon>
              {exerciseItem?.prescribedSets} sets{" "}
              {exerciseItem?.prescribedReps
                ? ` x ${exerciseItem?.prescribedReps} reps`
                : null}
              {exerciseItem.prescribedIntensity &&
              (exerciseItem.workoutItemType !== WorkoutItemType.RPE ||
                exerciseItem.prescribedIntensity !== "0")
                ? ` @ ${exerciseItem.prescribedIntensity} ${
                    exerciseItem.workoutItemType !== WorkoutItemType.UNDEFINED
                      ? exerciseItem.workoutItemType
                      : ""
                  }`
                : null}
              {exerciseItem.prescribedWeight &&
              exerciseItem.prescribedWeight !== "0"
                ? ` @ ${exerciseItem?.prescribedWeight} ${
                    exerciseItem?.workoutItemWeightUnit ?? "Kg"
                  }`
                : null}
            </Typography>
          </div>
        );
      })}
    </Box>
  );
};

export { WorkoutPrescribedData };
