import { UserRole } from "../../api";
import { UserState } from "./types";

const all = (state: UserState) => state.user;

const jwtToken = (state: UserState) => all(state).jwtToken;
const userId = (state: UserState) => all(state).id;

const userEmail = (state: UserState) => all(state).email;

const fullName = (state: UserState) =>
  `${all(state).firstName} ${all(state).lastName}`;

const hasRole = (state: UserState) => !!all(state).userRole;

const isAdminOrCoach = (state: UserState) =>
  all(state).userRole === UserRole.Admin ||
  all(state).userRole === UserRole.Coach;

const isAdmin = (state: UserState) => all(state).userRole === UserRole.Admin;
const isCoach = (state: UserState) => all(state).userRole === UserRole.Coach;
const userProfileImageUrl = (state: UserState) => all(state).profileImageUrl;

const orgId = (state: UserState) => all(state).organisationId;

export const userSelectors = {
  all,
  userId,
  jwtToken,
  fullName,
  isAdminOrCoach,
  isAdmin,
  isCoach,
  hasRole,
  orgId,
  userEmail,
  userProfileImageUrl,
};
