import { Box } from "@mui/system";
import { FC } from "react";
import { Button, Drawer, Icon, Typography } from "@mui/material";
import { Colors } from "shared/themes";

interface WorkoutProgramsDrawerProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  title: string;
  description: string;
  buttonText: string;
}

const WorkoutProgramsDrawer: FC<WorkoutProgramsDrawerProps> = ({
  isOpen,
  toggleIsOpen,
  title,
  description,
  buttonText,
}) => {
  return (
    <Drawer open={isOpen} anchor="bottom">
      <Box
        sx={{
          width: "100vw",
          height: "40vh",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "16px 24px 32px",
          justifyContent: "center",
          alignContent: "center",
          gap: "24px",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px",
            gap: "4px",
            width: "100%",
            height: "73px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            marginTop: "44px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              width: "72px",
              height: "72px",
              boxShadow: "0px 4px 12px rgba(119, 119, 119, 0.5)",
              borderRadius: "50%",
            }}
          >
            <Icon
              sx={{
                color: `${Colors.blue[1200]}`,
                fontSize: "72px !important",
                position: "relative",
                zIndex: 1,
              }}
            >
              check_circle
            </Icon>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px",
            gap: "4px",
            width: "100%",
            height: "73px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            marginTop: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "500",
              fontStyle: "normal",
              fontSize: "18px",
              color: `${Colors.oxford[1100]}`,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "400",
              fontStyle: "normal",
              fontSize: "14px",
              color: `${Colors.oxford[1100]}`,
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px",
            gap: "4px",
            width: "100%",
            height: "73px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            marginTop: "24px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={toggleIsOpen}
            sx={{
              width: "90%",
            }}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {buttonText}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export { WorkoutProgramsDrawer };
