import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Alert, LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import {
  BaseWorkoutProgramDesignerDto,
  ClientResponse,
  WorkoutProgramDesignerRow,
  WorkoutProgramDesignerSection,
  WorkoutProgramDesignerUpdateRequest,
  WorkoutProgramStatus,
  useCreateAiWorkoutProgramDesignMutation,
  useCreateNewVersionOnExistingWorkoutProgramMutation,
  useCreateNewWorkoutProgramDesignMutation,
  useUpdateDraftWorkoutProgramDesignMutation,
  useCreateNewWorkoutProgramTemplateMutation,
  useCheckStripeIntegrationQuery,
} from "shared/api";
import { ErrorResponse, useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { Icon, Typography } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { setApplyChanges } from "shared/stores/workoutBuilder/slice";
import dayjs from "dayjs";
import {
  CreateAiWorkoutProgramFormValues,
  CreateAiWorkoutProgramModal,
} from "./CreateAiWorkoutProgramModal";
import { SaveNewVersionModal } from "./SaveNewVersionModal";
import { LoadingAiGenerateModal } from "./LoadingAiGenerateModal";
import { SuccessGenerateModal } from "./SuccessGenerateModal";
import {
  CompleteAndShareModal,
  CompleteAndShareModalValues,
} from "./CompleteAndShareModal";

interface WorkoutProgramGridButtonsProps {
  templateProgram: boolean;
  selectedWorkoutProgram: BaseWorkoutProgramDesignerDto;
  numberOfWeeks: number;
  numberOfDays: number;
  user: ClientResponse | null;
  startDate: string;
  programTitle: string;
  programNotes: string;
  isLatestVersion: boolean;
  showSimplifiedView: boolean;
}

const WorkoutProgramGridButtons: FC<WorkoutProgramGridButtonsProps> = ({
  templateProgram,
  selectedWorkoutProgram,
  numberOfWeeks,
  numberOfDays,
  user,
  startDate,
  programTitle,
  programNotes,
  isLatestVersion,
  showSimplifiedView,
}) => {
  const { t } = useTranslation();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [openAiModal, setOpenAiModal] = useState(false);
  const [openSuccessGenerateModal, setOpenSuccessGenerateModal] =
    useState(false);
  const [openSaveVersionModal, setOpenSaveVersionModal] = useState(false);
  const [openCompleteAndShareModal, setOpenCompleteAndShareModal] =
    useState(false);
  const [aiModalValues, setAiModalValues] =
    useState<CreateAiWorkoutProgramFormValues>({
      goal: "",
      minutesPerWorkout: "",
    });
  const [completeAndShareModalValues, setCompleteAndShareModalValues] =
    useState<CompleteAndShareModalValues>({
      invoiceRequested: false,
      invoiceDueDate: dayjs().add(14, "day").format("YYYY-MM-DDTHH:mm:ss[Z]"),
    });
  const [generatedAiWorkoutProgramId, setGeneratedAiWorkoutProgramId] =
    useState<number>();

  const isDraftOrNewProgram =
    !selectedWorkoutProgram?.workoutProgramId ||
    selectedWorkoutProgram?.workoutProgramStatus === "Draft";

  const isExistingProgramNotInDraft =
    !!selectedWorkoutProgram?.workoutProgramId &&
    selectedWorkoutProgram?.workoutProgramStatus !== "Draft";

  const isNewProgram = !selectedWorkoutProgram?.workoutProgramId;

  const statuses = useTypedSelector((state) => state.workoutProgramStatus);
  const isLoadingApis =
    statuses.isLoadingCreateNewVersionOnExistingWorkoutProgram ||
    statuses.isLoadingCreateNewWorkoutProgramDesign ||
    statuses.isLoadingGetWorkoutProgramDesigns ||
    statuses.isLoadingUpdateDraftWorkoutProgramDesign ||
    statuses.isLoadingCreateAiWorkoutProgramDesign;

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const workoutProgramBuilder = useTypedSelector(
    (state) => state.workoutBuilder
  );
  const dispatch = useTypedDispatch();

  const [createNewWorkoutProgramTemplate] =
    useCreateNewWorkoutProgramTemplateMutation();
  const [createNewWorkoutProgramDesign] =
    useCreateNewWorkoutProgramDesignMutation();
  const [updateWorkoutProgramDesign] =
    useUpdateDraftWorkoutProgramDesignMutation();
  const [createNewVersionOnExistingWorkoutProgram] =
    useCreateNewVersionOnExistingWorkoutProgramMutation();
  const [aiGenerateWorkoutProgram] = useCreateAiWorkoutProgramDesignMutation();
  const {
    data: hasStripeIntegration,
    isLoading: isLoadingCheckStripeIntegration,
  } = useCheckStripeIntegrationQuery();

  const [actionRequested, setActionRequested] = useState<
    "save" | "update" | "ai" | ""
  >("");

  const resetState = () => {
    setOpenLoadingModal(false);
    setActionRequested("");
    setAiModalValues({
      goal: "",
      minutesPerWorkout: "",
    });
    setCompleteAndShareModalValues({
      invoiceRequested: false,
      invoiceDueDate: dayjs().add(14, "day").format("YYYY-MM-DDTHH:mm:ss[Z]"),
    });
    dispatch(setApplyChanges("idle"));
  };

  const validityCheck = (data: WorkoutProgramDesignerUpdateRequest) => {
    let hasDataErrorMessage = false;
    let hasExerciseErrorMessage = false;

    data.workouts.forEach((workout) => {
      const hasNoExercisesOnAGivenDay = workout.workoutItems.every(
        (workoutItem) => !workoutItem.exercise
      );

      if (hasNoExercisesOnAGivenDay) {
        hasDataErrorMessage = true;
      }

      if (!hasNoExercisesOnAGivenDay) {
        workout.workoutItems.forEach((workoutItem) => {
          const isInvalidExerciseName =
            !workoutItem.exercise ||
            workoutItem.exercise.toLowerCase() === "null";
          const hasAtLeastOneExerciseForEachDay =
            workoutItem.exercise &&
            !isInvalidExerciseName &&
            workoutItem.baseSets;
          if (!hasAtLeastOneExerciseForEachDay) {
            hasExerciseErrorMessage = true;
          }
        });
      }
    });
    if (hasDataErrorMessage || hasExerciseErrorMessage) {
      if (hasDataErrorMessage) {
        setErrorMessage(t("workout-program-builder.no-exercise-error-message"));
      } else if (hasExerciseErrorMessage) {
        setErrorMessage(
          t("workout-program-builder.incorrect-exercise-error-message")
        );
      }
      return false;
    }
    return true;
  };

  const callSaveGrid =
    async (): Promise<WorkoutProgramDesignerUpdateRequest | null> => {
      const gridData = workoutProgramBuilder.gridRowHeaders;
      const gridDataArray = Object.values(gridData);
      if (gridDataArray.length !== numberOfDays) {
        setErrorMessage(
          "Something went wrong. Please contact your administrator."
        );
        setActionRequested("");
        dispatch(setApplyChanges("pending"));
        return null;
      }

      const updatedSections = gridDataArray.map((gridRow, index) => {
        const rows: WorkoutProgramDesignerRow[] = [];
        gridRow.forEach((gridRowItem: any) => {
          const newRow: WorkoutProgramDesignerRow = {
            workoutItemId: gridRowItem.workoutItemId,
            baseValue: gridRowItem.base,
            baseIntensity: !gridRowItem.baseIntensity
              ? ""
              : gridRowItem.baseIntensity,
            exercise: gridRowItem.exercise,
            type: gridRowItem.type,
            unit: gridRowItem.unit,
            increase: gridRowItem.increase,
            baseSets: gridRowItem.sets,
            baseReps: gridRowItem.reps,
            baseRest: gridRowItem.rest,
            supersetGroup: gridRowItem.supersets,
            notes: gridRowItem.notes,
            order: gridRowItem.rowKey + 1,
            weeks: [],
          };
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= numberOfWeeks; i++) {
            // If simplified view, use base values for all weeks
            if (showSimplifiedView) {
              const weekWeight =
                gridRowItem.base?.toString() ||
                gridRowItem[`week${i}weight`] ||
                "";

              const weekIntensity = gridRowItem.baseIntensity
                ? gridRowItem.baseIntensity
                : gridRowItem[`week${i}intensity`] || "";

              // Ensure weekSets defaults to "1" if sets is 0, and prioritize gridRowItem.sets
              const weekSets =
                gridRowItem.sets === "0"
                  ? "1"
                  : gridRowItem.sets || gridRowItem[`week${i}sets`] || "1";
              // Set weekReps to "0" if gridRowItem.sets was explicitly "0", otherwise follow the original logic
              const weekReps =
                gridRowItem.sets === "0"
                  ? "0"
                  : gridRowItem.reps || gridRowItem[`week${i}reps`] || "";

              newRow.weeks.push({
                weekNumber: i,
                weight: weekWeight,
                rpe: weekIntensity,
                reps: weekReps,
                sets: weekSets,
                rest: gridRowItem.rest || "",
              });
            } else {
              // else if complex view, use week values as priority
              const weekWeight =
                gridRowItem[`week${i}weight`]?.toString() ||
                gridRowItem.base ||
                "";
              const weekIntensity = !gridRowItem.baseIntensity
                ? ""
                : gridRowItem[`week${i}intensity`] || gridRowItem.baseIntensity;
              // Ensure weekSets defaults to "1" if sets is 0, and prioritize week{i}sets
              const weekSets =
                gridRowItem[`week${i}sets`] === "0"
                  ? "1"
                  : gridRowItem[`week${i}sets`] ||
                    (gridRowItem.sets === "0" ? "1" : gridRowItem.sets) ||
                    "1";
              // Set weekReps to "0" if weekSets was explicitly "0", otherwise follow the original logic
              const weekReps =
                gridRowItem[`week${i}sets`] === "0" || gridRowItem.sets === "0"
                  ? "0"
                  : gridRowItem.reps || gridRowItem[`week${i}reps`] || "";
              newRow.weeks.push({
                weekNumber: i,
                weight: weekWeight,
                rpe: weekIntensity,
                reps: weekReps,
                sets: weekSets,
                rest: gridRowItem.rest || "",
              });
            }
          }
          rows.push(newRow);
        });

        return {
          dayNumber: index + 1,
          workoutItems: rows,
        } as WorkoutProgramDesignerSection;
      });

      const dataToUpdate = {
        workoutProgramId: selectedWorkoutProgram?.workoutProgramId,
        workoutProgramStatus: selectedWorkoutProgram?.workoutProgramStatus,
        startDate,
        programTitle,
        programNotes,
        weeksInProgram: numberOfWeeks,
        daysPerWeek: numberOfDays,
        user: user!!,
        userId: user?.id,
        organisationId: user?.organisationId,
        workouts: updatedSections,
        invoiceRequested: completeAndShareModalValues.invoiceRequested,
        invoiceDueDate: completeAndShareModalValues.invoiceDueDate,
      } as WorkoutProgramDesignerUpdateRequest;
      return dataToUpdate;
    };

  const onCreateNewWorkoutProgramDesign = async () => {
    setErrorMessage("");
    const data = await callSaveGrid();
    if (!data) return;
    if (!validityCheck(data)) return;
    const { workoutProgramId, ...rest } = data;
    const workoutProgramExistsAndIsCreatingANewVersion =
      workoutProgramId && rest.workoutProgramStatus !== "Draft";
    const workoutProgramExistsAndIsInDraft =
      workoutProgramId && rest.workoutProgramStatus === "Draft";

    if (templateProgram) {
      await createNewWorkoutProgramTemplate({
        ...rest,
        workoutProgramStatus: WorkoutProgramStatus.NOT_STARTED,
      })
        .unwrap()
        .then((resp) => {
          navigate(Routes.workoutProgramBuilderSuccess.url);
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    } else if (workoutProgramExistsAndIsCreatingANewVersion) {
      await createNewVersionOnExistingWorkoutProgram({
        ...data,
        workoutProgramStatus: WorkoutProgramStatus.NOT_STARTED,
      })
        .unwrap()
        .then((resp) => {
          navigate(`${Routes.workoutProgramBuilderSuccess.url}`);
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    } else if (workoutProgramExistsAndIsInDraft) {
      await updateWorkoutProgramDesign({
        ...data,
        workoutProgramStatus: WorkoutProgramStatus.NOT_STARTED,
      })
        .unwrap()
        .then((resp) => {
          navigate(`${Routes.workoutProgramBuilderSuccess.url}`);
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    } else {
      await createNewWorkoutProgramDesign({
        ...rest,
        workoutProgramStatus: WorkoutProgramStatus.NOT_STARTED,
      })
        .unwrap()
        .then((resp) => {
          navigate(Routes.workoutProgramBuilderSuccess.url);
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    }
  };

  const onUpdateDraftWorkoutProgramDesign = async () => {
    setErrorMessage("");
    const data = await callSaveGrid();
    if (!data) return;
    if (!validityCheck(data)) return;
    const workoutProgramExistsAndIsInDraft = data.workoutProgramId;

    if (workoutProgramExistsAndIsInDraft) {
      await updateWorkoutProgramDesign({
        ...data,
        workoutProgramStatus: WorkoutProgramStatus.DRAFT,
      })
        .unwrap()
        .then((resp) => {
          if (!resp?.id || resp?.id === 0) {
            throw new Error("Something went wrong. Please contact your admin.");
          }
          navigate(`${Routes.workoutProgramBuilder.url}/${resp.id}`);
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    } else {
      const { workoutProgramId, ...rest } = data;
      await createNewWorkoutProgramDesign({
        ...rest,
        workoutProgramStatus: WorkoutProgramStatus.DRAFT,
      })
        .unwrap()
        .then((resp) => {
          if (!resp?.id || resp?.id === 0) {
            throw new Error("Something went wrong. Please contact your admin.");
          }
          navigate(`${Routes.workoutProgramBuilder.url}/${resp.id}`, {
            replace: true,
          });
        })
        .catch((e: ErrorResponse) => {
          const status = e?.status;
          if (!status) {
            setErrorMessage(t("errors.server-unable"));
          } else {
            const message = e?.data?.message ?? "";
            setErrorMessage(
              `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
            );
          }
        })
        .finally(() => {
          resetState();
        });
    }
  };

  const onGenerateAiWorkoutProgramDesign = async () => {
    await aiGenerateWorkoutProgram({
      minutesPerWorkout: aiModalValues.minutesPerWorkout ?? "60",
      goal: aiModalValues.goal ?? "Build Muscle",
      workoutProgramTitle: programTitle,
      workoutProgramDescription: programNotes,
      startDate,
      weeksInProgram: numberOfWeeks,
      daysPerWeek: numberOfDays,
      userId: user!!.id,
    })
      .unwrap()
      .then((resp) => {
        if (!resp?.id || resp?.id === 0) {
          throw new Error("Something went wrong. Please contact your admin.");
        }
        setGeneratedAiWorkoutProgramId(resp.id);
        setOpenLoadingModal(false);
        setOpenSuccessGenerateModal(true);
      })
      .catch((e: ErrorResponse) => {
        const status = e?.status;
        if (!status) {
          setErrorMessage(t("errors.openai-unable"));
        } else {
          const message = e?.data?.message ?? "";
          setErrorMessage(
            `${status} - ${message} ${JSON.stringify(e?.data?.errors ?? "")}`
          );
        }
      })
      .finally(() => {
        resetState();
      });
  };

  const handleOnClickUpdate = () => {
    setErrorMessage("");
    setActionRequested("update");
    dispatch(setApplyChanges("pending"));
  };

  const handleOnClickTemplateSave = () => {
    setErrorMessage("");
    setActionRequested("save");
    dispatch(setApplyChanges("pending"));
  };

  const handleOnClickSave = () => {
    setErrorMessage("");
    if (isExistingProgramNotInDraft) {
      setOpenSaveVersionModal(true);
    } else if (hasStripeIntegration) {
      setOpenCompleteAndShareModal(true);
    } else {
      setActionRequested("save");
      dispatch(setApplyChanges("pending"));
    }
  };

  const handleOnClickAiGenerate = () => {
    setErrorMessage("");
    setOpenAiModal(true);
  };

  const handleAiWorkoutProgramSubmit = (
    payload: CreateAiWorkoutProgramFormValues
  ) => {
    setOpenAiModal(false);
    setAiModalValues(payload);
    setActionRequested("ai");
  };

  const handleSaveNewVersionSubmit = () => {
    setOpenSaveVersionModal(false);
    setActionRequested("save");
    dispatch(setApplyChanges("pending"));
  };

  const handleCompleteAndShareSubmit = (
    values: CompleteAndShareModalValues
  ) => {
    // if invoice requested, send invoice due date
    if (values.invoiceRequested) {
      setCompleteAndShareModalValues(values);
    }
    setOpenCompleteAndShareModal(false);
    setActionRequested("save");
    dispatch(setApplyChanges("pending"));
  };

  useEffect(() => {
    if (
      workoutProgramBuilder.applyChanges === "completed" &&
      !!actionRequested
    ) {
      if (actionRequested === "save") {
        onCreateNewWorkoutProgramDesign();
      } else if (actionRequested === "update") {
        onUpdateDraftWorkoutProgramDesign();
      }
    }
  }, [workoutProgramBuilder.applyChanges, actionRequested]);

  useEffect(() => {
    if (actionRequested === "ai" && !!aiModalValues) {
      setOpenLoadingModal(true);
      onGenerateAiWorkoutProgramDesign();
    }
  }, [actionRequested, aiModalValues]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(setApplyChanges("failed"));
    }
  }, [errorMessage]);

  const disableSaveButtonWhenNotLatestVersionIfExisting =
    isExistingProgramNotInDraft && !isLatestVersion;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 2,
        }}
      >
        {isNewProgram && !templateProgram && (
          <LoadingButton
            variant="outlined"
            color="primary"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              ml: "16px",
            }}
            onClick={handleOnClickAiGenerate}
            loading={isLoadingApis}
            disabled={isLoadingApis || !user}
          >
            <Icon>auto_fix_high</Icon>
            {t("workout-program-builder.ai-generate-draft")}
          </LoadingButton>
        )}
        {isDraftOrNewProgram && !templateProgram && (
          <div id="save-program-draft">
            <LoadingButton
              variant="outlined"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
                textDecoration: "none",
                ml: "16px",
              }}
              onClick={handleOnClickUpdate}
              loading={isLoadingApis}
              disabled={isLoadingApis || !user}
            >
              {t("workout-program-builder.save-as-draft")}
            </LoadingButton>
          </div>
        )}
        {templateProgram && (
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              ml: "16px",
            }}
            onClick={handleOnClickTemplateSave}
            loading={isLoadingApis}
            disabled={isLoadingApis}
          >
            {t("workout-program-builder.create-template")}
          </LoadingButton>
        )}
        {!templateProgram && (
          <div id="complete-program">
            <LoadingButton
              variant="contained"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
                textDecoration: "none",
                ml: "16px",
              }}
              onClick={handleOnClickSave}
              loading={isLoadingApis}
              disabled={
                isLoadingApis ||
                !user ||
                disableSaveButtonWhenNotLatestVersionIfExisting ||
                isLoadingCheckStripeIntegration
              }
            >
              {isExistingProgramNotInDraft
                ? `${t("workout-program-builder.update-existing")}`
                : t("workout-program-builder.complete")}{" "}
            </LoadingButton>
          </div>
        )}
      </Box>
      <CreateAiWorkoutProgramModal
        isOpenModal={openAiModal}
        handleCloseModal={() => setOpenAiModal(!openAiModal)}
        onSubmit={(payload: CreateAiWorkoutProgramFormValues) => {
          handleAiWorkoutProgramSubmit(payload);
        }}
      />
      <SaveNewVersionModal
        isOpenModal={openSaveVersionModal}
        handleCloseModal={() => setOpenSaveVersionModal(!openSaveVersionModal)}
        onSubmit={() => {
          handleSaveNewVersionSubmit();
        }}
        isTemplate={templateProgram}
      />
      <CompleteAndShareModal
        isOpenModal={openCompleteAndShareModal}
        handleCloseModal={() =>
          setOpenCompleteAndShareModal(!openCompleteAndShareModal)
        }
        onSubmit={(values) => {
          handleCompleteAndShareSubmit(values);
        }}
      />
      <LoadingAiGenerateModal
        isOpenModal={openLoadingModal}
        handleCloseModal={() => setOpenLoadingModal(!openLoadingModal)}
      />
      <SuccessGenerateModal
        isOpenModal={openSuccessGenerateModal}
        handleCloseModal={() =>
          setOpenSuccessGenerateModal(!openSuccessGenerateModal)
        }
        workoutProgramId={generatedAiWorkoutProgramId!!}
      />
      {errorMessage && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: 2, alignItems: "center", mt: 3 }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {errorMessage}
          </Typography>
        </Alert>
      )}
    </>
  );
};

export { WorkoutProgramGridButtons };
