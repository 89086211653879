import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateProgramParams,
  EditUserWorkoutData,
  IndividualWorkoutItem,
  ShareProgramParams,
  UpdateProgramParams,
  UpdateWorkoutProgramStatusParams,
  UserItemResponse,
  UserPaginationParams,
  UsersListParams,
  UserWorkoutItem,
  WorkoutItemResponse,
  WorkoutProgamListParams,
  WorkoutProgramItem,
  AssignWorkoutProgramTemplateToClientParams,
  WorkoutProgamCountOnlyParams,
  AllWorkoutProgamParams,
} from "./models";

export const programsApi = createApi({
  baseQuery,
  reducerPath: "programsApi",
  tagTypes: ["Workouts"],
  endpoints: (builder) => ({
    getWorkoutProgramsCount: builder.query<
      number,
      WorkoutProgamCountOnlyParams
    >({
      query: (params) => ({
        url: `/workout-programs`,
        method: "GET",
        params,
      }),
      providesTags: ["Workouts"],
    }),
    getWorkoutPrograms: builder.query<
      WorkoutProgramItem[],
      WorkoutProgamListParams
    >({
      query: (params) => ({
        url: "/workout-programs?orderByDescending=true",
        method: "GET",
        params,
      }),
      providesTags: ["Workouts"],
    }),
    getWorkoutProgramTemplates: builder.query<
      WorkoutProgramItem[],
      WorkoutProgamListParams
    >({
      query: (params) => ({
        url: "/workout-program-templates?orderByDescending=true",
        method: "GET",
        params,
      }),
      providesTags: ["Workouts"],
    }),
    getAllWorkoutPrograms: builder.query<
      WorkoutProgramItem[],
      AllWorkoutProgamParams
    >({
      query: (params) => ({
        url: "/workout-programs",
        method: "GET",
        params,
      }),
      providesTags: ["Workouts"],
    }),
    getUsersItems: builder.query<UserItemResponse[], UsersListParams>({
      query: (params) => ({
        url: `/users`,
        method: "GET",
        params,
      }),
    }),
    getUsersById: builder.query<UserItemResponse[], string>({
      query: (ids) => ({
        url: `/users?ids=${ids}`,
        method: "GET",
      }),
    }),
    getUserPrograms: builder.query<WorkoutProgramItem[], number>({
      query: (id) => ({
        url: `/workout-programs?userId=${id}`,
        method: "GET",
      }),
    }),
    getUserProgramsPagination: builder.query<
      WorkoutProgramItem[],
      UserPaginationParams
    >({
      query: (params) => ({
        url: `/workout-programs`,
        method: "GET",
        params,
      }),
    }),
    createNewProgram: builder.mutation<void, CreateProgramParams>({
      query: ({
        daysPerWeek,
        description,
        name,
        startDate,
        userId,
        weeksInProgram,
        workoutProgramStatus,
      }) => ({
        url: "/workout-programs",
        method: "POST",
        body: {
          daysPerWeek,
          description,
          name,
          startDate,
          userId,
          weeksInProgram,
          workoutProgramStatus,
        },
      }),
      invalidatesTags: ["Workouts"],
    }),
    updateExistingProgram: builder.mutation<void, UpdateProgramParams>({
      query: ({
        id,
        daysPerWeek,
        description,
        name,
        startDate,
        userId,
        weeksInProgram,
        workoutProgramStatus,
      }) => ({
        url: `/workout-programs/${id}`,
        method: "PUT",
        body: {
          id,
          daysPerWeek,
          description,
          name,
          startDate,
          userId,
          weeksInProgram,
          workoutProgramStatus,
        },
      }),
      invalidatesTags: ["Workouts"],
    }),
    createWorkoutItems: builder.mutation<
      WorkoutItemResponse[],
      Partial<IndividualWorkoutItem>[]
    >({
      query: (body) => ({
        url: "/workout-items",
        method: "POST",
        body,
      }),
    }),
    createWorkoutItemsLastStep: builder.mutation<
      WorkoutItemResponse[],
      Partial<IndividualWorkoutItem>[]
    >({
      query: (body) => ({
        url: "/workout-items",
        method: "POST",
        body,
      }),
    }),
    autoSaveWorkoutItems: builder.mutation<
      WorkoutItemResponse[],
      Partial<IndividualWorkoutItem>[]
    >({
      query: (body) => ({
        url: "/workout-items",
        method: "POST",
        body,
      }),
    }),
    updateWorkoutItem: builder.mutation<
      WorkoutItemResponse[],
      Partial<IndividualWorkoutItem>
    >({
      query: (body) => ({
        url: `/workout-items/${body.id}`,
        method: "PUT",
        body,
      }),
    }),

    getUserWorkoutItems: builder.query<UserWorkoutItem[], number>({
      query: (id) => ({
        url: `/workouts?workoutProgramId=${id}`,
        method: "GET",
      }),
    }),
    getIndividualUserWorkoutItems: builder.query<
      IndividualWorkoutItem[],
      string
    >({
      query: (workoutProgramIds) => ({
        url: `/workout-items?workoutIds=${workoutProgramIds}`,
        method: "GET",
      }),
    }),
    deleteWorkoutItem: builder.mutation<void, number>({
      query: (id) => ({
        url: `/workout-items/${id}`,
        method: "DELETE",
      }),
    }),
    shareWorkoutProgram: builder.mutation<void, ShareProgramParams>({
      query: (body) => ({
        url: `/share-workout-program`,
        method: "POST",
        body,
      }),
    }),
    getWorkoutToEdit: builder.query<EditUserWorkoutData, number>({
      query: (workoutId) => ({
        url: `workout-programs/${workoutId}`,
        method: "GET",
      }),
    }),
    updateWorkoutProgramStatus: builder.mutation<
      void,
      UpdateWorkoutProgramStatusParams
    >({
      query: ({ workoutProgramId, workoutProgramStatus }) => ({
        url: `workout-programs/${workoutProgramId}`,
        method: "PATCH",
        body: {
          workoutProgramStatus,
        },
      }),
      invalidatesTags: ["Workouts"],
    }),
    assignWorkoutTemplateToClient: builder.mutation<
      void,
      AssignWorkoutProgramTemplateToClientParams
    >({
      query: (body) => ({
        url: "/workout-program-templates/assign",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Workouts"],
    }),
    archiveWorkoutProgramTemplate: builder.mutation<void, number>({
      query: (workoutProgramId) => ({
        url: `/workout-program-templates/${workoutProgramId}`,
        method: "PATCH",
        body: {
          workoutProgramStatus: "Undefined",
        },
      }),
      invalidatesTags: ["Workouts"],
    }),
  }),
});

export const {
  useGetWorkoutProgramsCountQuery,
  useGetWorkoutProgramsQuery,
  useGetWorkoutProgramTemplatesQuery,
  useGetAllWorkoutProgramsQuery,
  useGetUsersItemsQuery,
  useGetUsersByIdQuery,
  useGetUserProgramsQuery,
  useGetUserProgramsPaginationQuery,
  useCreateNewProgramMutation,
  useGetUserWorkoutItemsQuery,
  useGetIndividualUserWorkoutItemsQuery,
  useCreateWorkoutItemsMutation,
  useUpdateWorkoutItemMutation,
  useDeleteWorkoutItemMutation,
  useCreateWorkoutItemsLastStepMutation,
  useShareWorkoutProgramMutation,
  useAutoSaveWorkoutItemsMutation,
  useGetWorkoutToEditQuery,
  useUpdateExistingProgramMutation,
  useUpdateWorkoutProgramStatusMutation,
  useAssignWorkoutTemplateToClientMutation,
  useArchiveWorkoutProgramTemplateMutation,
} = programsApi;
