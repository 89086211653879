import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetUsersQuery, useGetUsersCountQuery } from "shared/api";
import { createUsersManagementRows } from "shared/helpers";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { sharedStyles } from "shared/themes/shared/styles";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";

const OrganisationUsersContainer: FC = () => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { data: usersArrayLength } = useGetUsersCountQuery({
    countOnly: true,
    userRole: "admin",
  });
  const { data: users, isLoading } = useGetUsersQuery({
    count: pageSize,
    page,
    userRole: "admin",
    orderByName: true,
  });

  const [rowCountState, setRowCountState] = useState(usersArrayLength || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      usersArrayLength !== undefined ? usersArrayLength : prevRowCountState
    );
  }, [usersArrayLength, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        return (
          <IdenticonAvatar
            seedValue={params.row?.id}
            profileImageUrl={params.row?.profileImageUrl}
          />
        );
      },
    },
    {
      field: "userFullName",
      headerName: t("users-management.user-name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: t("users-management.email"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("users-management.status"),
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return <GridStatusItem status={params.value} />;
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.profile.url}/${id}`}>
            <Button
              color="primary"
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              {t("users-management.edit-btn")}
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = createUsersManagementRows(users!);

  return (
    <Box sx={sharedStyles.containers.dataGrid.wrapper}>
      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <DataGrid
            headerHeight={36}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows || []}
            disableColumnFilter
            rowCount={rowCountState}
            paginationMode="server"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { OrganisationUsersContainer };
