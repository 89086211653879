import { FC } from "react";
import { Icon } from "@mui/material";
import { WorkoutLoadStatus } from "shared/api";
import { Colors } from "../../../../shared/themes";

interface WorkoutTrendIconProps {
  finalTrend: WorkoutLoadStatus;
}

const WorkoutTrendIcon: FC<WorkoutTrendIconProps> = ({
  finalTrend = WorkoutLoadStatus.Undefined,
}) => {
  if (finalTrend === WorkoutLoadStatus.Increasing) {
    return (
      <Icon
        sx={{
          color: `${Colors.green[100]}`,
          fontSize: "16px",
          mr: "4px",
          ml: "4px",
        }}
      >
        trending_up
      </Icon>
    );
  }
  if (finalTrend === WorkoutLoadStatus.Decreasing) {
    return (
      <Icon
        sx={{
          color: `${Colors.red[200]}`,
          fontSize: "16px",
          mr: "4px",
          ml: "4px",
        }}
      >
        trending_down
      </Icon>
    );
  }
  return (
    <Icon
      sx={{
        color: `${Colors.yellow[200]}`,
        fontSize: "16px",
        mr: "4px",
        ml: "4px",
      }}
    >
      trending_flat
    </Icon>
  );
};

export { WorkoutTrendIcon };
