import { Box } from "@mui/system";
import { FC } from "react";
import { Workouts } from "./ui";

interface AthleteDashboardProps {
  closeSidebar: () => void;
}

const AthleteDashboard: FC<AthleteDashboardProps> = ({ closeSidebar }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "90vh",
      }}
    >
      <Workouts closeSidebar={closeSidebar} />
    </Box>
  );
};

export { AthleteDashboard };
