import { FC, useEffect } from "react";
import { Box } from "@mui/system";
import { Fade, Modal, Typography, Backdrop, Icon } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";

interface SuccessGenerateModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  workoutProgramId: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SuccessGenerateModal: FC<SuccessGenerateModalProps> = ({
  isOpenModal,
  handleCloseModal,
  workoutProgramId,
}) => {
  const navigate = useNavigate();
  const handleClose = (event: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (!!isOpenModal && !!workoutProgramId) {
      setTimeout(() => {
        handleCloseModal();
        navigate(`${Routes.workoutProgramBuilder.url}/${workoutProgramId}`);
      }, 5000);
    }
  }, [isOpenModal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: () => {},
        },
      }}
    >
      <Fade in={isOpenModal}>
        <Box sx={style}>
          <Icon
            onClick={handleCloseModal}
            sx={{
              color: `${Colors.gray[1400]}`,
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            close_icon
          </Icon>
          <Typography sx={sharedStyles.body.modalDescription}>
            ✅ Your workout program was successfully generated! Redirecting you
            in 5 seconds... or{" "}
            <Link
              style={sharedStyles.links.signIn}
              to={`${Routes.workoutProgramBuilder.url}/${workoutProgramId}`}
            >
              click here to go there immediately
            </Link>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export { SuccessGenerateModal };
