import { FC } from "react";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { UserBodyMeasurement } from "shared/api";

interface UserBodyMeasurementsChartProps {
  userBodyMeasurements: UserBodyMeasurement[] | undefined;
}

const UserBodyMeasurementsChart: FC<UserBodyMeasurementsChartProps> = ({
  userBodyMeasurements,
}) => {
  const chartData = {
    series: [
      {
        name: "Weight",
        data: userBodyMeasurements?.map((ubm) => ubm.weight),
      },
    ],
    xaxis: {
      categories: userBodyMeasurements?.map(
        (userBodyMeasurement) =>
          userBodyMeasurement?.dateTime &&
          dayjs(userBodyMeasurement.dateTime).format("DD/MM/YYYY")
      ),
    },
  };

  return (
    <Chart
      options={{
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Bodyweight",
          align: "left",
          style: {
            fontSize: "18px",
            fontFamily: "Inter",
            fontWeight: 600,
            color: "#4245FF",
          },
        },
        grid: {
          show: true,
          borderColor: "#E5E5EF",
          strokeDashArray: 7,
        },
        fill: {
          colors: ["#4245FF"],
        },
        xaxis: {
          ...chartData.xaxis,
          tickAmount: 4,
          labels: {
            show: true,
            style: {
              fontSize: "12px",
              fontFamily: "Inter",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      }}
      series={chartData.series as any}
      type="line"
      width="100%"
      height="400"
    />
  );
};

export { UserBodyMeasurementsChart };
