import React, { useEffect, useRef, useState } from "react";

const AudioIndicator: React.FC<{
  stream: MediaStream | null;
  isLoadingRecording: boolean;
}> = ({ stream, isLoadingRecording }) => {
  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);

  useEffect(() => {
    if (!stream) return;

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    audioContextRef.current = audioContext;
    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;

    const detectVolume = () => {
      if (!analyserRef.current || !dataArrayRef.current) return;
      analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

      let sum = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dataArrayRef.current.length; i++) {
        const amplitude = dataArrayRef.current[i] - 128;
        sum += amplitude * amplitude;
      }

      const volume = Math.sqrt(sum / dataArrayRef.current.length);
      let level = 0;
      if (volume > 25) {
        level = 3; // High volume
      } else if (volume > 10) {
        level = 2; // Medium volume
      } else if (volume > 0) {
        level = 1; // Low volume
      }

      setVolumeLevel(level);

      requestAnimationFrame(detectVolume);
    };

    detectVolume();

    // eslint-disable-next-line consistent-return
    return () => {
      audioContext.close();
    };
  }, [stream]);

  const renderBars = () => {
    const barWidth = 6;
    const barSpacing = 8;
    const totalWidth = barWidth * 3 + barSpacing * 2; // Total width of bars and spaces
    const startX = (56 - totalWidth) / 2; // Center the bars horizontally

    return [1, 2, 3].map((barLevel) => (
      <rect
        key={barLevel}
        x={startX + (barWidth + barSpacing) * (barLevel - 1)} // Center bars
        y={34 - barLevel * 6} // Center bars vertically
        width={barWidth}
        height={barLevel * 6}
        fill={volumeLevel >= barLevel ? "#00f" : "#aaa"} // Blue for active, gray for inactive
      />
    ));
  };

  const renderSpinner = () => (
    <circle
      cx="28"
      cy="28"
      r="20"
      stroke="#00f"
      strokeWidth="4"
      fill="none"
      strokeDasharray="31.4 31.4"
      strokeDashoffset="0"
      style={{
        animation: "spin 1s linear infinite",
      }}
    />
  );

  return (
    <svg width="56" height="56" viewBox="0 0 56 56">
      {isLoadingRecording ? renderSpinner() : renderBars()}
      <style>{`
        @keyframes spin {
          0% { stroke-dashoffset: 0; }
          100% { stroke-dashoffset: -62.8; }
        }
      `}</style>
    </svg>
  );
};

export default AudioIndicator;
