import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganisationApiKey } from "shared/api";
import { OrganisationIntegrationCard } from "./OrganisationIntegrationCard";

interface OrganisationIntegrationsContainerProps {
  organisationApiKeys: OrganisationApiKey[] | undefined;
}

const OrganisationIntegrationsContainer: FC<
  OrganisationIntegrationsContainerProps
> = ({ organisationApiKeys }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {organisationApiKeys?.length === 0 && (
        <Typography
          variant="body1"
          sx={{
            marginTop: "20px",
          }}
        >
          {t("organisation-management.integrations.noIntegrations")}
        </Typography>
      )}
      <Grid container spacing={2}>
        {organisationApiKeys?.map((organisationApiKey) => (
          <Grid
            item
            xs={6}
            sm={4}
            key={organisationApiKey.id}
            sx={{
              cursor: "pointer",
            }}
          >
            <OrganisationIntegrationCard
              organisationApiKey={organisationApiKey}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export { OrganisationIntegrationsContainer };
