import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  OrganisationApiKey,
  OrganisationApiKeyParams,
  FiveTranConnector,
  FiveTranConnectorCardSetupUrlResponse,
} from "./models";

export const organisationApiKeysApi = createApi({
  baseQuery,
  reducerPath: "organisationApiKeysApi",
  tagTypes: ["OrganisationApiKeys"],
  endpoints: (builder) => ({
    getOrganisationApiKeys: builder.query<
      OrganisationApiKey[],
      OrganisationApiKeyParams
    >({
      query: (params) => ({
        url: `/organisation-api-keys`,
        method: "GET",
        params,
      }),
      providesTags: ["OrganisationApiKeys"],
    }),
    getOrganisationApiKeyById: builder.query<OrganisationApiKey, number>({
      query: (id) => ({
        url: `/organisation-api-keys/${id}`,
        method: "GET",
      }),
      providesTags: ["OrganisationApiKeys"],
    }),
    getFiveTranConnectors: builder.query<FiveTranConnector[], void>({
      query: () => ({
        url: "/fivetran/connectors",
        method: "GET",
      }),
    }),
    addFiveTranConnector: builder.mutation<
      FiveTranConnectorCardSetupUrlResponse,
      string
    >({
      query: (apiIntegration) => ({
        url: `/fivetran/connectors/setup/${apiIntegration}`,
        method: "POST",
        body: { apiIntegration },
      }),
    }),
    authoriseFiveTranConnector: builder.mutation<void, string>({
      query: (apiIntegration) => ({
        url: `/fivetran/connectors/authorise/${apiIntegration}`,
        method: "POST",
        body: {
          apiIntegration,
        },
      }),
    }),
    checkStripeIntegration: builder.query<void, void>({
      query: () => ({
        url: "/organisation-api-keys/check-stripe-integration",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetOrganisationApiKeysQuery,
  useGetOrganisationApiKeyByIdQuery,
  useGetFiveTranConnectorsQuery,
  useAddFiveTranConnectorMutation,
  useAuthoriseFiveTranConnectorMutation,
  useCheckStripeIntegrationQuery,
} = organisationApiKeysApi;
