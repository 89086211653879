import { FC, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Icon,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CreateTimeLineItemParams,
  TimelineItemType,
  useCreateTimelineItemMutation,
  useUploadMediaMutation,
} from "shared/api";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { InputField } from "components/Form/InputField";
import { LoadingButton } from "@mui/lab";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { sharedStyles } from "shared/themes/shared/styles";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { TimelineViewItemIcon } from "../TimelineViewIcon";
import { TimelineViewReply } from "../TimelineViewReply";
import { AdminDropzone } from "./AdminDropzone";
import { AdminTimelineViewInputProps, TimelineViewInputSchema } from "./types";

const AdminTimelineViewInput: FC<AdminTimelineViewInputProps> = ({
  timelineId,
  timelineTitle,
  onAddItem,
  isReply,
  replyTo,
}) => {
  const { t } = useTranslation();
  const currentUserId = useTypedSelector(userSelectors.userId);
  const currentUserName = useTypedSelector(userSelectors.fullName);
  const currentProfileImageUrl = useTypedSelector(
    userSelectors.userProfileImageUrl
  );

  const isMobileView = useMediaQuery("(max-width: 600px)");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [createTimelineItem, { isLoading }] = useCreateTimelineItemMutation();
  const [uploadMedia, { isLoading: isFileUploading }] =
    useUploadMediaMutation();
  const parentIdToUse = replyTo?.timelineItemParentId
    ? replyTo?.timelineItemParentId
    : replyTo?.id;

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      timelineItemType: TimelineItemType.Message,
      title: `${currentUserName} left a message`,
      content: "",
      userId: currentUserId ?? 0,
      timelineId,
      timelineItemParentId: parentIdToUse ?? undefined,
      file: undefined,
    },
    validationSchema: TimelineViewInputSchema,
    onSubmit: (params) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSubmit(params);
    },
  });

  const handleImageClick = () => {
    setFieldValue("file", undefined);
    setShowDropzone(!showDropzone);
  };

  const uploadFile = (file: File) => {
    setFieldValue("file", file);
  };

  const onSubmit = async (params: CreateTimeLineItemParams) => {
    setErrorMessage("");
    try {
      const { file: uploadFile, ...rest } = params;
      if (uploadFile && !isReply) {
        await uploadMedia({
          uploadFile,
          timelineId: rest?.timelineId,
        }).unwrap();
      } else {
        await createTimelineItem(rest).unwrap();
      }
      setSuccessMessage(t("timeline.item-success-added"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setOpen(true);
      onAddItem();
      setShowDropzone(false);
      resetForm();
    }
  };

  if (!currentUserName || !currentUserId)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  const isButtonDisabled =
    isLoading || isFileUploading || (!values.content && !values.file);

  const isButtonLoading = isLoading || isFileUploading;
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={sharedStyles.containers.timelineCard.admin}>
        <TimelineViewItemIcon timelineItemType="Message" />
      </Box>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          boxShadow: "none",
          padding: 1,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            padding: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          {!isMobileView && (
            <IdenticonAvatar
              seedValue={currentUserId.toString() ?? ""}
              profileImageUrl={currentProfileImageUrl}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              gap: "0.5rem",
            }}
          >
            {!isMobileView && (
              <Typography sx={sharedStyles.body.timeline.cardTitle}>
                {currentUserName} • Coach
              </Typography>
            )}
            {isReply && (
              <TimelineViewReply
                title={replyTo?.title}
                content={replyTo?.content}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
              }}
            >
              {showDropzone && !isReply ? (
                <AdminDropzone
                  onDrop={uploadFile}
                  onImageClick={() => setShowDropzone(false)}
                  onSubmit={handleSubmit}
                  disabled={isButtonDisabled}
                  loading={isButtonLoading}
                />
              ) : (
                <>
                  <InputField
                    type="text"
                    fullWidth
                    value={values.content}
                    onBlur={handleBlur("content")}
                    onChange={handleChange("content")}
                    error={!!errors.content}
                    placeholder={`${t(
                      "timeline.admin-input-placeholder"
                    )}${timelineTitle}...`}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    multiline
                    rows={2}
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                  <Box sx={{ alignSelf: "flex-end" }}>
                    {!isReply && (
                      <IconButton
                        aria-label="add media"
                        size="small"
                        onClick={handleImageClick}
                      >
                        <OutlinedIcon iconName="image" />
                      </IconButton>
                    )}
                    <LoadingButton
                      type="submit"
                      variant="text"
                      onClick={() => handleSubmit()}
                      size="small"
                      loading={isButtonLoading}
                      disabled={isButtonDisabled}
                      sx={{
                        fontSize: "12px",
                      }}
                      endIcon={<Icon>send</Icon>}
                    >
                      Send
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </CardContent>
        {errorMessage && (
          <SnackbarAlert
            isOpen={open}
            onClose={handleClose}
            severity="error"
            message={errorMessage}
          />
        )}
        {successMessage && (
          <SnackbarAlert
            isOpen={open}
            onClose={handleClose}
            severity="success"
            message={successMessage}
          />
        )}
      </Card>
    </Box>
  );
};

export { AdminTimelineViewInput };
