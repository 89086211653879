export const mockFeedback: UserTestimonial[] = [
  {
    title:
      "Time is a premium when running a business and FitFocus has freed up more time and alleviated more stress than any other platform I’ve tried to implement. If you’re a personal trainer looking to optimize your business, FitFocus is a must-have!",
    rating: 5,
    name: "Nick Hogan",
    job: "STEALTH Conditioning & Olympus Gym",
  },
  {
    title:
      "FitFocus allows me to focus on what I do best, training my clients. The platform is easy to use and has everything I need to run my business. I highly recommend FitFocus to any personal trainer looking to grow their business.",
    rating: 5,
    name: "William Le",
    job: "Prime Performance & Evergrow Fitness Consulting",
  },
];

export type UserTestimonial = {
  title: string;
  rating: number;
  name: string;
  job?: string;
};

export function getRandomTestimonial(): UserTestimonial {
  const randomIndex = Math.floor(Math.random() * mockFeedback.length);
  return mockFeedback[randomIndex];
}
