import { FC } from "react";
import { Box } from "@mui/system";
import { Modal, Typography, Icon } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";

interface SaveNewVersionModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  onSubmit: () => void;
  isTemplate: boolean;
}

const SaveNewVersionModal: FC<SaveNewVersionModalProps> = ({
  isOpenModal,
  handleCloseModal,
  onSubmit,
  isTemplate = false,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Save new version
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mb: 2,
              mt: 2,
            }}
          >
            {isTemplate
              ? t("workout-program-builder.save-new-template-text")
              : t("workout-program-builder.save-new-version-text")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "10px",
              gap: "10px",
              mt: 8,
            }}
          >
            <LoadingButton
              onClick={onSubmit}
              variant="contained"
              color="primary"
              sx={{
                width: "45%",
                height: "42px",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Inter",
                textTransform: "none",
              }}
            >
              Save
            </LoadingButton>
            <LoadingButton
              onClick={handleCloseModal}
              variant="outlined"
              color="primary"
              sx={{
                width: "45%",
                height: "42px",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Inter",
                color: `${Colors.blue[1200]}`,
                textTransform: "none",
              }}
            >
              Cancel
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { SaveNewVersionModal };
