import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  Drawer,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CombinedData,
  WorkoutLoadStatus,
  useChangeWorkoutItemLogMutation,
  useGetWorkoutItemLogQuery,
  useGetUserProfileItemQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { userSelectors } from "shared/stores/user";
import { useTypedSelector } from "shared/stores";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { TrainingInsightsLineChartContainerForAverages } from "pages/TrainingInsights/TrainingInsightsLineChartContainerForAverages";
import { SwapExerciseModal } from "./SwapExerciseModal";
import { WorkoutExerciseVideo } from "./WorkoutExerciseVideo";
import { WorkoutPrescribedData } from "./WorkoutPrescribedData";
import { WorkoutPreviousWeekData } from "./WorkoutPreviousWeekData";
import { DefaultCardioInputScreen } from "./inputScreens/DefaultCardioInputScreen";
import { DefaultInputScreen } from "./inputScreens/DefaultInputScreen";
import { DefaultInputScreenWithPartials } from "./inputScreens/DefaultInputScreenWithPartials";
import { UnilateralInputScreen } from "./inputScreens/UnilateralInputScreen";
import { UnilateralInputScreenWithPartials } from "./inputScreens/UnilateralInputScreenWithPartials";
import { WorkoutTrendIcon } from "./WorkoutTrendIcon";

interface ChangeWorkoutDetailsModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  exercise: CombinedData;
  exerciseName: string;
  selectedUserId: number;
  setExerciseName: (value: string) => void;
}
interface SubmitValues {
  id: number;
  completedReps: string;
  completedPartialReps: string;
  completedIntensity: string;
  completedWeight: string;
  completedWeightRightSide: string;
  completedWeightLeftSide: string;
  completedRepsRightSide: string;
  completedRepsLeftSide: string;
  completedPartialRepsRightSide: string;
  completedPartialRepsLeftSide: string;
  completedIntensityRightSide: string;
  completedIntensityLeftSide: string;
  completedDistance: string;
  completedTime: string;
  completedSpeed: string;
  actualRestTime: string;
  setOrder: number | null;
  notes: string;
  workoutItemId: number;
}

const ChangeWorkoutDetailsModal: FC<ChangeWorkoutDetailsModalProps> = ({
  isOpenModal,
  closeModal,
  exercise,
  exerciseName,
  setExerciseName,
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const [changeWorkoutItemLog, { isLoading }] =
    useChangeWorkoutItemLogMutation();

  const [swapExerciseModal, setSwapExerciseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  const [isResponseModalOpen, setResponseModal] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setResponseModal(false);
  };

  const [selectedWorkoutItemId, setSelectedWorkoutItemId] = useState<number>(
    exercise.workoutItemSchemeBreakdowns.find((a) => true)?.workoutItemId ?? 0
  );

  const [selectedWorkoutItemLogId, setSelectedWorkoutItemLogId] =
    useState<number>(
      exercise.workoutItemSchemeBreakdowns.find((a) => true)?.workoutItemLogs[0]
        .id ?? 0
    );

  const { data: workoutItemLog } = useGetWorkoutItemLogQuery(
    selectedWorkoutItemLogId
  );

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(selectedUserId));

  const [partialRepsEnabled, setPartialRepsEnabled] = useState(false);

  const [unilateralEnabled, setUnilateralEnabled] = useState(false);

  const selectedWorkoutItem = exercise.workoutItemSchemeBreakdowns?.find(
    (item) => item.workoutItemId === selectedWorkoutItemId
  );
  const selectedWorkoutItemLogsLength =
    selectedWorkoutItem?.workoutItemLogs?.length ?? 0;

  const handleSelectedWorkoutItemIdChange = (
    newWorkoutItemId: number,
    id: number
  ) => {
    setSuccessMessage("");
    setErrorMessage("");
    setSelectedWorkoutItemId(newWorkoutItemId);
    setSelectedWorkoutItemLogId(id);
  };

  const isExerciseCompleted = (setOrder: number | null) =>
    exercise.workoutItemLogs?.find(
      (log) =>
        log.setOrder === setOrder &&
        (log.completedReps ||
          log.completedPartialReps ||
          log.completedIntensity ||
          log.completedWeight ||
          log.completedRepsLeftSide ||
          log.completedRepsRightSide ||
          log.completedPartialRepsLeftSide ||
          log.completedPartialRepsRightSide ||
          log.completedIntensityLeftSide ||
          log.completedIntensityRightSide ||
          log.completedWeightLeftSide ||
          log.completedWeightRightSide ||
          log.completedDistance ||
          log.completedTime ||
          log.completedSpeed)
    );

  useEffect(() => {
    const isUnilateralExercise =
      workoutItemLog?.completedIntensityLeftSide ||
      workoutItemLog?.completedIntensityRightSide ||
      workoutItemLog?.completedPartialRepsLeftSide ||
      workoutItemLog?.completedPartialRepsRightSide ||
      workoutItemLog?.completedRepsLeftSide ||
      workoutItemLog?.completedRepsRightSide ||
      workoutItemLog?.completedWeightLeftSide ||
      workoutItemLog?.completedWeightRightSide;

    const isPartialRepsExercise =
      workoutItemLog?.completedPartialReps ||
      workoutItemLog?.completedPartialRepsLeftSide ||
      workoutItemLog?.completedPartialRepsRightSide;

    if (isUnilateralExercise) {
      setUnilateralEnabled(true);
    }
    if (isPartialRepsExercise) {
      setPartialRepsEnabled(true);
    }
  }, [workoutItemLog]);

  const onApplyToAllSetsSubmit = async ({
    completedIntensity,
    completedWeight,
    completedReps,
    completedPartialReps,
    completedIntensityLeftSide,
    completedIntensityRightSide,
    completedPartialRepsLeftSide,
    completedPartialRepsRightSide,
    completedRepsLeftSide,
    completedRepsRightSide,
    completedWeightLeftSide,
    completedWeightRightSide,
    actualRestTime,
    completedDistance,
    completedTime,
    completedSpeed,
    notes,
  }: SubmitValues) => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const workoutItemSchema = exercise.workoutItemSchemeBreakdowns?.find(
        (item) =>
          item.workoutItemLogs.find(
            (log) => log.id === selectedWorkoutItemLogId
          )
      );
      workoutItemSchema?.workoutItemLogs.forEach(async (log) => {
        await changeWorkoutItemLog({
          completedReps: completedReps.toString(),
          completedPartialReps: completedPartialReps.toString(),
          completedIntensity: completedIntensity.toString(),
          completedWeight: completedWeight.toString(),
          completedWeightRightSide: completedWeightRightSide.toString(),
          completedWeightLeftSide: completedWeightLeftSide.toString(),
          completedRepsRightSide: completedRepsRightSide.toString(),
          completedRepsLeftSide: completedRepsLeftSide.toString(),
          completedPartialRepsRightSide:
            completedPartialRepsRightSide.toString(),
          completedPartialRepsLeftSide: completedPartialRepsLeftSide.toString(),
          completedIntensityRightSide: completedIntensityRightSide.toString(),
          completedIntensityLeftSide: completedIntensityLeftSide.toString(),
          actualRestTime: actualRestTime.toString(),
          completedDistance: completedDistance.toString(),
          completedTime: completedTime.toString(),
          completedSpeed: completedSpeed.toString(),
          notes,
          id: log.id,
        }).unwrap();
      });
      setSuccessMessage(t("item-modal.finish-title"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setResponseModal(true);
      setPartialRepsEnabled(false);
      setUnilateralEnabled(false);
    }
  };

  const onSubmit = async ({
    completedIntensity,
    completedWeight,
    completedReps,
    completedPartialReps,
    completedIntensityLeftSide,
    completedIntensityRightSide,
    completedPartialRepsLeftSide,
    completedPartialRepsRightSide,
    completedRepsLeftSide,
    completedRepsRightSide,
    completedWeightLeftSide,
    completedWeightRightSide,
    actualRestTime,
    completedDistance,
    completedTime,
    completedSpeed,
    notes,
  }: SubmitValues) => {
    setErrorMessage("");
    setSuccessMessage("");
    try {
      await changeWorkoutItemLog({
        completedReps: completedReps.toString(),
        completedPartialReps: completedPartialReps.toString(),
        completedIntensity: completedIntensity.toString(),
        completedWeight: completedWeight.toString(),
        completedWeightRightSide: completedWeightRightSide.toString(),
        completedWeightLeftSide: completedWeightLeftSide.toString(),
        completedRepsRightSide: completedRepsRightSide.toString(),
        completedRepsLeftSide: completedRepsLeftSide.toString(),
        completedPartialRepsRightSide: completedPartialRepsRightSide.toString(),
        completedPartialRepsLeftSide: completedPartialRepsLeftSide.toString(),
        completedIntensityRightSide: completedIntensityRightSide.toString(),
        completedIntensityLeftSide: completedIntensityLeftSide.toString(),
        actualRestTime: actualRestTime.toString(),
        completedDistance: completedDistance.toString(),
        completedTime: completedTime.toString(),
        completedSpeed: completedSpeed.toString(),
        notes,
        id: selectedWorkoutItemLogId,
      }).unwrap();
      setSuccessMessage(t("item-modal.finish-title"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setResponseModal(true);
      setPartialRepsEnabled(false);
      setUnilateralEnabled(false);
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        id: workoutItemLog?.id || 0,
        completedReps: workoutItemLog?.completedReps || "",
        completedPartialReps: workoutItemLog?.completedPartialReps || "",
        completedIntensity: workoutItemLog?.completedIntensity || "",
        completedWeight: workoutItemLog?.completedWeight || "",
        completedWeightRightSide:
          workoutItemLog?.completedWeightRightSide || "",
        completedWeightLeftSide: workoutItemLog?.completedWeightLeftSide || "",
        completedRepsRightSide: workoutItemLog?.completedRepsRightSide || "",
        completedRepsLeftSide: workoutItemLog?.completedRepsLeftSide || "",
        completedPartialRepsRightSide:
          workoutItemLog?.completedPartialRepsRightSide || "",
        completedPartialRepsLeftSide:
          workoutItemLog?.completedPartialRepsLeftSide || "",
        completedIntensityRightSide:
          workoutItemLog?.completedIntensityRightSide || "",
        completedIntensityLeftSide:
          workoutItemLog?.completedIntensityLeftSide || "",
        actualRestTime: workoutItemLog?.actualRestTime || "",
        setOrder: workoutItemLog?.setOrder || null,
        workoutItemId: workoutItemLog?.workoutItemId || 0,
        completedDistance: workoutItemLog?.completedDistance || "",
        completedTime: workoutItemLog?.completedTime || "",
        completedSpeed: workoutItemLog?.completedSpeed || "",
        notes: workoutItemLog?.notes || "",
      },
      onSubmit: async (params) => {
        await onSubmit(params);
      },
    });

  useEffect(() => {
    if (workoutItemLog) {
      resetForm({
        values: {
          id: workoutItemLog?.id || 0,
          completedReps: workoutItemLog?.completedReps || "",
          completedPartialReps: workoutItemLog?.completedPartialReps || "",
          completedIntensity: workoutItemLog?.completedIntensity || "",
          completedWeight: workoutItemLog?.completedWeight || "",
          completedWeightRightSide:
            workoutItemLog?.completedWeightRightSide || "",
          completedWeightLeftSide:
            workoutItemLog?.completedWeightLeftSide || "",
          completedRepsRightSide: workoutItemLog?.completedRepsRightSide || "",
          completedRepsLeftSide: workoutItemLog?.completedRepsLeftSide || "",
          completedPartialRepsRightSide:
            workoutItemLog?.completedPartialRepsRightSide || "",
          completedPartialRepsLeftSide:
            workoutItemLog?.completedPartialRepsLeftSide || "",
          completedIntensityRightSide:
            workoutItemLog?.completedIntensityRightSide || "",
          completedIntensityLeftSide:
            workoutItemLog?.completedIntensityLeftSide || "",
          actualRestTime: workoutItemLog?.actualRestTime || "",
          setOrder: workoutItemLog?.setOrder || null,
          workoutItemId: workoutItemLog?.workoutItemId || 0,
          completedDistance: workoutItemLog?.completedDistance || "",
          completedTime: workoutItemLog?.completedTime || "",
          completedSpeed: workoutItemLog?.completedSpeed || "",
          notes: workoutItemLog?.notes || "",
        },
      });
    }
  }, [workoutItemLog, resetForm]);

  const displayTrend =
    isAdminOrCoach &&
    exercise.workoutLoadStatus !== WorkoutLoadStatus.Undefined;

  const displayTrendTable = useMediaQuery("(min-width:700px)");

  const [showTrendGraph, setShowTrendGraph] = useState(false);

  const handleClickOpenTrend = () => {
    setShowTrendGraph(true);
  };

  const handleCloseTrend = () => {
    setShowTrendGraph(false);
  };

  return (
    <Drawer
      open={isOpenModal}
      variant="persistent"
      anchor="bottom"
      sx={{
        borderRadius: "16px 16px 0px 0px",
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "48px 24px 32px",
          gap: "24px",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            gap: "4px",
            width: "100%",
            flex: "none",
            order: 1,
            flexGrow: 0,
            marginTop: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.oxford[1100]}`,
              }}
            >
              {exerciseName}
              {displayTrend ? (
                <WorkoutTrendIcon finalTrend={exercise.workoutLoadStatus} />
              ) : null}
            </Typography>
            <Box>
              <LoadingButton
                variant="outlined"
                color="info"
                sx={{
                  height: "36px",
                  mt: "5px",
                  textAlign: "right",
                }}
                fullWidth
                type="button"
                size="small"
                onClick={() => handleClickOpenTrend()}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    ml: "3px",
                  }}
                >
                  View History
                </Typography>
              </LoadingButton>
              {currentUserData?.swapExercisesEnabled ? (
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  sx={{
                    height: "36px",
                    mt: "5px",
                    textAlign: "right",
                  }}
                  fullWidth
                  type="button"
                  size="small"
                  onClick={() => setSwapExerciseModal(!swapExerciseModal)}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  <Icon
                    sx={{
                      color: `${Colors.blue[1200]}`,
                      fontSize: "14px",
                    }}
                  >
                    sync_alt_outlined
                  </Icon>
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontSize: "14px",
                      color: `${Colors.blue[1200]}`,
                      ml: "3px",
                    }}
                  >
                    Swap
                  </Typography>
                </LoadingButton>
              ) : null}
            </Box>
          </Box>
          {exercise.notes && (
            <Box
              sx={{
                backgroundColor: `${Colors.gray[300]}`,
                padding: 1.5,
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.oxford[1100]}`,
                  opacity: "0.87",
                  textDecoration: "none",
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                <Icon
                  className="material-icons-outlined"
                  sx={{
                    fontSize: "16px !important",
                    mr: "4px",
                    ml: "4px",
                    color: `${Colors.oxford[1100]}`,
                    opacity: "0.87",
                  }}
                >
                  sms
                </Icon>
                {t("session-card.trainers-note")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.oxford[1100]}`,
                }}
              >
                {exercise.notes}
              </Typography>
            </Box>
          )}
          <SwapExerciseModal
            swapExerciseModal={swapExerciseModal}
            setSwapExerciseModal={setSwapExerciseModal}
            exercise={exercise}
            workoutItemId={selectedWorkoutItemId}
            exerciseName={exerciseName}
            setExerciseName={setExerciseName}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px",
              gap: "0px",
              flex: "none",
              mt: "16px",
              order: 1,
              flexGrow: 0,
            }}
          >
            {exercise.videoUrl && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "flex-start",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
              >
                <WorkoutExerciseVideo videoUrl={exercise.videoUrl} />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "flex-start",
                width: "100%",
                flex: "none",
                order: 1,
                flexGrow: 0,
              }}
            >
              <WorkoutPrescribedData
                selectedWorkoutItemId={selectedWorkoutItemId}
                workoutSchemas={exercise.workoutItemSchemeBreakdowns}
                videoUrl={exercise.videoUrl}
              />
            </Box>
          </Box>
          {(exercise.previousWeeksData?.length > 0 ||
            exercise?.previousWeeksNotes?.length > 0) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "flex-start",
                width: "100%",
                flex: "none",
                order: 1,
                flexGrow: 0,
              }}
            >
              <WorkoutPreviousWeekData
                previousWeeksData={exercise.previousWeeksData}
                previousWeeksNotes={exercise?.previousWeeksNotes ?? []}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            width: "100%",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <ToggleButtonGroup
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "0px",
              width: "auto",
              height: "40px",
              background: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
              flexWrap: "wrap",
              order: 0,
              flexGrow: 0,
            }}
          >
            {exercise.workoutItemLogs?.map((item) => (
              <ToggleButton
                key={item.id}
                value={item.id}
                selected={item.id === selectedWorkoutItemLogId}
                onClick={() =>
                  handleSelectedWorkoutItemIdChange(item.workoutItemId, item.id)
                }
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                  height: "40px",
                  boxShadow: "inset -1px 0px 0px rgba(0, 0, 0, 0.12)",
                  flex: "none",
                  order: 2,
                  flexGrow: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: `${
                      item.id === selectedWorkoutItemLogId ? "500" : "400"
                    }`,
                    fontSize: "16px",
                    lineHeight: "140%",
                    color: `${Colors.gray[1100]}`,
                    textTransform: "none",
                  }}
                >
                  Set {item.setOrder}
                </Typography>
                {isExerciseCompleted(item.setOrder) && (
                  <Icon
                    sx={{
                      color: `${Colors.blue[1200]}`,
                      fontSize: "16px !important",
                    }}
                  >
                    check_circle_outline
                  </Icon>
                )}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {exercise.exerciseType !== "Cardio" ? (
            <FormGroup
              row
              sx={{
                mt: exercise.workoutItemLogs.length > 5 ? 8 : 3,
                mb: 1,
                ml: 1,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    onChange={() => setUnilateralEnabled(!unilateralEnabled)}
                    checked={unilateralEnabled}
                  />
                }
                label={`${t("item-modal.unilateral-title")}`}
                sx={{
                  mt: 0,
                  "& span": {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: `${Colors.oxford[1100]}`,
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    onChange={() => setPartialRepsEnabled(!partialRepsEnabled)}
                    checked={partialRepsEnabled}
                  />
                }
                label={`${t("item-modal.partial-reps-title")}`}
                sx={{
                  mt: 0,
                  "& span": {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: `${Colors.oxford[1100]}`,
                  },
                }}
              />
            </FormGroup>
          ) : null}
        </Box>

        {exercise.exerciseType === "Cardio" && (
          <DefaultCardioInputScreen
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        )}
        {exercise.exerciseType !== "Cardio" &&
          !unilateralEnabled &&
          !partialRepsEnabled && (
            <DefaultInputScreen
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exercise.exerciseType !== "Cardio" &&
          partialRepsEnabled &&
          !unilateralEnabled && (
            <DefaultInputScreenWithPartials
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exercise.exerciseType !== "Cardio" &&
          unilateralEnabled &&
          !partialRepsEnabled && (
            <UnilateralInputScreen
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        {exercise.exerciseType !== "Cardio" &&
          unilateralEnabled &&
          partialRepsEnabled && (
            <UnilateralInputScreenWithPartials
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              exercise={exercise}
            />
          )}
        <Dialog
          open={showTrendGraph}
          onClose={handleCloseTrend}
          fullWidth
          maxWidth="lg"
          sx={{
            maxHeight: "90vh",
            paddingTop: 10,
          }}
        >
          <TrainingInsightsLineChartContainerForAverages
            exercise={{
              exerciseId: exercise.exerciseId,
              exerciseName,
            }}
            userId={selectedUserId!!}
            isAthleteView={!displayTrendTable}
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "7.5px",
              right: 25,
              width: "12px",
              height: "12px",
              cursor: "pointer",
            }}
            onClick={handleCloseTrend}
          >
            <Typography
              sx={{
                textTransform: "none",
                fontSize: "14px",
                ml: "3px",
              }}
            >
              Close
            </Typography>
            <Icon fontSize="medium" sx={{ color: `${Colors.gray[1200]}` }}>
              close
            </Icon>
          </IconButton>
        </Dialog>
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
            padding: "0px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          {selectedWorkoutItemLogsLength > 1 && (
            <LoadingButton
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                height: "42px",
              }}
              type="submit"
              size="large"
              onClick={() => onApplyToAllSetsSubmit(values)}
              loading={isLoading}
              disabled={isLoading}
            >
              <Typography
                sx={{
                  textTransform: "none",
                }}
              >
                {" "}
                {t("item-modal.apply-to-all-sets-btn")}
              </Typography>
            </LoadingButton>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              height: "42px",
              mt: "12px",
            }}
            type="submit"
            size="large"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {" "}
              {t("item-modal.save-btn")}
            </Typography>
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="error"
            sx={{
              width: "100%",
              height: "42px",
              mt: "12px",
              mb: 2,
            }}
            type="submit"
            size="large"
            onClick={closeModal}
            loading={isLoading}
            disabled={isLoading}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {" "}
              {t("item-modal.cancel-btn")}
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
      {errorMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="error"
          message={errorMessage}
        />
      )}
      {successMessage && (
        <SnackbarAlert
          isOpen={isResponseModalOpen}
          onClose={handleClose}
          severity="success"
          message={successMessage}
        />
      )}
    </Drawer>
  );
};

export { ChangeWorkoutDetailsModal };
