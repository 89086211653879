import React from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import { Colors } from "../../../../shared/themes";

const WorkoutPageSkeleton = () => {
  return (
    <Box
      sx={{
        padding: "9px 24px 23px 24px",
      }}
    >
      <Skeleton
        sx={{ marginTop: "10px", height: "34px" }}
        variant="rectangular"
        animation="wave"
      />
      <Skeleton
        sx={{ marginTop: "10px", height: "25px", mb: "17px" }}
        variant="rectangular"
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="184px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
          marginBottom: "55px",
          marginTop: "16px",
        }}
      />
      <Skeleton
        sx={{ marginTop: "10px", height: "25px", mb: "17px" }}
        variant="rectangular"
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="184px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
          marginBottom: "16px",
          marginTop: "16px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="184px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
          marginBottom: "55px",
          marginTop: "16px",
        }}
      />
    </Box>
  );
};

export { WorkoutPageSkeleton };
