import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { Organisation, OrganisationParams } from "./models";

export const organisationsApi = createApi({
  baseQuery,
  reducerPath: "organisationsApi",
  tagTypes: ["Organisations"],
  endpoints: (builder) => ({
    getOrganisations: builder.query<Organisation[], OrganisationParams>({
      query: (params) => ({
        url: `/organisations`,
        method: "GET",
        params,
      }),
      providesTags: ["Organisations"],
    }),
    getOrganisationById: builder.query<Organisation, number>({
      query: (id) => ({
        url: `/organisations/${id}`,
        method: "GET",
      }),
      providesTags: ["Organisations"],
    }),
    updateOrganisation: builder.mutation<Organisation, OrganisationParams>({
      query: ({ id, name }) => ({
        url: `/organisations/${id}`,
        method: "PUT",
        body: {
          name,
        },
        providesTags: ["Organisations"],
      }),
    }),
  }),
});

export const {
  useGetOrganisationsQuery,
  useGetOrganisationByIdQuery,
  useUpdateOrganisationMutation,
} = organisationsApi;
