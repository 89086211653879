import { Box } from "@mui/system";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { CompletedWorkouts } from "./ui/CompletedWorkouts";

interface AthleteProgramWorkoutsProps {
  closeSidebar: () => void;
}

const AthleteProgramWorkouts: FC<AthleteProgramWorkoutsProps> = ({
  closeSidebar,
}) => {
  const params = useParams();
  const workoutProgramId = Number(params.id);

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "90vh",
      }}
    >
      <CompletedWorkouts
        existingWorkoutProgramId={workoutProgramId}
        closeSidebar={closeSidebar}
      />
    </Box>
  );
};

export { AthleteProgramWorkouts };
