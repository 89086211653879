import { useGetTimelinesQuery } from "shared/api";

export const useTimeline = () => {
  const { data: timelineData, isLoading, refetch } = useGetTimelinesQuery({});
  const timeline = timelineData?.[0];

  const showTimelineUnreadCountBadge =
    !isLoading && (timeline?.unreadCount ?? 0) > 0;

  return {
    data: timeline,
    isVisible: showTimelineUnreadCountBadge,
    refetch,
  };
};
