import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useGetUsersQuery,
  useGetUsersCountQuery,
  useGetAllUsersQuery,
} from "shared/api";
import { createUsersManagementRows } from "shared/helpers";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { sharedStyles } from "shared/themes/shared/styles";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { UserCategoryRadioGroup } from "./UserCategoryRadioGroup";

type UserRow = {
  id: number;
  userFullName: string;
  email: string;
  status: string;
  userCategory: string;
};

const UsersContainer: FC = () => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [userCategoryRadio, setUserCategoryRadio] = useState<string>("");

  const { data: usersArrayLength } = useGetUsersCountQuery({
    countOnly: true,
    userRole: "athlete",
    userCategory: userCategoryRadio,
    includeInactive: true,
  });

  const { data: users, isLoading } = useGetUsersQuery({
    count: pageSize,
    page,
    userRole: "athlete",
    userCategory: userCategoryRadio,
    orderByName: true,
    includeInactive: true,
  });
  const [rowCountState, setRowCountState] = useState(usersArrayLength || 0);
  const { data: allUsersData, isLoading: isLoadingAllItems } =
    useGetAllUsersQuery({
      userRole: "athlete",
      userCategory: userCategoryRadio,
    });
  const [searchValue, setSearchValue] = useState<string>("");
  const [rows, setRows] = useState<UserRow[]>([]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (!users) return;
    if (searchValue) {
      const filteredData = allUsersData?.filter((item) => {
        return (
          item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.email.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      const rowsData = createUsersManagementRows(filteredData!);
      setRows(rowsData!);
      setPage(1);
      setPageSize(100);
      setRowCountState(rowsData?.length!);
    } else {
      const rowsData = createUsersManagementRows(users!);
      setRows(rowsData);
      setRowCountState((prevRowCountState) =>
        usersArrayLength !== undefined ? usersArrayLength : prevRowCountState
      );
    }
  }, [searchValue, users]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      usersArrayLength !== undefined ? usersArrayLength : prevRowCountState
    );
  }, [usersArrayLength, setRowCountState]);

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      flex: 0.25,
      sortable: false,
      renderCell: (params) => {
        return (
          <IdenticonAvatar
            seedValue={params.row?.id}
            profileImageUrl={params.row?.profileImageUrl}
          />
        );
      },
      filterable: false,
    },
    {
      field: "userFullName",
      headerName: t("users-management.user-name"),
      flex: 1,
      sortable: true,
    },
    {
      field: "email",
      headerName: t("users-management.email"),
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: t("users-management.status"),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return <GridStatusItem status={params.value} />;
      },
    },
    {
      field: "userCategory",
      headerName: t("users-management.user-category"),
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box sx={sharedStyles.containers.dataGrid.tagNotStarted}>
            <Box sx={sharedStyles.tags.tagNotStarted} />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <Link to={`${Routes.profile.url}/${id}`}>
            <Button
              color="primary"
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              {t("users-management.edit-btn")}
            </Button>
          </Link>
        );
      },
      filterable: false,
    },
  ];

  const matches = useMediaQuery("(min-width: 1280px)");

  return (
    <Box sx={sharedStyles.containers.dataGrid.wrapper}>
      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
            {matches && (
              <Typography sx={sharedStyles.headings.dataGrid}>
                Clients
              </Typography>
            )}
            <Box>
              <UserCategoryRadioGroup
                value={userCategoryRadio}
                setValue={(e: string) => setUserCategoryRadio(e)}
              />
              <TextField
                label="Search by user..."
                variant="standard"
                value={searchValue}
                onChange={handleOnChange}
                disabled={isLoadingAllItems}
              />
            </Box>
          </Box>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows || []}
            rowCount={rowCountState}
            paginationMode="server"
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { UsersContainer };
