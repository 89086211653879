import { FC } from "react";
import { Box } from "@mui/system";
import { Icon, Skeleton, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../shared/themes";
import { DashboardResponse } from "../../../../shared/api/dashboard/models";

interface AdminDashboardAnalyticsProps {
  dashboardResponse: DashboardResponse | undefined;
  isLoading: boolean;
}

const AdminDashboardAnalytics: FC<AdminDashboardAnalyticsProps> = ({
  dashboardResponse,
  isLoading,
}) => {
  const { t } = useTranslation();
  const workoutsCompletedInLast14Days =
    dashboardResponse?.workoutsCompletedInLast14Days ?? [];
  const chartData = {
    series: [
      {
        name: "Sessions completed",
        data: workoutsCompletedInLast14Days.map((wc) => wc.count),
      },
    ],
    xaxis: {
      categories: workoutsCompletedInLast14Days.map(
        (wc) => wc.dateTimeCompleted
      ),
    },
  };

  const workoutsCompletedInLast14DaysCount =
    workoutsCompletedInLast14Days.length > 0
      ? workoutsCompletedInLast14Days.reduce((acc, curr) => acc + curr.count, 0)
      : 0;

  const positiveAverageRating = dashboardResponse?.workoutProgramNoteRatings
    ? dashboardResponse!.workoutProgramNoteRatings.highCount +
      dashboardResponse!.workoutProgramNoteRatings.veryHighCount
    : 0;

  const neutralAverageRating = dashboardResponse?.workoutProgramNoteRatings
    ? dashboardResponse!.workoutProgramNoteRatings.neutralCount
    : 0;

  const negativeAverageRating = dashboardResponse?.workoutProgramNoteRatings
    ? dashboardResponse!.workoutProgramNoteRatings.lowCount +
      dashboardResponse!.workoutProgramNoteRatings.veryLowCount
    : 0;

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );
  }

  if (workoutsCompletedInLast14Days.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "340px",
          bgcolor: `${Colors.gray[100]}`,
          mb: "16px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "30px",
            display: "flex",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                width: "auto",
                height: "22px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
                mb: "16px",
              }}
            >
              {t("admin-dashboard-analytics.title")}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16px",
            color: `${Colors.blue[1300]}`,
            mb: "14px",
          }}
        >
          {t("admin-dashboard-analytics.no-data")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "460px",
        bgcolor: `${Colors.gray[100]}`,
        mb: "16px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "30px",
          display: "flex",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              width: "auto",
              height: "22px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mb: "16px",
            }}
          >
            {t("admin-dashboard-analytics.title")}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "16px",
          color: `${Colors.blue[1300]}`,
          mb: "20px",
        }}
      >
        {workoutsCompletedInLast14DaysCount}{" "}
        {t("admin-dashboard-analytics.sub-title")}
      </Typography>
      <Box
        sx={{
          height: "45px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            width: "60px",
            height: "45px",
            mr: "24px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              height: "17px",
              mr: "24px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              textAlign: "center",
              textTransform: "uppercase",
              mb: "4px",
            }}
          >
            {t("client-base-health.positive-star")}
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                color: `${Colors.green[900]}`,
                mr: "9.5px",
              }}
            >
              star_icon
            </Icon>
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              {positiveAverageRating}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "60px",
            height: "45px",
            mr: "24px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              height: "17px",
              mr: "24px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              textAlign: "center",
              textTransform: "uppercase",
              mb: "4px",
            }}
          >
            {t("client-base-health.neutral-star")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                color: `${Colors.yellow[200]}`,
                mr: "9.5px",
              }}
            >
              star_icon
            </Icon>
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              {neutralAverageRating}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "60px",
            height: "45px",
            mr: "24px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              height: "17px",
              mr: "24px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              textAlign: "center",
              textTransform: "uppercase",
              mb: "4px",
            }}
          >
            {t("client-base-health.negative-star")}
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                color: `${Colors.red[200]}`,
                mr: "9.5px",
              }}
            >
              star_icon
            </Icon>
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
              }}
            >
              {negativeAverageRating}
            </Box>
          </Box>
        </Box>
      </Box>
      <Chart
        options={{
          chart: {
            id: "basic-bar",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 7,
              borderRadiusApplication: "end",
              columnWidth: "70%",
            },
          },
          grid: {
            show: true,
            borderColor: "#E5E5EF",
            strokeDashArray: 7,
          },
          fill: {
            colors: ["#4245FF", "#2F32FF", "#7A7DFF"],
          },
          xaxis: {
            ...chartData.xaxis,
            tickAmount: 4,
            labels: {
              show: true,
              style: {
                fontSize: "12px",
                fontFamily: "Inter",
                fontWeight: 400,
                cssClass: "apexcharts-xaxis-label",
              },
            },
          },
        }}
        series={chartData.series}
        type="bar"
        width="100%"
        height="300"
      />
    </Box>
  );
};

export { AdminDashboardAnalytics };
