import { Button, Icon, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetUserProgramsQuery,
  useGetUsersByIdQuery,
  useGetUserProgramsPaginationQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { createCurrentUserRows } from "shared/helpers";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Routes } from "shared/routers";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { currentUserColumns } from "./constants/constants";
import { ProgramsSkeleton } from "./ui/ProgramsSkeleton";

interface ViewProgramsProps {
  openSidebar: () => void;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const ViewPrograms: FC<ViewProgramsProps> = ({
  openSidebar,
  getUserIdForWorkoutCreation,
}) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const { t } = useTranslation();
  const params = useParams();
  const {
    data: userData,
    refetch,
    isFetching,
  } = useGetUserProgramsQuery(Number(params.id));

  useEffect(() => {
    refetch();
  }, []);

  const { data: usersById, isLoading } = useGetUsersByIdQuery(
    params.id as string
  );
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: pagination } = useGetUserProgramsPaginationQuery({
    userId: Number(params.id),
    count: pageSize,
    page,
  });

  const rows = createCurrentUserRows(userData!);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "58px",
          mb: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "auto",
          }}
        >
          <IdenticonAvatar seedValue={params.id} />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              color: `${Colors.gray[900]}`,
              textTransform: "none",
            }}
          >
            {usersById?.map((item) => {
              getUserIdForWorkoutCreation(item.id);
              return `${item.firstName} ${item.lastName}`;
            })}
          </Typography>
        </Box>
        <Link to={`${Routes.workoutProgramBuilder.url}`}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              color: `${Colors.gray[100]}`,
              textTransform: "none",
            }}
          >
            <Icon
              sx={{
                marginRight: "8px",
              }}
            >
              add_icon
            </Icon>
            {t("programs.create-program")}
          </Button>
        </Link>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "322px",
          bgcolor: `${Colors.gray[100]}`,
          padding: "20px 24px",
          mb: "16px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "12px",
          }}
        >
          <Typography
            sx={{
              width: "auto",
              height: "22px",
              borderBottom: `1px solid ${Colors.gray[400]}`,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "140%,",
              color: `${Colors.blue[1300]}`,
            }}
          >
            Programs assigned
          </Typography>

          <Box
            sx={{
              width: "50%",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              position: "relative",
            }}
          />
        </Box>

        {!isLoading && !isFetching ? (
          <Box sx={{ height: "248px" }}>
            <DataGrid
              headerHeight={56}
              rowHeight={48}
              pageSize={pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              columns={currentUserColumns}
              rows={rows || []}
              disableColumnFilter
              sx={{
                "& .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
                "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                  bgcolor: `${Colors.gray[1700]}`,
                },
                "& .MuiDataGrid-menuIconButton": {
                  display: "none",
                },
              }}
            />
          </Box>
        ) : (
          <ProgramsSkeleton />
        )}
      </Box>
    </Box>
  );
};

export { ViewPrograms };
