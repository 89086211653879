import { FC, useEffect, useRef, useState } from "react";
import "tui-grid/dist/tui-grid.css";
import { FormControlLabel, Switch } from "@mui/material";
import { Colors } from "shared/themes";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { WorkoutSession, useGetExercisesLibraryQuery } from "shared/api";
import {
  resetGridRows,
  setApplyChanges,
} from "shared/stores/workoutBuilder/slice";
import { sleep } from "shared/helpers";
import { WorkoutProgramGrid } from "./WorkoutProgramGrid";
import { GridRowHeader } from "../types";
import { GridSavingModal } from "./GridSavingModal";

export type WorkoutProgramGridLogicProps = {
  templateProgram: boolean;
  existingData: GridRowHeader[];
  numberOfWeeks: number;
  numberOfDays: number;
  isDraftOrNewProgram: boolean;
  currentWorkoutList?: WorkoutSession[];
  showSimplifiedView: boolean;
  setShowSimplifiedView: (value: boolean) => void;
};

const WorkoutProgramGridActions: FC<WorkoutProgramGridLogicProps> = ({
  templateProgram,
  existingData,
  numberOfWeeks,
  numberOfDays,
  isDraftOrNewProgram,
  currentWorkoutList = [],
  showSimplifiedView,
  setShowSimplifiedView,
}) => {
  const buttonRefs = Array.from({ length: numberOfDays }, () =>
    useRef<HTMLButtonElement>(null)
  );
  const { data: exercisesList, isLoading: isLoadingExercises } =
    useGetExercisesLibraryQuery({});
  const applyChanges = useTypedSelector(
    (state) => state.workoutBuilder.applyChanges
  );
  const dispatch = useTypedDispatch();

  const exerciseNamesList = exercisesList?.map((exercise) => exercise.name);
  const [showNotesView, setShowNotesView] = useState(false);
  const [showLogView, setShowLogView] = useState(false);
  const [loadingControls, setLoadingControls] = useState(true);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const statuses = useTypedSelector((state) => state.workoutProgramStatus);
  const isLoadingApis =
    statuses.isLoadingCreateNewVersionOnExistingWorkoutProgram ||
    statuses.isLoadingCreateNewWorkoutProgramDesign ||
    statuses.isLoadingGetWorkoutProgramDesigns ||
    statuses.isLoadingUpdateDraftWorkoutProgramDesign ||
    statuses.isLoadingCreateAiWorkoutProgramDesign;

  const addGridRowsToState = async () => {
    try {
      dispatch(resetGridRows());
      await sleep(200);
      setOpenLoadingModal(true);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numberOfDays; i++) {
        buttonRefs[i]?.current?.click();
        // eslint-disable-next-line no-await-in-loop
        await sleep(2000);
        if (i === numberOfDays - 1) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(1000);
          dispatch(setApplyChanges("completed"));
        }
      }
    } catch {
      dispatch(setApplyChanges("failed"));
    }
  };

  useEffect(() => {
    if (!isLoadingApis) {
      setOpenLoadingModal(false);
    }
  }, [isLoadingApis]);

  useEffect(() => {
    if (applyChanges === "pending") {
      addGridRowsToState();
    }
    if (applyChanges === "failed") {
      setOpenLoadingModal(false);
    }
  }, [applyChanges]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingControls(false);
    }, 3000);
  }, []);

  return (
    <div>
      {!loadingControls && (
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setShowSimplifiedView(!showSimplifiedView)}
              checked={showSimplifiedView}
            />
          }
          label="Simplified View"
          sx={{
            mt: 1,
            "& span": {
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: `${Colors.oxford[1100]}`,
            },
          }}
        />
      )}
      {!loadingControls && (
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setShowNotesView(!showNotesView)}
              checked={showNotesView}
            />
          }
          label="Show Notes"
          sx={{
            mt: 1,
            "& span": {
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: `${Colors.oxford[1100]}`,
            },
          }}
        />
      )}
      {!loadingControls && !isDraftOrNewProgram && !templateProgram && (
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setShowLogView(!showLogView)}
              checked={showLogView}
            />
          }
          label="Show Completed Logs"
          sx={{
            mt: 1,
            "& span": {
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: `${Colors.oxford[1100]}`,
            },
          }}
        />
      )}
      <GridSavingModal
        isOpenModal={openLoadingModal}
        handleCloseModal={() => setOpenLoadingModal(!openLoadingModal)}
        numberOfDays={numberOfDays}
      />
      {!isLoadingExercises &&
        Array.from({ length: numberOfDays }, (_, index) => (
          <WorkoutProgramGrid
            key={index}
            ref={buttonRefs[index]}
            data={
              existingData
                ? existingData[index]
                : { dayNumber: index + 1, rows: [] }
            }
            exerciseNamesList={exerciseNamesList!!}
            currentWorkoutList={currentWorkoutList}
            dayNumber={index + 1}
            numberOfWeeks={numberOfWeeks}
            showSimplifiedView={showSimplifiedView}
            showLogView={showLogView}
            showNotesView={showNotesView}
            isLoadingControls={loadingControls}
          />
        ))}
    </div>
  );
};

export { WorkoutProgramGridActions };
