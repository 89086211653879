import { Routes } from "../../../../routers";

export const navData = [
  {
    id: 1,
    title: "Home",
    content: "home",
    icon: "home",
    link: Routes.dashboard.url,
  },
  {
    id: 2,
    title: "Programs",
    content: "programs",
    icon: "fitness_center",
    link: `${Routes.programs.url}`,
  },
  {
    id: 3,
    title: "Timeline",
    content: "timeline",
    icon: "timeline",
    link: `${Routes.athleteTimeline.url}`,
  },
  {
    id: 4,
    title: "Training Insights",
    content: "training-insights",
    icon: "workspace_premium",
    link: `${Routes.athleteTrainingInsights.url}`,
  },
];
