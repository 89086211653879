import { useTranslation } from "react-i18next";
import { FC } from "react";
import { sharedStyles } from "shared/themes/shared/styles";
import { Box, Typography, Button } from "@mui/material";
import { Colors } from "shared/themes";

interface NutritionMyFitnessPalContainerProps {
  myFitnessPalUsername: string;
}

const NutritionMyFitnessPalContainer: FC<
  NutritionMyFitnessPalContainerProps
> = ({ myFitnessPalUsername }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
        <Typography sx={sharedStyles.headings.dataGrid}>
          {t("nutrition.my-fitness-pal-title")}
        </Typography>
      </Box>
      <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            height: "36px",
          }}
          type="submit"
          size="small"
          onClick={() =>
            window.open(
              `https://www.myfitnesspal.com/food/diary/${myFitnessPalUsername}`,
              "_blank"
            )
          }
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              lineHeight: "24px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              color: `${Colors.blue[1200]}`,
              textTransform: "none",
            }}
          >
            View Diary
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export { NutritionMyFitnessPalContainer };
