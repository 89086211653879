import { TimelineItemLike } from "shared/api";

export const getTimelineLikeStatement = (
  likes: TimelineItemLike[],
  userId: number
) => {
  const otherUsers = likes.filter((like) => like.userId !== userId);

  let timelineLikeStatement = "";

  if (likes.some((like) => like.userId === userId)) {
    timelineLikeStatement = "You like this";
  }

  if (otherUsers.length === 1) {
    timelineLikeStatement = timelineLikeStatement
      ? `${otherUsers[0].firstName} and you like this`
      : `${otherUsers[0].firstName} likes this`;
  } else if (otherUsers.length > 1) {
    timelineLikeStatement = `${otherUsers[0].firstName} and ${otherUsers[1].firstName} like this`;
  }

  return timelineLikeStatement;
};
