import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompletedExerciseHistory,
  useGetCompletedHistoryByExerciseIdQuery,
} from "shared/api";
import { Alert, Box, Typography } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  TrainingInsightsLineChart,
  XAxisType,
  YAxisType,
} from "./TrainingInsightsLineChart";
import { TrainingInsightsTableForWeight } from "./TrainingInsightsTableForWeight";
import { SeriesType } from "./constants";
import { SelectedExercise } from "./TrainingInsightsContent";

interface TrainingInsightsLineChartContainerForWeightProps {
  userId: number;
  exercise: SelectedExercise;
  isAthleteView?: boolean;
}

type CompletedDataItem = {
  maxCompletedWeight: number;
  maxCompletedWeightLeftSide?: number;
  maxCompletedWeightRightSide?: number;
};

const TrainingInsightsLineChartContainerForWeight: FC<
  TrainingInsightsLineChartContainerForWeightProps
> = ({ userId, exercise, isAthleteView = false }) => {
  const { t } = useTranslation();

  const [xAxis, setXAxis] = useState<XAxisType>({ labels: [] });
  const [yAxisPrescribed, setYAxisPrescribed] = useState<YAxisType[]>([]);
  const [yAxisCompleted, setYAxisCompleted] = useState<YAxisType[]>([]);

  const {
    data: completedData,
    isLoading: isLoadingCompleted,
    error,
  } = useGetCompletedHistoryByExerciseIdQuery({
    userId: userId ?? 0,
    exerciseId: exercise.exerciseId ?? 0,
  });

  const handleRIRStrategy = (data: CompletedExerciseHistory[]) => {
    const yAxis: { lower: number; higher: number }[] = data.map(
      (prescribed) => {
        const maxPrescribedWeight = prescribed.maxPrescribedWeight.split("-");
        return {
          lower: Number(maxPrescribedWeight[0]),
          higher: Number(maxPrescribedWeight[1]),
        };
      }
    );

    const yAxisRanges: string[] = ["Lower Range", "Higher Range"];

    const yAxes: YAxisType[] = yAxisRanges.map((range, index) => ({
      values: yAxis.map((y) => (index === 0 ? y.lower : y.higher)),
      name: range,
    }));

    setYAxisPrescribed(yAxes);
  };

  function extractNumericValue(input: string): number {
    const regexPatterns = [
      /(\d+)/, // Matches one or more digits
      /(\d+)\s*([-+])\s*(\d+)(\w*)/, // Matches digits followed by + or - and digits with optional alphabet characters
      /(\d+)\s*@\s*(\d+)/, // Matches digits followed by @ and digits
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const pattern of regexPatterns) {
      const match = input.match(pattern);
      if (match) {
        const firstValue = parseInt(match[1], 10);
        const secondValue = match[3] ? parseInt(match[3], 10) : 0;

        if (secondValue !== 0) {
          return secondValue;
        }
        if (firstValue !== 0) {
          return firstValue;
        }
      }
    }

    return 0; // Return 0 if no matching pattern is found
  }

  useEffect(() => {
    if (completedData) {
      setXAxis({
        labels: completedData.map(
          (a) => `${a.workoutProgram} - Week ${a.weekNumber}`
        ),
      });
      const isRIRStrategy = completedData.some((a) =>
        a.maxPrescribedWeight.includes("-")
      );
      if (isRIRStrategy) {
        handleRIRStrategy(completedData);
      }
      // eslint-disable-next-line no-lonely-if
      if (completedData.some((a) => a.maxPrescribedWeight)) {
        setYAxisPrescribed([
          {
            values: completedData.map((a) => Number(a.maxPrescribedWeight)),
            name: SeriesType.PrescribedWeight,
          },
        ]);
      }

      const yAxisData: YAxisType[] = [];

      const addYAxisData = (
        property: keyof CompletedDataItem,
        label: string
      ) => {
        if (completedData.some((a) => a[property])) {
          const data = completedData.map((a) => {
            return extractNumericValue(a[property]);
          });
          yAxisData.push({
            values: data,
            name: label,
          });
        }
      };

      if (completedData.some((a) => a.maxCompletedWeight)) {
        addYAxisData("maxCompletedWeight", SeriesType.CompletedWeight);
      }
      if (completedData.some((a) => a.maxCompletedWeightLeftSide)) {
        addYAxisData(
          "maxCompletedWeightLeftSide",
          SeriesType.CompletedWeightLeftSide
        );
      }
      if (completedData.some((a) => a.maxCompletedWeightRightSide)) {
        addYAxisData(
          "maxCompletedWeightRightSide",
          SeriesType.CompletedWeightRightSide
        );
      }

      setYAxisCompleted(yAxisData);
    }
  }, [completedData]);

  const displayGraph =
    !isLoadingCompleted &&
    xAxis?.labels.length > 0 &&
    (yAxisPrescribed.length > 0 || yAxisCompleted.length > 0);

  return (
    <>
      <Alert
        variant="outlined"
        severity="info"
        sx={{ mb: "10px", alignItems: "center" }}
      >
        <Typography sx={sharedStyles.body.timeline.cardBody}>
          {t("training-insights.disclaimer")}
        </Typography>
      </Alert>
      {error && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("errors.server-unable")}
          </Typography>
        </Alert>
      )}
      {displayGraph &&
        !error &&
        (!isAthleteView ? (
          <Box
            sx={{
              gap: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                maxHeight: "65vh",
              }}
            >
              <TrainingInsightsLineChart
                title={`${exercise.exerciseName ?? ""} - Weight`}
                xAxis={xAxis}
                yAxisSeries={[...yAxisPrescribed, ...yAxisCompleted]}
              />
            </Box>
            <TrainingInsightsTableForWeight
              completedData={completedData ?? []}
            />
          </Box>
        ) : (
          <TrainingInsightsLineChart
            title={`${exercise.exerciseName ?? ""} - Weight`}
            xAxis={xAxis}
            yAxisSeries={[...yAxisPrescribed, ...yAxisCompleted]}
            isAthleteView
          />
        ))}
    </>
  );
};

export { TrainingInsightsLineChartContainerForWeight };
