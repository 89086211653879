import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  ClientInvoice,
  InvoiceRequestParams,
  UpdateClientInvoiceParams,
} from "./models";

export const billingApi = createApi({
  baseQuery,
  reducerPath: "billingApi",
  tagTypes: ["Billing", "InvoiceList", "Invoices", "Invoice", "InvoiceCount"],
  endpoints: (builder) => ({
    getInvoices: builder.query<ClientInvoice[], InvoiceRequestParams>({
      query: (params) => ({
        url: `/billing/invoices`,
        params,
      }),
      providesTags: ["InvoiceList"],
    }),
    getInvoicesCount: builder.query<number, void>({
      query: () => ({
        url: `/billing/invoices?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["InvoiceCount"],
    }),
    updateInvoiceStatus: builder.mutation<void, UpdateClientInvoiceParams>({
      query: ({ invoiceId, invoiceStatus }) => ({
        url: `/billing/invoices/${invoiceId}`,
        method: "PATCH",
        body: {
          invoiceStatus,
        },
      }),
      invalidatesTags: [
        "Invoices",
        "InvoiceList",
        "InvoiceCount",
        "Invoice",
        "Billing",
      ],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoicesCountQuery,
  useUpdateInvoiceStatusMutation,
} = billingApi;
