import { FC } from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "shared/themes";

const ProgramsSkeleton: FC = () => {
  return (
    <Box
      sx={{
        padding: "9px 0px 23px",
      }}
    >
      {Array.from(Array(8).keys()).map((item) => (
        <Skeleton
          key={item.toString()}
          variant="rectangular"
          animation="wave"
          width="100%"
          height="56px"
          sx={{
            bgcolor: `${Colors.gray[500]}`,
            mb: "5px",
          }}
        />
      ))}
    </Box>
  );
};

export { ProgramsSkeleton };
