import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Skeleton,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Routes } from "shared/routers";
import { useTypedSelector } from "shared/stores";
import { useNavigate, useParams } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  useGetUserNoteByIdQuery,
  useGetUserProfileItemQuery,
} from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Colors } from "shared/themes";
import { UserNoteTextEditor } from "./UserNoteTextEditor";

const UserNoteView: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const navigate = useNavigate();

  const params = useParams();

  const defaultStyles = showSidebar
    ? sharedStyles.containers.sidebar.content
    : sharedStyles.containers.sidebar.contentFullscreen;

  const [tabValue, setTabValue] = useState<string | null>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue) setTabValue(newValue);
  };

  const hasSetTabValue = useRef(false);

  const {
    data: userNote,
    isLoading,
    error,
  } = useGetUserNoteByIdQuery(Number(params.userNoteId), {
    skip: !params.userNoteId,
  });

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(userNote?.userId ?? 0, { skip: !userNote });

  const displayAudioTranscriptTab = userNote?.audioTranscript;
  useEffect(() => {
    if (hasSetTabValue.current) {
      return;
    }

    if (userNote && !isLoading) {
      const hasAudioTranscript = !!userNote.audioTranscript;
      const hasNote = !!userNote.note;
      const hasClientSummary = !!userNote.clientSummary;
      const hasReferralLetter = !!userNote.referralLetter;

      if (hasReferralLetter) {
        setTabValue("4");
      } else if (hasClientSummary) {
        setTabValue("3");
      } else if (hasNote) {
        setTabValue("2");
      } else if (hasAudioTranscript) {
        setTabValue("1");
      } else {
        // DEFAULT FALLBACK
        setTabValue("2");
      }

      hasSetTabValue.current = true;
    }
  }, [userNote, isLoading, displayAudioTranscriptTab]);

  const notes = (() => {
    if (userNote && !isLoading) {
      if (
        userNote?.fileName &&
        userNote?.userNoteStatus === "Completed" &&
        userNote?.note?.startsWith("{") &&
        userNote?.note.endsWith("}")
      ) {
        return JSON.parse(userNote?.note);
      }
      return userNote?.note;
    }
    return " ";
  })();

  const clientSummary = (() => {
    if (userNote && !isLoading) {
      if (
        userNote?.fileName &&
        userNote?.userNoteStatus === "Completed" &&
        userNote?.clientSummary?.startsWith("{") &&
        userNote?.clientSummary.endsWith("}")
      ) {
        return JSON.parse(userNote?.clientSummary);
      }
      return userNote?.clientSummary;
    }
    return null;
  })();

  if ((!userNote && !isLoading) || error) {
    return (
      <Box
        sx={{
          ...defaultStyles,
        }}
      >
        <Alert
          sx={{
            mt: 3,
            width: "100%",
          }}
          variant="outlined"
          severity="error"
        >
          No user note found.
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      {tabValue ? (
        <TabContext value={tabValue}>
          <Box
            sx={{
              ...defaultStyles,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <IdenticonAvatar
                  sizeValue={40}
                  seedValue={currentUserData?.id.toString()}
                  profileImageUrl={currentUserData?.profileImageUrl || ""}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "140%",
                  textTransform: "none",
                  color: `${Colors.gray[900]}`,
                  mb: "16px",
                  mt: "26px",
                }}
              >
                {currentUserData?.firstName} {currentUserData?.lastName}
              </Typography>
            </Box>
            {!!userNote && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mb: 2,
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={() =>
                    navigate(`${Routes.profile.url}/${userNote?.userId}/2`)
                  }
                >
                  Back to profile
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={() => navigate(`${Routes.userNotes.url}`)}
                >
                  View all notes
                </Button>
              </Box>
            )}
            <TabList onChange={handleTabChange} aria-label="Tabs">
              {displayAudioTranscriptTab && (
                <Tab label="Transcript" value="1" />
              )}
              <Tab label="Note" value="2" />
              <Tab label="Client Summary" value="3" />
              <Tab label="Referral Letter" value="4" />
            </TabList>
            {displayAudioTranscriptTab && !!userNote && (
              <TabPanel value="1">
                <UserNoteTextEditor
                  content={userNote?.audioTranscript}
                  userNote={userNote}
                  type="audioTranscript"
                />
              </TabPanel>
            )}
            {!!userNote && (
              <TabPanel value="2">
                <UserNoteTextEditor
                  content={notes}
                  userNote={userNote}
                  type="note"
                />
              </TabPanel>
            )}
            {!!userNote && (
              <TabPanel value="3">
                <UserNoteTextEditor
                  content={clientSummary}
                  userNote={userNote}
                  type="clientSummary"
                />
              </TabPanel>
            )}
            {!!userNote && (
              <TabPanel value="4">
                <UserNoteTextEditor
                  content={userNote?.referralLetter}
                  userNote={userNote}
                  type="referralLetter"
                />
              </TabPanel>
            )}
          </Box>
        </TabContext>
      ) : (
        <Box
          sx={{
            ...defaultStyles,
          }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ mt: 2, height: "700px", width: "100%", mb: "12px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export { UserNoteView };
