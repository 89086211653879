import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  Select,
  MenuItem,
  Typography,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Colors } from "../../shared/themes";
import {
  FiveTranConnector,
  useAddFiveTranConnectorMutation,
} from "../../shared/api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "../../shared/helpers";

interface OrganisationAddIntegrationModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  fiveTranConnectors: FiveTranConnector[] | undefined;
}

const OrganisationAddIntegrationModalSchema = Yup.object().shape({
  apiIntegration: Yup.string().required(
    "organisation-management.integrations.apiIntegration-required"
  ),
});

const OrganisationAddIntegrationModal: FC<
  OrganisationAddIntegrationModalProps
> = ({ isOpenModal, handleCloseModal, fiveTranConnectors }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [
    fiveTranConnectorCardSetupUrlResponse,
    setFiveTranConnectorCardSetupUrlResponse,
  ] = useState("");
  const [addFiveTranConnector, { isLoading }] =
    useAddFiveTranConnectorMutation();

  const onSubmit = async (params: string) => {
    setErrorMessage("");

    const fiveTranConnector = fiveTranConnectors?.find(
      (fiveTranConnector) => fiveTranConnector.apiIntegration === params
    );
    const isAlreadySetup = fiveTranConnector?.availableToSetup;

    if (!isAlreadySetup) {
      setErrorMessage(t("organisation-management.integrations.alreadySetup"));
      return;
    }

    try {
      const fiveTranConnectorCardSetupUrl = await addFiveTranConnector(
        params
      ).unwrap();

      window.open(
        fiveTranConnectorCardSetupUrl.connectorCardSetupUrl,
        "_blank"
      );

      setFiveTranConnectorCardSetupUrlResponse(
        fiveTranConnectorCardSetupUrl.connectorCardSetupUrl
      );
      setSuccessMessage(t("organisation-management.integrations.success"));
    } catch (error: AnyType) {
      setErrorMessage(t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      apiIntegration: "",
    },
    validationSchema: OrganisationAddIntegrationModalSchema,
    onSubmit: (params) => {
      onSubmit(params.apiIntegration);
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "400px",
            height: "auto",
            background: `${Colors.gray[100]}`,
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("organisation-management.integrations.title")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {t("organisation-management.integrations.description")}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "24px",
            }}
          >
            {t("organisation-management.integrations.sub-description")}
          </Typography>
          <Select
            value={values.apiIntegration}
            defaultValue={values.apiIntegration}
            onBlur={handleBlur("apiIntegration")}
            onChange={(e) => setFieldValue("apiIntegration", e.target.value)}
            size="small"
            sx={{
              width: "100%",
              mb: "24px",
            }}
          >
            <MenuItem value="" disabled>
              {t("organisation-management.integrations.placeholder")}
            </MenuItem>
            {fiveTranConnectors?.map((fiveTranConnector) => (
              <MenuItem
                key={fiveTranConnector.apiIntegration}
                value={fiveTranConnector.apiIntegration}
                disabled={!fiveTranConnector.availableToSetup}
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.gray[1400]}`,
                }}
              >
                {fiveTranConnector.name}
              </MenuItem>
            ))}
          </Select>
          <LoadingButton
            sx={{
              width: "100%",
              height: "36px",
              background: `${Colors.blue[1200]}`,
              mt: "5px",
            }}
            size="large"
            loading={isLoading}
            disabled={isLoading || values.apiIntegration === ""}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {t("organisation-management.integrations.addIntegrationBtn")}
            </Typography>
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
              If nothing opens, click{" "}
              <a
                href={fiveTranConnectorCardSetupUrlResponse}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { OrganisationAddIntegrationModal };
