/* eslint-disable */
// @ts-ignore
export class CustomTextEditor {
  constructor(props) {
    const el = document.createElement("div");

    const datalist = document.createElement("datalist");
    datalist.id = "";

    const { names } = props.columnInfo.editor.options;
    names.forEach((name) => {
      const option = document.createElement("option");
      option.value = name;
      datalist.appendChild(option);
    });

    const inputHTML = `
      <input 
        type="text" 
        id="exercise-choice" 
        name="exercise-choice" 
        value="${String(props.value)}"
        list="exercise-options"
      />
    `;
    const inputDiv = document.createElement('div');
    inputDiv.innerHTML = inputHTML;
    const inputEl = inputDiv.querySelector('input');

    inputEl.addEventListener("keyup", (e) => {
      if (e.target.value.length >= 3) {
          datalist.setAttribute("id", "exercise-options");
      } else {
          datalist.setAttribute("id", "");
      }
  });

    this.el = el;
    // append datalist to the element
    this.el.appendChild(inputEl);
    this.el.appendChild(datalist);
  }

  getElement() {
    return this.el;
  }

  getValue() {
    const input = this.el.querySelector('input');
    return input.value;
  }
}
