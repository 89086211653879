import { FC } from "react";
import { Box } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { Sidebar } from "../../../shared/ui";
import { Colors } from "../../../shared/themes";
import { YourSchedule } from "./ui/YourSchedule";
import { Header } from "./ui/Header";
import { ClientsGoingOffTrack } from "./ui/ClientsGoingOffTrack";
import { AdminDashboardAnalytics } from "./ui/AdminDashboardAnalytics";
import { OutstandingClientInvoices } from "./ui/OutstandingClientInvoices";
import { useGetDashboardInfoQuery } from "../../../shared/api";

interface AdminDashboardProps {
  closeSidebar: () => void;
  isOpenSidebar: boolean;
  toggleSidebar: () => void;
  openSidebar: () => void;
  getUserIdForWorkoutCreation: (userId: number) => void;
}

const AdminDashboard: FC<AdminDashboardProps> = ({
  closeSidebar,
  isOpenSidebar,
  toggleSidebar,
  openSidebar,
  getUserIdForWorkoutCreation,
}) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebarState = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebarState;

  const { data: dashboardResponse, isLoading } = useGetDashboardInfoQuery();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        bgcolor: `${Colors.gray[200]}`,
        flexGrow: 1,
      }}
    >
      <Sidebar
        isOpenSidebar={isOpenSidebar}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
      />
      <Header toggleSidebar={toggleSidebar} />
      <Box
        sx={{
          flexGrow: 1,
          marginTop: "20px",
          marginLeft: showSidebar ? "220px" : "0px",
          minHeight: "100vh",
          padding: "58px 20px 27px 24px",
          bgcolor: `${Colors.gray[200]}`,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "140%",
            textTransform: "none",
            color: `${Colors.gray[900]}`,
            mb: "16px",
          }}
        >
          Dashboard
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              height: "auto",
              overflowY: "auto",
              mr: "20px",
              bgcolor: `${Colors.gray[200]}`,
              overflow: "hidden",
            }}
          >
            <YourSchedule
              getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
              dashboardResponse={dashboardResponse}
              isLoading={isLoading}
            />
            <OutstandingClientInvoices
              dashboardResponse={dashboardResponse}
              isLoading={isLoading}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              height: "auto",
              overflowY: "auto",
              bgcolor: `${Colors.gray[200]}`,
            }}
          >
            <ClientsGoingOffTrack
              dashboardResponse={dashboardResponse}
              isLoading={isLoading}
            />
            <AdminDashboardAnalytics
              dashboardResponse={dashboardResponse}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdminDashboard };
