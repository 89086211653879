/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Container, TextField, Box, Typography } from "@mui/material";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { FormError } from "components/Form/FormError";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { Images } from "../../../shared/themes";
import { useClientSignupMutation } from "../../../shared/api";
import { Routes } from "../../../shared/routers/Routes";

const ClientSignupEmailVerificationSchema = Yup.object().shape({
  token: Yup.string().required("Verification code is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Confirm password is required"),
});

export const ClientSignupEmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [clientSignup, { isLoading, error }] = useClientSignupMutation();

  const formikEmailVerification = useFormik({
    initialValues: {
      token: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ClientSignupEmailVerificationSchema,
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        await clientSignup(values).unwrap();
        navigate(Routes.clientSignupSuccess.url);
      } catch (error) {
        setErrorMessage(
          "Something went wrong. Please check your email and verification code and try again."
        );
      }
    },
  });

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    const email = new URLSearchParams(window.location.search).get("email");
    if (email) {
      formikEmailVerification.setFieldValue("email", email ?? "");
    }
    if (token) formikEmailVerification.setFieldValue("token", token ?? "");
  }, []);

  return (
    <Container sx={sharedStyles.containers.signIn.form}>
      <Box
        sx={{
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "90%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img src={Images.Logo} alt="Logo" height={80} />
        </Box>
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t("signup.client.emailVerificationTitle")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t("signup.client.emailVerificationDescription")}
        </Typography>
        <form onSubmit={formikEmailVerification.handleSubmit}>
          <TextField
            id="email"
            name="email"
            label={t("signup.client.form.email")}
            fullWidth
            value={formikEmailVerification.values.email}
            onChange={formikEmailVerification.handleChange}
            error={
              formikEmailVerification.touched.email &&
              Boolean(formikEmailVerification.errors.email)
            }
            helperText={
              formikEmailVerification.touched.email &&
              formikEmailVerification.errors.email
            }
            sx={{ mb: 2 }}
          />
          <TextField
            id="token"
            name="token"
            label={t("signup.client.form.verificationCode")}
            fullWidth
            value={formikEmailVerification.values.token}
            onChange={formikEmailVerification.handleChange}
            error={
              formikEmailVerification.touched.token &&
              Boolean(formikEmailVerification.errors.token)
            }
            helperText={
              formikEmailVerification.touched.token &&
              formikEmailVerification.errors.token
            }
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            label={t("signup.client.form.password")}
            variant="outlined"
            margin="normal"
            value={formikEmailVerification.values.password}
            onChange={formikEmailVerification.handleChange}
            onBlur={formikEmailVerification.handleBlur}
            error={
              formikEmailVerification.touched.password &&
              Boolean(formikEmailVerification.errors.password)
            }
            helperText={
              formikEmailVerification.touched.password &&
              formikEmailVerification.errors.password
            }
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label={t("signup.client.form.confirmPassword")}
            variant="outlined"
            margin="normal"
            value={formikEmailVerification.values.confirmPassword}
            onChange={formikEmailVerification.handleChange}
            onBlur={formikEmailVerification.handleBlur}
            error={
              formikEmailVerification.touched.confirmPassword &&
              Boolean(formikEmailVerification.errors.confirmPassword)
            }
            helperText={
              formikEmailVerification.touched.confirmPassword &&
              formikEmailVerification.errors.confirmPassword
            }
          />
          {errorMessage && <FormError errorMessage={errorMessage} />}
          <PrimaryButton
            value={t("signup.client.buttons.verify")}
            size="large"
            disabled={isLoading}
            loading={isLoading}
            variant="contained"
            type="submit"
            fullWidth
          />
        </form>
        <Box sx={sharedStyles.containers.signIn.link}>
          <Link to={Routes.signIn.url} style={sharedStyles.links.backToSignIn}>
            {t("signup.client.buttons.signin")}
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
