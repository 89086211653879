export const Colors = {
  fluorescent: {
    100: "#ECFFFE",
    200: "#B3FFFB",
    300: "#67FFF7",
    400: "#00F5E9",
  },
  blue: {
    100: "#ECECFF",
    200: "#D9DAFF",
    300: "#C6C7FF",
    400: "#A0A2FF",
    500: "#7A7DFF",
    600: "#5557FF",
    700: "#4245FF",
    800: "#2F32FF",
    900: "#1C1FFF",
    1000: "#090DFF",
    1100: "#0005F5",
    1200: "#2500F5",
    1300: "#06243E",
    1400: "#423D55",
    1500: "#213F59",
    1600: "#152636",
    1700: "#F8F6FF",
    1800: "#4A4AFE",
    1900: "#E6E6F2",
    2000: "#F7F6FF",
    2100: "#1F3C56",
  },
  oxford: {
    100: "#DFF0FF",
    200: "#A0D3FF",
    300: "#61B5FF",
    400: "#2197FF",
    500: "#0189FF",
    600: "#0078E1",
    700: "#0067C1",
    800: "#0056A1",
    900: "#004582",
    1000: "#003462",
    1100: "#002240",
  },
  gray: {
    50: "#FAFAFA",
    100: "#FFFFFF",
    200: "#F5F5F5",
    300: "#EEEEEE",
    400: "#E0E0E0",
    500: "#BDBDBD",
    600: "#9E9E9E",
    700: "#757575",
    800: "#616161",
    900: "#424242",
    1000: "#212121",
    1100: "#000000",
    1200: "#303030",
    1300: "#DCDCDC",
    1400: "#141414",
    1500: "#758898",
    1600: "#292929",
    1700: "#F1F4F9",
    1800: "#EBEBEB",
    1900: "#607488",
    2000: "#656565",
    2100: "#636266",
    2200: "#121212",
    2300: "#7F7F7F",
    2400: "#666666",
    2500: "#626262",
    2600: "#718398",
    2700: "#727175",
    2800: "#777777",
  },
  green: {
    100: "#009D52",
    200: "#F1F9F1",
    300: "#1FA966",
    400: "#CDF6E2",
    500: "#007C40",
    600: "#677701",
    700: "#CDFFCD",
    800: "#007F00",
    900: "#00D06D",
    1000: "#20A868",
    1100: "#00D06C",
  },
  red: {
    100: "#FEF0EF",
    200: "#F5010D",
    300: "#FED3CC",
    400: "#4CAF50",
    500: "#00D06B",
  },
  yellow: {
    100: "#FFF0A8",
    200: "#FFB400",
  },
};
