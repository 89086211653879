import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";
import { isDev } from "./shared/helpers";
import { Router } from "./shared/routers";
import { store, persistor } from "./shared/stores";
import { Themes } from "./shared/themes/Themes";
import { LocalizationService } from "./shared/services";
import "./index.css";
import loadHeap from "./heap";

LocalizationService.initLocalization();

if (!isDev) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN!,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
  });
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID!);
  loadHeap(process.env.REACT_APP_HEAP_APP_ID!);
}

// Unregister the service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    console.log("Unregistering service worker");
    registration.unregister();
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Themes}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <Helmet htmlAttributes={{ lang: "en" }}>
              <title>FitFocus</title>

              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="initial-scale=1, width=device-width"
              />
              <meta name="theme-color" content="#fff" />
              <meta name="fitfocus" content="promotional materials" />

              <link
                href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
                rel="stylesheet"
              />
            </Helmet>
            <Router />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
