import { FC, useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { Routes } from "shared/routers";
import { Colors } from "../../shared/themes";
import { Workflow } from "../../shared/api";
import { WorkflowCard } from "./WorkflowCard";

interface WorkflowsContainerProps {
  handleWorkflowAddWorkflowModalOpen: () => void;
  workflows: Workflow[] | undefined;
}

const WorkflowsContainer: FC<WorkflowsContainerProps> = ({
  handleWorkflowAddWorkflowModalOpen,
  workflows,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 3,
        gap: "12px",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("workflows.title")}
        </Typography>
      </Box>
      <Box sx={{ mt: "-20px" }}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {t("workflows.sub-title")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: 3,
          gap: "12px",
          height: "100%",
          overflow: "auto",
        }}
      >
        {workflows?.length === 0 && (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "18px",
              mt: 2,
              color: `${Colors.oxford[1100]}`,
            }}
          >
            {t("workflows.empty-state")}
          </Typography>
        )}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {workflows?.map((workflow) => (
            <Grid
              key={workflow.id}
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                cursor: workflow.workflowStatus !== "Building" ? "pointer" : "",
              }}
            >
              <Link
                key={workflow.id}
                style={{ textDecoration: "none" }}
                to={`${Routes.workflows.url}/${workflow.id}`}
              >
                <WorkflowCard workflow={workflow} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { WorkflowsContainer };
