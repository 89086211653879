import { Box } from "@mui/system";
import { FC } from "react";
import { sharedStyles } from "shared/themes/shared/styles";

interface LeadStatusItemProps {
  status?: string;
}

const LeadStatusItem: FC<LeadStatusItemProps> = ({ status }) => {
  const statusBodyStyle = (status: string) => {
    switch (status) {
      case "New":
        return sharedStyles.containers.dataGrid.leadsNew;
      case "In Progress":
        return sharedStyles.containers.dataGrid.leadsInProgress;
      case "Qualified":
        return sharedStyles.containers.dataGrid.leadsQualified;
      case "Unqualified":
        return sharedStyles.containers.dataGrid.leadsUnqualified;
      case "Won":
        return sharedStyles.containers.dataGrid.leadsWon;
      case "Lost":
        return sharedStyles.containers.dataGrid.leadsLost;
      default:
        return sharedStyles.containers.dataGrid.leadsNew;
    }
  };

  const statusTagStyle = (status: string) => {
    switch (status) {
      case "New":
        return sharedStyles.tags.leadsNew;
      case "In Progress":
        return sharedStyles.tags.leadsInProgress;
      case "Qualified":
        return sharedStyles.tags.leadsQualified;
      case "Unqualified":
        return sharedStyles.tags.leadsUnqualified;
      case "Won":
        return sharedStyles.tags.leadsWon;
      case "Lost":
        return sharedStyles.tags.leadsLost;
      default:
        return sharedStyles.tags.leadsNew;
    }
  };

  return (
    <Box
      sx={{
        ...statusBodyStyle(status!),
      }}
    >
      <Box
        sx={{
          ...statusTagStyle(status!),
        }}
      />
      {status}
    </Box>
  );
};

export { LeadStatusItem };
