/* eslint-disable no-nested-ternary */
import { Alert, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Images } from "shared/themes";
import { useGetWorkoutsQuery } from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import { SessionCard } from "./SessionCard";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";
import { ActionButton } from "./ActionButton";
import { WorkoutList } from "./WorkoutList";

interface WorkoutsProps {
  closeSidebar: () => void;
}

const Workouts: FC<WorkoutsProps> = ({ closeSidebar }) => {
  dayjs.extend(advancedFormat);
  const { data, isLoading, refetch } = useGetWorkoutsQuery();
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const { t } = useTranslation();

  useEffect(() => {
    setActiveIndex(data?.currentWorkout ? 0 : 1);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const workoutProgramWeeksFrequency =
    data?.curentWorkoutProgram?.weeksInProgram === 1
      ? "1 week"
      : `${data?.curentWorkoutProgram?.weeksInProgram} weeks`;

  const workoutProgramDaysFrequency =
    data?.curentWorkoutProgram?.daysPerWeek === 1
      ? "1 day/week"
      : `${data?.curentWorkoutProgram?.daysPerWeek} days/week`;

  const workoutProgramFrequencyText = `${workoutProgramWeeksFrequency}, ${workoutProgramDaysFrequency}`;

  return !isLoading ? (
    <Box onClick={closeSidebar} sx={{ height: "88%", overflowY: "auto" }}>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h2"
            sx={{
              height: "34px",

              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              color: `${Colors.blue[1500]}`,
            }}
          >
            {data?.curentWorkoutProgram?.name ||
              t("athlete-dashboard.no-program")}
          </Typography>
          {data?.curentWorkoutProgram?.invoiceIsOverdue && (
            <Alert
              variant="outlined"
              severity="error"
              sx={{ mb: "10px", alignItems: "center" }}
            >
              <Typography variant="h6">
                {t("athlete-dashboard.invoice-overdue")}
              </Typography>

              <Typography variant="body2">
                {t("athlete-dashboard.invoice-overdue-description")}
              </Typography>
            </Alert>
          )}
          {data?.curentWorkoutProgram?.invoiceDueDateTime &&
            !data?.curentWorkoutProgram?.invoiceIsOverdue &&
            data?.curentWorkoutProgram?.invoiceStatus === "open" && (
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ mb: "10px", alignItems: "center" }}
              >
                <Typography variant="h6">
                  {t("athlete-dashboard.invoice-due-warning")}:{" "}
                  {dayjs(data?.curentWorkoutProgram?.invoiceDueDateTime).format(
                    "Do MMMM YYYY"
                  )}
                </Typography>

                <Typography variant="body2">
                  {t("athlete-dashboard.invoice-due-warning-description")}
                </Typography>
              </Alert>
            )}

          {data?.curentWorkoutProgram &&
            data?.curentWorkoutProgram?.invoiceIsOverdue === false && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  Start Date:{" "}
                  {dayjs(data?.curentWorkoutProgram?.startDate).format(
                    "Do MMMM YY"
                  )}
                </Typography>
                <Typography sx={sharedStyles.body.timeline.cardBody}>
                  {workoutProgramFrequencyText}
                </Typography>
              </Box>
            )}
        </Box>

        {data?.currentWorkout &&
          data?.curentWorkoutProgram?.invoiceIsOverdue === false && (
            <>
              <ActionButton
                isActive={activeIndex === 0}
                title={t("athlete-dashboard.todays-session")}
                onPress={() => setActiveIndex(activeIndex === 0 ? null : 0)}
              />

              <Collapse in={activeIndex === 0} timeout="auto" unmountOnExit>
                <SessionCard
                  item={data?.currentWorkout}
                  id={data?.currentWorkout?.id}
                  image={Images.CurrentSession}
                  dayNumber={data?.currentWorkout?.dayNumber}
                  weekNumber={data?.currentWorkout?.weekNumber}
                  workoutStatus={data?.currentWorkout?.workoutStatus}
                />
              </Collapse>
            </>
          )}

        {data?.curentWorkoutProgram?.invoiceIsOverdue === false && (
          <>
            <ActionButton
              isActive={activeIndex === 1}
              title={t("athlete-dashboard.upcoming-workouts")}
              onPress={() => setActiveIndex(activeIndex === 1 ? null : 1)}
            />

            <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
              {data?.remainingWorkouts?.length ? (
                <WorkoutList workouts={data?.remainingWorkouts || []} />
              ) : (
                <Alert
                  variant="outlined"
                  severity="success"
                  sx={{ mb: "10px", alignItems: "center" }}
                >
                  <Typography variant="h6">
                    {t("athlete-dashboard.awesome")}
                  </Typography>

                  <Typography variant="body2">
                    {t("athlete-dashboard.description")}
                  </Typography>
                </Alert>
              )}
            </Collapse>
          </>
        )}

        {!!data?.completedWorkouts && data?.completedWorkouts?.length !== 0 && (
          <>
            <ActionButton
              isActive={activeIndex === 2}
              title={t("athlete-dashboard.completed-workouts")}
              onPress={() => setActiveIndex(activeIndex === 2 ? null : 2)}
            />

            <Collapse in={activeIndex === 2} timeout="auto" unmountOnExit>
              <WorkoutList workouts={data?.completedWorkouts} isCompleted />
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  ) : (
    <WorkoutPageSkeleton />
  );
};

export { Workouts };
