import React, { FC } from "react";
import { Button, Icon, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../shared/themes";

interface SuccessfulUpdateModalProps {
  successfulUpdateModal: boolean;
  toggleModal: () => void;
}

const SuccessfulUpdateModal: FC<SuccessfulUpdateModalProps> = ({
  successfulUpdateModal,
  toggleModal,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={successfulUpdateModal}
      onClose={toggleModal}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          minWidth: "450px",
          maxWidth: "600px",
          height: "200px",
          bgcolor: "white",
          padding: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            color: `${Colors.gray[800]}`,
            textTransform: "none",
            marginBottom: "10px",
          }}
        >
          {t("update-user-modal.title")}
        </Typography>
        <Box
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50px",
            bgcolor: `${Colors.green[400]}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon sx={{ color: `${Colors.green[300]}` }}>check_icon</Icon>
        </Box>
        <Button
          onClick={toggleModal}
          color="primary"
          variant="contained"
          sx={{
            width: "50%",
            textTransform: "none",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: `${Colors.gray[100]}`,
          }}
        >
          {t("update-user-modal.ok-btn")}
        </Button>
      </Box>
    </Modal>
  );
};

export { SuccessfulUpdateModal };
