import { Box, Button, IconButton } from "@mui/material";
import { Editor } from "@tiptap/react";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { FC } from "react";
import { Colors } from "shared/themes";

type MenuBarProps = {
  editor: Editor | null;
};

export const MenuBar: FC<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <Box className="control-group">
      <Box
        className="button-group"
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: `${Colors.blue[1600]}`,
              border: `1px solid ${Colors.blue[1600]}`,
              textTransform: "none",
              fontSize: "1.2rem",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive("heading", { level: 1 }) ? "is-active" : ""
            }
          >
            h1
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: `${Colors.blue[1600]}`,
              border: `1px solid ${Colors.blue[1600]}`,
              textTransform: "none",
              fontSize: "0.9rem",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive("heading", { level: 2 }) ? "is-active" : ""
            }
          >
            h2
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: `${Colors.blue[1600]}`,
              border: `1px solid ${Colors.blue[1600]}`,
              textTransform: "none",
              fontSize: "0.7rem",
            }}
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive("heading", { level: 3 }) ? "is-active" : ""
            }
          >
            h3
          </Button>
          <Button
            variant="outlined"
            type="button"
            sx={{
              color: `${Colors.blue[1600]}`,
              border: `1px solid ${Colors.blue[1600]}`,
              textTransform: "none",
            }}
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive("paragraph") ? "is-active" : ""}
          >
            Normal
          </Button>
        </Box>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <OutlinedIcon iconName="format_bold" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <OutlinedIcon iconName="format_strikethrough" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive("underline") ? "is-active" : ""}
        >
          <OutlinedIcon iconName="format_underlined" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <OutlinedIcon iconName="format_list_bulleted" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <OutlinedIcon iconName="format_list_numbered" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().undo()}
        >
          <OutlinedIcon iconName="undo" />
        </IconButton>
        <IconButton
          type="button"
          size="small"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().redo()}
        >
          <OutlinedIcon iconName="redo" />
        </IconButton>
      </Box>
    </Box>
  );
};
