import { Box, Icon, Typography } from "@mui/material";
import { getRandomTestimonial, UserTestimonial } from "constants/feedback";
import React, { useState } from "react";
import { sharedStyles } from "shared/themes/shared/styles";

export const Testimonial: React.FC = () => {
  const [testimonial] = useState<UserTestimonial>(getRandomTestimonial());

  return (
    <div
      style={{
        ...(sharedStyles.containers.signIn
          .testimonialBlock as React.CSSProperties),
        overflow: "auto",
      }}
    >
      <Typography sx={sharedStyles.body.testimonial.title}>
        {testimonial.title}
      </Typography>

      <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            animation: "fadeIn 4s",
            "@keyframes fadeIn": {
              from: { opacity: 0 },
              to: { opacity: 1 },
            },
          }}
        >
          {[...Array(testimonial.rating)].map((_, index) => (
            // TODO: Add this color to shared theme
            <Icon key={index} style={{ color: "#FBBF24" }}>
              starrounded
            </Icon>
          ))}
        </Box>
        <div>
          <Typography sx={sharedStyles.body.testimonial.description}>
            {testimonial.name}
          </Typography>
          <Typography sx={sharedStyles.body.testimonial.subDescription}>
            {testimonial.job}
          </Typography>
        </div>
      </div>
    </div>
  );
};
