import { Box } from "@mui/system";
import { FC } from "react";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { AthleteTrainingInsightsContent } from "./AthleteTrainingInsights/AthleteTrainingInsightsContent";

interface AthleteTrainingInsightsProps {
  closeSidebar: () => void;
}

const AthleteTrainingInsights: FC<AthleteTrainingInsightsProps> = ({
  closeSidebar,
}) => {
  const userId = useTypedSelector(userSelectors.userId);
  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
      }}
    >
      <AthleteTrainingInsightsContent
        closeSidebar={closeSidebar}
        preselectedUserId={userId!}
      />
    </Box>
  );
};

export { AthleteTrainingInsights };
