import { FC } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  ToggleButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrganisationApiKey } from "../../shared/api";
import { Colors } from "../../shared/themes/Colors";

interface OrganisationIntegrationCardProps {
  organisationApiKey: OrganisationApiKey | undefined;
}

const OrganisationIntegrationCard: FC<OrganisationIntegrationCardProps> = ({
  organisationApiKey,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: "100%",
        height: "180px",
        position: "relative",
        mb: "16px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        boxShadow: "none",
        "&:hover, &:focus": {
          border: `2px solid ${Colors.gray[300]}`,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <CardContent
        sx={{
          position: "absolute",
          top: "30%",
          width: "100%",
          height: "60%",
          padding: "0px 20px 0px 20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                padding: "5px",
                borderRadius: "16px",
                bgcolor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: "16px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "0.16px",
                    color: `${Colors.oxford[1100]}`,
                  }}
                >
                  {organisationApiKey?.friendlyName}
                  <ToggleButton
                    sx={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      marginLeft: "8px",
                      padding: "0px",
                      "&.Mui-selected": {
                        backgroundColor: `${Colors.green[100]}`,
                        color: `${Colors.green[100]}`,
                      },
                    }}
                    value="check"
                    color="primary"
                    selected={organisationApiKey?.active === true}
                  />
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "0.16px",
                    color: `${Colors.oxford[1100]}`,
                    mt: "12px",
                  }}
                >
                  {organisationApiKey?.description}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { OrganisationIntegrationCard };
