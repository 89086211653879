import { Container, Box, Typography } from "@mui/material";
import { FormError } from "components/Form/FormError";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { FormContainer } from "components/Form/FormContainer";
import { InputField } from "components/Form/InputField";
import { LogoLink } from "components/Form/LogoLink";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { useResetPasswordMutation } from "shared/api";
import { Routes } from "shared/routers/Routes";

const ResetPasswordVerificationSchema = Yup.object().shape({
  token: Yup.string().required("Token is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Confirm Password is required"),
});

export const ResetPasswordVerification: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const formikEmailVerification = useFormik({
    initialValues: {
      token: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordVerificationSchema,
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        await resetPassword(values).unwrap();
        navigate(Routes.forgotPasswordSuccess.url);
      } catch (error) {
        setErrorMessage(
          "Something went wrong. Please check your verification code and try again."
        );
      }
    },
  });

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) formikEmailVerification.setFieldValue("token", token ?? "");
  }, []);

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <LogoLink />
        <Typography sx={sharedStyles.headings.signIn}>
          {t("reset-password.verification-title")}
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          {t("reset-password.verification-description")}
        </Typography>
        <form onSubmit={formikEmailVerification.handleSubmit}>
          <InputField
            id="token"
            name="token"
            label={t("reset-password.form.token")}
            value={formikEmailVerification.values.token}
            onChange={formikEmailVerification.handleChange}
            error={
              formikEmailVerification.touched.token &&
              Boolean(formikEmailVerification.errors.token)
            }
            helperText={
              formikEmailVerification.touched.token &&
              formikEmailVerification.errors.token
            }
            placeholder={t("placeholders.verificationCode")}
          />
          <InputField
            id="password"
            name="password"
            type="password"
            label={t("reset-password.form.password")}
            value={formikEmailVerification.values.password}
            onChange={formikEmailVerification.handleChange}
            onBlur={formikEmailVerification.handleBlur}
            error={
              formikEmailVerification.touched.password &&
              Boolean(formikEmailVerification.errors.password)
            }
            helperText={
              formikEmailVerification.touched.password &&
              formikEmailVerification.errors.password
            }
            startAdornment="lock"
            placeholder={t("placeholders.password")}
          />
          <InputField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label={t("reset-password.form.confirmPassword")}
            value={formikEmailVerification.values.confirmPassword}
            onChange={formikEmailVerification.handleChange}
            onBlur={formikEmailVerification.handleBlur}
            error={
              formikEmailVerification.touched.confirmPassword &&
              Boolean(formikEmailVerification.errors.confirmPassword)
            }
            helperText={
              formikEmailVerification.touched.confirmPassword &&
              formikEmailVerification.errors.confirmPassword
            }
            startAdornment="lock"
            placeholder={t("placeholders.password")}
          />
          {errorMessage && <FormError errorMessage={errorMessage} />}
          <PrimaryButton
            value={t("reset-password.buttons.reset")}
            size="large"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            type="submit"
            fullWidth
          />
        </form>
        <Box sx={sharedStyles.containers.signIn.link}>
          <Link to={Routes.signIn.url} style={sharedStyles.links.backToSignIn}>
            {t("signup.client.buttons.signin")}
          </Link>
        </Box>
      </Container>
    </FormContainer>
  );
};
