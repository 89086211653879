import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateExerciseParams,
  ExerciseItem,
  ExercisesListParams,
  UpdateExerciseParams,
  SubstituteExerciseParams,
  UploadExerciseMediaParams,
  MergeExerciseParams,
} from "./models";

export const exercisesApi = createApi({
  baseQuery,
  reducerPath: "exercisesApi",
  tagTypes: ["Exercises", "ExercisesCount"],
  endpoints: (builder) => ({
    addNewExercise: builder.mutation<void, CreateExerciseParams>({
      query: (params) => ({
        url: "/exercises",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["Exercises"],
    }),
    getSubstituteExercisesByExerciseId: builder.query<string[], number>({
      query: (id) => ({
        url: `/exercises/exercise-substitution/${id}`,
        method: "GET",
      }),
      providesTags: ["Exercises"],
    }),
    getExerciseById: builder.query<ExerciseItem, number>({
      query: (id) => ({
        url: `/exercises/${id}`,
        method: "GET",
      }),
      providesTags: ["Exercises"],
    }),
    getExercisesLibrary: builder.query<ExerciseItem[], ExercisesListParams>({
      query: (params) => ({
        url: `/exercises`,
        method: "GET",
        params,
      }),
      providesTags: ["Exercises"],
    }),
    getExerciseLibraryCount: builder.query<number, void>({
      query: () => ({
        url: `/exercises?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["ExercisesCount"],
    }),
    deleteExerciseById: builder.mutation<void, number>({
      query: (id) => ({
        url: `/exercises/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Exercises", "ExercisesCount"],
    }),
    updateExerciseById: builder.mutation<void, UpdateExerciseParams>({
      query: (params) => ({
        url: `/exercises/${params.id}`,
        method: "PUT",
        body: {
          name: params.name,
          description: params.description,
          videoUrl: params.videoUrl,
          imageUrl: "",
          primaryMuscleGroup: params.primaryMuscleGroup,
          muscleGroups: params.muscleGroups,
          exerciseType: params.exerciseType,
        },
      }),
      invalidatesTags: ["Exercises"],
    }),
    setAlternativeExercise: builder.mutation<
      ExerciseItem,
      SubstituteExerciseParams
    >({
      query: (params) => ({
        url: `/exercises/exercise-substitution`,
        method: "POST",
        body: {
          existingExerciseId: params.existingExerciseId,
          workoutItemId: params.workoutItemId,
          substitutionExerciseName: params.substitutionExerciseName,
        },
      }),
      invalidatesTags: ["Exercises"],
    }),
    uploadExerciseMedia: builder.mutation<void, UploadExerciseMediaParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append("file", params.uploadFile);

        return {
          url: "/exercises/upload",
          method: "POST",
          body: formData,
          params: {
            exerciseId: params.exerciseId,
            type: params.type,
          },
        };
      },
      invalidatesTags: ["Exercises"],
    }),
    mergeExercises: builder.mutation<string, MergeExerciseParams>({
      query: (body) => {
        return {
          url: `/exercises/merge`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Exercises", "ExercisesCount"],
    }),
  }),
});

export const {
  useGetExerciseByIdQuery,
  useAddNewExerciseMutation,
  useGetExerciseLibraryCountQuery,
  useGetExercisesLibraryQuery,
  useDeleteExerciseByIdMutation,
  useUpdateExerciseByIdMutation,
  useGetSubstituteExercisesByExerciseIdQuery,
  useSetAlternativeExerciseMutation,
  useUploadExerciseMediaMutation,
  useMergeExercisesMutation,
} = exercisesApi;
