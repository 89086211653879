import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { UserCategory } from "shared/api";

type UserCategoryRadioGroupProps = {
  value: string;
  setValue: (value: string) => void;
};

const UserCategoryRadioGroup: React.FC<UserCategoryRadioGroupProps> = ({
  value,
  setValue,
}) => {
  const [localValue, setLocalValue] = React.useState<string>(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
    setValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        height: "48px",
      }}
    >
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-user-category"
        name="row-radio-buttons-group-user-category"
        sx={{
          height: "48px",
        }}
        value={localValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value={UserCategory.Hybrid}
          control={<Radio />}
          label="Hybrid"
        />
        <FormControlLabel
          value={UserCategory.InPerson}
          control={<Radio />}
          label="InPerson"
        />
        <FormControlLabel
          value={UserCategory.Online}
          control={<Radio />}
          label="Online"
        />
        <FormControlLabel
          value={UserCategory.NonCoaching}
          control={<Radio />}
          label="Non Coaching"
        />
        <FormControlLabel
          value={UserCategory.All}
          control={<Radio />}
          label="All"
        />
      </RadioGroup>
    </FormControl>
  );
};

export { UserCategoryRadioGroup };
