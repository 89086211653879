/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUserProfileItemQuery } from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { useTypedSelector } from "shared/stores";
import { UserProfileSkeleton } from "./ui/skeletons/UserProfileSkeleton";
import { SuccessfulUpdateModal } from "./ui/modals/SuccessfulUpdateModal";
import { UpdateUserForm } from "./ui/UpdateUserForm";

interface UserProfileProps {
  closeSidebar: () => void;
}

const UserProfile: FC<UserProfileProps> = ({ closeSidebar }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const params = useParams();

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const toggleSuccessfulUpdateModal = () => {
    setSuccessfulUpdateModal((prev) => !prev);
  };

  if (isLoadingCurrentUserData) {
    return <UserProfileSkeleton />;
  }

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        overflowY: "scroll",
        position: "absolute",
        right: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: `${Colors.gray[200]}`,
      }}
    >
      {isLoadingCurrentUserData ? null : (
        <SuccessfulUpdateModal
          successfulUpdateModal={successfulUpdateModal}
          toggleModal={toggleSuccessfulUpdateModal}
        />
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "60px 20px 27px 24px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <IdenticonAvatar
              sizeValue={40}
              seedValue={currentUserData?.id.toString()}
              profileImageUrl={currentUserData?.profileImageUrl || ""}
              allowUpload
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              textTransform: "none",
              color: `${Colors.gray[900]}`,
              mb: "16px",
              mt: "26px",
            }}
          >
            {currentUserData?.firstName} {currentUserData?.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "10%",
            width: "100px",
            mb: "16px",
          }}
        >
          <GridStatusItem status={currentUserData?.userStatus} />
        </Box>
        <UpdateUserForm
          currentUserData={currentUserData}
          toggleSuccessfulUpdateModal={toggleSuccessfulUpdateModal}
        />
      </Box>
    </Box>
  );
};

export { UserProfile };
