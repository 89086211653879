import { FC, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { MoreInfoButton } from "./MoreInfoButton";

interface ProgramTemplateItemActionsProps {
  workoutProgramId?: number;
  onAssignUserClick: () => void;
  onArchiveClick: () => void;
}

const ProgramTemplateItemActions: FC<ProgramTemplateItemActionsProps> = ({
  workoutProgramId,
  onAssignUserClick,
  onArchiveClick,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignUser = () => {
    onAssignUserClick();
    handleClose();
  };

  const handleArchive = () => {
    onArchiveClick();
    handleClose();
  };

  return (
    <>
      <MoreInfoButton handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link
          style={{ textDecoration: "none" }}
          to={`${Routes.workoutProgramBuilder.url}/${workoutProgramId}?template=true`}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              border: "1px solid #EEEEEE",
              color: `${Colors.gray[1900]}`,
            }}
          >
            {t("programs-menu.templates.view")}
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleAssignUser}
          sx={{
            border: "1px solid #EEEEEE",
            color: `${Colors.gray[1900]}`,
          }}
        >
          {t("programs-menu.templates.assign")}
        </MenuItem>
        <MenuItem
          onClick={handleArchive}
          sx={{
            border: "1px solid #EEEEEE",
            color: `${Colors.gray[1900]}`,
          }}
        >
          {t("programs-menu.templates.archive")}
        </MenuItem>
      </Menu>
    </>
  );
};

export { ProgramTemplateItemActions };
