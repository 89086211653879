import { Container, Box, Typography } from "@mui/material";
import { FormError } from "components/Form/FormError";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { FormContainer } from "components/Form/FormContainer";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { LogoLink } from "components/Form/LogoLink";
import { useForgotPasswordMutation } from "shared/api";
import { Routes } from "shared/routers/Routes";

const ResetPasswordEmailInputSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

export const ResetPasswordEmailInput: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const formikEmailVerification = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ResetPasswordEmailInputSchema,
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        await forgotPassword(values).unwrap();
        navigate(Routes.forgotPasswordVerification.url);
      } catch (error) {
        setErrorMessage("Something went wrong. Please try again.");
      }
    },
  });

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <Box>
          <LogoLink />
          <Typography sx={sharedStyles.headings.signIn}>
            {t("reset-password.title")}
          </Typography>
          <Typography sx={sharedStyles.body.signIn}>
            {t("reset-password.description")}
          </Typography>
          <form onSubmit={formikEmailVerification.handleSubmit}>
            <InputField
              id="email"
              name="email"
              type="email"
              label={t("reset-password.form.email")}
              fullWidth
              value={formikEmailVerification.values.email}
              onChange={formikEmailVerification.handleChange}
              error={
                formikEmailVerification.touched.email &&
                Boolean(formikEmailVerification.errors.email)
              }
              helperText={
                formikEmailVerification.touched.email &&
                formikEmailVerification.errors.email
              }
              startAdornment="mail"
              placeholder={t("placeholders.email")}
            />
            {errorMessage && <FormError errorMessage={errorMessage} />}
            <Box sx={{ display: "flex", flexDirection: "row", mb: 2, mt: 2 }}>
              <PrimaryButton
                value={t("reset-password.buttons.reset")}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                loading={isLoading}
              />
            </Box>
          </form>
        </Box>
        <Box sx={sharedStyles.containers.signIn.link}>
          <Link to={Routes.signIn.url} style={sharedStyles.links.backToSignIn}>
            {t("signup.client.buttons.signin")}
          </Link>
        </Box>
      </Container>
    </FormContainer>
  );
};
