import { FC } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  ToggleButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Workflow } from "../../shared/api";
import { Colors } from "../../shared/themes/Colors";

interface WorkflowCardProps {
  workflow: Workflow;
}

const WorkflowCard: FC<WorkflowCardProps> = ({ workflow }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: "100%",
        height: "180px",
        mb: "16px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        boxShadow: "none",
        display: "flex",
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={workflow.imageUrl}
        alt={workflow.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "5px",
                  borderRadius: "16px",
                  bgcolor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.oxford[1100]}`,
                    }}
                  >
                    {workflow.name}
                    {workflow.workflowStatus !== "Building" && (
                      <ToggleButton
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          marginLeft: "8px",
                          padding: "0px",
                          "&.Mui-selected": {
                            backgroundColor: `${Colors.green[100]}`,
                            color: `${Colors.green[100]}`,
                          },
                        }}
                        value="check"
                        color="primary"
                        selected={workflow.workflowStatus === "Enabled"}
                      />
                    )}
                  </Typography>
                  {workflow.workflowStatus !== "Building" && (
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: "18px",
                        letterSpacing: "0.16px",
                        color: `${Colors.oxford[1100]}`,
                        mt: "12px",
                      }}
                    >
                      Runs every{" "}
                      {Math.round(workflow.workflowRunFrequencyMinutes / 60)}{" "}
                      hours after{" "}
                      {Math.round(
                        workflow.workflowTriggerConditionMinutes / 60
                      )}{" "}
                      hours has elapsed
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.oxford[1100]}`,
                      mt: "12px",
                    }}
                  >
                    {workflow.description}
                  </Typography>
                  {workflow.workflowStatus === "Building" && (
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: "18px",
                        letterSpacing: "0.16px",
                        color: `${Colors.oxford[1100]}`,
                        mt: "12px",
                      }}
                    >
                      The automation is currently building. You will receive an
                      email when it is ready.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export { WorkflowCard };
