import { FC } from "react";
import { Box } from "@mui/system";
import { Badge, Card, CardContent, Typography } from "@mui/material";
import { Colors } from "shared/themes";
import { Timeline } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import MailIcon from "@mui/icons-material/Mail";

interface TimelineCardProps {
  timeline: Timeline;
}

const TimelineCard: FC<TimelineCardProps> = ({ timeline }) => {
  return (
    <Link to={`${Routes.timeline.url}/${timeline.id}`}>
      <Card
        sx={{
          width: "100%",
          height: "94px",
          position: "relative",
          mb: "16px",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          boxShadow: "none",

          "&:hover, &:focus": {
            border: `2px solid ${Colors.gray[300]}`,
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <CardContent
          sx={{
            position: "absolute",
            top: "40%",
            width: "100%",
            height: "60%",
            padding: "0px 20px 0px 20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "5px",
                  borderRadius: "16px",
                  bgcolor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                <IdenticonAvatar
                  seedValue={timeline.userId.toString() ?? ""}
                  profileImageUrl={timeline.profileImageUrl}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      mb: 1,
                      color: `${Colors.oxford[1100]}`,
                    }}
                  >
                    {timeline.name}
                  </Typography>
                  {timeline.unreadCount > 0 && (
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: "18px",
                        letterSpacing: "0.16px",
                        color: `${Colors.oxford[1100]}`,
                      }}
                    >
                      <Badge
                        badgeContent={timeline.unreadCount}
                        color="warning"
                      >
                        <MailIcon color="action" />
                      </Badge>
                    </Typography>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export { TimelineCard };
