import { FC, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { WorkflowTemplateLibraryResponse } from "../../shared/api";
import { Colors } from "../../shared/themes/Colors";
import { PreviewWorkoutTemplateModal } from "./PreviewWorkoutTemplateModal";

interface WorkflowTemplateCardProps {
  workflowTemplate: WorkflowTemplateLibraryResponse;
  handleCardClick: (params: WorkflowTemplateLibraryResponse) => void;
  isSaving: boolean;
  successMessage: string;
  errorMessage: string;
}

const WorkflowTemplateCard: FC<WorkflowTemplateCardProps> = ({
  workflowTemplate,
  handleCardClick,
  isSaving,
  successMessage,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const [
    isPreviewWorkflowTemplateModalOpen,
    setIsPreviewWorkflowTemplateModalOpen,
  ] = useState(false);

  const handlePreviewWorkflowTemplateModalOpen = () => {
    setIsPreviewWorkflowTemplateModalOpen(true);
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "180px",
        mb: "16px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
        boxShadow: "none",
        display: "flex",
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={workflowTemplate.imageUrl}
        alt={workflowTemplate.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "5px",
                  borderRadius: "16px",
                  bgcolor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.oxford[1100]}`,
                    }}
                  >
                    {workflowTemplate.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.oxford[1100]}`,
                      mt: "12px",
                    }}
                  >
                    {workflowTemplate.description}
                  </Typography>
                  <Box
                    sx={{
                      gap: "8px",
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "0.4px",
                        textTransform: "none",
                      }}
                      onClick={handlePreviewWorkflowTemplateModalOpen}
                    >
                      {t("workflow-templates.preview-btn")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Box>
      <PreviewWorkoutTemplateModal
        isPreviewWorkflowTemplateModalOpen={isPreviewWorkflowTemplateModalOpen}
        handlePreviewWorkflowTemplateModalClose={() =>
          setIsPreviewWorkflowTemplateModalOpen(false)
        }
        workflowTemplate={workflowTemplate}
        handleCardClick={handleCardClick}
        isSaving={isSaving}
        successMessage={successMessage}
        errorMessage={errorMessage}
      />
    </Card>
  );
};

export { WorkflowTemplateCard };
