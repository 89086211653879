import { useMemo } from "react";
import { WorkoutSession } from "shared/api";
import { addTimezoneToUtcDate } from "shared/helpers";
import { CustomTextEditor } from "./CustomTextEditor";

const useGridColumns = (
  duration: number,
  exerciseNamesList: string[],
  currentWorkoutList: WorkoutSession[]
) => {
  const complexColumns = [
    {
      header: "Progression",
      name: "mergeColumn0",
      childNames: [
        "type",
        "unit",
        "base",
        "baseIntensity",
        "increase",
        "sets",
        "reps",
        "supersets",
        "rest",
        "notes",
      ],
    },
    {
      header: "Week 1",
      name: "mergeColumn1",
      childNames: [
        "week1intensity",
        "week1weight",
        "week1log",
        "week1reps",
        "week1sets",
      ],
    },
    {
      header: "Week 2",
      name: "mergeColumn2",
      childNames: [
        "week2intensity",
        "week2weight",
        "week2log",
        "week2reps",
        "week2sets",
      ],
    },
    {
      header: "Week 3",
      name: "mergeColumn3",
      childNames: [
        "week3intensity",
        "week3weight",
        "week3log",
        "week3reps",
        "week3sets",
      ],
    },
    {
      header: "Week 4",
      name: "mergeColumn4",
      childNames: [
        "week4intensity",
        "week4weight",
        "week4log",
        "week4reps",
        "week4sets",
      ],
    },
    {
      header: "Week 5",
      name: "mergeColumn5",
      childNames: [
        "week5intensity",
        "week5weight",
        "week5log",
        "week5reps",
        "week5sets",
      ],
    },
    {
      header: "Week 6",
      name: "mergeColumn6",
      childNames: [
        "week6intensity",
        "week6weight",
        "week6log",
        "week6reps",
        "week6sets",
      ],
    },
    {
      header: "Week 7",
      name: "mergeColumn7",
      childNames: [
        "week7intensity",
        "week7weight",
        "week7log",
        "week7reps",
        "week7sets",
      ],
    },
    {
      header: "Week 8",
      name: "mergeColumn8",
      childNames: [
        "week8intensity",
        "week8weight",
        "week8log",
        "week8reps",
        "week8sets",
      ],
    },
    {
      header: "Week 9",
      name: "mergeColumn9",
      childNames: [
        "week9intensity",
        "week9weight",
        "week9log",
        "week9reps",
        "week9sets",
      ],
    },
    {
      header: "Week 10",
      name: "mergeColumn10",
      childNames: [
        "week10intensity",
        "week10weight",
        "week10log",
        "week10reps",
        "week10sets",
      ],
    },
    {
      header: "Week 11",
      name: "mergeColumn11",
      childNames: [
        "week11intensity",
        "week11weight",
        "week11log",
        "week11reps",
        "week11sets",
      ],
    },
    {
      header: "Week 12",
      name: "mergeColumn12",
      childNames: [
        "week12intensity",
        "week12weight",
        "week12log",
        "week12reps",
        "week12sets",
      ],
    },
  ];

  const columns = [
    {
      header: "Exercise",
      name: "exercise",
      editor: {
        type: CustomTextEditor,
        options: {
          names: exerciseNamesList,
        },
      },
      width: 200,
    },
    {
      header: "Type",
      name: "type",
      editor: {
        type: "select",
        options: {
          listItems: [
            { text: "RPE", value: "RPE" },
            { text: "RIR", value: "RIR" },
            { text: "Load", value: "Load" },
            { text: "Percentage", value: "Percentage" },
            { text: "Time", value: "Time" },
            { text: "None", value: "" },
          ],
        },
      },
    },
    {
      header: "Unit",
      name: "unit",
      editor: {
        type: "select",
        options: {
          listItems: [
            { text: "Kg", value: "Kg" },
            { text: "Lbs", value: "Lbs" },
            { text: "None", value: "" },
          ],
        },
      },
    },
    {
      header: "Intensity",
      name: "baseIntensity",
      editor: "text",
    },
    {
      header: "Weight",
      name: "base",
      editor: "text",
    },
    {
      header: "Increase",
      name: "increase",
      editor: "text",
    },
    {
      header: "Sets",
      name: "sets",
      editor: "text",
    },
    {
      header: "Reps",
      name: "reps",
      editor: "text",
    },
    {
      header: "Superset",
      name: "supersets",
      editor: {
        type: "select",
        options: {
          listItems: [
            { text: "", value: "" },
            { text: "1", value: "1" },
            { text: "2", value: "2" },
            { text: "3", value: "3" },
            { text: "4", value: "4" },
            { text: "5", value: "5" },
            { text: "6", value: "6" },
            { text: "7", value: "7" },
            { text: "8", value: "8" },
            { text: "9", value: "9" },
            { text: "10", value: "10" },
          ],
        },
      },
    },
    {
      header: "Rest",
      name: "rest",
      editor: "text",
    },
    {
      header: "Notes",
      name: "notes",
      editor: "text",
      width: 500,
      hidden: true,
    },
    {
      header: "Intensity",
      name: "week1intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week1weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week1sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week1reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 1)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 1)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week1log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week2intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week2weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week2sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week2reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 2)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 2)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week2log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week3intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week3weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week3sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week3reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 3)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 3)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week3log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week4intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week4weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week4sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week4reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 4)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 4)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week4log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week5intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week5weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week5sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week5reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 5)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 5)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week5log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week6intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week6weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week6sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week6reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 6)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 6)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week6log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week7intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week7weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week7sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week7reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 7)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 7)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week7log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week8intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week8weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week8sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week8reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 8)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 8)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week8log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week9intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week9weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week9sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week9reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 9)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 9)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week9log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week10intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week10weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week10sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week10reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 10)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 10)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week10log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week11intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week11weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week11sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week11reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 11)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 11)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week11log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
    {
      header: "Intensity",
      name: "week12intensity",
      editor: "text",
      hidden: true,
    },
    {
      header: "Weight",
      name: "week12weight",
      editor: "text",
      hidden: true,
    },
    {
      header: "Sets",
      name: "week12sets",
      editor: "text",
      hidden: true,
    },
    {
      header: "Reps",
      name: "week12reps",
      editor: "text",
      hidden: true,
    },
    {
      header: `${
        currentWorkoutList.find((workout) => workout.weekNumber === 12)
          ?.dateTimeCompleted
          ? addTimezoneToUtcDate(
              currentWorkoutList.find((workout) => workout.weekNumber === 12)!
                .dateTimeCompleted
            ).format("DD/MM/YYYY hh:mm A")
          : "Log"
      }`,
      name: "week12log",
      editor: "text",
      hidden: true,
      disabled: true,
      width: 500,
    },
  ];

  // slice the rows to the duration
  const DEFAULT_HEADERS = 1;
  const DEFAULT_COLUMNS = 11;
  const NUMBER_OF_COLUMNS_PER_WEEK = 5;
  const slicedComplexColumns = complexColumns.slice(
    0,
    DEFAULT_HEADERS + duration
  );
  const slicedColumns = columns.slice(
    0,
    DEFAULT_COLUMNS + duration * NUMBER_OF_COLUMNS_PER_WEEK
  );

  return useMemo(
    () => ({
      complexColumns: slicedComplexColumns,
      columns: slicedColumns,
    }),
    [complexColumns, columns]
  );
};

export default useGridColumns;
