/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import { Colors } from "../../shared/themes";
import { steps } from "./constants";
import {
  ClientResponse,
  WorkoutProgramDesignerResponse,
} from "../../shared/api";
import { WorkoutProgramDetailsV2 } from "./WorkoutProgramDetailsV2";
import { WorkoutProgramGridContainer } from "./Grid/WorkoutProgramGridContainer";
import { WorkoutProgramBuilderTitle } from "./WorkoutProgramBuilderTitle";
import { WorkoutProgramBuilderStepper } from "./WorkoutProgramBuilderStepper";
import { WorkoutProgramBuilderValuesV2 } from "./models/WorkoutProgramBuilderModelsV2";
import { WorkoutProgramBuilderButtonGroup } from "./WorkoutProgramBuilderButtonGroup";
import { WorkoutProgramGridHeader } from "./Grid/WorkoutProgramGridHeader";
import { UnsavedGridChangesModal } from "./UnsavedGridChangesModal";

const WorkoutProgramBuilderBlankSlate: FC = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkoutProgram] = useState<WorkoutProgramDesignerResponse>();
  const [selectedUserValue, setSelectedUserValue] =
    useState<ClientResponse | null>(null);

  const [startDateValue, setStartDateValue] = useState<string>(
    dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]")
  );
  const [weeksInProgramValue, setWeeksInProgramValue] = useState<number>(2);
  const [programTitleValue, setProgramTitleValue] = useState<string>("");
  const [programNotesValue, setProgramNotesValue] = useState<string>("");
  const [daysInProgramValue, setDaysInProgramValue] = useState<number>(2);

  const queryString = window.location.search.slice(1).split("=");

  const [templateProgram, setTemplateProgram] = React.useState(false);

  useEffect(() => {
    if (queryString[0] === "template") {
      setTemplateProgram(true);
    } else {
      setTemplateProgram(false);
    }
  }, [queryString]);

  // eslint-disable-next-line
  let workoutProgramBuilderValues: WorkoutProgramBuilderValuesV2 = {
    name: "",
    startDate: "",
    weeksInProgram: null,
    daysPerWeek: null,
    description: "",
    workoutProgramStatus: "Draft",
    userId: null,
    userName: "",
    workoutProgramNotes: "",
  };

  const handleStep = (step: number) => () => {
    if (step === 0 && gridIsModified) {
      setShowUnsavedChangesModal(true);
      return;
    }
    setActiveStep(step);
  };

  const gridIsModified = useTypedSelector(
    (state) => state.workoutBuilder.isModified
  );
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);
  const dispatch = useTypedDispatch();

  const handleDiscardChangesOnGrid = () => {
    dispatch(setIsModified(false));
    setShowUnsavedChangesModal(false);
    setActiveStep(0);
  };

  useEffect(() => {
    function handleBeforeUnload(e: any) {
      if (gridIsModified) {
        e.returnValue = t("workout-program-builder.unsaved-changes");
        return e;
      }
      return e;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [gridIsModified]);

  const allowGridStep =
    !!selectedUserValue &&
    !!programTitleValue &&
    !!startDateValue &&
    !!weeksInProgramValue &&
    !!daysInProgramValue;

  const allowGridStepOnTemplate =
    !!programTitleValue &&
    !!startDateValue &&
    !!weeksInProgramValue &&
    !!daysInProgramValue;

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        height: "auto",
        bgcolor: `${Colors.gray[200]}`,
        position: "absolute",
        right: "0px",
        padding: "62px 32px 24px",
      }}
    >
      <UnsavedGridChangesModal
        isOpenModal={showUnsavedChangesModal}
        handleCloseModal={() =>
          setShowUnsavedChangesModal(!showUnsavedChangesModal)
        }
        onSubmit={() => {
          handleDiscardChangesOnGrid();
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "space-between",
          width: "100%",
          height: "58px",
          mt: "24px",
          mb: "24px",
        }}
      >
        <WorkoutProgramBuilderTitle
          selectedUserValue={selectedUserValue}
          isTemplate={templateProgram}
        />
      </Box>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          bgcolor: `${Colors.gray[100]}`,
          borderRadius: "8px",
          padding: "32px",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <WorkoutProgramBuilderStepper
            activeStep={activeStep}
            handleStep={handleStep}
            steps={steps}
            allowGridStep={
              templateProgram ? allowGridStepOnTemplate : allowGridStep
            }
          />
          <WorkoutProgramBuilderButtonGroup
            activeStep={activeStep}
            handleStep={handleStep}
            allowGridStep={
              templateProgram ? allowGridStepOnTemplate : allowGridStep
            }
          />
        </Box>

        {activeStep === 0 && (
          <WorkoutProgramDetailsV2
            templateProgram={templateProgram}
            workoutProgramBuilderValues={workoutProgramBuilderValues}
            selectedUserValue={selectedUserValue}
            setSelectedUserValue={setSelectedUserValue}
            startDateValue={startDateValue}
            setStartDateValue={setStartDateValue}
            weeksInProgramValue={weeksInProgramValue}
            setWeeksInProgramValue={setWeeksInProgramValue}
            programTitleValue={programTitleValue}
            setProgramTitleValue={setProgramTitleValue}
            programNotesValue={programNotesValue}
            setProgramNotesValue={setProgramNotesValue}
            daysInProgramValue={daysInProgramValue}
            setDaysInProgramValue={setDaysInProgramValue}
            selectedWorkoutProgram={selectedWorkoutProgram}
          />
        )}
        {activeStep === 1 && (
          <>
            <WorkoutProgramGridHeader selectedUserValue={selectedUserValue} />
            <WorkoutProgramGridContainer
              templateProgram={templateProgram}
              selectedWorkoutProgram={selectedWorkoutProgram}
              existingData={[]}
              numberOfWeeks={weeksInProgramValue}
              numberOfDays={daysInProgramValue}
              user={selectedUserValue}
              startDate={startDateValue}
              programTitle={programTitleValue}
              programNotes={programNotesValue}
              isLatestVersion
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export { WorkoutProgramBuilderBlankSlate };
