import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetExercisesLibraryQuery,
  useGetExerciseLibraryCountQuery,
} from "shared/api/exercises/exercises";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { ExerciseItem } from "shared/api";
import { MergeExerciseModal } from "components/Modals/MergeExerciseModal";
import { ExerciseLibraryItemAction } from "./ExerciseLibraryItemAction";

const ExerciseLibraryContent: FC = () => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { data: exercisesArrayLength } = useGetExerciseLibraryCountQuery();
  const [rowCountState, setRowCountState] = useState(exercisesArrayLength || 0);
  const [rows, setRows] = useState<ExerciseItem[]>([]);
  const [openMergeExerciseModal, setOpenMergeExerciseModal] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState<ExerciseItem | null>(
    null
  );

  const { data, isLoading } = useGetExercisesLibraryQuery({
    count: pageSize,
    page,
  });

  const { data: allExercisesData, isLoading: isLoadingAllItems } =
    useGetExercisesLibraryQuery({});

  const [searchValue, setSearchValue] = useState<string>("");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (!data) return;
    if (searchValue) {
      const filteredData = allExercisesData?.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.primaryMuscleGroup
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      });
      setRows(filteredData!);
      setPage(1);
      setPageSize(100);
      setRowCountState(filteredData?.length!);
    } else {
      setRows(data);
      setRowCountState((prevRowCountState) =>
        exercisesArrayLength !== undefined
          ? exercisesArrayLength
          : prevRowCountState
      );
    }
  }, [searchValue, data]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      exercisesArrayLength !== undefined
        ? exercisesArrayLength
        : prevRowCountState
    );
  }, [exercisesArrayLength, setRowCountState]);

  const handleCloseModal = () => {
    setOpenMergeExerciseModal(false);
    const currentSearchValue = searchValue;
    setSearchValue("");
    setSearchValue(currentSearchValue);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.25,
      sortable: false,
    },
    {
      field: "primaryMuscleGroup",
      headerName: "Muscle Group",
      minWidth: 50,
      flex: 1,
      sortable: false,
    },
    {
      field: "exerciseStatus",
      headerName: t("users-management.status"),
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return <GridStatusItem status={params.value} />;
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <ExerciseLibraryItemAction
            exerciseId={id}
            onMergeModalClick={() =>
              setOpenMergeExerciseModal(!openMergeExerciseModal)
            }
          />
        );
      },
    },
  ];

  return (
    <Box sx={sharedStyles.containers.dataGrid.wrapper}>
      <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
        <Typography sx={sharedStyles.headings.dataGrid}>
          {t("exercise-library.sub-title")}
        </Typography>
        <TextField
          label="Search by exercise..."
          variant="standard"
          value={searchValue}
          onChange={handleOnChange}
          disabled={isLoadingAllItems}
        />
      </Box>

      {!isLoading ? (
        <Box sx={{ height: "700px" }}>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            paginationMode="server"
            onRowClick={(params) => {
              setSelectedExercise(params.row);
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
          <MergeExerciseModal
            isOpenModal={openMergeExerciseModal}
            handleCloseModal={handleCloseModal}
            fromExercise={selectedExercise ?? null}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};

export { ExerciseLibraryContent };
