import { FC } from "react";
import { Colors } from "shared/themes";
import { TimelineItemType } from "shared/api";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";

interface TimelineViewItemIconProps {
  timelineItemType?: string;
  variant?: "primary" | "secondary" | "";
}

const TimelineViewItemIcon: FC<TimelineViewItemIconProps> = ({
  timelineItemType,
  variant = "",
}) => {
  const getColorForVariant = (variant: string) => {
    if (variant === "primary") return Colors.blue[1200];
    if (variant === "secondary") return Colors.gray[500];
    return "";
  };
  const styles = {
    color: getColorForVariant(variant),
  };

  if (timelineItemType === TimelineItemType.Message)
    return <OutlinedIcon iconName="3p" style={styles} />;

  if (timelineItemType === TimelineItemType.WorkoutProgramClientFeedback)
    return <OutlinedIcon iconName="flag" style={styles} />;

  return <OutlinedIcon iconName="workspace_premium" style={styles} />;
};

export { TimelineViewItemIcon };
