import { FC, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { MoreInfoButton } from "./MoreInfoButton";

interface ProgramItemActionsProps {
  userId?: number;
  workoutProgramId?: number;
  getUserIdForWorkoutCreation: (userId: number) => void;
  onChangeStatusClick: () => void;
  onCopyModalClick: () => void;
}

const ProgramItemActions: FC<ProgramItemActionsProps> = ({
  userId,
  workoutProgramId,
  getUserIdForWorkoutCreation,
  onChangeStatusClick,
  onCopyModalClick,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    getUserIdForWorkoutCreation(userId!);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    onChangeStatusClick();
    handleClose();
  };

  const handleCopyClick = () => {
    onCopyModalClick();
    handleClose();
  };

  return (
    <>
      <MoreInfoButton handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link
          style={{ textDecoration: "none" }}
          to={`${Routes.workoutProgramBuilder.url}/${workoutProgramId}`}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              border: "1px solid #EEEEEE",
              color: `${Colors.gray[1900]}`,
            }}
          >
            {t("programs-menu.view-program")}
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleChangeStatus}
          sx={{
            border: "1px solid #EEEEEE",
            color: `${Colors.gray[1900]}`,
          }}
        >
          {t("programs-menu.change-status")}
        </MenuItem>
        <MenuItem
          onClick={handleCopyClick}
          sx={{
            border: "1px solid #EEEEEE",
            color: `${Colors.gray[1900]}`,
          }}
        >
          {t("programs-menu.copy-program")}
        </MenuItem>
      </Menu>
    </>
  );
};

export { ProgramItemActions };
