import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompletedExerciseHistory,
  useGetCompletedHistoryByExerciseIdQuery,
} from "shared/api";
import { Alert, Box, Typography } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import {
  TrainingInsightsLineChart,
  XAxisType,
  YAxisType,
} from "./TrainingInsightsLineChart";
import { TrainingInsightsTableForReps } from "./TrainingInsightsTableForReps";
import { SeriesType } from "./constants";
import { SelectedExercise } from "./TrainingInsightsContent";

interface TrainingInsightsLineChartContainerForRepsProps {
  userId: number;
  exercise: SelectedExercise;
  isAthleteView?: boolean;
}

type CompletedDataItem = {
  completedReps?: number;
  completedPartialReps?: number;
  completedPartialRepsLeftSide?: number;
  completedPartialRepsRightSide?: number;
  completedRepsLeftSide?: number;
  completedRepsRightSide?: number;
};

const TrainingInsightsLineChartContainerForReps: FC<
  TrainingInsightsLineChartContainerForRepsProps
> = ({ userId, exercise, isAthleteView = false }) => {
  const { t } = useTranslation();

  const [xAxis, setXAxis] = useState<XAxisType>({ labels: [] });
  const [yAxisCompleted, setYAxisCompleted] = useState<YAxisType[]>([]);

  const {
    data: completedData,
    isLoading: isLoadingCompleted,
    error,
  } = useGetCompletedHistoryByExerciseIdQuery({
    userId: userId ?? 0,
    exerciseId: exercise.exerciseId ?? 0,
  });

  useEffect(() => {
    if (completedData) {
      setXAxis({
        labels: completedData.map(
          (a) => `${a.workoutProgram} - Week ${a.weekNumber}`
        ),
      });

      const yAxisData: YAxisType[] = [];

      const addYAxisData = (
        property: keyof CompletedDataItem,
        label: string
      ) => {
        if (completedData.some((a) => a[property])) {
          const isRIRStrategy = completedData.some((a) =>
            a[property]?.toString().includes("-")
          );
          const data = completedData.map((a) => {
            if (isRIRStrategy) {
              const maxCompletedReps = a[property]
                .replace(/[^0-9-.]/g, "")
                .split("-")
                .map((a) => (a === "" ? "0" : a))
                .map(Number);
              return (
                maxCompletedReps.reduce((a: number, b: number) => a + b, 0) /
                maxCompletedReps.length
              );
            }

            return Number(a[property].replace(/[^0-9-.]/g, "")) ?? 0;
          });

          yAxisData.push({
            values: data,
            name: label,
          });
        }
      };

      if (completedData.some((a) => a.completedReps)) {
        addYAxisData("completedReps", SeriesType.CompletedReps);
      }
      if (completedData.some((a) => a.completedPartialReps)) {
        addYAxisData("completedPartialReps", SeriesType.CompletedPartialReps);
      }
      if (completedData.some((a) => a.completedPartialRepsLeftSide)) {
        addYAxisData(
          "completedPartialRepsLeftSide",
          SeriesType.CompletedPartialRepsLeftSide
        );
      }
      if (completedData.some((a) => a.completedPartialRepsRightSide)) {
        addYAxisData(
          "completedPartialRepsRightSide",
          SeriesType.CompletedPartialRepsRightSide
        );
      }
      if (completedData.some((a) => a.completedRepsLeftSide)) {
        addYAxisData("completedRepsLeftSide", SeriesType.CompletedRepsLeftSide);
      }
      if (completedData.some((a) => a.completedRepsRightSide)) {
        addYAxisData(
          "completedRepsRightSide",
          SeriesType.CompletedRepsRightSide
        );
      }
      setYAxisCompleted(yAxisData);
    }
  }, [completedData]);

  const displayGraph =
    !isLoadingCompleted &&
    xAxis?.labels.length > 0 &&
    yAxisCompleted.length > 0;

  return (
    <>
      <Alert
        variant="outlined"
        severity="info"
        sx={{ mb: "10px", alignItems: "center" }}
      >
        <Typography sx={sharedStyles.body.timeline.cardBody}>
          {t("training-insights.disclaimer")}
        </Typography>
      </Alert>
      {error && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ mb: "10px", alignItems: "center" }}
        >
          <Typography sx={sharedStyles.body.timeline.cardBody}>
            {t("errors.server-unable")}
          </Typography>
        </Alert>
      )}
      {displayGraph &&
        !error &&
        (!isAthleteView ? (
          <Box
            sx={{
              gap: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                maxHeight: "65vh",
              }}
            >
              <TrainingInsightsLineChart
                title={`${exercise.exerciseName ?? ""} - Volume`}
                xAxis={xAxis}
                yAxisSeries={yAxisCompleted}
                useZeroMinimum
              />
            </Box>
            <TrainingInsightsTableForReps completedData={completedData ?? []} />
          </Box>
        ) : (
          <TrainingInsightsLineChart
            title={`${exercise.exerciseName ?? ""} - Volume`}
            xAxis={xAxis}
            yAxisSeries={yAxisCompleted}
            useZeroMinimum
            isAthleteView
          />
        ))}
    </>
  );
};

export { TrainingInsightsLineChartContainerForReps };
