export const emptyGrid = {
  dayNumber: null,
  rows: [
    {
      workoutItemId: 0,
      exercise: "",
      type: "",
      unit: "Kg",
      base: "",
      baseIntensity: "",
      sets: "",
      reps: "",
      supersets: "",
      rest: "",
      increase: "",
      notes: "",
      _attributes: {
        checkDisabled: false,
        checked: false,
        className: {
          row: [],
          column: {},
        },
        disabled: false,
        rowNum: 1,
        rowSpan: undefined,
      },
    },
    {
      workoutItemId: 0,
      exercise: "",
      type: "",
      unit: "Kg",
      base: "",
      baseIntensity: "",
      sets: "",
      reps: "",
      supersets: "",
      rest: "",
      increase: "",
      notes: "",
      _attributes: {
        checkDisabled: false,
        checked: false,
        className: {
          row: [],
          column: {},
        },
        disabled: false,
        rowNum: 2,
        rowSpan: undefined,
      },
    },
    {
      workoutItemId: 0,
      exercise: "",
      type: "",
      unit: "Kg",
      base: "",
      baseIntensity: "",
      sets: "",
      reps: "",
      supersets: "",
      rest: "",
      increase: "",
      notes: "",
      _attributes: {
        checkDisabled: false,
        checked: false,
        className: {
          row: [],
          column: {},
        },
        disabled: false,
        rowNum: 3,
        rowSpan: undefined,
      },
    },
    {
      workoutItemId: 0,
      exercise: "",
      type: "",
      unit: "Kg",
      base: "",
      baseIntensity: "",
      sets: "",
      reps: "",
      supersets: "",
      rest: "",
      increase: "",
      notes: "",
      _attributes: {
        checkDisabled: false,
        checked: false,
        className: {
          row: [],
          column: {},
        },
        disabled: false,
        rowNum: 4,
        rowSpan: undefined,
      },
    },
    {
      workoutItemId: 0,
      exercise: "",
      type: "",
      unit: "Kg",
      base: "",
      baseIntensity: "",
      sets: "",
      reps: "",
      supersets: "",
      rest: "",
      increase: "",
      notes: "",
      _attributes: {
        checkDisabled: false,
        checked: false,
        className: {
          row: [],
          column: {},
        },
        disabled: false,
        rowNum: 5,
        rowSpan: undefined,
      },
    },
  ],
};

export const DIRECTION = "rtl";

export const steps = ["create-program.step-1", "create-program.step-2"];

export const MAXIMUM_WEEKS_IN_PROGRAM = 12;
export const MINIMUM_WEEKS_IN_PROGRAM = 1;
