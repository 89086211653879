import { Button, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../shared/themes";

interface InvoiceOverdueModalProps {
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
}

const InvoiceOverdueModal: FC<InvoiceOverdueModalProps> = ({
  isShow,
  setIsShow,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={isShow}
      anchor="bottom"
      sx={{
        borderRadius: "16px 16px 0px 0px",
      }}
    >
      <Box
        sx={{
          width: "auto",
          height: "30vh",
          background: `${Colors.gray[100]}`,
          borderRadius: "4px",
          padding: "16px 24px 16px 24px",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            color: `${Colors.oxford[1100]}`,
            textTransform: "none",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          {t("athlete-dashboard.invoice-overdue")}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: `${Colors.oxford[1100]}`,
            textAlign: "center",
          }}
        >
          {t("athlete-dashboard.invoice-overdue-description")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            paddingTop: "14px",
          }}
        >
          <Button
            onClick={() => {
              setIsShow(false);
            }}
            variant="outlined"
            color="primary"
            sx={{
              width: "45%",
              height: "42px",
              fontWeight: "500",
              fontSize: "16px",
              fontFamily: "Inter",
              color: `${Colors.blue[1200]}`,
              textTransform: "none",
            }}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export { InvoiceOverdueModal };
