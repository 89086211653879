import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { AnyType } from "../helpers";
import { RootState } from "../stores/rootReducer";
import { clearUser, setUser } from "../stores/user";

const mutex = new Mutex();

const initialQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }: AnyType) => {
    const token = (getState() as RootState).user.jwtToken;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await initialQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken: AnyType = await initialQuery(
          {
            url: "/users/refresh-token",
            method: "POST",
            body: {
              refreshToken: (api.getState() as RootState).user.refreshToken,
            },
          },
          api,
          extraOptions
        );

        if (refreshToken?.data) {
          api.dispatch(setUser(refreshToken?.data));

          result = await initialQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearUser());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await initialQuery(args, api, extraOptions);
    }
  }

  return result;
};
