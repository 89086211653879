import { Container, TextField, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { FormContainer } from "components/Form/FormContainer";
import { LogoLink } from "components/Form/LogoLink";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { FormError } from "components/Form/FormError";
import { useVerifyVerificationCodeMutation } from "shared/api";
import { Routes } from "shared/routers/Routes";

const FitnessProfessionalSignupEmailVerificationSchema = Yup.object().shape({
  token: Yup.string().required("Verification code is required"),
});

export const FitnessProfessionalSignupEmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [verifyVerificationCode, { isLoading }] =
    useVerifyVerificationCodeMutation();

  const formikEmailVerification = useFormik({
    initialValues: {
      token: "",
      email: "",
    },
    validationSchema: FitnessProfessionalSignupEmailVerificationSchema,
    onSubmit: async (values) => {
      try {
        setErrorMessage("");
        await verifyVerificationCode(values).unwrap();
        navigate(Routes.fitnessProfessionalSignupSuccess.url);
      } catch (error) {
        setErrorMessage(
          "Something went wrong. Please check your email and verification code and try again."
        );
      }
    },
  });

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    const email = new URLSearchParams(window.location.search).get("email");
    if (email) {
      formikEmailVerification.setFieldValue("email", email ?? "");
    }
    if (token) formikEmailVerification.setFieldValue("token", token ?? "");
  }, []);

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <LogoLink />
        <Typography sx={sharedStyles.headings.signIn}>
          {t("signup.fitness-professionals.emailVerificationTitle")}
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          {t("signup.fitness-professionals.emailVerificationDescription")}
        </Typography>
        <form onSubmit={formikEmailVerification.handleSubmit}>
          <TextField
            id="email"
            name="email"
            label={t("signup.fitness-professionals.form.email")}
            fullWidth
            type="email"
            value={formikEmailVerification.values.email}
            onChange={formikEmailVerification.handleChange}
            error={
              formikEmailVerification.touched.email &&
              Boolean(formikEmailVerification.errors.email)
            }
            helperText={
              formikEmailVerification.touched.email &&
              formikEmailVerification.errors.email
            }
            sx={{
              mb: 2,
            }}
          />
          <TextField
            id="token"
            name="token"
            label={t("signup.fitness-professionals.form.verificationCode")}
            fullWidth
            value={formikEmailVerification.values.token}
            onChange={formikEmailVerification.handleChange}
            error={
              formikEmailVerification.touched.token &&
              Boolean(formikEmailVerification.errors.token)
            }
            helperText={
              formikEmailVerification.touched.token &&
              formikEmailVerification.errors.token
            }
          />
          {errorMessage && <FormError errorMessage={errorMessage} />}
          <PrimaryButton
            value={t("signup.fitness-professionals.buttons.verify")}
            size="large"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            type="submit"
            fullWidth
          />
        </form>
        <Box sx={sharedStyles.containers.signIn.link}>
          <Link to={Routes.signIn.url} style={sharedStyles.links.backToSignIn}>
            {t("signup.fitness-professionals.buttons.signin")}
          </Link>
        </Box>
      </Container>
    </FormContainer>
  );
};
