import { FC } from "react";
import { Icon, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { Colors } from "../../../themes";

interface WhatsNewListItemProps {
  icon: string;
  title: string;
  className?: string;
}

const WhatsNewListItem: FC<WhatsNewListItemProps> = ({
  icon,
  title,
  className = "",
}) => {
  return (
    <Link style={{ textDecoration: "none" }} to="/" className={className}>
      <ListItem
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "54px",
          padding: 0,
          bgcolor: `${Colors.gray[50]}`,
        }}
      >
        <ListItemButton>
          <Box
            sx={{
              marginRight: "16px",
              color: `${Colors.blue[1200]}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "24px",
              height: "24px",
            }}
          >
            <Icon sx={{ color: `${Colors.blue[1200]}` }} fontSize="small">
              {icon}
            </Icon>
          </Box>

          <ListItemText
            primary={title}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "140%",
              color: `${Colors.gray[900]}`,
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
export { WhatsNewListItem };
