import React, { FC } from "react";
import { Step, StepButton, Stepper, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

interface WorkoutProgramBuilderStepperProps {
  activeStep: number;
  steps: string[];
  handleStep: (step: number) => () => void;
  allowGridStep: boolean;
}

const WorkoutProgramBuilderStepper: FC<WorkoutProgramBuilderStepperProps> = ({
  activeStep,
  steps,
  handleStep,
  allowGridStep = false,
}) => {
  const matches = useMediaQuery("(min-width:900px)");
  const { t } = useTranslation();

  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      sx={{ width: matches ? "50%" : "80%" }}
    >
      <Step>
        <StepButton onClick={handleStep(0)}>{t(steps[0])}</StepButton>
      </Step>
      <Step>
        <StepButton
          onClick={handleStep(1)}
          sx={{
            cursor: allowGridStep ? "pointer" : "not-allowed",
            pointerEvents: allowGridStep ? "all" : "none",
          }}
        >
          {t(steps[1])}
        </StepButton>
      </Step>
    </Stepper>
  );
};

export { WorkoutProgramBuilderStepper };
