import React from "react";
import Image from "mui-image";
import { Images } from "shared/themes";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers/Routes";

export const LogoLink: React.FC = () => {
  return (
    <Link to={Routes.signIn.url}>
      <Image
        src={Images.Logo}
        duration={250}
        fit="contain"
        height="80px"
        width="150px"
      />
    </Link>
  );
};
