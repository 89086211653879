import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC } from "react";
import { Colors } from "shared/themes";

type WorkoutPreviousWeekDataProps = {
  previousWeeksData?: string[];
  previousWeeksNotes?: string[];
};

const WorkoutPreviousWeekData: FC<WorkoutPreviousWeekDataProps> = ({
  previousWeeksData,
  previousWeeksNotes = [],
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
      }}
    >
      <Accordion
        sx={{
          width: "100%",
          background: "#F5F5F5",
        }}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "bold",
              color: `${Colors.oxford[1100]}`,
            }}
          >
            Previous Session
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {previousWeeksData && previousWeeksData?.length > 0
              ? previousWeeksData?.map((item, key) => (
                  <Box key={item}>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: `${Colors.oxford[1100]}`,
                      }}
                    >
                      {item}
                      {key !== previousWeeksData.length - 1 ? "" : null}
                    </Typography>
                  </Box>
                ))
              : ""}
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography>
            {previousWeeksNotes && previousWeeksNotes?.length > 0
              ? previousWeeksNotes?.map((item, key) => (
                  <Box key={item}>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: `${Colors.oxford[1100]}`,
                      }}
                    >
                      {item}
                      {key !== previousWeeksNotes.length - 1 ? "" : null}
                    </Typography>
                  </Box>
                ))
              : ""}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { WorkoutPreviousWeekData };
