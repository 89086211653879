import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../../themes";

interface AdminListItemProps {
  title: string;
  content: string;
  icon: any;
}

const AdminListItem: FC<AdminListItemProps> = ({ content, title, icon }) => {
  return (
    <Accordion
      sx={{
        padding: 0,
        margin: 0,
        bgcolor: `${Colors.gray[50]}`,
        boxShadow: "none",
        borderBottom: "none",
        marginBottom: "8px",
        position: "static",
      }}
    >
      <AccordionSummary
        expandIcon={<Icon fontSize="small">expand_more</Icon>}
        sx={{
          width: "172px",
          minHeight: "20px",
          maxHeight: "20px",
          padding: 0,
          margin: 0,
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ color: `${Colors.gray[900]}` }}
            fontSize="small"
          >
            {icon}
          </Icon>
        </Box>

        <Typography
          sx={{
            m: 0,
            ml: "10px",
            color: `${Colors.gray[900]}`,
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "140%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          mt: "-10px",
          color: `${Colors.gray[900]}`,
        }}
      >
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export { AdminListItem };
