import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Colors } from "../../shared/themes";
import {
  useInviteUserMutation,
  InviteUserParams,
  Organisation,
} from "../../shared/api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "../../shared/helpers";

interface OrganisationNewUserModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  currentOrganisationData: Organisation | undefined;
}

const OrganisationNewUserModalSchema = Yup.object().shape({
  firstName: Yup.string().required(
    "invite-modal-validations.firstName-required"
  ),
  lastName: Yup.string().required("invite-modal-validations.lastName-required"),
  email: Yup.string()
    .email("invite-modal-validations.email-incorrect")
    .required("invite-modal-validations.email-required"),
});

const OrganisationNewUserModal: FC<OrganisationNewUserModalProps> = ({
  isOpenModal,
  handleCloseModal,
  currentOrganisationData,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [inviteUser, { isLoading }] = useInviteUserMutation();

  const onSubmit = async (params: InviteUserParams) => {
    setErrorMessage("");

    try {
      await inviteUser(params).unwrap();

      setSuccessMessage(t("organisation-management.invite-modal.success"));
    } catch (error: AnyType) {
      setErrorMessage(t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        userRole: "Undefined",
        liftingProfileType: "Undefined",
        organisationId: currentOrganisationData?.id || 0,
      },
      validationSchema: OrganisationNewUserModalSchema,
      onSubmit: (params) => {
        onSubmit(params);
      },
    });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "400px",
            height: "auto",
            background: `${Colors.gray[100]}`,
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("organisation-management.invite-modal.title")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {t("organisation-management.invite-modal.content")}
          </Typography>
          <TextField
            sx={{ width: "100%", height: "56px", mb: "24px" }}
            type="text"
            value={values.firstName}
            label="First Name"
            onBlur={handleBlur("firstName")}
            onChange={handleChange("firstName")}
            error={!!errors.firstName}
            helperText={errors.firstName ? t(errors.firstName) : ""}
          />
          <TextField
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            type="text"
            value={values.lastName}
            label="Last Name"
            onBlur={handleBlur("lastName")}
            onChange={handleChange("lastName")}
            error={!!errors.lastName}
            helperText={errors.lastName ? t(errors.lastName) : ""}
          />
          <TextField
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            type="email"
            value={values.email}
            label="Email"
            onBlur={handleBlur("email")}
            onChange={handleChange("email")}
            error={!!errors.email}
            helperText={errors.email ? t(errors.email) : ""}
          />
          <Typography sx={{ width: "100%", pl: "10px" }}>
            {t("user-profile.user-role")}
          </Typography>
          <Select
            sx={{ width: "100%", height: "56px", mb: "24px", mt: "10px" }}
            type="text"
            value={values.userRole}
            label="User Role"
            onBlur={handleBlur("userRole")}
            onChange={(_e) => {
              handleChange("userRole")(_e.target.value);
            }}
            error={!!errors.userRole}
          >
            <MenuItem value="Undefined">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Coach">Coach</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
          <LoadingButton
            sx={{
              width: "100%",
              height: "36px",
              background: `${Colors.blue[1200]}`,
              mt: "5px",
            }}
            size="large"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {t("organisation-management.invite-modal.btnText")}
            </Typography>
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { OrganisationNewUserModal };
