import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { OutstandingClientInvoiceResponse } from "../../../../shared/api";
import { Colors } from "../../../../shared/themes";
import { Routes } from "../../../../shared/routers";
import { IdenticonAvatar } from "../../../../shared/ui/IdenticonAvatar/IdenticonAvatar";

interface OutstandingClientInvoiceItemProps {
  item: OutstandingClientInvoiceResponse;
}

const OutstandingClientInvoiceItem: FC<OutstandingClientInvoiceItemProps> = ({
  item,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", height: "48px", mb: "8px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          mb: "10px",
        }}
      >
        <Box
          sx={{
            width: "75%",
            display: "flex",
            alignItem: "center",
            padding: "0px 16px 0px 16px",
          }}
        >
          <IdenticonAvatar
            seedValue={item.clientUserId.toString()}
            profileImageUrl={item.profileImageUrl}
          />
          <Typography
            sx={{
              mt: "14px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {item.clientName}:{" "}
            {dayjs(item.invoiceDueDateTime).format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "25%",
            height: "100%",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            sx={{
              mt: "10px",
              textTransform: "none",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "22px",
              letterSpacing: " 0.46px",
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to={`${Routes.profile.url}/${item.clientUserId}`}
            >
              {t("overdue-invoices.profile")}
            </Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { OutstandingClientInvoiceItem };
