import { FC, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { MoreInfoButton } from "pages/Programs/AdminPrograms/ui/MoreInfoButton";

interface ExerciseLibraryItemActionProps {
  exerciseId: number;
  onMergeModalClick: () => void;
}

const ExerciseLibraryItemAction: FC<ExerciseLibraryItemActionProps> = ({
  exerciseId,
  onMergeModalClick,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMergeModalClick = () => {
    onMergeModalClick();
    handleClose();
  };

  return (
    <>
      <MoreInfoButton handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid #EEEEEE",
          },
          "& .MuiList-root": { padding: "0px" },
        }}
      >
        <Link to={`${Routes.exercise.url}/${exerciseId}`}>
          <MenuItem
            onClick={handleClose}
            sx={{
              border: "1px solid #EEEEEE",
              color: `${Colors.gray[1900]}`,
            }}
          >
            {t("users-management.edit-btn")}
          </MenuItem>
        </Link>
        <MenuItem
          onClick={handleMergeModalClick}
          sx={{
            border: "1px solid #EEEEEE",
            color: `${Colors.gray[1900]}`,
          }}
        >
          {t("users-management.merge-exercise")}
        </MenuItem>
      </Menu>
    </>
  );
};

export { ExerciseLibraryItemAction };
