import { FC } from "react";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "shared/themes";

const TimelineSkeleton: FC = () => {
  return (
    <Box
      sx={{
        padding: "9px 0px 23px",
      }}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="100px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
          mb: 3,
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="100px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
          mb: 3,
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="100px"
        sx={{
          bgcolor: `${Colors.gray[500]}`,
        }}
      />
    </Box>
  );
};

export { TimelineSkeleton };
