import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { CreateExerciseParams, useAddNewExerciseMutation } from "shared/api";
import { SelectInputField } from "components/Form/SelectInputField";
import { Colors } from "shared/themes";

interface CreateExerciseModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
}

const PRIMARY_MUSCLE_GROUPS = [
  { label: "", value: "Undefined" },
  { label: "Anterior Chain", value: "AnteriorChain" },
  { label: "Arms", value: "Arms" },
  { label: "Back", value: "Back" },
  { label: "Biceps", value: "Biceps" },
  { label: "Calves", value: "Calves" },
  { label: "Chest", value: "Chest" },
  { label: "Compound Movement", value: "CompoundMovement" },
  { label: "Core", value: "Core" },
  { label: "Forearms", value: "Forearms" },
  { label: "Glutes", value: "Glutes" },
  { label: "Hamstrings", value: "Hamstrings" },
  { label: "Legs", value: "Legs" },
  { label: "Posterior Chain", value: "PosteriorChain" },
  { label: "Quads", value: "Quads" },
  { label: "Shoulders", value: "Shoulders" },
  { label: "Triceps", value: "Triceps" },
];

const EXERCISE_TYPES = [
  { label: "", value: "Undefined" },
  { label: "Cardio", value: "Cardio" },
  { label: "CoolDown", value: "CoolDown" },
  { label: "Strength", value: "Strength" },
  { label: "Stretching", value: "Stretching" },
  { label: "WarmUp", value: "WarmUp" },
];

const CreateExerciseSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string(),
  imageUrl: Yup.string(),
  videoUrl: Yup.string(),
  primaryMuscleGroup: Yup.string().required(),
  exerciseType: Yup.string(),
});

const CreateExerciseModal: FC<CreateExerciseModalProps> = ({
  isOpenModal,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [createExercise, { isLoading }] = useAddNewExerciseMutation();

  const onSubmit = async (params: CreateExerciseParams) => {
    setErrorMessage("");

    try {
      await createExercise(params).unwrap();
      setSuccessMessage(t("exercise-library-modal.exercise-success-added"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      imageUrl: "",
      videoUrl: "",
      primaryMuscleGroup: "",
      muscleGroups: "",
      exerciseType: "Undefined",
    },
    validationSchema: CreateExerciseSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("exercise-library-modal.create-heading")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          <InputField
            type="text"
            value={values.name}
            label="Name"
            onBlur={handleBlur("name")}
            onChange={handleChange("name")}
            error={!!errors.name}
            helperText={errors.name ? t(errors.name) : ""}
          />
          <InputField
            type="text"
            value={values.description}
            label="Description"
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
            error={!!errors.description}
            helperText={errors.description ? t(errors.description) : ""}
          />
          <SelectInputField
            label="Primary Muscle Group"
            data={PRIMARY_MUSCLE_GROUPS}
            value={values.primaryMuscleGroup}
            defaultValue={values.primaryMuscleGroup}
            onBlur={handleBlur("primaryMuscleGroup")}
            onChange={(e) =>
              setFieldValue("primaryMuscleGroup", e.target.value)
            }
            error={!!errors.primaryMuscleGroup}
          />
          <SelectInputField
            label="Type"
            data={EXERCISE_TYPES}
            value={values.exerciseType}
            defaultValue={values.exerciseType}
            onBlur={handleBlur("exerciseType")}
            onChange={(e) => setFieldValue("exerciseType", e.target.value)}
            error={!!errors.exerciseType}
          />
          <InputField
            type="text"
            value={values.videoUrl}
            label="Video URL"
            onBlur={handleBlur("videoUrl")}
            onChange={handleChange("videoUrl")}
            error={!!errors.videoUrl}
            helperText={errors.videoUrl ? t(errors.videoUrl) : ""}
          />
          <PrimaryButton
            size="large"
            type="submit"
            value={t("exercise-library-modal.btnText")}
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          />

          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateExerciseModal };
