import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { CompletedExerciseHistory } from "shared/api";

interface TrainingInsightsTableForWeightProps {
  completedData: CompletedExerciseHistory[];
}

const TrainingInsightsTableForWeight: FC<
  TrainingInsightsTableForWeightProps
> = ({ completedData }) => {
  const combinedRows = completedData.map((item, index) => {
    const {
      workoutProgram,
      weekNumber,
      maxCompletedWeight,
      maxCompletedWeightLeftSide,
      maxCompletedWeightRightSide,
      maxPrescribedWeight,
    } = item;

    return {
      workoutProgramNameAndWeek: `${workoutProgram} - Week ${weekNumber}`,
      completed: maxCompletedWeight,
      completedLeftSide: maxCompletedWeightLeftSide,
      completedRightSide: maxCompletedWeightRightSide,
      prescribed: maxPrescribedWeight,
    };
  });

  const containsCompleted = combinedRows.some((a) => a.completed);
  const containsCompletedLeftSide = combinedRows.some(
    (a) => a.completedLeftSide
  );
  const containsCompletedRightSide = combinedRows.some(
    (a) => a.completedRightSide
  );
  const containsPrescribed = combinedRows.some((a) => a.prescribed);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Program</TableCell>
            {containsPrescribed && (
              <TableCell>Maximum Prescribed Weight</TableCell>
            )}
            {containsCompleted && <TableCell>Best Weight Completed</TableCell>}
            {containsCompletedLeftSide && (
              <TableCell>Best Weight Completed (Left)</TableCell>
            )}
            {containsCompletedRightSide && (
              <TableCell>Best Weight Completed (Right)</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedRows.map((row) => (
            <TableRow
              key={`${row.workoutProgramNameAndWeek}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.workoutProgramNameAndWeek}</TableCell>
              {containsPrescribed && <TableCell>{row.prescribed}</TableCell>}
              {containsCompleted && <TableCell>{row.completed}</TableCell>}
              {containsCompletedLeftSide && (
                <TableCell>{row.completedLeftSide}</TableCell>
              )}
              {containsCompletedRightSide && (
                <TableCell>{row.completedRightSide}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TrainingInsightsTableForWeight };
