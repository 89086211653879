import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ClientResponse } from "shared/api";
import { Typography } from "@mui/material";
import { Colors } from "shared/themes";

interface WorkoutProgramBuilderTitleProps {
  selectedUserValue: ClientResponse | null;
  isTemplate: boolean;
}

const WorkoutProgramBuilderTitle: FC<WorkoutProgramBuilderTitleProps> = ({
  selectedUserValue,
  isTemplate = false,
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "140%",
        color: `${Colors.gray[900]}`,
        textTransform: "none",
      }}
    >
      {isTemplate
        ? t("workout-program-builder.template-title")
        : t("workout-program-builder.title")}
      {selectedUserValue?.firstName && selectedUserValue?.lastName && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "140%",
            color: `${Colors.gray[900]}`,
            textTransform: "none",
          }}
        >
          {`for ${selectedUserValue?.firstName} ${selectedUserValue?.lastName} : ${selectedUserValue.email}`}
        </Typography>
      )}
    </Typography>
  );
};

export { WorkoutProgramBuilderTitle };
