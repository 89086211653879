import { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { sharedStyles } from "shared/themes/shared/styles";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  CreateNutritionPlanDto,
  useCreateNutritionPlanMutation,
  NutritionPlan,
} from "shared/api";
import { Colors } from "shared/themes";

interface NutritionMacroInputModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  preselectedUserId: number | null;
  calculateCalories: (protein: number, carbs: number, fat: number) => number;
  latestNutritionPlan: NutritionPlan | undefined;
}

const CreateNutritionTargets = Yup.object().shape({
  proteinTarget: Yup.number().required("nutrition.protein-required"),
  carbohydrateTarget: Yup.number().required("nutrition.carbs-required"),
  fatTarget: Yup.number().required("nutrition.fat-required"),
  calorieTarget: Yup.number().required("nutrition.calories-required"),
  weeksInPlan: Yup.number().required("nutrition.weeks-required"),
  description: Yup.string(),
});

const NutritionMacroInputModal: FC<NutritionMacroInputModalProps> = ({
  isOpenModal,
  handleCloseModal,
  preselectedUserId,
  calculateCalories,
  latestNutritionPlan,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [createNutritionTargets, { isLoading }] =
    useCreateNutritionPlanMutation();

  const onSubmit = async (params: CreateNutritionPlanDto) => {
    setErrorMessage("");

    try {
      await createNutritionTargets(params).unwrap();
      setSuccessMessage(t("nutrition.success-added"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      proteinTarget: latestNutritionPlan
        ? latestNutritionPlan.nutritionPlanItems[0].proteinTarget
        : 0,
      carbohydrateTarget: latestNutritionPlan
        ? latestNutritionPlan.nutritionPlanItems[0].carbohydrateTarget
        : 0,
      fatTarget: latestNutritionPlan
        ? latestNutritionPlan.nutritionPlanItems[0].fatTarget
        : 0,
      calorieTarget: latestNutritionPlan
        ? latestNutritionPlan.nutritionPlanItems[0].calorieTarget
        : 0,
      weeksInPlan: latestNutritionPlan ? latestNutritionPlan.weeksInPlan : 0,
      description: latestNutritionPlan ? latestNutritionPlan.description : "",
    },
    enableReinitialize: true,
    validationSchema: CreateNutritionTargets,
    onSubmit: (params) => {
      const dataToUpdate = {
        description: params.description,
        userId: preselectedUserId,
        weeksInPlan: params.weeksInPlan,
        createNutritionPlanItemDtos: [],
      } as CreateNutritionPlanDto;

      // eslint-disable-next-line
      for (let i = 0; i < 7; i++) {
        dataToUpdate.createNutritionPlanItemDtos.push({
          dayOfWeek: i,
          proteinTarget: params.proteinTarget ?? 0,
          carbohydrateTarget: params.carbohydrateTarget ?? 0,
          fatTarget: params.fatTarget ?? 0,
          calorieTarget: params.calorieTarget ?? 0,
        });
      }

      onSubmit(dataToUpdate);
    },
  });

  useEffect(() => {
    const calories = calculateCalories(
      values.proteinTarget ?? 0,
      values.carbohydrateTarget ?? 0,
      values.fatTarget ?? 0
    );

    setFieldValue("calorieTarget", calories);
  }, [values.proteinTarget, values.carbohydrateTarget, values.fatTarget]);

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("nutrition.create-heading")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          <InputField
            type="number"
            value={values.proteinTarget}
            label="Protein"
            onBlur={handleBlur("proteinTarget")}
            onChange={handleChange("proteinTarget")}
            error={!!errors.proteinTarget}
            helperText={errors.proteinTarget ? t(errors.proteinTarget) : ""}
          />
          <InputField
            type="number"
            value={values.carbohydrateTarget}
            label="Carbohydrates"
            onBlur={handleBlur("carbohydrateTarget")}
            onChange={handleChange("carbohydrateTarget")}
            error={!!errors.carbohydrateTarget}
            helperText={
              errors.carbohydrateTarget ? t(errors.carbohydrateTarget) : ""
            }
          />
          <InputField
            type="number"
            value={values.fatTarget}
            label="Fat"
            onBlur={handleBlur("fatTarget")}
            onChange={handleChange("fatTarget")}
            error={!!errors.fatTarget}
            helperText={errors.fatTarget ? t(errors.fatTarget) : ""}
          />
          <InputField
            type="number"
            value={values.weeksInPlan}
            label="Weeks in plan"
            onBlur={handleBlur("weeksInPlan")}
            onChange={handleChange("weeksInPlan")}
            error={!!errors.weeksInPlan}
            helperText={errors.weeksInPlan ? t(errors.weeksInPlan) : ""}
          />
          <InputField
            type="text"
            value={values.description}
            label="Notes"
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
            error={!!errors.description}
            helperText={errors.description ? t(errors.description) : ""}
          />
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.gray[1200]}`,
              mt: "16px",
            }}
          >
            Total Calories Goal
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "140%",
              color: `${Colors.blue[1200]}`,
              mt: "8px",
            }}
          >
            {values.calorieTarget} Calories
          </Typography>
          <PrimaryButton
            size="large"
            type="submit"
            value={t("nutrition.save")}
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          />

          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { NutritionMacroInputModal };
