import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Colors } from "shared/themes";
import { useGetWorkoutProgramsQuery, WorkoutProgramItem } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar/IdenticonAvatar";
import { ChangeStatusModal } from "components/Modals/ChangeStatusModal";
import { createAllUsersRows } from "shared/helpers";
import { CopyAndAssignProgramToUserModal } from "components/Modals/CopyAndAssignProgramToUserModal";
import { StatusItem } from "./StatusItem";
import { ProgramItemActions } from "./ProgramItemActions";
import { ProgramsSkeleton } from "./ProgramsSkeleton";
import { ProgramRow } from "./ProgramsContainer";

type UserProfileProgramsContainerProps = {
  userId: number;
};

const UserProfileProgramsContainer: FC<UserProfileProgramsContainerProps> = ({
  userId,
}) => {
  const { t } = useTranslation();
  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<WorkoutProgramItem>();
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [copyProgramModal, setOpenCopyProgramModal] = useState(false);

  const {
    data: workoutPrograms,
    isLoading: isLoadingWorkouts,
    refetch: refetchWorkoutPrograms,
  } = useGetWorkoutProgramsQuery({
    userId,
  });

  const [rows, setRows] = useState<ProgramRow[]>([]);

  useEffect(() => {
    refetchWorkoutPrograms();
  }, []);

  useEffect(() => {
    if (!workoutPrograms) return;
    const rowsData = createAllUsersRows(workoutPrograms!);
    setRows(rowsData);
  }, [workoutPrograms]);

  const columns: GridColDef[] = [
    {
      field: "photoUrl",
      headerName: "",
      width: 60,
      sortable: false,
      renderCell: (params) => {
        const { userId } = params.row;
        return <IdenticonAvatar seedValue={userId} />;
      },
      filterable: false,
    },
    {
      field: "userFullName",
      headerName: t("programs.table-name"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { userFullName } = params.row;
        return (
          <Box
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
            }}
          >
            {userFullName}
          </Box>
        );
      },
    },
    {
      field: "nameOfProgram",
      headerName: t("programs.table-program-name"),
      flex: 2,
      sortable: false,
    },
    {
      field: "date",
      headerName: t("programs.date"),
      flex: 2,
      sortable: false,
    },
    {
      field: "workoutProgramStatus",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <StatusItem status={params.value} />;
      },
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { userId } = params.row;
        return (
          <ProgramItemActions
            userId={userId}
            workoutProgramId={params.row.id}
            getUserIdForWorkoutCreation={() => {}}
            onChangeStatusClick={() => setOpenChangeStatusModal(true)}
            onCopyModalClick={() => setOpenCopyProgramModal(true)}
          />
        );
      },
      filterable: false,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "60vh",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: "48px",
        mb: "16px",
        overflow: "auto",
      }}
    >
      {!isLoadingWorkouts ? (
        <Box sx={{ height: "500px" }}>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={10}
            pagination
            rows={rows ?? []}
            columns={columns}
            rowCount={workoutPrograms?.length ?? 0}
            paginationMode="client"
            onRowClick={(params) => {
              setSelectedWorkoutProgram(params.row);
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
          <ChangeStatusModal
            isOpenModal={openChangeStatusModal}
            handleCloseModal={() =>
              setOpenChangeStatusModal(!openChangeStatusModal)
            }
            workoutProgram={selectedWorkoutProgram}
          />
          <CopyAndAssignProgramToUserModal
            isOpenModal={copyProgramModal}
            handleCloseModal={() => setOpenCopyProgramModal(!copyProgramModal)}
            workoutProgram={selectedWorkoutProgram}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};
export { UserProfileProgramsContainer };
