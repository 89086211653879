import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { WorkoutProgram } from "../workoutProgramBuilder/model";
import {
  WorkoutProgramDesignerCreateRequest,
  WorkoutProgramDesignerResponse,
  WorkoutProgramDesignerUpdateRequest,
  WorkoutProgramGeneratorRequest,
} from "./model";

export const workoutProgramDesignerApi = createApi({
  reducerPath: "workoutProgramDesignerApi",
  baseQuery,
  tagTypes: ["WorkoutProgramDesigner"],
  endpoints: (builder) => ({
    getWorkoutProgramDesigns: builder.query<
      WorkoutProgramDesignerResponse[],
      number
    >({
      query: (parentWorkoutProgramId) => ({
        url: `/workout-program-designer/${parentWorkoutProgramId}`,
        method: "GET",
      }),
      providesTags: ["WorkoutProgramDesigner"],
    }),
    createNewWorkoutProgramTemplate: builder.mutation<
      WorkoutProgram,
      WorkoutProgramDesignerCreateRequest
    >({
      query: (body) => ({
        url: "/workout-program-designer/create-template",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkoutProgramDesigner"],
    }),
    createNewWorkoutProgramDesign: builder.mutation<
      WorkoutProgram,
      WorkoutProgramDesignerCreateRequest
    >({
      query: (body) => ({
        url: "/workout-program-designer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkoutProgramDesigner"],
    }),
    createNewVersionOnExistingWorkoutProgram: builder.mutation<
      WorkoutProgram,
      WorkoutProgramDesignerUpdateRequest
    >({
      query: (body) => ({
        url: "/workout-program-designer/existing-workout-program",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkoutProgramDesigner"],
    }),
    updateDraftWorkoutProgramDesign: builder.mutation<
      WorkoutProgram,
      WorkoutProgramDesignerUpdateRequest
    >({
      query: (body) => ({
        url: "/workout-program-designer",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkoutProgramDesigner"],
    }),
    createAiWorkoutProgramDesign: builder.mutation<
      WorkoutProgram,
      WorkoutProgramGeneratorRequest
    >({
      query: (body) => ({
        url: "/workout-program-generator",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WorkoutProgramDesigner"],
    }),
  }),
});

export const {
  useGetWorkoutProgramDesignsQuery,
  useCreateNewWorkoutProgramTemplateMutation,
  useCreateNewWorkoutProgramDesignMutation,
  useUpdateDraftWorkoutProgramDesignMutation,
  useCreateNewVersionOnExistingWorkoutProgramMutation,
  useCreateAiWorkoutProgramDesignMutation,
} = workoutProgramDesignerApi;
