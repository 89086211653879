import { Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";

type DropzoneProps = {
  onDrop: (file: File) => void;
  onSubmit: () => void;
  disabled: boolean;
  loading: boolean;
};

const AthleteDropzone: React.FC<DropzoneProps> = ({
  onDrop,
  onSubmit,
  disabled,
  loading,
}) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const { t } = useTranslation();

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFileInfo(file);
      onDrop(file);
    }
  };

  return (
    <Dropzone onDrop={handleDrop} maxFiles={1} ref={dropzoneRef}>
      {({ getRootProps, getInputProps }) => (
        <Box
          {...getRootProps()}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: "2px dashed grey",
            borderRadius: "0.25em",
            backgroundColor: "white",
            color: `${Colors.oxford[1100]}`,
            outline: "none",
            transition: "250ms ease-in-out",
            cursor: "pointer",
            width: "100%",
            paddingLeft: "1em",
          }}
        >
          <input {...getInputProps()} />
          <Box>
            {fileInfo ? (
              <div>
                <Typography variant="subtitle1">{fileInfo.name}</Typography>
                <Typography variant="body2">{fileInfo.size} bytes</Typography>
                <Typography variant="body2">{fileInfo.type}</Typography>
              </div>
            ) : (
              <Typography sx={sharedStyles.body.timeline.cardTitle}>
                {t("timeline.drag-and-drop")}
              </Typography>
            )}
          </Box>
          <LoadingButton
            type="submit"
            variant="text"
            onClick={(e) => {
              onSubmit();
              e.stopPropagation();
            }}
            size="small"
            loading={loading}
            disabled={disabled}
          >
            <Icon>send</Icon>
          </LoadingButton>
        </Box>
      )}
    </Dropzone>
  );
};

export { AthleteDropzone };
