import { Grid } from "@mui/material";
import React, { PropsWithChildren } from "react";
import Image from "mui-image";
import { Images } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { Testimonial } from "components/Testimonial/Testimonial";

export const FormContainer: React.FC<PropsWithChildren> = ({ ...props }) => {
  return (
    <Grid container spacing={2} sx={sharedStyles.containers.signIn.grid}>
      <Grid
        xs={12}
        sm={12}
        md={6}
        lg={6}
        item
        sx={(theme) => ({
          padding: `2em`,
          [theme.breakpoints.down("sm")]: {
            padding: `10px`,
          },
        })}
      >
        {props.children}
      </Grid>
      <Grid item md={6} lg={6} sx={{ display: { xs: "none", md: "flex" } }}>
        <div style={{ position: "relative" }}>
          <Image
            src={Images.CurrentSession}
            height="100%"
            width="100%"
            fit="cover"
            distance="100px"
            bgColor="inherit"
            style={{ borderRadius: "12px" }}
          />
          <Testimonial />
        </div>
      </Grid>
    </Grid>
  );
};
