import { FC } from "react";
import { IconButton, Typography, Icon } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../../themes";
import { useLogoutMutation } from "../../../api";
import { Routes } from "../../../routers";
import { useTypedSelector } from "../../../stores";
import { userSelectors } from "../../../stores/user";
import { IdenticonAvatar } from "../../IdenticonAvatar/IdenticonAvatar";

const SidebarUserProfile: FC = () => {
  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logout().unwrap();
    } finally {
      navigate(Routes.signIn.url);
    }
  };

  const userId = useTypedSelector(userSelectors.userId);
  const fullName = useTypedSelector(userSelectors.fullName);
  const profileImageUrl = useTypedSelector(userSelectors.userProfileImageUrl);

  return (
    <Box
      sx={{
        width: "100%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={`${Routes.profile.url}/${userId}`}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IdenticonAvatar
            seedValue={userId?.toString() ?? ""}
            profileImageUrl={profileImageUrl}
          />

          <Typography
            sx={{
              fontSize: "14px",
              color: `${Colors.gray[900]}`,
            }}
          >
            {fullName}
          </Typography>
        </Box>
      </Link>

      <IconButton onClick={onLogout}>
        <Icon
          sx={{ color: `${Colors.gray[900]}`, cursor: "pointer", mr: -1 }}
          fontSize="small"
        >
          logout
        </Icon>
      </IconButton>
    </Box>
  );
};

export { SidebarUserProfile };
