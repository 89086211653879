import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../shared/themes";

interface ActiveWorkoutStatusProps {
  isActiveWorkoutSession: boolean;
  handleCloseModal: () => void;
  handlePutRequest: () => void;
}

const ActiveWorkoutStatus: FC<ActiveWorkoutStatusProps> = ({
  isActiveWorkoutSession,
  handleCloseModal,
  handlePutRequest,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isActiveWorkoutSession}
      onClose={handleCloseModal}
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "400px",
          height: "auto",
          background: `${Colors.gray[100]}`,
          borderRadius: "4px",
          padding: "16px 24px 16px 24px",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
          }}
        >
          {t("session-card.modal-header")}
        </Typography>
        <Typography
          sx={{
            width: "100%",
            height: "44px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
            mb: 2,
            mt: 2,
          }}
        >
          {t("session-card.modal-text")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "16px 0px 16px 0px",
          }}
        >
          <LoadingButton
            onClick={handlePutRequest}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              textTransform: "none",
              mr: 1,
            }}
          >
            Start
          </LoadingButton>
          <PrimaryButton
            value="Cancel"
            variant="contained"
            fullWidth
            onClick={handleCloseModal}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export { ActiveWorkoutStatus };
