/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { TabContext, TabList } from "@mui/lab";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import { Colors } from "../../shared/themes";
import { steps } from "./constants";
import {
  ClientResponse,
  useGetWorkoutProgramDesignsQuery,
  useGetWorkoutProgramGroupItemsQuery,
  WorkoutProgramDesignerResponse,
  WorkoutProgramGroupItemParams,
  WorkoutSession,
} from "../../shared/api";
import { WorkoutProgramDetailsV2 } from "./WorkoutProgramDetailsV2";
import { WorkoutProgramGridContainer } from "./Grid/WorkoutProgramGridContainer";
import { WorkoutProgramBuilderTitle } from "./WorkoutProgramBuilderTitle";
import { WorkoutProgramBuilderStepper } from "./WorkoutProgramBuilderStepper";
import { WorkoutProgramBuilderValuesV2 } from "./models/WorkoutProgramBuilderModelsV2";
import { GridRowHeader } from "./types";
import { WorkoutProgramBuilderButtonGroup } from "./WorkoutProgramBuilderButtonGroup";
import { WorkoutProgramGridHeader } from "./Grid/WorkoutProgramGridHeader";
import { findMatchingWorkoutProgram, mapToExerciseGridRows } from "./utils";
import { UnsavedGridChangesModal } from "./UnsavedGridChangesModal";

const WorkoutProgramBuilderV2: FC = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const params = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<WorkoutProgramDesignerResponse>();
  const [currentWorkoutList, setCurrentWorkoutList] = useState<
    WorkoutSession[]
  >([]);
  const [versionNumber, setVersionNumber] = useState<number>(1);
  const [selectedUserValue, setSelectedUserValue] =
    useState<ClientResponse | null>(null);
  const [startDateValue, setStartDateValue] = useState<string>(
    dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]")
  );
  const [weeksInProgramValue, setWeeksInProgramValue] = useState<number>(2);
  const [programTitleValue, setProgramTitleValue] = useState<string>("");
  const [programNotesValue, setProgramNotesValue] = useState<string>("");
  const [daysInProgramValue, setDaysInProgramValue] = useState<number>(2);
  const [existingRowData, setExistingRowData] = useState<GridRowHeader[]>([]);
  const [loadingGrid, setLoadingGrid] = useState<boolean>(true);
  const gridIsModified = useTypedSelector(
    (state) => state.workoutBuilder.isModified
  );
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);
  const dispatch = useTypedDispatch();

  const handleStep = (step: number) => () => {
    if (step === 0 && gridIsModified) {
      setShowUnsavedChangesModal(true);
      return;
    }
    setActiveStep(step);
  };

  const handleDiscardChangesOnGrid = () => {
    dispatch(setIsModified(false));
    setShowUnsavedChangesModal(false);
    setActiveStep(0);
  };

  useEffect(() => {
    function handleBeforeUnload(e: any) {
      if (gridIsModified) {
        e.returnValue = t("workout-program-builder.unsaved-changes");
        return e;
      }
      return e;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [gridIsModified]);

  const queryString = window.location.search.slice(1).split("=");

  const [templateProgram, setTemplateProgram] = React.useState(false);

  useEffect(() => {
    if (queryString[0] === "template") {
      setTemplateProgram(true);
    } else {
      setTemplateProgram(false);
    }
  }, [queryString]);

  const {
    data: workoutPrograms,
    isLoading: isLoadingPrograms,
    refetch: refetchPrograms,
  } = useGetWorkoutProgramDesignsQuery(Number(params.workoutProgramId));

  const {
    data: workoutProgramGroupItems,
    isLoading: isLoadingProgramGroup,
    refetch: refetchVersions,
  } = useGetWorkoutProgramGroupItemsQuery({
    parentWorkoutProgramId: Number(params.workoutProgramId),
  } as WorkoutProgramGroupItemParams);

  useEffect(() => {
    refetchPrograms();
    refetchVersions();
  }, []);

  const handleSelectedWorkoutProgramGroupItemChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setLoadingGrid(true);
    setVersionNumber(Number(newValue));
    const matchingWorkoutProgram = findMatchingWorkoutProgram(
      workoutProgramGroupItems!!,
      workoutPrograms!!,
      Number(newValue)
    );

    if (matchingWorkoutProgram) {
      setSelectedWorkoutProgram(matchingWorkoutProgram);
    }
  };

  useEffect(() => {
    if (workoutPrograms) {
      setCurrentWorkoutList(workoutPrograms[0].currentWorkoutListForClient);

      if (versionNumber === 1) {
        setSelectedWorkoutProgram(workoutPrograms[0]);
      } else {
        const matchingWorkoutProgram = findMatchingWorkoutProgram(
          workoutProgramGroupItems!!,
          workoutPrograms!!,
          versionNumber
        );

        if (matchingWorkoutProgram) {
          setSelectedWorkoutProgram(matchingWorkoutProgram);
        }
      }
    }
  }, [versionNumber, workoutPrograms]);

  useEffect(() => {
    const workoutProgramGroupVersionsLoaded =
      workoutProgramGroupItems && workoutProgramGroupItems?.length !== 0;
    if (workoutProgramGroupVersionsLoaded) {
      setVersionNumber(workoutProgramGroupItems.length);
    }
  }, [workoutProgramGroupItems]);

  // eslint-disable-next-line
  let workoutProgramBuilderValues: WorkoutProgramBuilderValuesV2 = {
    name: "",
    startDate: "",
    weeksInProgram: null,
    daysPerWeek: null,
    description: "",
    workoutProgramStatus: "Draft",
    userId: null,
    userName: "",
    workoutProgramNotes: "",
  };

  useEffect(() => {
    if (selectedWorkoutProgram) {
      setProgramTitleValue(selectedWorkoutProgram?.programTitle ?? "");
      setProgramNotesValue(selectedWorkoutProgram?.programNotes ?? "");
      setStartDateValue(selectedWorkoutProgram?.startDate ?? "");
      setSelectedUserValue(selectedWorkoutProgram?.user);
      setWeeksInProgramValue(selectedWorkoutProgram?.weeksInProgram ?? 1);
      setDaysInProgramValue(selectedWorkoutProgram?.daysPerWeek ?? 1);
      const rows: GridRowHeader[] = selectedWorkoutProgram?.workouts.map(
        mapToExerciseGridRows
      );
      const orderedRows = rows.sort((a, b) => {
        if (a.dayNumber != null && b.dayNumber != null) {
          return a.dayNumber - b.dayNumber;
        }
        return 0;
      });
      setExistingRowData(orderedRows);
    }
  }, [selectedWorkoutProgram]);

  useEffect(() => {
    if (existingRowData) {
      setLoadingGrid(false);
    }
  }, [existingRowData]);

  const anythingIsLoading =
    isLoadingPrograms ||
    isLoadingProgramGroup ||
    loadingGrid ||
    !selectedWorkoutProgram;

  const allowGridStep =
    !!selectedUserValue &&
    !!programTitleValue &&
    !!startDateValue &&
    !!weeksInProgramValue &&
    !!daysInProgramValue;

  const allowGridStepOnTemplate =
    !!programTitleValue &&
    !!startDateValue &&
    !!weeksInProgramValue &&
    !!daysInProgramValue;

  const isLatestVersion = versionNumber === workoutProgramGroupItems?.length;

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        height: "auto",
        bgcolor: `${Colors.gray[200]}`,
        position: "absolute",
        right: "0px",
        padding: "62px 32px 24px",
      }}
    >
      <UnsavedGridChangesModal
        isOpenModal={showUnsavedChangesModal}
        handleCloseModal={() =>
          setShowUnsavedChangesModal(!showUnsavedChangesModal)
        }
        onSubmit={() => {
          handleDiscardChangesOnGrid();
        }}
      />
      {!anythingIsLoading && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "right",
              justifyContent: "space-between",
              width: "100%",
              height: "58px",
              mt: "24px",
              mb: "24px",
            }}
          >
            <WorkoutProgramBuilderTitle
              selectedUserValue={selectedUserValue}
              isTemplate={templateProgram}
            />
          </Box>
          <Box
            sx={{
              width: "auto",
              height: "auto",
              bgcolor: `${Colors.gray[100]}`,
              borderRadius: "8px",
              padding: "32px",
              mb: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <WorkoutProgramBuilderStepper
                activeStep={activeStep}
                handleStep={handleStep}
                steps={steps}
                allowGridStep={
                  templateProgram ? allowGridStepOnTemplate : allowGridStep
                }
              />
              <WorkoutProgramBuilderButtonGroup
                activeStep={activeStep}
                handleStep={handleStep}
                allowGridStep={
                  templateProgram ? allowGridStepOnTemplate : allowGridStep
                }
              />
            </Box>
            {activeStep === 0 && (
              <WorkoutProgramDetailsV2
                templateProgram={templateProgram}
                workoutProgramBuilderValues={workoutProgramBuilderValues}
                selectedUserValue={selectedUserValue}
                setSelectedUserValue={setSelectedUserValue}
                startDateValue={startDateValue}
                setStartDateValue={setStartDateValue}
                weeksInProgramValue={weeksInProgramValue}
                setWeeksInProgramValue={setWeeksInProgramValue}
                programTitleValue={programTitleValue}
                setProgramTitleValue={setProgramTitleValue}
                programNotesValue={programNotesValue}
                setProgramNotesValue={setProgramNotesValue}
                daysInProgramValue={daysInProgramValue}
                setDaysInProgramValue={setDaysInProgramValue}
                selectedWorkoutProgram={selectedWorkoutProgram}
              />
            )}
            {activeStep === 1 && (
              <>
                <WorkoutProgramGridHeader
                  selectedUserValue={selectedUserValue}
                />
                {!templateProgram && (
                  <TabContext value={versionNumber.toString()}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleSelectedWorkoutProgramGroupItemChange}
                        aria-label="Tabs"
                      >
                        {workoutProgramGroupItems?.map(
                          (workoutProgramGroupItem) => (
                            <Tab
                              key={workoutProgramGroupItem.id}
                              label={`Version ${workoutProgramGroupItem.versionNumber}`}
                              value={workoutProgramGroupItem.versionNumber.toString()}
                            />
                          )
                        )}
                      </TabList>
                    </Box>
                  </TabContext>
                )}
                <WorkoutProgramGridContainer
                  templateProgram={templateProgram}
                  selectedWorkoutProgram={selectedWorkoutProgram}
                  currentWorkoutList={currentWorkoutList}
                  existingData={existingRowData}
                  numberOfWeeks={weeksInProgramValue}
                  numberOfDays={daysInProgramValue}
                  user={selectedUserValue}
                  startDate={startDateValue}
                  programTitle={programTitleValue}
                  programNotes={programNotesValue}
                  isLatestVersion={isLatestVersion}
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export { WorkoutProgramBuilderV2 };
