import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { CombinedData } from "shared/api";
import { Colors } from "shared/themes";

type UnilateralInputScreenWithPartialsProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
  exercise: CombinedData;
};
// eslint-disable-next-line
const UnilateralInputScreenWithPartials: FC<
  UnilateralInputScreenWithPartialsProps
> = ({ values, handleChange, handleBlur, exercise }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: `${Colors.gray[200]}`,
      }}
    >
      <Box>
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            mb: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Inter",
              fontWeight: 700,
              color: `${Colors.gray[800]}`,
            }}
          >
            Left side
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            mb: "12px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedWeightLeftSide}
            label={
              exercise.workoutItemWeightUnit === "Lbs"
                ? "Weight (Lbs)"
                : "Weight (Kg)"
            }
            onBlur={handleBlur("completedWeightLeftSide")}
            onChange={handleChange("completedWeightLeftSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedPartialRepsLeftSide}
            label="Partial reps"
            onBlur={handleBlur("completedPartialRepsLeftSide")}
            onChange={handleChange("completedPartialRepsLeftSide")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedRepsLeftSide}
            label="Reps"
            onBlur={handleBlur("completedRepsLeftSide")}
            onChange={handleChange("completedRepsLeftSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedIntensityLeftSide}
            label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
            onBlur={handleBlur("completedIntensityLeftSide")}
            onChange={handleChange("completedIntensityLeftSide")}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <hr
          style={{
            border: "none",
            height: "1px",
            background: `${Colors.gray[500]}`,
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0px",
            mb: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "Inter",
              fontWeight: 700,
              color: `${Colors.gray[800]}`,
            }}
          >
            Right side
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            mb: "12px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedWeightRightSide}
            label={
              exercise.workoutItemWeightUnit === "Lbs"
                ? "Weight (Lbs)"
                : "Weight (Kg)"
            }
            onBlur={handleBlur("completedWeightRightSide")}
            onChange={handleChange("completedWeightRightSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedPartialRepsRightSide}
            label="Partial reps"
            onBlur={handleBlur("completedPartialRepsRightSide")}
            onChange={handleChange("completedPartialRepsRightSide")}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            gap: "12px",
            flex: "none",
            order: 1,
            flexGrow: 0,
          }}
        >
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedRepsRightSide}
            label="Reps"
            onBlur={handleBlur("completedRepsRightSide")}
            onChange={handleChange("completedRepsRightSide")}
          />
          <TextField
            sx={{
              width: "50%",
              height: "56px",
              background: "white",
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            }}
            type="number"
            inputProps={{
              step: "any",
              inputMode: "decimal",
            }}
            value={values.completedIntensityRightSide}
            label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
            onBlur={handleBlur("completedIntensityRightSide")}
            onChange={handleChange("completedIntensityRightSide")}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mt: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "56px",
            background: "white",
          }}
          value={values.notes}
          label="Notes"
          onBlur={handleBlur("notes")}
          onChange={handleChange("notes")}
        />
      </Box>
    </Box>
  );
};

export { UnilateralInputScreenWithPartials };
