import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  Lead,
  LeadNote,
  CreateLeadNoteRequest,
  CreateLeadRequest,
  LeadsRequestParams,
  LeadNotesRequestParams,
} from "./models";

export const leadApi = createApi({
  baseQuery,
  reducerPath: "leadApi",
  tagTypes: ["Lead", "LeadList", "LeadCount"],
  endpoints: (builder) => ({
    getLeads: builder.query<Lead[], LeadsRequestParams>({
      query: (params) => ({
        url: `/leads`,
        params,
      }),
      providesTags: ["LeadList"],
    }),
    getLeadsCount: builder.query<number, void>({
      query: () => ({
        url: `/leads?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["LeadCount"],
    }),
    getLead: builder.query<Lead, number>({
      query: (id) => ({
        url: `/leads/${id}`,
      }),
      providesTags: ["Lead"],
    }),
    createLead: builder.mutation<Lead, CreateLeadRequest>({
      query: (body) => ({
        url: `/leads`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Lead", "LeadList", "LeadCount"],
    }),
    updateLead: builder.mutation<Lead, CreateLeadRequest>({
      query: (body) => ({
        url: `/leads/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Lead", "LeadList", "LeadCount"],
    }),
    deleteLead: builder.mutation<void, number>({
      query: (id) => ({
        url: `/leads/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead", "LeadList", "LeadCount"],
    }),
    getLeadNotes: builder.query<LeadNote[], LeadNotesRequestParams>({
      query: (params) => ({
        url: `/lead-notes`,
        params,
      }),
    }),
    getLeadNotesCount: builder.query<number, number>({
      query: (id) => ({
        url: `/lead-notes?countOnly=true`,
        method: "GET",
      }),
      providesTags: ["Lead"],
    }),
    getLeadNote: builder.query<LeadNote, number>({
      query: (id) => ({
        url: `/lead-notes/${id}`,
      }),
      providesTags: ["Lead"],
    }),
    createLeadNote: builder.mutation<LeadNote, CreateLeadNoteRequest>({
      query: (body) => ({
        url: `/lead-notes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Lead"],
    }),
    updateLeadNote: builder.mutation<LeadNote, CreateLeadNoteRequest>({
      query: (body) => ({
        url: `/lead-notes/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Lead"],
    }),
    deleteLeadNote: builder.mutation<void, number>({
      query: (id) => ({
        url: `/lead-notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead"],
    }),
    convertLeadToClient: builder.mutation<void, number>({
      query: (id) => ({
        url: `/leads/${id}/convert`,
        method: "POST",
      }),
      invalidatesTags: ["Lead", "LeadList", "LeadCount"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetLeadQuery,
  useCreateLeadMutation,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
  useGetLeadNotesQuery,
  useGetLeadNoteQuery,
  useCreateLeadNoteMutation,
  useUpdateLeadNoteMutation,
  useDeleteLeadNoteMutation,
  useGetLeadsCountQuery,
  useGetLeadNotesCountQuery,
  useConvertLeadToClientMutation,
} = leadApi;
