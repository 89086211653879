import { createApi } from "@reduxjs/toolkit/query/react";
import {
  FitnessProfessionalSignupRequest,
  VerifyVerificationCodeRequest,
  ClientSignupRequest,
} from "./models";
import { baseQuery } from "../baseQuery";

export const signupApi = createApi({
  baseQuery,
  reducerPath: "signupApi",
  endpoints: (builder) => ({
    fintessProfessionalSignup: builder.mutation<
      void,
      FitnessProfessionalSignupRequest
    >({
      query: ({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        organisationName,
        acceptTerms,
      }) => ({
        url: "/users/register",
        method: "POST",
        body: {
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          organisationName,
          acceptTerms,
        },
      }),
    }),
    verifyVerificationCode: builder.mutation<
      void,
      VerifyVerificationCodeRequest
    >({
      query: ({ token, email }) => ({
        url: "/users/verify-email",
        method: "POST",
        body: {
          token,
          email,
        },
      }),
    }),
    clientSignup: builder.mutation<void, ClientSignupRequest>({
      query: ({ token, email, password, confirmPassword }) => ({
        url: "/users/register/client",
        method: "POST",
        body: {
          token,
          email,
          password,
          confirmPassword,
        },
      }),
    }),
  }),
});

export const {
  useFintessProfessionalSignupMutation,
  useVerifyVerificationCodeMutation,
  useClientSignupMutation,
} = signupApi;
