import { FC, useState } from "react";
import {
  ClientResponse,
  WorkoutProgramDesignerResponse,
  WorkoutSession,
} from "shared/api";
import { WorkoutProgramGridActions } from "./WorkoutProgramGridActions";
import { GridRowHeader } from "../types";
import { WorkoutProgramGridButtons } from "./WorkoutProgramGridButtons";

type WorkoutProgramGridContainerProps = {
  templateProgram: boolean;
  selectedWorkoutProgram?: WorkoutProgramDesignerResponse;
  currentWorkoutList?: WorkoutSession[];
  existingData?: GridRowHeader[];
  numberOfWeeks: number;
  numberOfDays: number;
  user: ClientResponse | null;
  startDate: string;
  programTitle: string;
  programNotes: string;
  isLatestVersion: boolean;
};

export type GridActionsRef = {
  onCreateNewWorkoutProgramDesign: () => void;
  onUpdateDraftWorkoutProgramDesign: () => void;
  workoutProgramId: number | null;
};

const WorkoutProgramGridContainer: FC<WorkoutProgramGridContainerProps> = ({
  templateProgram,
  selectedWorkoutProgram,
  currentWorkoutList = [],
  existingData = [],
  numberOfWeeks,
  numberOfDays,
  user,
  startDate,
  programTitle,
  programNotes,
  isLatestVersion = false,
}) => {
  const isDraftOrNewProgram =
    !selectedWorkoutProgram?.workoutProgramId ||
    selectedWorkoutProgram?.workoutProgramStatus === "Draft";

  const [showSimplifiedView, setShowSimplifiedView] = useState(true);

  return (
    <>
      <WorkoutProgramGridButtons
        templateProgram={templateProgram}
        selectedWorkoutProgram={selectedWorkoutProgram!!}
        numberOfWeeks={numberOfWeeks}
        numberOfDays={numberOfDays}
        user={user!!}
        startDate={startDate}
        programTitle={programTitle}
        programNotes={programNotes}
        isLatestVersion={isLatestVersion}
        showSimplifiedView={showSimplifiedView}
      />
      <WorkoutProgramGridActions
        templateProgram={templateProgram}
        existingData={existingData ?? []}
        currentWorkoutList={currentWorkoutList}
        showSimplifiedView={showSimplifiedView}
        setShowSimplifiedView={setShowSimplifiedView}
        numberOfWeeks={numberOfWeeks}
        numberOfDays={numberOfDays}
        isDraftOrNewProgram={isDraftOrNewProgram}
      />
    </>
  );
};

export { WorkoutProgramGridContainer };
