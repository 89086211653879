export enum SeriesType {
  CompletedWeightLeftSide = "Completed Weight Left Side",
  CompletedWeightRightSide = "Completed Weight Right Side",
  CompletedWeight = "Completed Weight",
  PrescribedWeight = "Prescribed Weight",
  PrescribedReps = "Prescribed Reps",
  LowerRange = "Lower Range",
  HigherRange = "Higher Range",
  CompletedReps = "Total Reps Completed",
  CompletedPartialReps = "Total Reps Partially Completed",
  CompletedPartialRepsLeftSide = "Total Reps Partially Completed (Left)",
  CompletedPartialRepsRightSide = "Total Reps Partially Completed (Right)",
  CompletedRepsLeftSide = "Total Reps Completed (Left)",
  CompletedRepsRightSide = "Total Reps Completed (Right)",
  AverageRepsCompleted = "Total Reps Completed",
  AveragePartialRepsCompleted = "Total Partial Reps Completed",
  AverageWeightCompleted = "Best Weight Completed",
}

export const seriesColours: Record<
  string,
  {
    borderColor: string;
    backgroundColor: string;
  }
> = {
  [SeriesType.CompletedWeightLeftSide]: {
    borderColor: "#0078e1",
    backgroundColor: "#0078e1",
  },
  [SeriesType.CompletedWeightRightSide]: {
    borderColor: "#ec79b6",
    backgroundColor: "#ec79b6",
  },
  [SeriesType.CompletedWeight]: {
    borderColor: "#e02488",
    backgroundColor: "#e02488",
  },
  [SeriesType.PrescribedWeight]: {
    borderColor: "#81bb3e",
    backgroundColor: "#81bb3e",
  },
  [SeriesType.PrescribedReps]: {
    borderColor: "#81bb3e",
    backgroundColor: "#81bb3e",
  },
  [SeriesType.LowerRange]: {
    borderColor: "#eba970",
    backgroundColor: "#eba970",
  },
  [SeriesType.HigherRange]: {
    borderColor: "#e1812d",
    backgroundColor: "#e1812d",
  },
  [SeriesType.CompletedReps]: {
    borderColor: "#e02488",
    backgroundColor: "#e02488",
  },
  [SeriesType.CompletedPartialReps]: {
    borderColor: "#394e60",
    backgroundColor: "#394e60",
  },
  [SeriesType.CompletedPartialRepsLeftSide]: {
    borderColor: "#0078e1",
    backgroundColor: "#0078e1",
  },
  [SeriesType.CompletedPartialRepsRightSide]: {
    borderColor: "#ec79b6",
    backgroundColor: "#ec79b6",
  },
  [SeriesType.CompletedRepsLeftSide]: {
    borderColor: "#63e85e",
    backgroundColor: "#63e85e",
  },
  [SeriesType.CompletedRepsRightSide]: {
    borderColor: "#7579eb",
    backgroundColor: "#7579eb",
  },
  [SeriesType.AverageRepsCompleted]: {
    borderColor: "#0078e1",
    backgroundColor: "#0078e1",
  },
  [SeriesType.AveragePartialRepsCompleted]: {
    borderColor: "#394e60",
    backgroundColor: "#394e60",
  },
  [SeriesType.AverageWeightCompleted]: {
    borderColor: "#ec79b6",
    backgroundColor: "#ec79b6",
  },
};
