import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Icon } from "@mui/material";
import React from "react";
import { sharedStyles } from "shared/themes/shared/styles";

type PrimaryButtonProps = {
  icon?: string;
};

export const PrimaryButton: React.FC<
  LoadingButtonProps & PrimaryButtonProps
> = ({ ...props }) => {
  return (
    <LoadingButton sx={sharedStyles.buttons.normal} {...props}>
      {props.icon && <Icon sx={{ marginRight: "8px" }}>{props.icon}</Icon>}
      {props.value}
    </LoadingButton>
  );
};
