import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { ExerciseItem, useDeleteExerciseByIdMutation } from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Routes } from "shared/routers";
import { Colors } from "shared/themes";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface DeleteExerciseModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  exerciseItem?: ExerciseItem;
}

const DeleteExerciseModal: FC<DeleteExerciseModalProps> = ({
  isOpenModal,
  handleCloseModal,
  exerciseItem,
}) => {
  const { t } = useTranslation();
  const [deleteExercise, { isLoading }] = useDeleteExerciseByIdMutation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async () => {
    try {
      await deleteExercise(exerciseItem?.id ?? 0);
      setSuccessMessage(t("exercise-library-modal.exercise-success-deleted"));
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setTimeout(() => {
        navigate(Routes.exerciseLibrary.url);
      }, 1500);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("exercise-library-modal.archive-heading")} {exerciseItem?.name}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              mb: 2,
              mt: 2,
            }}
          >
            {t("exercise-library-modal.archive-content")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "10px",
              gap: "10px",
            }}
          >
            <LoadingButton
              type="submit"
              value="Archive"
              fullWidth
              loading={isLoading || !exerciseItem}
              disabled={isLoading || !exerciseItem}
              onClick={handleSubmit}
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
              }}
            >
              {t("exercise-library-modal.archive-button")}
            </LoadingButton>
            <PrimaryButton
              value="Cancel"
              variant="contained"
              fullWidth
              onClick={handleCloseModal}
            />
          </div>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { DeleteExerciseModal };
