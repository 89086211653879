import { FC, useEffect, useState } from "react";
import { Button, Icon, Typography, Tab, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { UploadNewWorkoutProgramCsvModal } from "shared/ui/Modals/UploadNewWorkoutProgramCsvModal";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { ProgramsContainer } from "./ui/ProgramsContainer";
import { ProgramTemplatesContainer } from "./ui/ProgramTemplatesContainer";

interface ProgramsProps {
  getUserIdForWorkoutCreation: (userId: number) => void;
  setCreateWorkoutUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

const AdminPrograms: FC<ProgramsProps> = ({
  getUserIdForWorkoutCreation,
  setCreateWorkoutUserId,
}) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setCreateWorkoutUserId(null);
  }, []);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <UploadNewWorkoutProgramCsvModal
        isOpenModal={isOpenModal}
        handleCloseModal={handleToggleModal}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "space-between",
          width: "100%",
          height: "58px",
          mb: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "140%",
            color: `${Colors.gray[900]}`,
            textTransform: "none",
          }}
        >
          {t("programs.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
            height: "auto",
            "& > *:not(:last-child)": {
              marginRight: "16px",
            },
          }}
        >
          <Link
            style={{ textDecoration: "none" }}
            to={`${Routes.workoutProgramBuilder.url}?template=true`}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
              }}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                add_icon
              </Icon>
              {t("programs.create-template-program")}
            </Button>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to={`${Routes.workoutProgramBuilder.url}`}
          >
            <div id="add-workout-program">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  color: `${Colors.gray[100]}`,
                  textTransform: "none",
                }}
              >
                <Icon
                  sx={{
                    marginRight: "8px",
                  }}
                >
                  add_icon
                </Icon>
                {t("programs.create-program")}
              </Button>
            </div>
          </Link>
        </Box>
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Programs" value="1" />
            <Tab label="Templates" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ProgramsContainer
            getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
          />
        </TabPanel>
        <TabPanel value="2">
          <ProgramTemplatesContainer />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export { AdminPrograms };
