/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, Dispatch, useState, useEffect } from "react";
import {
  Autocomplete,
  Typography,
  useMediaQuery,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { useTypedDispatch } from "shared/stores";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import {
  ClientResponse,
  useGetAllUsersQuery,
  UserRole,
  WorkoutProgramDesignerResponse,
  WorkoutProgramStatus,
} from "../../shared/api";
import { WorkoutProgramBuilderValues } from "./models/WorkoutProgramBuilderModels";
import {
  MAXIMUM_WEEKS_IN_PROGRAM,
  MINIMUM_WEEKS_IN_PROGRAM,
} from "./constants";

interface WorkoutProgramDetailsProps {
  templateProgram: boolean;
  workoutProgramBuilderValues: WorkoutProgramBuilderValues;
  selectedUserValue: ClientResponse | null;
  setSelectedUserValue: Dispatch<ClientResponse | null>;
  startDateValue: string;
  setStartDateValue: Dispatch<string>;
  weeksInProgramValue: number;
  setWeeksInProgramValue: Dispatch<number>;
  programTitleValue: string;
  setProgramTitleValue: Dispatch<string>;
  programNotesValue: string;
  setProgramNotesValue: Dispatch<string>;
  daysInProgramValue: number;
  setDaysInProgramValue: Dispatch<number>;
  selectedWorkoutProgram?: WorkoutProgramDesignerResponse;
}

const WorkoutProgramDetailsV2: FC<WorkoutProgramDetailsProps> = ({
  templateProgram,
  workoutProgramBuilderValues,
  selectedUserValue,
  setSelectedUserValue,
  startDateValue,
  setStartDateValue,
  weeksInProgramValue,
  setWeeksInProgramValue,
  programTitleValue,
  setProgramTitleValue,
  programNotesValue,
  setProgramNotesValue,
  daysInProgramValue,
  setDaysInProgramValue,
  selectedWorkoutProgram,
}) => {
  const { t } = useTranslation();

  const matches = useMediaQuery("(min-width:900px)");

  const { data: allUsers, isLoading: isLoadingAllUsers } = useGetAllUsersQuery(
    {}
  );

  const onlyAthletes = allUsers?.filter(
    (item) => item.userRole === UserRole.Athlete
  );
  const dispatch = useTypedDispatch();
  const [localIsModified, setLocalModified] = useState(false);

  useEffect(() => {
    if (localIsModified) {
      dispatch(setIsModified(true));
    }
  }, [localIsModified]);

  const handleWeeksSplit = (weeks: number): void => {
    if (weeks > MAXIMUM_WEEKS_IN_PROGRAM) {
      setWeeksInProgramValue(MAXIMUM_WEEKS_IN_PROGRAM);
      return;
    }
    if (weeks < MINIMUM_WEEKS_IN_PROGRAM) {
      setWeeksInProgramValue(MINIMUM_WEEKS_IN_PROGRAM);
      return;
    }
    setWeeksInProgramValue(weeks);
    setLocalModified(true);
  };

  const handleDaysSplit = (
    event: React.MouseEvent<HTMLElement>,
    days: number
  ) => {
    setDaysInProgramValue(days);
    setLocalModified(true);
  };

  const toggleButtonStyles = {
    width: matches ? "99px" : "69px",
    height: matches ? "56px" : "35px",
    padding: matches ? "11px" : "5px",
    fontSize: matches ? "14px" : "10px",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: `${Colors.gray[1800]}`,
      color: `${Colors.blue[2100]}`,
    },
  };

  const handleProgramTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProgramTitleValue(event.target.value);
    setLocalModified(true);
  };

  const handleProgramNotesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProgramNotesValue(event.target.value);
    setLocalModified(true);
  };

  const handleDateChange = (date: string | null) => {
    setStartDateValue(date ?? startDateValue);
    setLocalModified(true);
  };

  const workoutProgramIsInDraft =
    selectedWorkoutProgram?.workoutProgramStatus === WorkoutProgramStatus.DRAFT;

  const disableAthleteSelect = templateProgram
    ? false
    : !!selectedWorkoutProgram;

  const disableFieldsIfNotDraft = templateProgram
    ? false
    : !workoutProgramIsInDraft && !!selectedWorkoutProgram;
  return (
    <Box
      sx={{
        mt: "16px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "10px",
          mb: "32px",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "140%",
            color: `${Colors.blue[1300]}`,
          }}
        >
          {!templateProgram
            ? t("first-step.create-program")
            : t("first-step.create-template-program")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!templateProgram && (
          <Box
            sx={{
              width: matches ? "50%" : "100%",
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                height: "5%",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: `${Colors.gray[2400]}`,
                mb: "10px",
              }}
            >
              {t("first-step.client")}
            </Typography>
            {!isLoadingAllUsers && onlyAthletes !== undefined ? (
              <Autocomplete
                disabled={disableAthleteSelect}
                size={matches ? "medium" : "small"}
                sx={{ width: "70%", mt: "-10px" }}
                options={onlyAthletes}
                defaultValue={null}
                renderInput={(params) => <TextField {...params} />}
                onChange={(_e, user) => {
                  setLocalModified(true);
                  setSelectedUserValue(user ?? null);
                  if (workoutProgramBuilderValues.userId) {
                    workoutProgramBuilderValues.userId = user!.id;
                    workoutProgramBuilderValues.userName = `${
                      user!.firstName
                    } ${user!.lastName} - ${user!.email}`;
                  }
                }}
                value={selectedUserValue}
                getOptionLabel={(item) =>
                  `${`${item.firstName} ${item.lastName}`} - ${item.email}`
                }
              />
            ) : null}
          </Box>
        )}
        <Box
          sx={{
            width: matches ? "50%" : "100%",
            mb: "16px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              mb: "10px",
              color: `${Colors.gray[2400]}`,
            }}
          >
            {t("first-step.title-program")}
          </Typography>
          <TextField
            size={matches ? "medium" : "small"}
            sx={{ width: "70%" }}
            type="text"
            value={programTitleValue}
            onChange={handleProgramTitleChange}
            disabled={disableFieldsIfNotDraft}
          />
        </Box>
        {!templateProgram && (
          <Box
            sx={{
              width: matches ? "50%" : "100%",
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                height: "5%",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                mb: "10px",
                color: `${Colors.gray[2400]}`,
              }}
            >
              {t("first-step.start-date")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={startDateValue}
                onChange={(date) => handleDateChange(date)}
                disabled={disableFieldsIfNotDraft}
                renderInput={(params: any) => (
                  <TextField
                    sx={{
                      width: "70%",
                      mb: { xs: "15px", md: "10px" },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        )}
        <Box
          sx={{
            width: matches ? "50%" : "100%",
            mb: "16px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              mb: "10px",
              color: `${Colors.gray[2400]}`,
            }}
          >
            {t("first-step.weeks")}
          </Typography>
          <TextField
            size={matches ? "medium" : "small"}
            sx={{ width: "70%", mb: "15px" }}
            type="number"
            inputProps={{
              max: MAXIMUM_WEEKS_IN_PROGRAM,
              min: MINIMUM_WEEKS_IN_PROGRAM,
            }}
            value={weeksInProgramValue}
            onChange={(event) => {
              // eslint-disable-next-line
              handleWeeksSplit(parseInt(event.target.value));
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: `${Colors.gray[2400]}`,
              mb: "10px",
            }}
          >
            {t("first-step.workout-split")}
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={daysInProgramValue}
            exclusive
            onChange={handleDaysSplit}
            sx={{ mb: "15px" }}
          >
            <ToggleButton value={2} sx={toggleButtonStyles}>
              {t("first-step.2-days")}
            </ToggleButton>
            <ToggleButton value={3} sx={toggleButtonStyles}>
              {t("first-step.3-days")}
            </ToggleButton>
            <ToggleButton value={4} sx={toggleButtonStyles}>
              {t("first-step.4-days")}
            </ToggleButton>
            <ToggleButton value={5} sx={toggleButtonStyles}>
              {t("first-step.5-days")}
            </ToggleButton>
            <ToggleButton value={6} sx={toggleButtonStyles}>
              {t("first-step.6-days")}
            </ToggleButton>
            <ToggleButton value={7} sx={toggleButtonStyles}>
              {t("first-step.7-days")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: `${Colors.gray[2400]}`,
              mb: "10px",
            }}
          >
            {t("first-step.program-notes")}
          </Typography>
          <TextField
            size={matches ? "medium" : "small"}
            sx={{ width: "70%" }}
            type="text"
            value={programNotesValue}
            onChange={handleProgramNotesChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { WorkoutProgramDetailsV2 };
