import { Alert, Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Images } from "shared/themes";
import dayjs from "dayjs";
import { WorkoutProgramStatus, useGetWorkoutProgramsQuery } from "shared/api";
import {
  ActionButton,
  WorkoutPageSkeleton,
} from "pages/Dashboard/AthleteDashboard/ui";
import { useNavigate } from "react-router-dom";
import { Routes } from "shared/routers";
import { sharedStyles } from "shared/themes/shared/styles";
import { WorkoutProgramsDrawer } from "./WorkoutProgramsDrawer";
import { ProgramCard } from "./ProgramCard";

interface WorkoutsProps {
  closeSidebar: () => void;
}

const WorkoutPrograms: FC<WorkoutsProps> = ({ closeSidebar }) => {
  const [pageSize] = useState(100);
  const [page] = useState(1);
  const navigate = useNavigate();
  const [showStartedDrawer, setShowStartedDrawer] = useState(false);
  const [showCompletedDrawer, setShowCompletedDrawer] = useState(false);

  const { data: workoutPrograms, isLoading: isLoadingWorkouts } =
    useGetWorkoutProgramsQuery({
      count: pageSize,
      page,
    });

  const hasInProgressWorkout =
    workoutPrograms?.some(
      (w) => w.workoutProgramStatus === WorkoutProgramStatus.IN_PROGRESS
    ) ?? false;

  const { t } = useTranslation();

  const showDrawerOnSuccess = (workoutStatus: string) => {
    if (workoutStatus === WorkoutProgramStatus.IN_PROGRESS) {
      setShowCompletedDrawer(true);
    } else if (workoutStatus === WorkoutProgramStatus.NOT_STARTED) {
      setShowStartedDrawer(true);
    }
  };

  const onStartedDrawerClose = () => {
    setShowStartedDrawer(false);
    navigate(Routes.dashboard.url);
  };

  const onCompletedDrawerClose = () => {
    setShowCompletedDrawer(false);
  };

  const invoiceDueDateTime =
    workoutPrograms?.find(
      (w) =>
        w.invoiceDueDateTime &&
        new Date(w.invoiceDueDateTime) > new Date() &&
        w.invoiceStatus === "open"
    )?.invoiceDueDateTime ?? null;

  const anyInvoiceOverdue =
    workoutPrograms?.some((w) => w.invoiceIsOverdue) ?? false;

  const completeWorkoutPrograms =
    workoutPrograms?.filter(
      (w) => w.workoutProgramStatus === WorkoutProgramStatus.COMPLETED
    ) ?? [];

  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  return !isLoadingWorkouts ? (
    <Box onClick={closeSidebar} sx={{ height: "88%", overflowY: "auto" }}>
      <Box
        sx={{
          padding: "9px 24px 23px 24px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            // height: "34px",
            mb: "17px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "140%",
            color: `${Colors.blue[1500]}`,
          }}
        >
          {t("program-card.heading")}
        </Typography>
        {workoutPrograms?.length === 0 && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ mb: "10px", alignItems: "center" }}
          >
            <Typography sx={sharedStyles.body.timeline.cardBody}>
              {t("athlete-dashboard.no-program-description")}
            </Typography>
          </Alert>
        )}
        {anyInvoiceOverdue && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ mb: "10px", alignItems: "center" }}
          >
            <Typography variant="h6">
              {t("athlete-dashboard.invoice-overdue")}
            </Typography>

            <Typography variant="body2">
              {t("athlete-dashboard.invoice-overdue-description")}
            </Typography>
          </Alert>
        )}
        {invoiceDueDateTime && (
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ mb: "10px", alignItems: "center" }}
          >
            <Typography variant="h6">
              {t("athlete-dashboard.invoice-due-warning")}:{" "}
              {dayjs(invoiceDueDateTime).format("Do MMMM YYYY")}
            </Typography>

            <Typography variant="body2">
              {t("athlete-dashboard.invoice-due-warning-description")}
            </Typography>
          </Alert>
        )}

        <ActionButton
          isActive={activeIndex === 0}
          title="Current"
          onPress={() => setActiveIndex(activeIndex === 0 ? null : 0)}
        />

        <Collapse in={activeIndex === 0} timeout="auto" unmountOnExit>
          {workoutPrograms
            ?.filter(
              (w) =>
                w.workoutProgramStatus === WorkoutProgramStatus.IN_PROGRESS ||
                w.workoutProgramStatus === WorkoutProgramStatus.NOT_STARTED
            )
            .map((workoutProgram, index) => (
              <ProgramCard
                key={index}
                id={workoutProgram.id}
                image={Images.WorkoutProgram}
                workoutStatus={workoutProgram.workoutProgramStatus}
                startDate={workoutProgram.startDate}
                programName={workoutProgram.name}
                hasInProgressWorkout={hasInProgressWorkout}
                weeksInProgram={workoutProgram.weeksInProgram}
                daysPerWeek={workoutProgram.daysPerWeek}
                invoiceIsOverdue={workoutProgram.invoiceIsOverdue}
                showDrawerOnSuccess={showDrawerOnSuccess}
              />
            ))}
        </Collapse>

        {completeWorkoutPrograms?.length > 0 && (
          <>
            <ActionButton
              isActive={activeIndex === 1}
              title="Completed"
              onPress={() => setActiveIndex(activeIndex === 1 ? null : 1)}
            />
            <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
              {completeWorkoutPrograms?.map((workoutProgram, index) => (
                <ProgramCard
                  key={index}
                  id={workoutProgram.id}
                  image={Images.WorkoutProgram}
                  workoutStatus={workoutProgram.workoutProgramStatus}
                  startDate={workoutProgram.startDate}
                  programName={workoutProgram.name}
                  hasInProgressWorkout={hasInProgressWorkout}
                  weeksInProgram={workoutProgram.weeksInProgram}
                  daysPerWeek={workoutProgram.daysPerWeek}
                  invoiceIsOverdue={workoutProgram.invoiceIsOverdue}
                  showDrawerOnSuccess={showDrawerOnSuccess}
                />
              ))}
            </Collapse>
          </>
        )}
      </Box>
      <WorkoutProgramsDrawer
        isOpen={showStartedDrawer}
        toggleIsOpen={onStartedDrawerClose}
        title={t("program-card.modal-program-started-title")}
        description={t("program-card.modal-program-started-message")}
        buttonText={t("program-card.modal-program-started-button")}
      />
      <WorkoutProgramsDrawer
        isOpen={showCompletedDrawer}
        toggleIsOpen={onCompletedDrawerClose}
        title={t("program-card.modal-program-completed-title")}
        description={t("program-card.modal-program-completed-message")}
        buttonText={t("program-card.modal-program-completed-button")}
      />
    </Box>
  ) : (
    <WorkoutPageSkeleton />
  );
};

export { WorkoutPrograms };
