import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Alert, Modal, Typography, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { LoadingButton } from "@mui/lab";
import { Colors } from "shared/themes";
import { useFormik } from "formik";

interface CreateAiWorkoutProgramModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  onSubmit: (params: CreateAiWorkoutProgramFormValues) => void;
}

export type CreateAiWorkoutProgramFormValues = {
  goal: string;
  minutesPerWorkout: string;
};

const CreateAiWorkoutProgramModal: FC<CreateAiWorkoutProgramModalProps> = ({
  isOpenModal,
  handleCloseModal,
  onSubmit: onParentSubmit,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { values, handleBlur, setFieldValue, errors, handleSubmit } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        goal: "",
        minutesPerWorkout: "60",
      },
      onSubmit: (params) => {
        onParentSubmit({
          goal: params.goal,
          minutesPerWorkout: params.minutesPerWorkout,
        });
      },
    }
  );

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box sx={sharedStyles.containers.modal}>
        <Box sx={sharedStyles.containers.modalContent}>
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              Generate
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
            }}
          >
            This will generate a workout program for the client based on their
            current training type and their historical training data.
            <br />
            <br />
            You will be able to review the program and make any necessary
            changes prior to sending it to the client.
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              mt: 2,
            }}
            onClick={() => handleSubmit()}
          >
            <Icon>auto_awesome</Icon> Generate
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateAiWorkoutProgramModal };
