import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { CompletedExerciseHistory } from "shared/api";

interface TrainingInsightsTableForRepsProps {
  completedData: CompletedExerciseHistory[];
}

const TrainingInsightsTableForReps: FC<TrainingInsightsTableForRepsProps> = ({
  completedData,
}) => {
  const combinedRows = completedData.map((item, index) => {
    const {
      workoutProgram,
      weekNumber,
      completedReps,
      completedRepsLeftSide,
      completedRepsRightSide,
      completedPartialReps,
      completedPartialRepsLeftSide,
      completedPartialRepsRightSide,
      maxPrescribedRepsPerSet,
      totalPrescribedSets,
    } = item;

    return {
      workoutProgramNameAndWeek: `${workoutProgram} - Week ${weekNumber}`,
      completed: completedReps,
      completedLeftSide: completedRepsLeftSide,
      completedRightSide: completedRepsRightSide,
      partiallyCompleted: completedPartialReps,
      partiallyCompletedLeftSide: completedPartialRepsLeftSide,
      partiallyCompletedRightSide: completedPartialRepsRightSide,
      prescribed: maxPrescribedRepsPerSet,
      sets: totalPrescribedSets,
    };
  });

  const containsCompleted = combinedRows.some((a) => a.completed);
  const containsCompletedLeftSide = combinedRows.some(
    (a) => a.completedLeftSide
  );
  const containsCompletedRightSide = combinedRows.some(
    (a) => a.completedRightSide
  );
  const containsPartiallyCompleted = combinedRows.some(
    (a) => a.partiallyCompleted
  );
  const containsPartiallyCompletedLeftSide = combinedRows.some(
    (a) => a.partiallyCompletedLeftSide
  );
  const containsPartiallyCompletedRightSide = combinedRows.some(
    (a) => a.partiallyCompletedRightSide
  );

  const containsPrescribed = combinedRows.some((a) => a.prescribed);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Program</TableCell>
            {containsPrescribed && (
              <TableCell align="right">Prescribed Reps</TableCell>
            )}
            {containsPrescribed && (
              <TableCell align="right">Total Prescribed Sets</TableCell>
            )}
            {containsCompleted && (
              <TableCell align="right">Total Reps Completed</TableCell>
            )}
            {containsCompletedLeftSide && (
              <TableCell align="right">Total Reps Completed (Left)</TableCell>
            )}
            {containsCompletedRightSide && (
              <TableCell align="right">Total Reps Completed (Right)</TableCell>
            )}
            {containsPartiallyCompleted && (
              <TableCell align="right">
                Total Reps Partially Completed
              </TableCell>
            )}
            {containsPartiallyCompletedLeftSide && (
              <TableCell align="right">
                Total Reps Partially Completed (Left)
              </TableCell>
            )}
            {containsPartiallyCompletedRightSide && (
              <TableCell align="right">
                Total Reps Partially Completed (Right)
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedRows.map((row) => (
            <TableRow
              key={`${row.workoutProgramNameAndWeek}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.workoutProgramNameAndWeek}</TableCell>
              {containsPrescribed && (
                <TableCell align="right">{row.prescribed} / set</TableCell>
              )}
              {containsPrescribed && (
                <TableCell align="right">{row.sets}</TableCell>
              )}
              {containsCompleted && (
                <TableCell align="right">{row.completed}</TableCell>
              )}
              {containsCompletedLeftSide && (
                <TableCell align="right">{row.completedLeftSide}</TableCell>
              )}
              {containsCompletedRightSide && (
                <TableCell align="right">{row.completedRightSide}</TableCell>
              )}
              {containsPartiallyCompleted && (
                <TableCell align="right">{row.partiallyCompleted}</TableCell>
              )}
              {containsPartiallyCompletedLeftSide && (
                <TableCell align="right">
                  {row.partiallyCompletedLeftSide}
                </TableCell>
              )}
              {containsPartiallyCompletedRightSide && (
                <TableCell align="right">
                  {row.partiallyCompletedRightSide}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TrainingInsightsTableForReps };
