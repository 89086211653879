import {
  ButtonGroup,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { Timeline } from "shared/api";
import {
  useGetAllTimelinesQuery,
  useGetTimelinesCountQuery,
  useGetTimelinesQuery,
  useClearAllUnreadNotificationsMutation,
} from "shared/api/timeline/timelines";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { TimelineCard } from "../TimelineCard";
import { TimelineSkeleton } from "./TimelineSkeleton";

const PAGE_SIZE_OPTIONS = [3, 6, 9];

const TimelineContent: FC = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(9);
  const [page, setPage] = useState(1);
  const { data: timelinesLength } = useGetTimelinesCountQuery();
  const { data, isLoading, refetch, isFetching } = useGetTimelinesQuery({
    count: pageSize,
    page,
    orderByUnreadItems: true,
  });
  const { data: allTimelinesData, isLoading: isLoadingAllItems } =
    useGetAllTimelinesQuery();

  const [
    trigger,
    { isLoading: isLoadingClearAllNotifications, isSuccess, isError },
  ] = useClearAllUnreadNotificationsMutation();
  const [timelinesData, setTimelinesData] = useState<Timeline[]>([]);
  const totalPages = Math.ceil(timelinesLength! / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [searchValue, setSearchValue] = useState<string>("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    refetch();
  }, []);

  const handleClearAllUnreadNotifications = () => {
    trigger();
    setShowSnackbar(true);
  };

  useEffect(() => {
    if (!data) return;
    if (searchValue) {
      const filteredData = allTimelinesData?.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      setTimelinesData(filteredData!);
      setPage(1);
      setPageSize(100);
    } else {
      setTimelinesData(data);
    }
  }, [searchValue, data]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "520px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: "32px",
        mb: "16px",
      }}
    >
      <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
        <Typography sx={sharedStyles.headings.dataGrid}>
          {t("timeline.sub-title")}
        </Typography>
        <TextField
          label="Search by user..."
          variant="standard"
          value={searchValue}
          onChange={handleOnChange}
          disabled={isLoadingAllItems}
        />
      </Box>
      {isLoading || isFetching ? (
        <TimelineSkeleton />
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            height: 400,
            overflow: "auto",
          }}
        >
          {timelinesData?.map((item, key) => (
            <Grid item xs={6} sm={4} key={key}>
              <TimelineCard timeline={item} />
            </Grid>
          ))}
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <LoadingButton
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleClearAllUnreadNotifications}
          disabled={isLoading || isLoadingClearAllNotifications}
          loading={isLoading || isLoadingClearAllNotifications}
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            textTransform: "none",
            paddingRight: "16px",
          }}
        >
          {t("timeline.mark-all-as-read")}
        </LoadingButton>
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Typography variant="body2">Rows per page: </Typography>
          <Select
            defaultValue={pageSize}
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value as number);
            }}
            size="small"
          >
            {PAGE_SIZE_OPTIONS.map((item, key) => (
              <MenuItem value={item} key={key}>
                {item}
              </MenuItem>
            ))}
          </Select>

          {!searchValue && (
            <Typography variant="body2">
              {startIndex + 1}-{Math.min(endIndex, timelinesLength!)} of{" "}
              {timelinesLength!}
            </Typography>
          )}
          <ButtonGroup>
            <IconButton
              onClick={() => {
                setPage((prevPage) => Math.max(prevPage - 1, 1));
              }}
              disabled={page === 1}
            >
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => {
                setPage((prevPage) => Math.min(prevPage + 1, totalPages));
              }}
              disabled={page === totalPages}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
          </ButtonGroup>
        </Box>
      </Box>
      {isSuccess && showSnackbar && (
        <SnackbarAlert
          isOpen={isSuccess}
          onClose={() => setShowSnackbar(false)}
          severity="success"
          message="All notifications have been marked as read."
        />
      )}
      {isError && showSnackbar && (
        <SnackbarAlert
          isOpen={isError}
          onClose={() => setShowSnackbar(false)}
          severity="error"
          message="An error occurred while marking all notifications as read."
        />
      )}
    </Box>
  );
};

export { TimelineContent };
