import { FC } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useGetUsersQuery } from "../../shared/api";

interface UserNotesClientSelectorProps {
  setSelectedUserId: (id: number | null) => void;
}

const UserNotesClientSelector: FC<UserNotesClientSelectorProps> = ({
  setSelectedUserId,
}) => {
  const { data: users, isLoading } = useGetUsersQuery({
    userRole: "athlete",
    orderByName: true,
  });

  return (
    <Box>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <FormControl
          sx={{
            width: "100%",
            height: "56px",
            mb: 1,
            mt: "10px",
            minWidth: 250,
          }}
          variant="outlined"
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Select Client
          </InputLabel>
          <Select
            label="Select Client"
            onChange={(e: { target: { value: any } }) =>
              setSelectedUserId(e.target.value)
            }
            defaultValue=""
          >
            {users?.map((user) => (
              <MenuItem value={user.id} key={user.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export { UserNotesClientSelector };
