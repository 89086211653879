import { Box } from "@mui/material";
import { FC } from "react";
import ReactPlayer from "react-player/lazy";

type WorkoutExerciseVideoProps = {
  videoUrl?: string;
};
// eslint-disable-next-line
const WorkoutExerciseVideo: FC<WorkoutExerciseVideoProps> = ({ videoUrl }) => {
  return (
    <Box sx={{ width: 343 }}>
      <ReactPlayer
        url={videoUrl}
        // eslint-disable-next-line
        controls={true}
        height={214}
        width={343}
        // eslint-disable-next-line
        pip={true}
        // eslint-disable-next-line
        light={true}
      />
    </Box>
  );
};

export { WorkoutExerciseVideo };
