import { createApi } from "@reduxjs/toolkit/query/react";
import { UserPersonalRecord, CreateUserPersonalRecordParams } from "./models";
import { baseQuery } from "../baseQuery";

export const userPersonalRecordsApi = createApi({
  baseQuery,
  reducerPath: "userPersonalRecordsApi",
  tagTypes: ["UserPersonalRecord"],
  endpoints: (builder) => ({
    getUserPersonalRecordsByUserId: builder.query<UserPersonalRecord[], number>(
      {
        query: (id) => ({
          url: `/user-personal-records/user/${id}`,
          method: "GET",
        }),
      }
    ),
    createUserPersonalRecord: builder.mutation<
      void,
      CreateUserPersonalRecordParams
    >({
      query: ({ exerciseName, userId, value }) => ({
        url: "/user-personal-records",
        method: "POST",
        body: {
          exerciseName,
          userId,
          value,
        },
      }),
    }),
  }),
});

export const {
  useGetUserPersonalRecordsByUserIdQuery,
  useCreateUserPersonalRecordMutation,
} = userPersonalRecordsApi;
