import { FC } from "react";
import { Typography } from "@mui/material";
import { TimelineItem } from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import Image from "mui-image";
import { Colors } from "shared/themes";
import ReactPlayer from "react-player/lazy";

interface TimelineMediaProps {
  timelineItem: TimelineItem;
}
const videoSuffixes = [".mp4", ".avi", ".mov", ".wmv", ".mkv"];
const imageSuffixes = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

const TimelineMedia: FC<TimelineMediaProps> = ({ timelineItem }) => {
  const isVideo = videoSuffixes.some((suffix) =>
    timelineItem?.content?.toLowerCase().includes(suffix)
  );

  const isImage = imageSuffixes.some((suffix) =>
    timelineItem?.content?.toLowerCase().includes(suffix)
  );

  return (
    <Typography sx={sharedStyles.body.timeline.cardBody}>
      {isVideo && (
        <ReactPlayer
          url={timelineItem.mediaUrl}
          // eslint-disable-next-line
          controls={true}
          // eslint-disable-next-line
          pip={true}
          // eslint-disable-next-line
          light={true}
        />
      )}
      {isImage && (
        <Image
          src={timelineItem.mediaUrl}
          duration={250}
          width="200px"
          height="200px"
          title={timelineItem.content}
        />
      )}
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          color: `${Colors.blue[1200]}`,
          cursor: "pointer",
        }}
        onClick={() => window.open(timelineItem.mediaUrl, "_blank")}
      >
        {timelineItem.content}
      </Typography>
    </Typography>
  );
};

export { TimelineMedia };
