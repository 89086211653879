import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { useTypedSelector } from "shared/stores";
import { UsersContainer } from "./UsersContainer";
import { InviteClientModal } from "../../shared/ui";

interface UsersProps {
  openSidebar: () => void;
}

const Users: FC<UsersProps> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const { t } = useTranslation();

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("users-management.title")}
        </Typography>
        <div id="invite-client">
          <PrimaryButton
            value={t("users-management.add-btn")}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleToggleModal}
            icon="add_icon"
          />
        </div>
      </Box>
      <UsersContainer />

      <InviteClientModal
        handleCloseModal={handleToggleModal}
        isOpenModal={isOpenModal}
      />
    </Box>
  );
};

export { Users };
