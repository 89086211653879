import { createApi } from "@reduxjs/toolkit/query/react";
import {
  WorkflowTemplateLibraryResponse,
  WorkflowTemplateLibraryRequest,
} from "./models";
import { Workflow } from "../workflows/models";
import { baseQuery } from "../baseQuery";

export const workflowTemplateLibraryApi = createApi({
  baseQuery,
  reducerPath: "workflowTemplateLibraryApi",
  endpoints: (builder) => ({
    getWorkflowLibraryTemplates: builder.query<
      WorkflowTemplateLibraryResponse[],
      void
    >({
      query: () => ({
        url: "/workflow-template-library",
        method: "GET",
      }),
    }),
    getWorkflowLibraryTemplateItemByName: builder.query<
      WorkflowTemplateLibraryResponse,
      string
    >({
      query: (name) => ({
        url: `/workflow-template-library/${name}`,
        method: "GET",
      }),
    }),
    addWorkflowTemplateItemByNameToOrganisation: builder.mutation<
      Workflow,
      WorkflowTemplateLibraryRequest
    >({
      query: ({ name }) => ({
        url: `/workflow-template-library`,
        method: "POST",
        body: {
          name,
        },
      }),
    }),
  }),
});

export const {
  useGetWorkflowLibraryTemplatesQuery,
  useGetWorkflowLibraryTemplateItemByNameQuery,
  useAddWorkflowTemplateItemByNameToOrganisationMutation,
} = workflowTemplateLibraryApi;
