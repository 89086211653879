import { Box } from "@mui/system";
import { FC } from "react";
import { sharedStyles } from "shared/themes/shared/styles";

interface InvoiceStatusItemProps {
  status?: string;
}

const InvoiceStatusItem: FC<InvoiceStatusItemProps> = ({ status }) => {
  const statusBodyStyle = (status: string) => {
    switch (status) {
      case "open":
        return sharedStyles.containers.dataGrid.leadsNew;
      case "paid":
        return sharedStyles.containers.dataGrid.leadsWon;
      case "void":
        return sharedStyles.containers.dataGrid.leadsUnqualified;
      case "overdue":
        return sharedStyles.containers.dataGrid.leadsLost;
      default:
        return sharedStyles.containers.dataGrid.leadsNew;
    }
  };

  return (
    <Box
      sx={{
        ...statusBodyStyle(status!),
      }}
    >
      {status}
    </Box>
  );
};

export { InvoiceStatusItem };
