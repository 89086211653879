import { Box } from "@mui/system";
import { FC } from "react";
import { WorkoutPrograms } from "./ui/WorkoutPrograms";

interface AthleteProgramsProps {
  closeSidebar: () => void;
}

const AthletePrograms: FC<AthleteProgramsProps> = ({ closeSidebar }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "90vh",
      }}
    >
      <WorkoutPrograms closeSidebar={closeSidebar} />
    </Box>
  );
};

export { AthletePrograms };
