import { Box, Container } from "@mui/system";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Image from "mui-image";
import { Icon, Typography, useMediaQuery } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { setIsModified } from "shared/stores/workoutBuilder/slice";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { SidebarUserProfile } from "../SidebarUserProfile";
import { SidebarOrganisation } from "../SidebarOrganisation";
import { Colors, Images } from "../../../themes";
import { navData } from "./constants";

interface AdminSideNavigationProps {
  isOpenSidebar: boolean;
  openSidebar: (() => void) | undefined;
}

const AdminSideNavigation: FC<AdminSideNavigationProps> = ({
  isOpenSidebar,
  openSidebar,
}) => {
  const matches = useMediaQuery("(min-width:900px)");
  const { t } = useTranslation();
  const isAdmin = useTypedSelector(userSelectors.isAdmin);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const isModified = useTypedSelector(
    (state) => state.workoutBuilder.isModified
  );

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    if (isModified) {
      // eslint-disable-next-line no-alert
      const userConfirmed = window.confirm(
        t("workout-program-builder.unsaved-changes")
      );
      if (userConfirmed) {
        dispatch(setIsModified(false));
        navigate(e.currentTarget.pathname);
      }
    } else {
      navigate(e.currentTarget.pathname);
    }
  };

  return (
    <Container
      sx={{
        padding: "24px",
        height: "100vh",
        width: "220px",
        position: "fixed",
        top: 0,
        left: 0,
        bgcolor: `${Colors.gray[50]}`,
        borderRight: `1px solid ${Colors.gray[300]}`,
        transform: isOpenSidebar ? "translateX(0%)" : "translateX(-100%)",
        transition: "0.5s",
        zIndex: 1000,
      }}
    >
      <Image src={Images.Logo} duration={600} width="108px" height="34px" />

      <Box
        sx={{
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginBottom: "27px",
          marginTop: "21px",
        }}
      >
        <Box>
          {navData.map((item) =>
            (item.adminOnly && isAdmin) || !item.adminOnly ? (
              <div key={item.id} id={item.navId ?? item.content}>
                <Box
                  sx={{
                    width: "172px",
                    minHeight: "20px",
                    maxHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    marginBottom: "15px",
                  }}
                >
                  <Icon
                    fontSize="small"
                    baseClassName="material-icons-outlined"
                    sx={{ color: `${Colors.gray[900]}` }}
                  >
                    {item.icon}
                  </Icon>
                  <Typography
                    sx={{
                      m: 0,
                      ml: "10px",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "140%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <NavLink
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: `${Colors.blue[1200]}`,
                              textDecoration: "none",
                            }
                          : {
                              color: `${Colors.gray[900]}`,
                              textDecoration: "none",
                            }
                      }
                      to={item.link}
                      onClick={handleClick}
                    >
                      {item.title}
                    </NavLink>
                  </Typography>
                </Box>
              </div>
            ) : null
          )}
        </Box>
        {isAdmin && <SidebarOrganisation />}
      </Box>
      <Box
        sx={{
          height: "22%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <SidebarUserProfile />
      </Box>
    </Container>
  );
};

export { AdminSideNavigation };
