import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type GridRowHeaderKeyValue = { [key: number]: any[] };
export type ApiStatus = "pending" | "completed" | "idle" | "failed";

export interface WorkoutBuilderState {
  gridRowHeaders: GridRowHeaderKeyValue;
  applyChanges: ApiStatus;
  isModified: boolean;
}

const initialState: WorkoutBuilderState = {
  gridRowHeaders: {},
  applyChanges: "idle",
  isModified: false,
};

const workoutBuilderSlice = createSlice({
  name: "workoutBuilderSlice",
  initialState,
  reducers: {
    updateGridRowHeaders: (
      state,
      action: PayloadAction<GridRowHeaderKeyValue>
    ) => {
      state.gridRowHeaders = {
        ...state.gridRowHeaders,
        ...action.payload,
      };
    },
    setApplyChanges: (
      state,
      action: PayloadAction<"pending" | "completed" | "idle" | "failed">
    ) => {
      state.applyChanges = action.payload;
    },
    setIsModified: (state, action: PayloadAction<boolean>) => {
      state.isModified = action.payload;
    },
    resetGridRows: (state) => {
      state.gridRowHeaders = {};
    },
  },
});

export const {
  updateGridRowHeaders,
  setApplyChanges,
  setIsModified,
  resetGridRows,
} = workoutBuilderSlice.actions;

export default workoutBuilderSlice.reducer;
