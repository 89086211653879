import { createTheme } from "@mui/material";

export const Themes = createTheme({
  palette: {
    primary: {
      main: "#2500F5",
    },
    error: {
      main: "#D70200",
    },
    success: {
      main: "#009D52",
    },
  },
});
