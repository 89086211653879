import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { CompletedExerciseHistory } from "shared/api";
import { YMultiAxisType } from "./TrainingInsightsMultiAxisLineChart";
import { SeriesType } from "./constants";

interface TrainingInsightsTableForAveragesProps {
  completedData: CompletedExerciseHistory[];
  yAxisSeries: YMultiAxisType[];
}

const TrainingInsightsTableForAverages: FC<
  TrainingInsightsTableForAveragesProps
> = ({ completedData, yAxisSeries }) => {
  const combinedRows = completedData.map((item, index) => {
    const { workoutProgram, weekNumber } = item;

    const averageRepsCompleted = yAxisSeries.find(
      (a) => a.type === "y" && a.name === SeriesType.AverageRepsCompleted
    )?.values[index];
    const averagePartialRepsCompleted = yAxisSeries.find(
      (a) => a.type === "y" && a.name === SeriesType.AveragePartialRepsCompleted
    )?.values[index];
    const averageWeightCompleted = yAxisSeries.find((a) => a.type === "y1")
      ?.values[index];

    return {
      workoutProgramNameAndWeek: `${workoutProgram} - Week ${weekNumber}`,
      averageRepsCompleted,
      averagePartialRepsCompleted,
      averageWeightCompleted,
    };
  });

  const containsPartialReps = combinedRows.some(
    (a) => a.averagePartialRepsCompleted !== 0 && a.averagePartialRepsCompleted
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Program</TableCell>
            <TableCell>Total Reps Completed</TableCell>
            {containsPartialReps && (
              <TableCell>Total Partial Reps Completed</TableCell>
            )}
            <TableCell>Best Weight Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {combinedRows.map((row) => (
            <TableRow
              key={`${row.workoutProgramNameAndWeek}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.workoutProgramNameAndWeek}</TableCell>
              <TableCell>{row.averageRepsCompleted}</TableCell>
              {containsPartialReps && (
                <TableCell>{row.averagePartialRepsCompleted}</TableCell>
              )}
              <TableCell>{row.averageWeightCompleted}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TrainingInsightsTableForAverages };
