import { createSlice } from "@reduxjs/toolkit";
import { workoutProgramDesignerApi } from "shared/api";

export interface WorkoutProgramStatusReducer {
  isLoadingGetWorkoutProgramDesigns: boolean;
  isLoadingCreateNewWorkoutProgramDesign: boolean;
  isLoadingUpdateDraftWorkoutProgramDesign: boolean;
  isLoadingCreateNewVersionOnExistingWorkoutProgram: boolean;
  isLoadingCreateAiWorkoutProgramDesign: boolean;
}

export interface WorkoutProgramStatusState {
  status: WorkoutProgramStatusReducer;
}

const initialState: WorkoutProgramStatusReducer = {
  isLoadingGetWorkoutProgramDesigns: false,
  isLoadingCreateNewWorkoutProgramDesign: false,
  isLoadingUpdateDraftWorkoutProgramDesign: false,
  isLoadingCreateNewVersionOnExistingWorkoutProgram: false,
  isLoadingCreateAiWorkoutProgramDesign: false,
};

const workoutProgramStatusesSlice = createSlice({
  name: "workoutProgramStatusesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // add cases for all the actions generated from the query endpoints
      .addMatcher(
        workoutProgramDesignerApi.endpoints.getWorkoutProgramDesigns
          .matchPending,
        (state) => {
          state.isLoadingGetWorkoutProgramDesigns = true;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.getWorkoutProgramDesigns
          .matchFulfilled,
        (state) => {
          state.isLoadingGetWorkoutProgramDesigns = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.getWorkoutProgramDesigns
          .matchRejected,
        (state) => {
          state.isLoadingGetWorkoutProgramDesigns = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createNewWorkoutProgramDesign
          .matchPending,
        (state) => {
          state.isLoadingCreateNewWorkoutProgramDesign = true;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createNewWorkoutProgramDesign
          .matchFulfilled,
        (state) => {
          state.isLoadingCreateNewWorkoutProgramDesign = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createNewWorkoutProgramDesign
          .matchRejected,
        (state) => {
          state.isLoadingCreateNewWorkoutProgramDesign = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.updateDraftWorkoutProgramDesign
          .matchPending,
        (state) => {
          state.isLoadingUpdateDraftWorkoutProgramDesign = true;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.updateDraftWorkoutProgramDesign
          .matchFulfilled,
        (state) => {
          state.isLoadingUpdateDraftWorkoutProgramDesign = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.updateDraftWorkoutProgramDesign
          .matchRejected,
        (state) => {
          state.isLoadingUpdateDraftWorkoutProgramDesign = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints
          .createNewVersionOnExistingWorkoutProgram.matchPending,
        (state) => {
          state.isLoadingCreateNewVersionOnExistingWorkoutProgram = true;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints
          .createNewVersionOnExistingWorkoutProgram.matchFulfilled,
        (state) => {
          state.isLoadingCreateNewVersionOnExistingWorkoutProgram = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints
          .createNewVersionOnExistingWorkoutProgram.matchRejected,
        (state) => {
          state.isLoadingCreateNewVersionOnExistingWorkoutProgram = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createAiWorkoutProgramDesign
          .matchPending,
        (state) => {
          state.isLoadingCreateAiWorkoutProgramDesign = true;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createAiWorkoutProgramDesign
          .matchFulfilled,
        (state) => {
          state.isLoadingCreateAiWorkoutProgramDesign = false;
        }
      )
      .addMatcher(
        workoutProgramDesignerApi.endpoints.createAiWorkoutProgramDesign
          .matchRejected,
        (state) => {
          state.isLoadingCreateAiWorkoutProgramDesign = false;
        }
      );
  },
});

export default workoutProgramStatusesSlice.reducer;
