import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkoutReducer } from "./types";

const initialState: WorkoutReducer = {
  id: null,
  dayNumber: null,
  weekNumber: null,
  description: "",
  workoutStatus: null,
  workoutProgramId: null,
};

const workout = createSlice({
  name: "workout",
  initialState,
  reducers: {
    setCurrentWorkout: (_, action: PayloadAction<WorkoutReducer>) =>
      action.payload,
  },
});

export const {
  reducer: workoutReducer,
  actions: { setCurrentWorkout },
} = workout;
