import React, { FC } from "react";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface WorkoutProgramBuilderButtonGroupProps {
  activeStep: number;
  handleStep: (step: number) => () => void;
  allowGridStep: boolean;
}

const WorkoutProgramBuilderButtonGroup: FC<
  WorkoutProgramBuilderButtonGroupProps
> = ({ activeStep, handleStep, allowGridStep = false }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "auto",
        height: "auto",
        "& > *:not(:last-child)": {
          marginRight: "16px",
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <LoadingButton
          variant="outlined"
          color="primary"
          onClick={handleStep(0)}
          disabled={activeStep === 0}
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.4px",
            textTransform: "none",
            textDecoration: "none",
          }}
        >
          {t("workout-program-builder.back")}
        </LoadingButton>
        <div id="next-workouts">
          <LoadingButton
            variant="outlined"
            color="primary"
            onClick={handleStep(1)}
            disabled={activeStep === 1 || !allowGridStep}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              ml: "16px",
              letterSpacing: "0.4px",
              textTransform: "none",
              textDecoration: "none",
              cursor: allowGridStep ? "pointer" : "not-allowed",
              pointerEvents: allowGridStep ? "all" : "none",
            }}
          >
            {t("workout-program-builder.next")}
          </LoadingButton>
        </div>
      </Box>
    </Box>
  );
};

export { WorkoutProgramBuilderButtonGroup };
