import { Box, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { CombinedData } from "shared/api";
import { Colors } from "shared/themes";

type DefaultInputScreenWithPartialsProps = {
  values: any;
  handleChange: any;
  handleBlur: any;
  exercise: CombinedData;
};
// eslint-disable-next-line
const DefaultInputScreenWithPartials: FC<
  DefaultInputScreenWithPartialsProps
> = ({ values, handleChange, handleBlur, exercise }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        gap: "4px",
        flex: "none",
        order: 1,
        flexGrow: 0,
        borderRadius: "8px",
        background: `${Colors.gray[200]}`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mb: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontWeight: 700,
            color: `${Colors.gray[800]}`,
          }}
        >
          Your working set
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          mb: "12px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedWeight}
          label={
            exercise.workoutItemWeightUnit === "Lbs"
              ? "Weight (Lbs)"
              : "Weight (Kg)"
          }
          onBlur={handleBlur("completedWeight")}
          onChange={handleChange("completedWeight")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedPartialReps}
          label="Partial reps"
          onBlur={handleBlur("completedPartialReps")}
          onChange={handleChange("completedPartialReps")}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
          gap: "12px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedReps}
          label="Reps"
          onBlur={handleBlur("completedReps")}
          onChange={handleChange("completedReps")}
        />
        <TextField
          sx={{
            width: "50%",
            height: "56px",
            background: "white",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          type="number"
          inputProps={{
            step: "any",
            inputMode: "decimal",
          }}
          value={values.completedIntensity}
          label={exercise.workoutItemType === "RIR" ? "RIR" : "RPE"}
          onBlur={handleBlur("completedIntensity")}
          onChange={handleChange("completedIntensity")}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "0px",
          mt: "12px",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            height: "56px",
            background: "white",
          }}
          value={values.notes}
          label="Notes"
          onBlur={handleBlur("notes")}
          onChange={handleChange("notes")}
        />
      </Box>
    </Box>
  );
};

export { DefaultInputScreenWithPartials };
