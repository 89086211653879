import { FC, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { useAuthoriseFiveTranConnectorMutation } from "../../shared/api";
import { useTypedSelector } from "../../shared/stores";

interface OrganisationIntegrationSetupSuccessProps {
  openSidebar: () => void;
}

const OrganisationIntegrationSetupSuccess: FC<
  OrganisationIntegrationSetupSuccessProps
> = ({ openSidebar }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [authoriseFiveTranConnector, { isLoading }] =
    useAuthoriseFiveTranConnectorMutation();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const apiIntegration = urlParams.get("apiIntegration");
  const organisationId = urlParams.get("organisationId");
  const userOrganisationId = useTypedSelector(
    (state) => state.user.organisationId
  );

  let friendlyApiIntegrationName = "";

  switch (apiIntegration) {
    default:
      friendlyApiIntegrationName = apiIntegration ?? "";
  }

  useEffect(() => {
    const authoriseApiKey = async () => {
      if (apiIntegration && organisationId === userOrganisationId) {
        try {
          await authoriseFiveTranConnector(apiIntegration).unwrap();
          setSuccessMessage(
            t("organisation-management.integrations.setup.success")
          );
        } catch (error: any) {
          setErrorMessage(t("errors.server-unable"));
        }
      }
    };
    authoriseApiKey();
  }, [apiIntegration, authoriseFiveTranConnector, t]);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      {isLoading ? (
        /* eslint-disable react/jsx-no-useless-fragment */
        <>
          <Box sx={sharedStyles.containers.sidebar}>
            <Typography>
              {t("organisation-management.integrations.setup.loading")}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box sx={sharedStyles.containers.sidebar.heading}>
            <Typography sx={sharedStyles.headings.sidebar}>
              {friendlyApiIntegrationName}{" "}
              {t("organisation-management.integrations.setup.title")}
            </Typography>
          </Box>
          <Box sx={sharedStyles.containers.sidebar}>
            <Typography>
              {t("organisation-management.integrations.setup.description")}
            </Typography>
          </Box>
        </>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export { OrganisationIntegrationSetupSuccess };
