import { FC } from "react";
import { useTypedSelector } from "../../stores";
import { userSelectors } from "../../stores/user";
import { AdminSideNavigation } from "./AdminSideNavigation";
import { AthleteSideNavigation } from "./AthleteSideNavigation";

interface SidebarProps {
  isOpenSidebar: boolean;
  closeSidebar?: () => void;
  openSidebar?: () => void;
}

const Sidebar: FC<SidebarProps> = ({
  isOpenSidebar,
  closeSidebar,
  openSidebar,
}) => {
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const hasRole = useTypedSelector(userSelectors.hasRole);

  if (!hasRole) {
    return null;
  }

  if (isAdminOrCoach) {
    return (
      <AdminSideNavigation
        isOpenSidebar={isOpenSidebar}
        openSidebar={openSidebar}
      />
    );
  }

  return (
    <AthleteSideNavigation
      isOpenSidebar={isOpenSidebar}
      closeSidebar={closeSidebar}
    />
  );
};
export { Sidebar };
