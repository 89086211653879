/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { sharedStyles } from "shared/themes/shared/styles";
import { ClientResponse } from "shared/api";
import { ClientInsights } from "pages/Users/ClientInsights";

interface WorkoutProgramGridHeaderProps {
  selectedUserValue: ClientResponse | null;
}

const WorkoutProgramGridHeader: React.FC<WorkoutProgramGridHeaderProps> = ({
  selectedUserValue,
}) => {
  const { t } = useTranslation();
  const userExistsAndHasLiftingProfile =
    selectedUserValue && selectedUserValue.userLiftingProfileType;
  return (
    <>
      {userExistsAndHasLiftingProfile && (
        <Box
          sx={{
            mt: 4,
            mb: 2,
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <ClientInsights selectedUserId={selectedUserValue?.id} />
            <Grid item xs={12} md={12}>
              <TrainingInsightsContent
                preselectedUserId={selectedUserValue?.id}
                showOverviewOnly
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export { WorkoutProgramGridHeader };
