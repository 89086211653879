import { FC, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { AnyType, addTimezoneToUtcDate } from "shared/helpers";
import { Colors } from "shared/themes";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import {
  CreateLeadRequest,
  Lead,
  useUpdateLeadMutation,
  CreateLeadNoteRequest,
  useCreateLeadNoteMutation,
  useConvertLeadToClientMutation,
  leadApi,
} from "shared/api";

const IndividualLead: FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const getLead = leadApi.endpoints.getLead.initiate(Number(params.id), {
    forceRefetch: true,
  });

  const [lead, setLead] = useState<Lead>();

  async function callGetLead() {
    try {
      setErrorMessage("");
      setSuccessMessage("");
      setIsLoading(true);
      const response = await dispatch(getLead);
      setLead(response.data ?? undefined);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setFieldValue("leadStatus", response.data?.leadStatus);
    } catch (error: any) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      setIsLoading(false);
    }
  }

  const LeadSchema = Yup.object().shape({
    firstName: Yup.string().min(2, "Min 2").required("This field is required"),
    lastName: Yup.string().min(2, "Min 2").required("This field is required"),
  });

  const [updateLead] = useUpdateLeadMutation();

  const onSubmit = async (params: CreateLeadRequest) => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      setIsLoading(true);
      await updateLead(params).unwrap();
      await callGetLead();
      setSuccessMessage("Lead updated successfully");
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
      setIsLoading(false);
      window.setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
    }
  };

  const [convertLeadToClientMutation] = useConvertLeadToClientMutation();

  const convertLeadToClient = async () => {
    if (lead?.id) {
      try {
        setSuccessMessage("");
        setErrorMessage("");
        setIsLoading(true);
        const leadId = lead.id ?? 0;
        await convertLeadToClientMutation(leadId).unwrap();
        await callGetLead();
        setSuccessMessage("Lead converted to client successfully");
      } catch (error: AnyType) {
        setErrorMessage(error?.data?.message);
      } finally {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetForm();
        setIsLoading(false);
        window.setTimeout(() => {
          setSuccessMessage("");
          setErrorMessage("");
        }, 5000);
      }
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<CreateLeadRequest>({
    initialValues: {
      id: lead?.id,
      firstName: lead?.firstName,
      lastName: lead?.lastName,
      email: lead?.email,
      phoneNumber: lead?.phoneNumber,
      leadStatus: lead?.leadStatus,
      address: lead?.address,
      city: lead?.city,
      state: lead?.state,
      postcode: lead?.postcode,
      country: lead?.country,
    },
    enableReinitialize: true,
    validationSchema: LeadSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const [leadNotes, setLeadNote] = useState("");

  const [createLeadNote] = useCreateLeadNoteMutation();

  const onSubmitLeadNote = async (params: CreateLeadNoteRequest) => {
    try {
      if (!lead?.id) return;
      if (leadNotes === "") return;
      setSuccessMessage("");
      setErrorMessage("");
      setIsLoading(true);
      params.leadId = lead?.id;
      params.note = leadNotes;
      await createLeadNote(params).unwrap();
      await callGetLead();
      setSuccessMessage("Note added successfully");
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setLeadNote("");
      setIsLoading(false);
      window.setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    callGetLead();
  }, []);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {lead?.firstName} {lead?.lastName}
        </Typography>
      </Box>
      {errorMessage && (
        <Alert
          sx={{
            mt: 3,
            width: "100%",
          }}
          variant="outlined"
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}

      {successMessage && (
        <Alert
          sx={{
            mt: 3,
            width: "100%",
          }}
          variant="outlined"
          severity="success"
        >
          {successMessage}
        </Alert>
      )}
      {lead ? (
        <Box
          sx={{
            ...sharedStyles.containers.dataGrid.wrapper,
            overflow: "auto",
            height: "80vh",
          }}
        >
          <Grid container spacing={2} sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                type="text"
                label="First Name"
                value={values.firstName}
                onBlur={handleBlur("firstName")}
                onChange={handleChange("firstName")}
                sx={{
                  height: "40%",
                  width: "95%",
                  mb: { xs: "15px", md: "0px" },
                }}
                inputProps={{ style: { marginTop: "3px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                type="text"
                label="Last Name"
                value={values.lastName}
                onBlur={handleBlur("lastName")}
                onChange={handleChange("lastName")}
                sx={{
                  height: "40%",
                  width: "95%",
                  mb: { xs: "15px", md: "0px" },
                }}
                inputProps={{ style: { marginTop: "3px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                type="email"
                label="Email"
                disabled
                value={values.email}
                onBlur={handleBlur("email")}
                onChange={handleChange("email")}
                sx={{
                  height: "40%",
                  width: "95%",
                  mb: { xs: "15px", md: "0px" },
                }}
                inputProps={{ style: { marginTop: "3px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                type="text"
                label="Phone Number"
                value={values.phoneNumber}
                onBlur={handleBlur("phoneNumber")}
                onChange={handleChange("phoneNumber")}
                sx={{
                  height: "40%",
                  width: "95%",
                  mb: { xs: "15px", md: "0px" },
                }}
                inputProps={{ style: { marginTop: "3px" } }}
              />
            </Grid>
            <FormControl
              sx={{ width: "100%", mb: 2, mt: 2 }}
              variant="outlined"
            >
              <InputLabel>Status</InputLabel>
              <Select
                value={values.leadStatus || lead?.leadStatus}
                defaultValue={values.leadStatus}
                onBlur={handleBlur("leadStatus")}
                onChange={(e) => setFieldValue("leadStatus", e.target.value)}
                sx={{
                  width: "100%",
                  paddingTop: "3px",
                }}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="InProgress">In Progress</MenuItem>
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Unqualified">Unqualified</MenuItem>
                <MenuItem value="Won">Won</MenuItem>
                <MenuItem value="Lost">Lost</MenuItem>
              </Select>
            </FormControl>
            {lead && !lead?.clientId && (
              <>
                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  variant="outlined"
                  color="primary"
                  sx={{ textTransform: "none", mt: 2, ml: 2 }}
                  onClick={() => handleSubmit()}
                >
                  Update Lead
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  variant="outlined"
                  color="primary"
                  sx={{ textTransform: "none", mt: 2, ml: 2 }}
                  onClick={() => convertLeadToClient()}
                >
                  Convert to Client
                </LoadingButton>
              </>
            )}
          </Grid>
          {lead?.leadNotes && lead?.leadNotes?.length > 0 && (
            <>
              <Divider />
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Notes
                </Typography>
                {lead && !lead?.clientId && (
                  <>
                    <TextField
                      type="text"
                      label="Note"
                      value={leadNotes}
                      onChange={(e) => setLeadNote(e.target.value)}
                      sx={{
                        height: "40%",
                        width: "95%",
                        mt: 2,
                      }}
                      inputProps={{ style: { marginTop: "3px" } }}
                    />
                    <LoadingButton
                      loading={isLoading}
                      disabled={isLoading}
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        onSubmitLeadNote({
                          leadId: lead?.id,
                        })
                      }
                      sx={{ textTransform: "none", mt: 2 }}
                    >
                      Save Note
                    </LoadingButton>
                  </>
                )}
              </Box>
            </>
          )}

          {lead?.leadNotes?.map((note) => (
            <Card
              key={note?.id}
              sx={{
                backgroundColor: `${Colors.gray[100]}`,
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {addTimezoneToUtcDate(note.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {note?.note}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Box sx={sharedStyles.containers.dataGrid.wrapper}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Loading...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export { IndividualLead };
