import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  TextField,
  Typography,
  Alert,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  Input,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Colors } from "../../themes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "../../helpers";
import {
  useGetAllUsersQuery,
  UserRole,
  WorkoutProgramUploadSubmitValues,
  ClientResponse,
  useUploadWorkoutProgramMutation,
} from "../../api";

interface UploadNewWorkoutProgramCsvModalProps {
  isOpenModal: boolean;
  uploadWorkoutProgramUserId?: number | null;
  handleCloseModal: () => void;
}

const UploadNewWorkoutProgramCsvModal: FC<
  UploadNewWorkoutProgramCsvModalProps
> = ({ isOpenModal, handleCloseModal, uploadWorkoutProgramUserId }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const [selectedUserValue, setSelectedUserValue] =
    useState<ClientResponse | null>(null);
  const [selectedDateValue, setSelectedDateValue] = useState<Date | null>(null);
  const [selectedProgramTitleValue, setSelectedProgramTitleValue] = useState<
    string | null
  >(null);
  const [
    selectedWorkoutProgramStatusValue,
    setSelectedWorkoutProgramStatusValue,
  ] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadWorkoutProgram, { isLoading }] =
    useUploadWorkoutProgramMutation();
  const { data: allUsers, isLoading: isLoadingAllUsers } = useGetAllUsersQuery(
    {}
  );
  const onlyAthletes = allUsers?.filter(
    (item) => item.userRole === UserRole.Athlete
  );

  const filtered = onlyAthletes?.filter(
    (item) => item.id === uploadWorkoutProgramUserId
  );

  const onSubmit = async (params: WorkoutProgramUploadSubmitValues) => {
    setErrorMessage("");
    const formData = new FormData();

    if (selectedUserValue) {
      formData.append("userId", selectedUserValue.id.toString());
    }

    if (selectedWorkoutProgramStatusValue) {
      formData.append(
        "workoutProgramStatus",
        selectedWorkoutProgramStatusValue
      );
    }

    if (selectedDateValue) {
      formData.append("startDate", selectedDateValue.toISOString());
    }

    if (selectedProgramTitleValue) {
      formData.append("programTitle", selectedProgramTitleValue);
    }

    if (file) {
      formData.append("file", file);
    }

    try {
      await uploadWorkoutProgram(formData).unwrap();

      setSuccessMessage(t("workout-program-upload-modal.upload-success"));
    } catch (error: AnyType) {
      setErrorMessage(
        error?.data?.message || t("workout-program-upload-modal.upload-error")
      );
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, resetForm } =
    useFormik({
      initialValues: {
        formData: new FormData(),
        workoutProgramStatus: "",
        userId: 0,
      },
      onSubmit: () => {
        const formData = new FormData();

        formData.append("userId", "14");
        formData.append("workoutProgramStatus", "completed");
        if (file) {
          formData.append("file", file);
        }

        const workoutProgramUploadSubmitValue = {
          formData,
        } as WorkoutProgramUploadSubmitValues;
        onSubmit(workoutProgramUploadSubmitValue);
      },
    });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFile(file);
      values.formData.append("file", file);
    }
  };

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "400px",
            height: "auto",
            background: `${Colors.gray[100]}`,
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {t("workout-program-upload-modal.header")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>
          <Typography
            sx={{
              width: "100%",
              height: "44px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "140%",
              color: `${Colors.blue[1300]}`,
              marginBottom: "8px",
            }}
          >
            {t("workout-program-upload-modal.content")}
          </Typography>
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: `${Colors.gray[2400]}`,
              mb: "10px",
            }}
          >
            {t("workout-program-upload-modal.client-text")}
          </Typography>
          {!isLoadingAllUsers && onlyAthletes !== undefined ? (
            <Autocomplete
              size={matches ? "medium" : "small"}
              sx={{ width: "100%", mt: "-10px", mb: "16px" }}
              options={onlyAthletes}
              defaultValue={null}
              renderInput={(params) => <TextField {...params} />}
              onChange={(_e, user) => {
                setSelectedUserValue(user);
              }}
              value={
                uploadWorkoutProgramUserId ? filtered![0] : selectedUserValue
              }
              getOptionLabel={(item) =>
                `${`${item.firstName} ${item.lastName}`} - ${item.email}`
              }
            />
          ) : null}
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: `${Colors.gray[2400]}`,
            }}
          >
            {t("workout-program-upload-modal.program-title")}
          </Typography>
          <TextField
            size={matches ? "medium" : "small"}
            sx={{ width: "100%", mb: "16px" }}
            type="text"
            value={selectedProgramTitleValue}
            onChange={(e) => setSelectedProgramTitleValue(e.target.value)}
          />
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: `${Colors.gray[2400]}`,
            }}
          >
            {t("workout-program-upload-modal.start-date")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={selectedDateValue}
              onChange={(date) => setSelectedDateValue(date)}
              renderInput={(params: any) => (
                <TextField
                  sx={{
                    width: "100%",
                    mb: { xs: "15px", md: "10px" },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Typography
            sx={{
              width: "100%",
              height: "5%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              mt: "10px",
              color: `${Colors.gray[2400]}`,
            }}
          >
            {t("workout-program-upload-modal.status")}
          </Typography>
          <Typography sx={{}}>
            {selectedWorkoutProgramStatusValue ?? ""}
          </Typography>
          <FormControl
            sx={{
              width: "100%",
              height: "56px",
              mb: "24px",
            }}
          >
            <Select
              sx={{
                width: "100%",
                height: "56px",
                mb: "24px",
                mt: "10px",
                color: `${Colors.gray[1400]}`,
              }}
              value={values.workoutProgramStatus}
              onChange={(_e) => {
                setSelectedWorkoutProgramStatusValue(_e.target.value);
              }}
              onBlur={handleBlur("workoutProgramStatus")}
              error={!!errors.workoutProgramStatus}
            >
              <MenuItem value="Draft">
                {t("workout-program-upload-modal.status-draft")}
              </MenuItem>
              <MenuItem value="NotStarted">
                {t("workout-program-upload-modal.status-not-started")}
              </MenuItem>
              <MenuItem value="InProgress">
                {t("workout-program-upload-modal.status-in-progress")}
              </MenuItem>
              <MenuItem value="Completed">
                {t("workout-program-upload-modal.status-completed")}
              </MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "24px",
            }}
          >
            <Input
              sx={{
                width: "100%",
                height: "56px",
                mb: "24px",
                color: `${Colors.gray[1400]}`,
              }}
              type="file"
              onChange={handleFileChange}
            />
          </Box>
          <LoadingButton
            sx={{
              width: "100%",
              height: "36px",
              background: `${Colors.blue[1200]}`,
              mt: "5px",
            }}
            size="large"
            loading={isLoading}
            disabled={isLoading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            <Typography
              sx={{
                textTransform: "none",
              }}
            >
              {t("workout-program-upload-modal.btn-text")}
            </Typography>
          </LoadingButton>
          {errorMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="error"
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              sx={{
                mt: 3,
                width: "100%",
              }}
              variant="outlined"
              severity="success"
            >
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export { UploadNewWorkoutProgramCsvModal };
