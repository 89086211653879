import { FC } from "react";
import { useTypedSelector } from "../../shared/stores";
import { userSelectors } from "../../shared/stores/user";
import { Sidebar } from "../../shared/ui";
import { Header } from "../../features";
import { Header as AdminHeader } from "../Dashboard/AdminDashboard/ui/Header";

interface LayoutProps {
  children: React.ReactNode | React.FC;
  isOpenSidebar: boolean;
  closeSidebar?: () => void;
  toggleSidebar: () => void;
  openSidebar: () => void;
}

const Layout: FC<LayoutProps> = ({
  children,
  isOpenSidebar,
  closeSidebar,
  toggleSidebar,
  openSidebar,
}) => {
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  return (
    <>
      <Sidebar
        openSidebar={openSidebar}
        isOpenSidebar={isOpenSidebar}
        closeSidebar={closeSidebar}
      />
      {isAdminOrCoach ? (
        <AdminHeader toggleSidebar={toggleSidebar} />
      ) : (
        <Header toggleSidebar={toggleSidebar} />
      )}
      {children}
    </>
  );
};

export { Layout };
