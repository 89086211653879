import { Routes } from "../../../../routers";

export const navData = [
  {
    id: 1,
    title: "Dashboard",
    content: "dashboard",
    icon: "home_outlined",
    link: `${Routes.dashboard.url}`,
    adminOnly: false,
    navId: "dashboard-nav",
  },
  {
    id: 2,
    title: "Clients",
    content: "clients",
    icon: "account_circle",
    link: `${Routes.clients.url}`,
    adminOnly: false,
    navId: "clients-nav",
  },
  {
    id: 3,
    title: "Notes",
    content: "user notes",
    icon: "summarize",
    link: `${Routes.userNotes.url}`,
    adminOnly: false,
    navId: "userNotes-nav",
  },
  {
    id: 4,
    title: "Programs",
    content: "programs",
    icon: "create_new_folder_outlined_icon",
    link: `${Routes.programs.url}`,
    adminOnly: false,
    navId: "programs-nav",
  },
  {
    id: 5,
    title: "Exercises",
    content: "exercise_library",
    icon: "fitness_center",
    link: `${Routes.exerciseLibrary.url}`,
    adminOnly: false,
  },
  {
    id: 6,
    title: "Live Training",
    content: "live training",
    icon: "live_tv",
    link: `${Routes.liveTraining.url}`,
    adminOnly: false,
  },
  {
    id: 7,
    title: "Timelines",
    content: "timeline",
    icon: "message",
    link: `${Routes.timeline.url}`,
    adminOnly: false,
  },
  {
    id: 8,
    title: "Billing",
    content: "billing",
    icon: "money",
    link: `${Routes.billing.url}`,
    adminOnly: false,
  },
  {
    id: 9,
    title: "Workflows",
    content: "workflows",
    icon: "settings_suggest",
    link: `${Routes.workflows.url}`,
    adminOnly: true,
  },
];

export const organisationSettings = [
  {
    id: 1,
    title: "Organisation",
    content: "organisation",
    icon: "settings",
    link: `${Routes.organisation.url}`,
  },
];

export const linksData = [
  {
    title: "Invite clients",
    icon: "add_circle_outlined",
  },
  {
    title: "Support Center",
    icon: "chat_outlined",
  },
];
