import { createApi } from "@reduxjs/toolkit/query/react";
import {
  UserInsightsEngineQuestionRequest,
  UserInsightsEngineQuestionResponse,
} from "./models";
import { baseQuery } from "../baseQuery";

export const userInsightsEngineApi = createApi({
  baseQuery,
  reducerPath: "userInsightsEngineApi",
  endpoints: (builder) => ({
    userInsightsEngineQuestion: builder.mutation<
      UserInsightsEngineQuestionResponse,
      UserInsightsEngineQuestionRequest
    >({
      query: ({ inputText, userId }) => ({
        url: "/user-insights-engine/question",
        method: "POST",
        body: {
          userId,
          inputText,
        },
      }),
    }),
  }),
});

export const { useUserInsightsEngineQuestionMutation } = userInsightsEngineApi;
