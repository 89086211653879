import { FC, useEffect, useState } from "react";
import { LiveTrainingSelectedWorkout } from "pages/LiveTraining/LiveTrainingSelectedWorkout";
import {
  useGetWorkoutForTimelineItemQuery,
  useGetWorkoutQuery,
} from "shared/api";
import { Box, Typography } from "@mui/material";
import { Colors } from "shared/themes";
import { addTimezoneToUtcDate } from "shared/helpers";

type TimelineWorkoutPreviewProps = {
  selectedUserId: number;
  timelineItemId: number;
  showWorkoutPreview: boolean;
};

const TimelineWorkoutPreview: FC<TimelineWorkoutPreviewProps> = ({
  selectedUserId,
  timelineItemId,
  showWorkoutPreview = false,
}) => {
  const {
    data: workout,
    isLoading,
    error,
  } = useGetWorkoutForTimelineItemQuery(timelineItemId, {
    skip: !showWorkoutPreview,
  });
  const completedDateString =
    workout?.dateTimeCompleted &&
    `- ${addTimezoneToUtcDate(workout.dateTimeCompleted).format(
      "DD/MM/YYYY hh:mm A"
    )}`;

  if (isLoading)
    return (
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "22px",
          letterSpacing: "0.16px",
          color: `${Colors.oxford[1100]}`,
        }}
      >
        Loading...
      </Typography>
    );

  if (error) {
    <Box
      sx={{
        border: `1px solid ${Colors.gray[400]}`,
        padding: 2,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "18px",
          letterSpacing: "0.16px",
          color: `${Colors.oxford[1100]}`,
        }}
      >
        Something went wrong. Please try again later.
      </Typography>
    </Box>;
  }

  return (
    <Box
      sx={{
        border: `1px solid ${Colors.gray[400]}`,
        padding: 2,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "18px",
          letterSpacing: "0.16px",
          color: `${Colors.oxford[1100]}`,
        }}
      >
        Week {workout?.weekNumber} - Session {workout?.dayNumber} -{" "}
        {workout?.workoutStatus} {completedDateString}
      </Typography>
      <LiveTrainingSelectedWorkout
        selectedUserId={selectedUserId}
        selectedWorkoutId={workout?.id ?? 0}
        handleProgressWorkout={() => {}}
      />
    </Box>
  );
};

export { TimelineWorkoutPreview };
